import {Component, Input, OnInit} from '@angular/core';
import {TenantDocumentStatistic, TenantService} from 'src/app/services/tenant.service';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';

@Component({
	selector: 'app-application-document-average',
	templateUrl: './application-document-average.component.html',
	styleUrls: ['./application-document-average.component.scss']
})
export class ApplicationDocumentAverageComponent implements OnInit {

	@Input() filter: Subject<Organization|null|undefined>;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	progress: number;
	percentage: number;
	totalDocument: number;

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private tenantService: TenantService) {
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getTenantDocumentStatistic(this.tenantId, org?.organizationId)),
				tap(data => this.setDocumentationStatistic(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	setDocumentationStatistic(statistic: TenantDocumentStatistic) {
		const chartRatio: number = 148 / 100;
		const reversedPercent: number = 100 - statistic.averageCompletion;
		this.progress = reversedPercent * chartRatio;
		this.percentage = statistic.averageCompletion;
		this.totalDocument = statistic.totalDocument;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}
