import {Component, OnInit} from "@angular/core";
import {TopbarService} from "src/app/services/front/topbar.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
	selector: 'app-diagrams',
	templateUrl: './diagrams.component.html',
	styleUrls: ['./diagrams.component.scss']
})
export class DiagramsComponent implements OnInit {

	constructor(private topbarService: TopbarService,
							private translate: TranslateService) {
	}

	ngOnInit() {
		this.topbarService.onTitleChange(
			this.translate.instant('menu.diagrams'),
			this.translate.instant('menu.subtitle.diagrams')
		);
	}
}
