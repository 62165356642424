import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AccountComponent} from "./account.component";
import {TranslateModule} from "@ngx-translate/core";
import {ReactiveFormsModule} from "@angular/forms";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
	declarations: [
		AccountComponent,
	],
	imports: [
		CommonModule,
		TranslateModule,
		MatDialogModule,
		ReactiveFormsModule,
		DesignSystemModule,
	],
	providers: [
	],
	exports: [
		AccountComponent
	]
})
export class AccountModule {
}
