import {Component, OnDestroy, OnInit} from '@angular/core';
import {TenantFinanceStatistic, TenantOverview, TenantService} from 'src/app/services/tenant.service';
import {TopbarService} from 'src/app/services/front/topbar.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Organization, OrganizationService, OrganizationTree} from 'src/app/services/organization.service';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {MiniWidget} from '../../global/mini-widget/mini-widget.component';
import {APPLICATIONS_URL, AppRouteUrl, SETTINGS_BILLING_URL} from 'src/app/models/home/navigation.model';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {TenantFinanceService} from 'src/app/services/back/tenant-finance.service';
import {ContractType} from 'src/app/services/model/application-contract.model';

@Component({
  selector: 'app-finance-dashboard',
  templateUrl: './finance-dashboard.component.html',
  styleUrls: ['./finance-dashboard.component.scss']
})
export class FinanceDashboardComponent implements OnInit, OnDestroy {

	tenant: TenantOverview;
	organizationTree: OrganizationTree;
	filter: Subject<Organization|null|undefined> = new Subject<Organization|null|undefined>();

	_initializing: boolean;
	_loading: boolean;
	_loadingOrganization: boolean;
	_contractCountLoading: boolean;

	noContracts: boolean;
	tcoTotalWidget: MiniWidget;
	tcoLicenseWidget: MiniWidget;
	tcoServiceWidget: MiniWidget;
	tcoPerUserWidget: MiniWidget;

	settingsBillingUrl: AppRouteUrl = SETTINGS_BILLING_URL;

	subscription: Subscription = new Subscription();

	constructor(private topBarService: TopbarService,
							private translateService: TranslateService,
							private currentTenantService: CurrentTenantService,
							public activatedRoute: ActivatedRoute,
							private organizationService: OrganizationService,
							private tenantFinanceService: TenantFinanceService,
							private tenantService: TenantService,
							private router: Router) {
	}

	ngOnInit() {
		this.setDefaultWidgetData();
		this.topBarService.onTitleChange(this.translateService.instant('menu.finance'), this.translateService.instant('menu.subtitle.finance'));
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(
				tap(tenant => this.tenant = tenant),
				switchMap(() => this.fetchOrganizationTree()))
			.subscribe(() => this.checkContractCountBeforeInitialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	private setDefaultWidgetData(): void {
		this.tcoTotalWidget = {
			numericSubject: 'page.dashboard.mini-widget.tco',
			iconUrl: 'assets/icons/tco-widget.svg',
			iconSize: 'm',
			type: 'money'
		};
		this.tcoLicenseWidget = {
			numericSubject: 'page.financeDashboard.licences',
			iconUrl: 'assets/icons/licences.svg',
			iconSize: 'l',
			type: 'money'
		};
		this.tcoServiceWidget = {
			numericSubject: 'page.financeDashboard.services',
			iconUrl: 'assets/icons/services.svg',
			iconSize:'l',
			type: 'money'
		};
		this.tcoPerUserWidget = {
			numericSubject: 'page.financeDashboard.perUser',
			iconUrl: 'assets/icons/users-widget.svg',
			iconSize:'m',
			type: 'money'
		};
	}

	private checkContractCountBeforeInitialize(): void {
		this._contractCountLoading = true;
		this.tenantFinanceService.getTenantContractCount(this.tenant.configuration.id).subscribe(result => {
			this.noContracts = result === 0;
			this._contractCountLoading = false;
			if (!this.noContracts) {
				this.initialize();
			}
		});
	}

	private fetchOrganizationTree(): Observable<OrganizationTree> {
		this._loadingOrganization = true;
		return this.organizationService.getOrganizationTreeByTenantId(this.tenant.configuration.id)
			.pipe(
				tap(organizationTree => this.organizationTree = organizationTree),
				tap(() => this._loadingOrganization = false));
	}

	initialize(org?: Organization|null): void {
		this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getTenantFinanceStatistic(this.tenant.configuration.id, org?.organizationId)),
				tap(data => this.setWidgetData(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private setWidgetData(statistic: TenantFinanceStatistic): void {
		this.tcoTotalWidget.numericIndicator = statistic.costByTypes
			.map(c => c.cost.value ?? 0)
			.reduce((c1, c2) => c1 + c2, 0);
		this.tcoLicenseWidget.numericIndicator = statistic.costByTypes
			.filter(c => c.contractType === ContractType.SUBSCRIPTION || c.contractType === ContractType.LICENSE)
			.map(c => c.cost.value ?? 0)
			.reduce((c1, c2) => c1 + c2, 0);
		this.tcoServiceWidget.numericIndicator = statistic.costByTypes
			.find(c => c.contractType === ContractType.SERVICE)!.cost.value;
		this.tcoPerUserWidget.numericIndicator = Math.round(this.tcoTotalWidget.numericIndicator / Math.max(statistic.usage.value ?? 0, 1));
	}

	goToApps(): Promise<boolean> {
		return this.router.navigate([APPLICATIONS_URL]);
	}

	refreshWithFilters(filter: Organization|null): void {
		this.filter.next(filter);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
