<div class="flex-column gap-S">
	<div class="flex-space-between w-100">
		<div class="font-MM fw-700">
			{{ 'page.appDetails.usage.histogram.title' | translate }}
		</div>
		<div *ngIf="application.usageActivated" class="flex-align-center gap-XS c-p" style="height: fit-content;"
             (click)="histogramRangeChange()">
			<div class="font-S fw-700 grey">
				{{ ('page.appDetails.usage.histogram.' + histogramRange) | translate}}
			</div>
			<img style="height: 8px; width: 11px;" class="filter-grey" src="assets/icons/card-arrow.svg"/>
		</div>
	</div>
	<div class="w-100" style="max-height: 216px; position: relative;"
		 (mouseout)="tooltip.display = 'none'">
		<div *ngIf="!application.usageActivated" class="flex-column flex-center grey gap-S" style="height: 216px;">
			<div class="flex-center grey">
				<img src="assets/icons/info-circle.svg">
			</div>
			<div class="text-center">
				{{'page.appDetails.usage.tooltipHistogramDeactivated1' | translate}}&nbsp;
				<a class="accent text-underline"
				   (click)="navigateToUsageParameter()">{{ 'page.appDetails.usage.settings' | translate }}</a>
				&nbsp;{{ 'page.appDetails.usage.tooltipHistogramDeactivated2' | translate }}
			</div>
		</div>
		<canvas *ngIf="application.usageActivated"
				[height]="216"
				baseChart
				[data]="barChartData"
				[type]="barChartType"
				[options]="barOptions"
		></canvas>
		<div class="tooltip" style="padding: 3px 7px; pointer-events: none;" [ngStyle]="{'top': tooltip.top, 'left': tooltip.left, 'display': tooltip.display}" >
			<div class="flex-space-between font-M fw-700">
				<div>
					<span>{{ tooltip.day }} {{ 'global.monthShort.' + tooltip.month | translate }} {{ tooltip.year }}</span>
				</div>
				<div>
					<span *ngIf="histogramRange == 'day'">{{tooltip.hour}}h00</span>
				</div>
			</div>
			<div class="font-S fw-700 lowercase">
				{{ tooltip.value }} {{'global.users' | translate}}
			</div>
		</div>
	</div>
</div>
