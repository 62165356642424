<div class="op-filter">
	<div class="filters pl-xl">
		<img src="assets/icons/building-black.svg">
		<div class="font-L fw-700 ml-s">
			<span *ngIf="organizationTree">{{ organizationTree.organization.name }}</span>
			<span *ngIf="!organizationTree">-</span>
		</div>
		<div style="margin-left: 60px;  width: 200px;">
			<app-multi-select class="w-100" [inputControl]="teamGroupFormControl">
				<app-select-trigger appearance="field">
					<span>{{ teamGroupFormValue?.name || 'page.organization.structure.allStructures' | translate }}</span>
				</app-select-trigger>
				<app-select-search [searchControl]="searchTeamGroupControl"></app-select-search>
				<app-select-option [value]="undefined">{{ 'page.organization.structure.allStructures' | translate }}</app-select-option>
				<app-select-option *ngFor="let teamGroup of filteredTeamGroups" [value]="teamGroup">
					<span>{{ teamGroup.name }}</span>
				</app-select-option>
			</app-multi-select>
		</div>
		<div style="margin-left: 20px;  width: 170px;">
			<app-multi-select class="w-100" [inputControl]="teamFormControl">
				<app-select-trigger appearance="field">
					<span>{{ teamFormValue?.name || 'page.organization.businessUnit.allBusinessUnits' | translate }}</span>
				</app-select-trigger>
				<app-select-search [searchControl]="searchTeamControl"></app-select-search>
				<app-select-option [value]="undefined">{{ 'page.organization.businessUnit.allBusinessUnits' | translate }}</app-select-option>
				<app-select-option *ngFor="let team of filteredTeams" [value]="team">
					<span>{{ team.name }}</span>
				</app-select-option>
			</app-multi-select>
		</div>
		<button *ngIf="teamFormValue || teamGroupFormValue" type="button" class="delete-filter ml-m" (click)="deleteFilters()" [disabled]="filtersLoading">
			{{ 'button.deleteFilters' | translate }}
		</button>
	</div>
	<div>
		<button type="button" class="refresh-btn disp-f a-i-c j-c-c" (click)="refresh(teamFormValue ? 'team' : 'team_group', teamFormValue ? teamFormValue : teamGroupFormValue)" [disabled]="filtersLoading">
			<img *ngIf="!filtersLoading" class="filter-white" src="assets/icons/refresh.svg">
			<div *ngIf="filtersLoading">
				<mat-spinner diameter="20"></mat-spinner>
			</div>
		</button>
	</div>
</div>
