import {Component, Input} from '@angular/core';
import {PerformanceService, ApplicationPerformance} from 'src/app/services/back/performance.service';
import {ApplicationDetailComponent, ApplicationDetailInput} from '../../applications/application-detail/application-detail.component';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {WorstPerformanceAppsDrawerComponent, WorstPerformanceAppsDrawerData} from 'src/app/modules/home/operation-dashboard/worst-performance-apps/drawer/worst-performance-apps-drawer.component';
import {ExportService, ExportType} from 'src/app/services/front/export.service';

@Component({
  selector: 'app-worst-performance-apps',
  templateUrl: './worst-performance-apps.component.html',
  styleUrl: './worst-performance-apps.component.scss'
})
export class WorstPerformanceAppsComponent {

	@Input() defaultFilter?: Organization|null|undefined;
	@Input() filter?: Subject<Organization|null|undefined>;
	@Input() search?: Observable<string|null|undefined>;
	@Input() export?: Subject<ExportType>;
	@Input() limit?: number;
	@Input() type: 'widget'|'drawer' = 'widget';

	tenantId: string;
	lastFilter?: Organization|null;

	_initializing: boolean;
	_loading: boolean;

	applications: ApplicationPerformance[] = [];
	filteredApplications: ApplicationPerformance[] = [];

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private rightSliderService: RightSliderService,
							private performanceService: PerformanceService,
							private exportService: ExportService) {
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize(this.defaultFilter)));
		if (this.filter) {
			this.subscription.add(this.filter
				.pipe(tap(filter => this.lastFilter = filter))
				.subscribe(filter => this.initialize(filter)));
		}
		if (this.search) {
			this.subscription.add(this.search
				.subscribe(search => this.filterApplications(search!)));
		}
		if (this.export) {
			this.subscription.add(this.export
				.subscribe(type => this.exportData(type)));
		}
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.performanceService.getAllWorstApplicationPerformance(this.tenantId, org?.organizationId, this.limit, QueryRangeType.PAST_1_MONTH)),
				tap(data => this.applications = data),
				tap(data => this.filteredApplications = data),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	filterApplications(search?: string|null): void {
		this.filteredApplications = this.applications
			.filter(app => !search || app.application.name.toLowerCase().includes(search.toLowerCase()));
	}

	goToApp(applicationInstanceId: string): void {
		const data: ApplicationDetailInput = {
			applicationId: applicationInstanceId
		};
		this.rightSliderService.openComponent(ApplicationDetailComponent, data)
			.subscribe(() => this.filter?.next(this.lastFilter));
	}

	openDrawer(): void {
		if (this.type === 'widget' && this.applications.length > 0) {
			const data: WorstPerformanceAppsDrawerData = {
				filter: this.lastFilter
			};
			this.rightSliderService.openComponent(WorstPerformanceAppsDrawerComponent, data);
		}
	}

	getFcpLcp(value: number|null): string {
		if (value) {
			return (value / 1000).toFixed(1).replace('.0', '').replace('.', ',') + 's'
		}
		return '-';
	}

	exportData(type: ExportType): void {
		const rows: ApplicationPerformanceCsv[] = this.filteredApplications.map(a => ({
			name: a.application.name,
			users: a.userCount.toString(),
			lcp: this.getFcpLcp(a.lcp),
			fcp: this.getFcpLcp(a.fcp)
		}));
		this.exportService.export(type, rows, 'application-performance');
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}

interface ApplicationPerformanceCsv {
	name: string;
	users: string;
	lcp: string;
	fcp: string;
}
