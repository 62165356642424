@if (_initializing || _loading) {
    <div class="border-box-full">
        <app-dashboard-page-loader></app-dashboard-page-loader>
    </div>
} @else {
<div class="pl-m pr-m pt-m pb-xl">
	<div class="flex-align-center mb-l">
        <span class="font-MM fw-700">{{'menu.infrastructure'|translate}}</span>
	</div>
	<div class="flex-align-center flex-space-between gap-M">
		<app-linear-gauge class="w-50" *ngFor="let stat of loadAndStorage" [percent]="stat.percent" [legend]="stat.legend"></app-linear-gauge>
	</div>
</div>
}
