<div class="flex-column gap-XS select-insert-expandable" [class.opened]="openForm">
    <div class="flex-space-between flex-align-center pad-0-XS c-p"
         (click)="openForm = !openForm">
        <span class="font-S fw-700 grey">{{titlePath|translate}}</span>
        <img src="assets/icons/arrow-right.svg" style="margin-right: 6px;" height="12px" alt=""
             [class.select-insert-icon-close]="!openForm"
             [class.select-insert-icon-open]="openForm"/>
    </div>
    <div class="flex-align-center">
        <app-text-input class="w-100" [input]="createForm"
                        (keydown.enter)="onCreate()"></app-text-input>
        <div class="flex-align-center flex-justify-end" style="width: 41px">
            <app-icon-button *ngIf="!saving" [img]="'assets/icons/add.svg'" [size]="'xs'" type="flat"
                             [disabled]="!formValid" [focused]="formValid" [panelClass]="'img-size-50'"
                             (click)="onCreate()">
            </app-icon-button>
            <mat-spinner *ngIf="saving" color="accent" [diameter]="16"></mat-spinner>
        </div>
    </div>
</div>
