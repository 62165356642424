import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationIconComponent } from './application-icon.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";



@NgModule({
    declarations: [
        ApplicationIconComponent
    ],
    exports: [
        ApplicationIconComponent
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ]
})
export class ApplicationIconModule { }
