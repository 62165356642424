<div>
	<div *ngIf="logo.url" class="flex justify-center a-i-c" style="border-radius: 8px;" [ngStyle]="{'background-color': logo.backgroundColor, 'height': tileHeight, 'width': tileWidth}">
		<img [src]="logo.url" style="object-fit: fill" [height]="height" [width]="width" [ngStyle]="{'max-width': width, 'max-height': height}"/>
	</div>
	<div *ngIf="!logo.url" class="flex justify-center a-i-c" style="border-radius: 8px;" [ngStyle]="{'background-color': logo.backgroundColor, 'height': tileHeight, 'width': tileWidth}">
		<div class="flex justify-center a-i-c" [ngStyle]="{'max-width': width, 'max-height': height}" style="padding: 3px;text-align: center;font-weight: 700;">
			<span [ngStyle]="{'cursor': cursor ? cursor : '', 'color': logo.fontColor, 'font-size': fontSize}">{{ logo.name }}</span>
		</div>
	</div>
</div>
