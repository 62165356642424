import {Component, EventEmitter, Input, signal, WritableSignal} from '@angular/core';

@Component({
  selector: 'app-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['../multi-select.component.scss']
})
export class SelectOptionComponent {

  @Input() value: any;
  @Input() disabled: boolean = false;

  selectChanges: EventEmitter<void> = new EventEmitter<void>();
  selected: WritableSignal<boolean> = signal(false);
  multiple: WritableSignal<boolean> = signal(false);
  prefix: WritableSignal<boolean> = signal(false);

  onSelect(): void {
    this.selected.update(s => !s);
    this.selectChanges.emit();
  }
}
