import {NgModule} from "@angular/core";
import {SimpleTextInputModule} from "../../../global/input/simple-text-input/simple-text-input.module";
import {CardModule} from "../../../global/cards/card/card.module";
import {CatalogComponent} from "./catalog.component";
import {CommonModule} from "@angular/common";
import {TextareaModule} from "../../../global/input/textarea/textarea.module";
import {CatalogApplicationService} from "src/app/services/back/catalog-application.service";
import {ChipsModule} from "../../../global/chips/chips/chips.module";
import {ApplicationIconModule} from "../../../global/application-icon/application-icon.module";
import {DeckContentLoaderModule} from "../../../global/title-content-loader/deck-content-loader.module";
import {MatRippleModule} from "@angular/material/core";
import {TranslateModule} from "@ngx-translate/core";
import {CheckModule} from "../../../global/check/check.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
  imports: [
    SimpleTextInputModule,
    CardModule,
    CommonModule,
    TextareaModule,
    ChipsModule,
    ApplicationIconModule,
    DeckContentLoaderModule,
    MatRippleModule,
    TranslateModule,
    CheckModule,
    DesignSystemModule,
  ],
	declarations: [
		CatalogComponent
	],
	providers: [
		CatalogApplicationService
	]
})


export class CatalogModule {}
