import {Component, EventEmitter, Input, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {CreateTenant, TenantService} from "../../../../services/tenant.service";
import {tap} from "rxjs/operators";
import {finalize, Observable, of, Subscription, switchMap} from "rxjs";
import {CurrentTenantService} from "../../../../services/front/current-tenant.service";
import {Router} from "@angular/router";
import {HOME_URL} from "../../../../models/home/navigation.model";

@Component({
  selector: 'app-onboarding-step-3',
  standalone: true,
  imports: [CommonModule, DesignSystemModule, TranslateModule],
  templateUrl: './onboarding-step-3.component.html',
	styleUrl: '../onboarding.component.scss'
})
export class OnboardingStep3Component {
	constructor(private breakpointObserver: BreakpointObserver,
				private tenantService: TenantService,
				private currentTenantService: CurrentTenantService,
				private router: Router) {
	}

	form: FormGroup;

	height: number = 60;
	panelClass: string = '';
	panelClassInput: string = 'little-input';
	fontSize: 'S' | 'L' = 'L';

	@Input() secondStepForm: FormGroup;
	@Input() stepCount: number = 6;
	@Input() finalStep: boolean = false;
	@Input() canClose: boolean = false;
	@Input() isMobile: boolean;

	@Output() onNextClick = new EventEmitter<string>();

	@Output() onComplete = new EventEmitter<string>();

	subscriptions: Subscription = new Subscription();

	_loading = false;

	ngOnInit() {
		this.subscriptions.add(this.breakpointObserver
			.observe(['(max-width: 1280px)'])
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
					this.panelClass = ''
					this.panelClassInput = 'little-input'
					this.height = 35;
					this.fontSize = 'S'
				} else {
					this.height = 60;
					this.panelClass = 'big-button'
					this.panelClassInput = 'big-input'
					this.fontSize = 'L'
				}
			}))
		this.createForm();
	}

	createForm() {
		this.form = new FormGroup({
			'name': new FormControl(undefined, [Validators.required]),
			'size': new FormControl('xs'),
			'activity': new FormControl(undefined),
		});
	}

	submitForm() {
		if (this.form.valid) {
			const request: CreateTenant = {
				name: this.form.get('name')!.value,
				organizationEmployees: this.form.get('size')?.value,
				organizationSector: this.form.get('activity')?.value,
				accountExpectations: this.secondStepForm.get('objectives')?.value,
				userType: this.secondStepForm.get('userKind')?.value,
				companyType: this.secondStepForm.get('workPlace')?.value,
				onboardingCompleted: this.stepCount === 3
			};

			if (!this.finalStep) {
				this.subscriptions.add(this.switchLoading()
					.pipe(
						switchMap(() => this.tenantService.createTenant(request)),
						tap((tenantId) => this.currentTenantService.initialize(tenantId, false)),
						finalize(() => this.switchLoading()))
					.subscribe(() => {
						this.onNextClick.emit('Step 4')
					}));
			} else {
				this.subscriptions.add(this.switchLoading()
					.pipe(
						switchMap(() => this.tenantService.createTenant(request)))
					.subscribe((tenantId) => {
						this.onComplete.emit(tenantId)
					}));
			}

		}
	}

	goBackHome() {
		return this.router.navigate([HOME_URL])
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}
}
