import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {TopbarService} from 'src/app/services/front/topbar.service';
import {TenantOverview, TenantService} from 'src/app/services/tenant.service';
import {Subscription} from 'rxjs';
import {Notification, NotificationService, NotificationType,} from "src/app/services/notification.service";
import {ApplicationDetailComponent, ApplicationDetailInput} from "../applications/application-detail/application-detail.component";
import {RightSliderService} from "src/app/services/front/right-slider.service";
import {
	APPLICATIONS_URL,
	LOGIN_URL,
	SETTINGS_ACCOUNT_URL
} from "src/app/models/home/navigation.model";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {TenantAccount} from 'src/app/services/model/account.model';

@Component({
	selector: 'app-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {

	@ViewChild('accountToggle') accountToggle: ElementRef;
	@ViewChild('notificationToggle') notificationToggle: ElementRef;
	@ViewChild('accountMenu') accountMenu: ElementRef;
	@ViewChild('notificationMenu') notificationMenu: ElementRef;

	isAccountMenuOpen: boolean = false;
	isNotificationMenuOpen: boolean = false;

	end = 2;

	settingsAccountUrl = SETTINGS_ACCOUNT_URL;

	notifications: Notification[];
	unread = 0;

	currentUser: TenantAccount;
	tenant: TenantOverview;

	protected subscription = new Subscription();

	constructor(protected router: Router,
	            protected authenticationService: AuthenticationService,
	            public translateService: TranslateService,
	            public activatedRoute: ActivatedRoute,
	            protected currentTenantService: CurrentTenantService,
	            protected tenantService: TenantService,
							protected topbarService: TopbarService,
							private renderer: Renderer2,
							private notificationService: NotificationService,
							private rightSliderService: RightSliderService) {

		this.renderer.listen('window', 'click',(e:Event)=>{
			if(e.target !== this.accountToggle.nativeElement && e.target!==this.accountMenu.nativeElement && !this.accountToggle.nativeElement.contains(e.target) && !this.accountMenu.nativeElement.contains(e.target)){
				this.isAccountMenuOpen=false;
			}
			if(e.target !== this.notificationToggle.nativeElement && e.target!==this.notificationMenu.nativeElement && !this.notificationToggle.nativeElement.contains(e.target) && !this.notificationMenu.nativeElement.contains(e.target)){
				this.isNotificationMenuOpen=false;
			}
		});
	}

	toggleAccountMenu() {
		this.isAccountMenuOpen = !this.isAccountMenuOpen;
	}

	toggleNotificationMenu() {
		this.isNotificationMenuOpen = !this.isNotificationMenuOpen;
	}

	get tenants(): Array<TenantOverview> {
		return this.currentTenantService.getTenants().filter(t => t.configuration.id !== this.tenant.configuration.id) || [];
	};

	ngOnInit() {
		this.subscription.add(this.topbarService.getTitle().subscribe(title => this.title = title));
		this.subscription.add(this.topbarService.getSubtitle().subscribe(subtitle => this.subtitle = subtitle));
		this.subscription.add(this.topbarService.getIndicatorWithOtherNameThanIndicatorBecauseITriedToBeConsistentWithOldCodeButSonarDontLikeItSoIRenamedThisMethodToMakeHimHappy().subscribe(indicator => this.indicator = indicator));

		this.subscription.add(this.currentTenantService.getCurrentTenantChanges().subscribe((tenant: TenantOverview) => {
			this.tenant = tenant;

			this.getNotifications(tenant.configuration.id);

			this.notificationService.recheckNotificationsEvent.subscribe(() => {
				this.getNotifications(tenant.configuration.id);
			});
		}));
		this.subscription.add(this.currentTenantService.getTenantAccountChanges().subscribe(currentUser => {
			this.currentUser = currentUser;
		}));
	}

	getNotifications(tenantId: string) {
		this.unread = 0;
		this.notificationService.getNotifications(tenantId).subscribe(notifications => {
			this.notifications = notifications.notifications
				.filter(n => n.type !== NotificationType.COMMENT_ADDED)
				.map(n => {
					if (n.status === 'unread') {
						this.unread++;
					}
					n.date = new Date(n.date);
					return n;
				})
		})
	}

	public title: string;
	public subtitle: string;
	public indicator: string|undefined;

	logout(): void {
		this.authenticationService.logout();
		this.router.navigate([LOGIN_URL]);
	}

	onCreateNewTenant() {
		this.router.navigate(['/new-tenant']);
	}

	selectTenant(tenant: TenantOverview) {
		this.currentTenantService.changeTenant(tenant.configuration.id);
		this.isAccountMenuOpen = false;
	}

	redirectToNotificationEntity(notification: Notification) {
		switch (notification.type) {
			case NotificationType.COMMENT_ADDED:
			case NotificationType.USER_TAGGED_IN_COMMENT:
				const data: ApplicationDetailInput = {
					applicationId: notification.applicationId!
				};
				this.router.navigate([APPLICATIONS_URL], {queryParams: {applicationId: notification.applicationId, comments: 1}, queryParamsHandling: "merge"})
					.then(() => this.rightSliderService.openComponent(ApplicationDetailComponent, data));
				break;
			default:
				break;
		}
		//this.router.navigate([notification.entityLink]);
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
