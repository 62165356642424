import {Component, Input} from '@angular/core';
import {Health, HealthStatus} from 'src/app/services/model/new-application.model';

@Component({
  selector: 'app-chips-health',
  templateUrl: './chips-health.component.html',
  styleUrls: ['./chips-health.component.scss']
})
export class ChipsHealthComponent {

  @Input() health: Health|null|undefined;

  get color(): string {
    switch (this.health?.status) {
      case HealthStatus.GOOD: return 'success';
      case HealthStatus.NEEDS_IMPROVEMENT: return 'warning';
      case HealthStatus.POOR: return 'danger';
      default: return 'grey';
    }
  }
}
