<app-snackbar></app-snackbar>
<div class="flex-column gap-XXL pad-XL-0 pad-0-L main-container overflow-hidden">
	<div class="flex gap-XXL w-100">
		<div class="f3 flex-align-center flex-space-between flex-wrap pad-XL home-widget overflow-hidden">
			<div class="square-bg sq-1"></div>
			<div class="square-bg sq-2"></div>
			<div class="square-bg sq-3"></div>
			<div class="square-bg sq-4"></div>
			<div class="square-bg sq-5"></div>
			<div class="square-bg sq-6"></div>
			<div class="f3 flex-column flex-space-between h-100" style="min-width: 300px;">
                <span class="font-XXL fw-500 white">{{'page.home.homepage.hello' | translate}}<span class="fw-700 capitalize">{{!userAccount ? '' : userAccount.firstName + '.'}}</span></span>
                <span class="font-LL fw-500 white l-h-n">{{'page.home.homepage.kabeenPresentation' | translate}}</span>
                <span class="font-LL fw-500 white l-h-n" [style.visibility]="unread > 0 ? 'visible' : 'hidden'">{{ 'page.home.youHaveReceived' | translate }} <span class="text-underline">{{ unread }} notification(s)</span> {{ 'page.home.duringYourAbsence' | translate }}</span>
			</div>
            <div class="f2 flex-center h-100">
                <img src="assets/illustrations/home.svg" alt="">
            </div>
		</div>
		<div class="f2 flex-column gap-L" style="min-width: 500px;">
			<div class="flex-space-between flex-align-center w-100 mt-m">
				<div class="font-L fw-700">{{'page.home.homepage.history.lastActions' | translate}}</div>
				<div class="flex-align-center c-p" (click)="onOpenHistoryDrawer()">
					<div class="font-M fw-500 grey mr-m" style="margin-top: 1px">{{'page.home.homepage.history.displayHistory' | translate}}</div>
					<div>
						<img src="assets/icons/arrow-right-black.svg" class="filter-grey icon-14">
					</div>
				</div>
			</div>
            <div *ngIf="loading" style="margin-bottom: auto;">
                <app-home-page-history-loader></app-home-page-history-loader>
            </div>
            <div *ngIf="!loading" class="flex-column gap-L">
                <div *ngFor="let action of logs" class="flex-align-center flex-space-between log-row c-p"
                     (click)="goToApp(action.application.id)">
                    <app-display-logo [logo]="action.application.logo" [size]="38"></app-display-logo>
                    <div class="flex-wrap w-100 log-text">
                        <span class="font-M fw-700">{{action.application.name}}</span>
                        <span class="font-M fw-500">{{(action.actionType === 'create' ? 'page.home.homepage.history.createdBy' : 'page.home.homepage.history.updatedBy') | translate}}</span>
                        <span class="font-M fw-700">{{(!action.user ? 'Kabeen' : action.user!.account.firstName + ' ' + action.user!.account.lastName)}}</span>
                    </div>
                    <div class="log-date flex-justify-end">
                        <span class="font-M fw-500">{{getDateGap(action.creationDate)}}</span>
                    </div>
                </div>
                <div *ngIf="logs.length == 0" style="height: 291px" class="flex-center">
					<span class="font-S fw-500 grey w-100 text-center">{{'page.home.homepage.history.noHistory' | translate}}</span>
				</div>
            </div>
		</div>
	</div>

	<div class="flex-column gap-S">
		<div class="font-LL fw-700">{{ 'page.home.homepage.fastAction.sectionTitle' | translate}}</div>
		<div class="fast-action-carousel pad-0-XL over-padding">
			<div class="fast-action-carousel-wrapper pad-top-S pad-right-XL gap-XL">
				<div *ngFor="let action of fastActions" class="bg-rounded flex-column pad-L c-p fast-action"
					 (click)="openFastActionDrawer(action.id)">
					<div class="flex-center bkg-accent-light radius-10" style="height: 45px !important; width: 45px !important;">
						<img [src]="action.icon" class="svg-24 filter-accent">
					</div>
					<div class="font-L fw-700 pt-xs mt-m mb-s">
						{{ 'page.home.homepage.fastAction.title.' + action.id | translate}}
					</div>
					<div class="font-M fw-500 grey l-h-n" style="white-space: pre-line;">
						{{ 'page.home.homepage.fastAction.description.' + action.id | translate}}
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="p-relative pad-0-XL over-padding" (mouseenter)="hovered = true" (mouseleave)="hovered = false">
		<div *ngIf="leftScroll && hovered" class="scroll-carousel-left" (click)="goLeft()">
			<img ngSrc="assets/icons/arrow-right-black.svg" height="18" width="12" class="filter-white white-arrow-left" alt="">
		</div>
		<div *ngIf="rightScroll && hovered" class="scroll-carousel-right" (click)="goRight()">
			<img ngSrc="assets/icons/arrow-right-black.svg" height="18" width="12" class="filter-white white-arrow-right" alt="">
		</div>
		<div class="flex-column gap-L" *ngFor="let carousel of tipCarousels">
			<div class="font-LL fw-700">{{'page.home.homepage.carousel.' + carousel.title | translate}}</div>
			<div class="p-relative">
				<div #carouselElt class="tip-carousel pad-left-XL over-padding" (scroll)="onScroll($event)">
					<div class="tip-carousel-wrapper gap-XL pr-l pb-xl">
						<div *ngFor="let tip of carousel.cards; let i = index" class="tip-card c-p" (click)="onOpenTipDrawer(carousel, i)">
							<div class="tip-card-illustration">
								<img [src]="tip.picture">
							</div>
							<div class="tip-card-legend pad-L">
								<div class="font-L fw-700">{{'page.home.homepage.tip.title.' + tip.id | translate}}</div>
								<div class="font-M fw-500 l-h-n grey">{{'page.home.homepage.tip.description.' + tip.id |translate}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
