import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ContentLoaderComponent} from '@ngneat/content-loader';


@Component({
	selector: 'app-list-loader',
	template: `
    <content-loader [viewBox]="'0 0 400 180'" >
		<svg:rect *ngFor="let row of [].constructor(rowCount); let i = index" x="0" [attr.y]=" i === 0 ? 0 : (marginTop + rowStep) * i " width="400" height="40" rx="10" />
	</content-loader>
  `,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListLoaderComponent extends ContentLoaderComponent {
	@Input() rowCount = 4;
	marginTop = 5;
	rowStep = 40;
}
