<div class="add-url-to-app-modal-container">
	<div class="sub-container h-100">
		<div class="w-100 flex-align-center flex-justify-end">
			<app-icon-button (click)="dismiss()" img='assets/icons/close.svg' size='m' color='transparent' type='flat' shape='default' [disabled]='false' [loading]='false' [focused]='false' [background]='false' panelClass='img-size-30'></app-icon-button>
		</div>
		<div class="flex-column flex-align-center h-100">
			<div class="flex-column flex-align-center white">
				<div class="connect-apps-header flex-center mb-xxl" style="margin-top: 35px;">
					<img ngSrc="assets/icons/link.svg" class="filter-accent-secondary" height="30" alt="" width="30">
				</div>
				<span class="fw-700 font-LL mb-xl">{{ 'page.appDetails.addUrl.title' | translate }}</span>
				<span class="text-center w-95 font-MM l-h-n mb-xxl" style="line-height: 20px;">
					{{ 'page.appDetails.addUrl.subtitle' | translate }}
				</span>
				<div class="mb-xxl w-95">
					<app-text-input [input]="url" [panelClass]="'input-transparent'" [placeholder]="'https://www.new-app.com'"></app-text-input>
					<span *ngIf="error" class="w-100 text-right display-block text-red">{{ error }}</span>
				</div>
			</div>
			<div class="w-95 flex-column gap-S">
				<app-text-button [loading]="_loading || _initializing" (click)="submit()" [disabled]="url.invalid" [type]="'flat'" [color]="'accent-secondary-inverted'">{{ 'page.appDetails.addUrl.submit' | translate }}</app-text-button>
				<app-text-button [color]="'accent-secondary-inverted'" [type]="'default'" (click)="dismiss()">{{ 'page.appDetails.addUrl.later' | translate }}</app-text-button>
			</div>
		</div>
	</div>
</div>
