import {Component, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DesignSystemModule} from "../../../../design-system/design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {animate, style, transition, trigger} from "@angular/animations";
import {CopyTextModule} from "../../../../global/input/copy-text/copy-text.module";
import {SnackbarModule} from "../../../../global/snackbar/snackbar.module";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {ApikeyService} from "src/app/services/apikey.service";
import {MatDialogRef} from "@angular/material/dialog";
import {AddServerManuallyComponent} from "./add-server-manually/add-server-manually.component";
import {AddServerAutomaticallyComponent} from "./add-server-automatically/add-server-automatically.component";

@Component({
	selector: 'app-add-server',
	standalone: true,
	imports: [CommonModule, DesignSystemModule, TranslateModule, NgOptimizedImage, CopyTextModule, SnackbarModule, AddServerManuallyComponent, AddServerAutomaticallyComponent],
	templateUrl: './add-server.component.html',
	styleUrl: './add-server.component.scss',
	animations: [
		trigger(
			'inAnimation1',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(100%)' }),
						animate('300ms 0ms cubic-bezier(.47,1.64,.41,.8)',
							style({ transform: 'translateY(0)'}))
					]
				),
			]
		),
		trigger(
			'inAnimation2',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(100%)' }),
						animate('300ms 100ms cubic-bezier(.47,1.64,.41,.8)',
							style({ transform: 'translateY(0)'}))
					]
				),
			]
		),
		trigger(
			'inAnimation3',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(300%)' }),
						animate('300ms 200ms cubic-bezier(.47,1.64,.41,.8)',
							style({ transform: 'translateY(0)'}))
					]
				),
			]
		)
	],
})
export class AddServerComponent implements OnInit {
	step: number = 1;
	apiKey: string;

	install?: 'automatic' | 'manual' = undefined;

	constructor(private currentTenantService: CurrentTenantService,
				private apiKeyService: ApikeyService,
				private dialogRef: MatDialogRef<AddServerComponent>) { }

	ngOnInit() {
		this.currentTenantService.getCurrentTenantIdChanges().subscribe(tenantId => {
			this.apiKeyService.getDefaultApiKey(tenantId).subscribe(apiKey => {
				this.apiKey = apiKey.apiKey.value;
			});
		});
	}

	closeDialog() {
		this.dialogRef.close(false);
	}

	onAddManualServer() {
		this.dialogRef.close(true);
	}

	protected readonly close = close;
}
