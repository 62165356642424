import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SecurityUtils} from './security.utils';
import {ApiKey} from '../models/home/global/apikey.interface';

@Injectable()
export class ApikeyService {

	constructor(protected httpClient: HttpClient) {

	}

	getDefaultApiKey(tenantId:String) {
		return this.httpClient.get<{apiKey: ApiKey}>(environment.engineURL + '/tenants/' + tenantId + '/apikeys/default', {
			headers: SecurityUtils.getAuthHttpHeaders()
		});

	}
}
