import {DestroyRef, Injectable} from "@angular/core";
import {BehaviorSubject, forkJoin, mergeMap, Observable, of, skip} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {UserPreferenceService} from "src/app/services/front/user-preference.service";
import {CurrentTenantService} from "./current-tenant.service";
import {AuthenticationService} from "../authentication.service";

@Injectable({
	providedIn: 'root'
})
export class MenuStateService {

	private static readonly OPENED_MENU_WIDTH = 250;
	private static readonly CLOSED_MENU_WIDTH = 90;

	private _state: MenuState = {
		open: true,
		width: MenuStateService.OPENED_MENU_WIDTH
	};

	private _stateChange: BehaviorSubject<MenuState>;

	constructor(
		private destroyRef: DestroyRef,
		private currentTenantService: CurrentTenantService,
		private authenticationService: AuthenticationService,
		private userPreferenceService: UserPreferenceService
	) {
		this._stateChange = new BehaviorSubject<MenuState>(this._state);

		// Check user prefrences to set the menu state
		this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(tenantId => {
				const userId = this.authenticationService.getUserInfo()?.id;
				if (userId) {
					const pref = this.userPreferenceService.getUserPreferences(tenantId, userId)
					if (pref && pref.menuPreference) {
						if (pref.menuPreference.isExpanded) {
							this._stateChange.next({open: true, width: MenuStateService.OPENED_MENU_WIDTH})
						} else {
							this._stateChange.next({open: false, width: MenuStateService.CLOSED_MENU_WIDTH})
						}
					}
				}
			});

		// Save the menu state in user preferences
		this._stateChange.pipe(
			// Skip initial state
			skip(1),
			mergeMap(state => forkJoin([of(state), this.currentTenantService.getCurrentTenantId()]))
		).subscribe(([state, tenantId]) => {
			const userId = this.authenticationService.getUserInfo()?.id;
			if (userId) {
				const pref =  { menuPreference: { isExpanded: state.open } }
				this.userPreferenceService.setUserPreferences(tenantId, userId, pref);
			}
		});
	}

	openMenu() {
		this._state = {
			open: true,
			width: MenuStateService.OPENED_MENU_WIDTH
		}
		this._stateChange.next(this._state);
	}

	closeMenu() {
		this._state = {
			open: false,
			width: MenuStateService.CLOSED_MENU_WIDTH
		}
		this._stateChange.next(this._state);
	}

	onMenuStateChange(): Observable<MenuState> {
		return this._stateChange.asObservable().pipe(takeUntilDestroyed(this.destroyRef))
	}
}

export interface MenuState {
	open: boolean;
	width: number;
}
