<div *ngIf="tip != undefined" class="h-100">
	<div  class="pl-xl pr-xl pt-l pb-l slider-header">
		<div class="disp-f j-c-s a-i-c w-100">
			<div>
				<div class="disp-f a-i-c j-c-c col bkg-accent-light radius-10" style="height: 45px; width: 45px">
					<img src="assets/icons/lightbulb.svg" class="svg-24 filter-accent">
				</div>
			</div>
			<div class="ml-s flex-column gap-XS">
				<div class="font-L fw-700" style="display:inline-block;overflow: hidden;">{{'page.home.homepage.tip.title.' + tip.id | translate}}</div>
				<div class="font-M fw-300" >{{'page.home.homepage.tip.subTitle.' + tip.id | translate}}</div>
			</div>
		</div>

		<div class="flex-align-center gap-S">
			<app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
							 (click)="close()">
			</app-icon-button>
		</div>
	</div>


	<div class="pt-xl pr-xl pl-xl pb-xl disp-f f-d-col j-c-sb drawer-content">
		<div>
			<div *ngIf="url != undefined" class="video-wrapper" style="position: relative; padding-bottom: 56.25%; height: 0;"><iframe [src]="url" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border-radius: 15px"></iframe></div>

			<div class="pt-xl">
				<div class="font-LL fw-700">{{'page.home.homepage.tip.title.' + tip.id | translate}}</div>
				<div class="font-M fw-500 pt-l l-h-n">{{'page.home.homepage.tip.content.' + tip.id | translate}}</div>
			</div>
			<div class="pt-xl">
				<div class="font-M fw-700">{{'page.home.homepage.tip.whatYouLearn' | translate}}</div>
				<ul class="tip-list">
					<li *ngFor="let elt of list" class="font-M fw-500">
						{{ 'page.home.homepage.tip.learn.' + tip.id + '.' + elt | translate }}
					</li>
				</ul>
			</div>
		</div>
		<div class="disp-f a-i-c gap-L f-d-col w-100 pt-l pb-l">
			<app-text-button style="width: 220px" type="flat" class="w-100"
                             [disabled]="index + 1 >= data.carousel.cards.length"
                             (click)="next()">
				{{'page.home.homepage.nextTip' | translate}}
			</app-text-button>
			<app-text-button style="width: 220px" type="stroked" class="w-100"
                             [disabled]="tip.documentation == undefined"
							 (click)="openDoc(tip.documentation)">
				{{'page.home.homepage.documentation' | translate}}
			</app-text-button>
		</div>
	</div>
</div>
