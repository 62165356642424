<div class="flex-column gap-XS">
	<div class="flex-space-between flex-align-end" [style.font-weight]="fontWeight">
		<span [class]="'font-' + size">{{label|translate}}</span>
		<span [style.font-size]="indicatorFontSize" [class]="indicatorColorClass + ' font-' + size">{{indicatorLabel}}</span>
	</div>
	<div class="bkg-light-grey-3 radius-S overflow-hidden" [style.height]="progressBarHeight">
		<div class="radius-S h-100"
			 [style.width]="progressBarWidth"
			 [class]="'bkg-' + indicatorColorClass">
		</div>
	</div>
</div>
