import {Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {MatRippleModule} from "@angular/material/core";
import {RightSliderService} from "src/app/services/front/right-slider.service";
import {ApplicationDetailComponent} from "../../applications/application-detail/application-detail.component";
import {TranslateModule} from "@ngx-translate/core";

@Component({
	selector: 'diagram-toolbar',
	standalone: true,
	template: `
        <div class="diagram-toolbar-container" [style.top]="positionTop + 'px'" [style.left]="positionLeft + 'px'">
            <div class="diagram-toolbar-arrow"></div>
            <div class="diagram-toolbar flex-align-center gap-M pad-0-XS">
							<div class="flex-align-center gap-XS c-p" matRipple
									 (click)="openApplication()">
								<img alt="open application" src="assets/icons/open.svg"/>
								<span class="font-M fw-500">{{ 'page.diagram.openApp' | translate}}</span>
							</div>
							<div class="border-grey-left" style="height: 10px;"></div>
							<div class="flex-align-center gap-XS c-p" matRipple
									 (click)="focusApplication()">
								<img alt="focus" src="assets/icons/grid-03.svg"/>
								<span class="font-M fw-500">{{ 'page.diagram.focus' | translate}}</span>
							</div>
            </div>
        </div>
	`,
	imports: [
		MatRippleModule,
		TranslateModule
	],
	styles: [`
		.diagram-toolbar-container {
			position: absolute;
			pointer-events: none;
		}

		.diagram-toolbar {
			position: relative;
			user-select: none;
			margin-left: -50%;
			background-color: white;
			border: 1px solid #F2F2F7;
			padding: 8px;
			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
			border-radius: 8px;
			z-index: 1;
			width: fit-content;
			margin-top: -11px;
			pointer-events: all;
		}

		.diagram-toolbar-arrow {
			z-index: 2;
			border-bottom : 10px solid transparent;
			border-left : 10px solid white;
			position:relative;
			top:0;
			rotate: 45deg;
			display : inline-block;
			height : 0;
			width : 0;
			margin-top: 10px;
			margin-left: -2px;
		}
	`]
})
export class DiagramToolbarComponent implements OnChanges {
	@Input() positionTop: number = 0;
	@Input() positionLeft: number = 0;
	@Input() applicationId: string|undefined;

	@Output() onClose: EventEmitter<void> = new EventEmitter<void>();
	@Output() focus: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private elementRef: ElementRef,
		private rightSliderService: RightSliderService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.postionTop) {
			this.positionTop = changes.positionTop.currentValue;
		}

		if (changes.positionLeft) {
			this.positionLeft = changes.positionLeft.currentValue;
		}

		if (changes.applicationId) {
			this.applicationId = changes.applicationId.currentValue;
		}
	}

	openApplication() {
		if (this.applicationId) {
			this.rightSliderService.openComponent(ApplicationDetailComponent, { applicationId: this.applicationId });
		}
		this.onClose.emit();
	}

	focusApplication() {
		this.focus.emit(this.applicationId);
		this.onClose.emit();
	}

	@HostListener('document:click', ['$event'])
	onGlobalClick(event: any): void {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.onClose.emit();
		}
	}
}
