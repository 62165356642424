import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationContractForm, ApplicationContractLicenseForm, ApplicationContractServiceForm, ApplicationContractSubscriptionForm, ContractInfo, ContractType, Currency, DurationOfCommitment, DurationOfUse, DurationUnit, NoticePeriod, ServiceType} from 'src/app/services/model/application-contract.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, of, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApplicationContractService} from 'src/app/services/back/application-contract.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {OrganizationTree} from 'src/app/services/organization.service';

@Component({
  selector: 'app-application-contract-form-dialog',
  templateUrl: './application-contract-form-dialog.component.html',
  styleUrls: ['./application-contract-form-dialog.component.scss']
})
export class ApplicationContractFormDialogComponent implements OnInit {

	tenantId: string;

	_saving: boolean;
	_uploading: boolean;

	contractForm: FormGroup;

	tabIndex: 0|1|2 = 0;
	typeChoices: ContractTypeChoice[] = [];
	noticeChoices: NoticeChoice[] = [];
	types: typeof ContractType = ContractType;
	form: typeof Form = Form;

	constructor(@Inject(MAT_DIALOG_DATA) public data: ContractFormDialogData,
							public dialog: MatDialogRef<ApplicationContractFormDialogComponent>,
							public applicationContractService: ApplicationContractService,
							public currentTenantService: CurrentTenantService,
							public translate: TranslateService) {
	}

	ngOnInit() {
		this.buildForm();
		this.buildSelectionLists();
		this.setDefaultData();
		this.currentTenantService.getCurrentTenantId()
			.subscribe(tenantId => this.tenantId = tenantId);
	}

	private buildForm(): void {
		this.contractForm = new FormGroup({
			[Form.type]: new FormControl(undefined, Validators.required),
			[Form.amount]: new FormControl(undefined, Validators.min(0)),
			[Form.startDate]: new FormControl(undefined, Validators.required),
			[Form.endDate]: new FormControl(undefined),
			[Form.paymentDurationValue]: new FormControl(undefined, Validators.min(1)),
			[Form.paymentDurationUnit]: new FormControl(undefined, Validators.required),
			[Form.isRecurringPayment]: new FormControl(undefined, Validators.required),
			[Form.serviceType]: new FormControl(undefined),
			[Form.durationOfUse]: new FormControl(undefined),
			[Form.durationOfCommitment]: new FormControl(undefined),
			[Form.noticePeriod]: new FormControl(undefined),
			[Form.licensedUsers]: new FormControl(undefined, Validators.min(1)),
			[Form.comment]: new FormControl(undefined),
			[Form.organizationIds]: new FormControl([]),
			[Form.fileLinkIds]: new FormControl([]),
		});
	}

	private buildSelectionLists(): void {
		this.typeChoices = [
			{id: ContractType.LICENSE, name: this.translate.instant('page.appDetails.finance.contracts.licence')},
			{id: ContractType.SERVICE, name: this.translate.instant('page.appDetails.finance.contracts.service')},
			{id: ContractType.SUBSCRIPTION, name: this.translate.instant('page.appDetails.finance.contracts.subscription')},
		];
		this.noticeChoices = [
			{id: NoticePeriod.ONE_MONTH, name: '1 ' + this.translate.instant('page.appDetails.finance.contracts.monthLittle')},
			{id: NoticePeriod.TWO_MONTHS, name: '2 ' + this.translate.instant('page.appDetails.finance.contracts.monthLittlePlural')},
			{id: NoticePeriod.THREE_MONTHS, name: '3 ' + this.translate.instant('page.appDetails.finance.contracts.monthLittlePlural')},
			{id: NoticePeriod.SIX_MONTHS, name: '6 ' + this.translate.instant('page.appDetails.finance.contracts.monthLittlePlural')},
			{id: NoticePeriod.NINE_MONTHS, name: '9 ' + this.translate.instant('page.appDetails.finance.contracts.monthLittlePlural')},
			{id: NoticePeriod.TWELVE_MONTHS, name: '12 ' + this.translate.instant('page.appDetails.finance.contracts.monthLittlePlural')}
		];
	}

	private setDefaultData(): void {
		if (this.data.info) {
			this.contractForm.get(Form.type)!.setValue(this.typeChoices.find(t => t.id === this.data.info!.type));
			this.contractForm.get(Form.noticePeriod)!.setValue(this.noticeChoices.find(n => n.id === this.data.info!.noticePeriod));
			this.contractForm.get(Form.licensedUsers)!.setValue(this.data.info!.licensedUsers);
			this.contractForm.get(Form.comment)!.setValue(this.data.info!.comment);
			this.contractForm.get(Form.fileLinkIds)!.setValue(this.data.info!.contract.documents.map(d => d.fileLinkId));
			// other fields are set through the specific form
		} else {
			this.contractForm.get(Form.type)!.setValue(this.typeChoices.find(t => t.id === ContractType.SUBSCRIPTION));
		}
	}

	onLicenseFormChange(form: TempLicenseForm): void {
		this.contractForm.get(Form.amount)!.setValue(form.amount);
		this.contractForm.get(Form.startDate)!.setValue(form.startDate);
		this.contractForm.get(Form.paymentDurationValue)!.setValue(form.paymentDurationValue);
		this.contractForm.get(Form.paymentDurationUnit)!.setValue(form.paymentDurationUnit);
		this.contractForm.get(Form.isRecurringPayment)!.setValue(false);
		this.contractForm.get(Form.durationOfUse)!.setValue(form.durationOfUse);
		this.contractForm.get(Form.organizationIds)!.setValue(form.organizationIds);
	}

	onServiceFormChange(form: TempServiceFrom): void {
		this.contractForm.get(Form.amount)!.setValue(form.amount);
		this.contractForm.get(Form.startDate)!.setValue(form.startDate);
		this.contractForm.get(Form.paymentDurationValue)!.setValue(form.paymentDurationValue);
		this.contractForm.get(Form.paymentDurationUnit)!.setValue(form.paymentDurationUnit);
		this.contractForm.get(Form.isRecurringPayment)!.setValue(form.isRecurringPayment);
		this.contractForm.get(Form.serviceType)!.setValue(form.serviceType);
		this.contractForm.get(Form.endDate)!.setValue(form.expirationDate);
		this.contractForm.get(Form.organizationIds)!.setValue(form.organizationIds);
	}

	onSubscriptionFormChange(form: TempSubscriptionForm): void {
		this.contractForm.get(Form.amount)!.setValue(form.amount);
		this.contractForm.get(Form.startDate)!.setValue(form.startDate);
		this.contractForm.get(Form.paymentDurationValue)!.setValue(1);
		this.contractForm.get(Form.paymentDurationUnit)!.setValue(form.paymentDurationUnit);
		this.contractForm.get(Form.isRecurringPayment)!.setValue(true);
		this.contractForm.get(Form.durationOfCommitment)!.setValue(form.durationOfCommitment);
		this.contractForm.get(Form.endDate)!.setValue(form.customEndDate);
		this.contractForm.get(Form.organizationIds)!.setValue(form.organizationIds);
	}

	save(): void {
		this.switchSaving()
			.pipe(
				map(() => this.buildApplicationContractForm()),
				switchMap(form => !this.data.info?.contract.contractId
					? this.applicationContractService.createApplicationContract(this.tenantId, this.data.applicationId, form)
					: this.applicationContractService.updateApplicationContract(this.tenantId, this.data.applicationId, this.data.info!.contract.contractId, form)),
				switchMap(() => this.switchSaving()))
			.subscribe(() => this.close(true));
	}

	private buildApplicationContractForm(): ApplicationContractForm {
		const licenseForm: ApplicationContractLicenseForm = {
			durationOfUse: this.contractForm.get(Form.durationOfUse)!.value
		};
		const serviceForm: ApplicationContractServiceForm = {
			serviceType: this.contractForm.get(Form.serviceType)!.value,
			expirationDate: this.contractForm.get(Form.endDate)!.value
		};
		const subscriptionForm: ApplicationContractSubscriptionForm = {
			durationOfCommitment: this.contractForm.get(Form.durationOfCommitment)!.value,
			customEndDate: this.contractForm.get(Form.endDate)!.value
		};
		const amount: number = parseFloat(this.contractForm.get(Form.amount)!.value);
		return {
			type: this.contractType,
			amount: isNaN(amount) ? undefined : amount,
			startDate: this.contractForm.get(Form.startDate)!.value,
			paymentDurationValue: this.contractForm.get(Form.paymentDurationValue)!.value,
			paymentDurationUnit: this.contractForm.get(Form.paymentDurationUnit)!.value,
			isRecurringPayment: this.contractForm.get(Form.isRecurringPayment)!.value,
			noticePeriod: (this.contractForm.get(Form.noticePeriod)!.value as NoticeChoice)?.id,
			licensedUsers: this.contractForm.get(Form.licensedUsers)!.value,
			comment: this.contractForm.get(Form.comment)!.value,
			organizations: this.contractForm.get(Form.organizationIds)!.value,
			documents: this.contractForm.get(Form.fileLinkIds)!.value,
			licenseInfo: this.contractType === ContractType.LICENSE ? licenseForm : undefined,
			serviceInfo: this.contractType === ContractType.SERVICE ? serviceForm : undefined,
			subscriptionInfo: this.contractType === ContractType.SUBSCRIPTION ? subscriptionForm : undefined
		};
	}

	get contractType(): ContractType {
		return (this.contractForm.get(Form.type)!.value as ContractTypeChoice).id;
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		return of({});
	}

	close(event: boolean): void {
		this.dialog.close(event);
	}
}

export interface ContractFormDialogData {
	applicationId: string;
	organization: OrganizationTree;
	info?: ContractInfo;
}

interface ContractTypeChoice {
	id: ContractType;
	name: string;
}

interface NoticeChoice {
	id: NoticePeriod;
	name: string;
}

export interface TempLicenseForm {
	amount?: number;
	startDate?: Date;
	paymentDurationValue?: number;
	paymentDurationUnit?: DurationUnit;
	durationOfUse?: DurationOfUse;
	organizationIds?: string[];
}

export interface TempServiceFrom {
	amount?: number;
	startDate?: Date;
	paymentDurationValue?: number;
	paymentDurationUnit?: DurationUnit;
	isRecurringPayment?: boolean;
	serviceType?: ServiceType;
	expirationDate?: Date;
	organizationIds?: string[];
}

export interface TempSubscriptionForm {
	amount?: number;
	startDate?: Date;
	paymentDurationUnit?: DurationUnit;
	durationOfCommitment?: DurationOfCommitment;
	customEndDate?: Date;
	organizationIds?: string[];
}

enum Form {
	type = 'type',
	amount = 'amount',
	startDate = 'startDate',
	endDate = 'endDate',
	paymentDurationValue = 'paymentDurationValue',
	paymentDurationUnit = 'paymentDurationUnit',
	isRecurringPayment = 'isRecurringPayment',
	serviceType = 'serviceType',
	durationOfUse = 'durationOfUse',
	durationOfCommitment = 'durationOfCommitment',
	noticePeriod = 'noticePeriod',
	licensedUsers = 'licensedUsers',
	comment = 'comment',
	organizationIds = 'organizationIds',
	fileLinkIds = 'fileLinkIds',
}
