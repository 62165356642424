import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class FocusInputService {
	private subject = new Subject();

	sendFocusMessage() {
		this.subject.next({});
	}

	getFocusMessage(): Observable<any> {
		return this.subject.asObservable();
	}
}
