import {Component, Input} from "@angular/core";

@Component({
	selector: 'app-spinner',
	template: `
		<div class="spinner" [style.scale]="scale"><div [ngClass]="color"></div><div [ngClass]="color"></div><div [ngClass]="color"></div><div [ngClass]="color"></div></div>
	`,
	styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
	@Input() color: 'accent' | 'secondary' | 'white' | 'grey' = 'white'
	@Input() scale: number = 1
}
