<div class="flex-column gap-XL">
	<!-- Users rates-->
	<div class="flex-space-between w-100 gap-L">
		<div class="w-100">
            <div class="bg-rounded w-100 top-widget-height"
                 [tooltip]="'page.appDetails.usage.tooltipDeactivated'|translate"
                 [tooltipDisabled]="!!application && application.usageActivated">
                <application-mini-widget
                        [class.c-p]="!!application && !application.usageActivated"
                        [widget]="applicationUsersWidget"
                        [type]="'users'"
                        [title]="'menu.users' | translate"
                        (click)="navigateToUsageParameter()"
                ></application-mini-widget>
            </div>
		</div>

		<div class="w-100">
            <div class="bg-rounded w-100 top-widget-height"
                 [tooltip]="'page.appDetails.usage.tooltipDeactivated'|translate"
                 [tooltipDisabled]="!!application && application.usageActivated">
                <application-mini-widget
                        [class.c-p]="!!application && !application.usageActivated"
                        [widget]="activityTimeWidget"
                        [type]="'activity'"
                        [title]="'page.appDetails.activityTimePerDay' | translate"
                        (click)="navigateToUsageParameter()"
                ></application-mini-widget>
            </div>
		</div>

		<div class="w-100">
            <div class="bg-rounded w-100 top-widget-height"
                 [tooltip]="'page.appDetails.usage.tooltipDeactivated'|translate"
                 [tooltipDisabled]="!!application && application.usageActivated">
                <application-mini-widget
                        [class.c-p]="!!application && !application.usageActivated"
                        [widget]="satisfactionWidget"
                        [type]="'satisfaction'"
                        [title]="'Satisfaction'"
                        [subtitle]="!applicationUsage || !applicationUsage.satisfaction.rating.value ? undefined : applicationUsage.satisfaction.votersCount + ' avis'"
                        (click)="navigateToUsageParameter()"
                ></application-mini-widget>
            </div>
		</div>
	</div>

	<!-- Activity histogram -->
	<div class="bg-rounded pad-M">
		<div *ngIf="_initializing || _loading" style="margin-bottom: 4px;">
			<app-linechart-loader></app-linechart-loader>
		</div>
		<app-usage-histogram *ngIf="!_initializing && !_loading"
							 [application]="application"
							 [usagePerHour]="applicationUsage.usagePerHour"
							 [usagePerDay]="applicationUsage.usagePerDay">
		</app-usage-histogram>
	</div>

	<!-- Teams -->
	<div class="flex-column bg-rounded gap-S pad-M">
		<div class="flex-align-center flex-space-between p-relative">
			<div class="font-MM fw-700">{{'global.teams'|translate}}</div>
			<app-mini-button size="s" color="accent"
							 [disable]="_initializing || _loading"
							 (onClick)="onChangeTeams()">
				<img src="assets/icons/add.svg" class="filter-accent">
			</app-mini-button>
			<div class="auto-discover-badge flex-center" *ngIf="autodiscoveredTeamsCount > 0">
				<span class="font-S fw-500">{{ autodiscoveredTeamsCount }}</span>
			</div>
		</div>
		<div *ngIf="!initialized">
			<app-list-loader ></app-list-loader>
		</div>
		<div *ngIf="initialized && applicationUsage.teams.length > 0" class="flex-column gap-S">
			<app-team-card *ngFor="let team of applicationUsage.teams.slice(0, displayAll ? applicationUsage.teams.length : 4)"
						   [team]="team">
			</app-team-card>
			<div *ngIf="applicationUsage.teams.length > 4" class="flex-justify-center">
				<button class="display-all" type="button"
						(click)="displayAll = !displayAll">
					<span *ngIf="!displayAll">{{ 'page.appDetails.usage.displayAllTeam' | translate }} {{ '(+' + (applicationUsage.teams.length - 4) + ')' }}</span>
					<span *ngIf="displayAll">{{ 'page.appDetails.usage.displayLessTeam' | translate }}</span>
				</button>
			</div>
		</div>
	</div>
</div>
