<div class="flex-center h-100">
	<div class="flex-justify-center flex-align-center onboarding-step-container h-100 w-100">
		<div class="flex-justify-center gap-M h-100 w-100 h-auto m-auto">
			<div *ngIf="!isMobile" class="onboarding-stepper-container flex-column gap-M mt-l">
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper active-step"></div>
				<div *ngIf="stepCount >= 3" class="onboarding-stepper"></div>
				<div *ngIf="stepCount >= 4" class="onboarding-stepper"></div>
				<div *ngIf="stepCount >= 5" class="onboarding-stepper"></div>
				<div *ngIf="stepCount >= 6" class="onboarding-stepper"></div>
			</div>
			<div class="onboarding-step flex-column flex-space-between w-30 gap-L border-box pad-L">
				<div class="h-100" [ngClass]="{ 'mt-xxl': !canClose }">
					<div *ngIf="canClose" class="flex-justify-end">
						<app-icon-button (click)="goBackHome()" img='assets/icons/close.svg' size='s' color='accent' type='stroked'
										 shape='default' [disabled]='false' [focused]='false'
										 [background]='true' panelClass='img-size-30'></app-icon-button>
					</div>
					<div class="fw-700 font-XXL text-center mb-xxl onboarding-title">{{ 'page.onboarding.step2.st1' | translate }}</div>
					<div class="flex-justify-center text-center font-L fw-300 mb-l">
						<span class="onboarding-subtitle" [style.line-height.px]="panelClass === 'big-button' ? 27 : 23">{{ 'page.onboarding.step2.st2' | translate }}</span>
					</div>
					<div class="w-100 border-box onboarding-input">
						<span class="font-L fw-500 grey">{{ 'page.onboarding.step2.st3' | translate }}</span>
						<app-multi-select [inputControl]="$any(form.get('userKind'))">
							<app-select-trigger appearance="field">
                                <div class="flex-align-center" [style.height.px]="height" [class]="'font-' + fontSize">{{'page.onboarding.userKind.' + $any(form.get('userKind')).value | translate}}</div>
                            </app-select-trigger>
							<app-select-option *ngFor="let type of ['cio']" [value]="type">
								{{'page.onboarding.userKind.' + type | translate}}
							</app-select-option>
						</app-multi-select>
					</div>
					<div class="w-100 border-box onboarding-input">
						<span class="font-L fw-500 grey">{{ 'page.onboarding.step2.st4' | translate }}</span>
						<app-multi-select [inputControl]="$any(form.get('workPlace'))">
							<app-select-trigger appearance="field">
                                <div class="flex-align-center" [style.height.px]="height" [class]="'font-' + fontSize">{{'page.onboarding.workPlace.' + $any(form.get('workPlace')).value | translate}}</div>
                            </app-select-trigger>
							<app-select-option *ngFor="let type of ['group']" [value]="type">
								{{'page.onboarding.workPlace.' + type | translate}}
							</app-select-option>
						</app-multi-select>
					</div>
					<div class="w-100 border-box onboarding-input">
						<span class="font-L fw-500 grey">{{ 'page.onboarding.step2.st5' | translate }}</span>
						<app-multi-select [inputControl]="$any(form.get('objectives'))">
							<app-select-trigger appearance="field">
                                <div class="flex-align-center" [style.height.px]="height" [class]="'font-' + fontSize">{{'page.newTenant.organization.wishes.' + $any(form.get('objectives')).value | translate}}</div>
                            </app-select-trigger>
							<app-select-option *ngFor="let type of ['mapping', 'indicators', 'monitor']" [value]="type">
								{{'page.newTenant.organization.wishes.' + type | translate}}
							</app-select-option>
						</app-multi-select>
					</div>
				</div>
				<div class="flex-center w-100 pb-l" style="height: 64px;">
					<app-text-button (click)="goToNextStep()" class="w-90" color='accent' type='flat'
									 shape='default' [disabled]='false' [loading]='false' badge=''
									 badgePosition='above after' [panelClass]="panelClass">{{ 'page.onboarding.step2.st6' | translate }}
					</app-text-button>
				</div>
			</div>
		</div>
	</div>
</div>
