import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {SecurityUtils} from "./security.utils";
import {ActivatedRoute, Router} from "@angular/router";
import {OrganizationIcon, OrganizationType} from 'src/app/services/model/autodiscover.model';
import {ApplicationHealth, Health} from 'src/app/services/model/new-application.model';
import {Cost, Usage} from 'src/app/services/model/application-contract.model';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Injectable({ providedIn: 'root' })
export class OrganizationService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/organizations`;

	constructor(protected httpClient: HttpClient, protected activatedRoute: ActivatedRoute, protected router: Router) {
	}

	getOrganizationTreeByTenantId(tenantId: string): Observable<OrganizationTree> {
		return this.httpClient.get<OrganizationTree>(`${this.serviceUrl(tenantId)}/tree`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getOrganizationTreeStatisticByTenantId(tenantId: string): Observable<OrganizationTreeStatistic> {
		return this.httpClient.get<OrganizationTreeStatistic>(`${this.serviceUrl(tenantId)}/tree-statistic`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	create(tenantId: string, form: CreateOrganizationForm): Observable<string> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	updateName(tenantId: string, organizationId: string, form: UpdateOrganizationNameForm): Observable<boolean> {
		return this.httpClient.patch<boolean>(`${this.serviceUrl(tenantId)}/${organizationId}/name`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	updateIcon(tenantId: string, organizationId: string, form: UpdateOrganizationIconForm): Observable<boolean> {
		return this.httpClient.patch<boolean>(`${this.serviceUrl(tenantId)}/${organizationId}/icon`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	updateDescription(tenantId: string, organizationId: string, form: UpdateOrganizationDescriptionForm): Observable<boolean> {
		return this.httpClient.patch<boolean>(`${this.serviceUrl(tenantId)}/${organizationId}/description`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	delete(tenantId: string, organizationId: string): Observable<any> {
		return this.httpClient.delete(`${this.serviceUrl(tenantId)}/${organizationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	getOrganizationStatistic(tenantId: string, organizationId: string, costRange?: QueryRangeType, elasticRange?: QueryRangeType): Observable<OrganizationStatistic> {
		let params: HttpParams = new HttpParams();
		if (costRange) params = params.append('costRange', costRange);
		if (elasticRange) params = params.append('elasticRange', elasticRange);
		return this.httpClient.get<OrganizationStatistic>(`${this.serviceUrl(tenantId)}/${organizationId}/statistic`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		});
	}

	getAllApplicationHealthByOrganizationId(tenantId: string, organizationId: string, range?: QueryRangeType): Observable<ApplicationHealth[]> {
		let params: HttpParams = new HttpParams();
		if (range) params = params.append('range', range);
		return this.httpClient.get<ApplicationHealth[]>(`${this.serviceUrl(tenantId)}/${organizationId}/applications`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		});
	}

	changeTeamApplications(tenantId: string, teamId: string, applications: { appId: string, checked: boolean }[]): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.serviceUrl(tenantId)}/${teamId}/applications`, applications, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	// Settings TODO @TAN remove this part from the service
	navigateInAndOutOfParameter(parameter: number | null) {
		/*
			Working the same way as chmod, each new value = sum of all previous value + 1
			sum result of the opening of the different value used in the sum
			null -> out
			nothing opened ->0
			general -> 1
		*/

		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: {
				settings: parameter
			},
			queryParamsHandling: 'merge'
		});
	}

	public getParameter(): number | null {
		let index = this.activatedRoute.snapshot.queryParamMap.get('settings');
		if (index != null)
			return parseInt(index)
		return null
	}
}

export interface OrganizationTreeStatistic {
	organization: Organization;
	children: OrganizationTreeStatistic[];
	appCount: number;
	usage: number|null;
	satisfaction: number|null;
	// TODO @TAN statistic: OrganizationStatistic
}

export interface CreateOrganizationForm {
	name: string;
	type: OrganizationType;
	icon?: OrganizationIcon;
	ascendant: string;
}

export interface UpdateOrganizationNameForm {
	name: string;
}

export interface UpdateOrganizationIconForm {
	icon?: OrganizationIcon|null;
}

export interface UpdateOrganizationDescriptionForm {
	description?: string|null;
}

export interface OrganizationStatistic {
	availability: Health;
	cost: Cost;
	usage: Usage;
}

export interface OrganizationTree {
	organization: Organization;
	children: OrganizationTree[];
}

// TODO @TAN parentOrganizationId & other model with parent ?
export interface Organization {
	organizationId: string;
	parentOrganization: Organization|null;
	name: string;
	type: OrganizationType;
	description: string|null;
	icon: OrganizationIcon|null;
}
