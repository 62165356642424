import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {distinctUntilChanged, finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ResourceMappingService} from 'src/app/services/back/resource-mapping.service';
import {InfrastructureUtils} from '../../../../infrastructure/infrastructure.utils';
import {map} from 'rxjs/operators';
import {ResourceMapping, ResourceMappingResourceListForm} from 'src/app/services/model/resource-mapping.model';
import {ServerService} from 'src/app/services/back/server.service';
import {ServerOverview, ResourceInstanceOverview} from 'src/app/services/model/resource.model';
import {Logo} from "../../../../../../services/application-instance.service";

@Component({
	selector: 'app-application-mapping-resource-form',
	templateUrl: './application-mapping-resource-form.component.html',
	styleUrls: ['./application-mapping-resource-form.component.scss']
})
export class ApplicationMappingResourceFormComponent implements OnInit, OnDestroy {

	@Input() data: ApplicationMappingResourceFormData;

	@Output() cancel = new EventEmitter<void>();
	@Output() saved = new EventEmitter<boolean>();
	@Output() hasResources = new EventEmitter<boolean>();

	_initializing: boolean;
	_saving: boolean;

	formGroup: FormGroup;
	formName = Form;
	formElementName = FormElement;

	subscription: Subscription = new Subscription();

	constructor(private resourceMappingService: ResourceMappingService,
				private serverService: ServerService) {
	}

	ngOnInit(): void {
		this.createForm();
		this.subscription.add(this.switchInitializing()
			.pipe(
				switchMap(() => !this.data.defaultList
					? this.serverService.getAllServersByTenantId(this.data.tenantId, false, true)
					: of(this.data.defaultList.resources)),
				tap(resources => this.hasResources.emit(resources.length > 0)),
				tap(resources => this.setResources(resources)),
				finalize(() => this.switchInitializing()))
			.subscribe(() => this.setDefaultData()));
	}

	private createForm(): void {
		this.formGroup = new FormGroup({
			[Form.search]: new FormControl('', []),
			[Form.resources]: new FormArray([])
		});
		this.subscription.add(this.formGroup.get(Form.search)?.valueChanges
			.pipe(distinctUntilChanged())
			.subscribe(() => this.filterResourceFormGroups()));
	}

	private setResources(resources: ServerOverview[]): void {
		resources
			.map(resource => new FormGroup({
				[FormElement.resource]: new FormControl(resource, []),
				[FormElement.selected]: new FormControl(undefined, [])
			}))
			.forEach(group => this.resourceFormArray.push(group));
	}

	private setDefaultData(): void {
		this.resourceFormGroups.forEach(group => {
			const selected: ResourceMapping|undefined = this.data.defaultData?.find(t => t.resource.id === this.getResource(group).id);
			group.get(FormElement.selected)?.setValue(!!selected);
		});
		this.filterResourceFormGroups();
	}

	save(): void {
		this.switchSaving()
			.pipe(
				map(() => this.buildResourceMappingListForm()),
				switchMap(form => this.resourceMappingService.updateResourceMappingResourceListForm(this.data.tenantId, form)),
				finalize(() => this.switchSaving()))
			.subscribe(updated => this.saved.emit(updated));
	}

	private buildResourceMappingListForm(): ResourceMappingResourceListForm {
		return {
			applicationId: this.data.instanceId,
			resources: this.resourceFormGroups
				.filter(group => !!group.get(FormElement.selected)?.value)
				.map(group => ({
					resourceId: (group.get(FormElement.resource)?.value as ResourceInstanceOverview).id,
					description: undefined
				}))
		};
	}

	getResourceIcon(group: FormGroup): Logo {
		return InfrastructureUtils.buildSystemLogo(this.getResource(group).osType);
	}

	getResource(group: FormGroup): ServerOverview {
		return group.get(FormElement.resource)?.value as ServerOverview;
	}

	get searchFormControl(): FormControl {
		return this.formGroup.get(Form.search) as FormControl;
	}

	get resourceFormArray(): FormArray {
		return this.formGroup.get(Form.resources) as FormArray;
	}

	get resourceFormGroups(): FormGroup[] {
		return this.resourceFormArray.controls as FormGroup[];
	}

	private filterResourceFormGroups(): FormGroup[] {
		return this.resourceFormGroups.sort(this.sortResources);
	}

	private sortResources = (form1: FormGroup, form2: FormGroup) => {
		const matchG1 = this.isFormMatchSearch(form1);
		const matchG2 = this.isFormMatchSearch(form2);
		if (matchG1 && !matchG2) {
			return -1;
		} else if (!matchG1 && matchG2) {
			return 1;
		} else {
			return this.getResource(form1).name.localeCompare(this.getResource(form2).name);
		}
	}

	filteredOut(form: FormGroup) {
		return !this.isFormMatchSearch(form);
	}

	private isFormMatchSearch(form: FormGroup): boolean {
		return this.getResource(form).name.toLowerCase().includes(this.formGroup.get(Form.search)!.value?.toLowerCase());
	}

	switchSaving(): Observable<{}> {
		this._saving = !this._saving;
		this.checkFormDisabled();
		return of({});
	}

	switchInitializing(): Observable<{}> {
		this._initializing = !this._initializing;
		this.checkFormDisabled();
		return of({});
	}

	private checkFormDisabled(): void {
		if (this._initializing || this._saving) {
			this.formGroup.disable({emitEvent: false});
		} else {
			this.formGroup.enable({emitEvent: false});
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface ApplicationMappingResourceFormData {
	tenantId: string;
	instanceId: string;
	defaultData?: ResourceMapping[];
	defaultList?: {
		resources: ServerOverview[]
	};
}

enum Form {
	search = 'search',
	resources = 'resources'
}

enum FormElement {
	resource = 'resource',
	selected = 'selected'
}
