import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from 'src/app/services/security.utils';
import {HttpClient} from '@angular/common/http';
import {CatalogApplicationOverview, CatalogCategoryStatistics} from 'src/app/services/model/catalog-application.model';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CatalogApplicationService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/catalog/applications`;

	constructor(protected httpClient: HttpClient,
				private translateService: TranslateService) {
	}

	public getCatalogCategoryStatistics(tenantId: string, lang: string = 'en'): Observable<CatalogCategoryStatistics> {
		return this.httpClient.get<CatalogCategoryStatistics>(`${this.serviceUrl(tenantId)}/_statistics`,
			{ params: { lang }, headers: SecurityUtils.getAuthHttpHeaders()});
	}

	public search(tenantId: string, page: number, lang: string = 'en', query : {category?: string, searchQuery?: string, queryAllCategory?: boolean} ): Observable<{total:number, applications: CatalogApplicationOverview[]}> {
		const params:any = { lang, page };
		if (query.category) params.category = query.category;
		if (query.searchQuery && query.searchQuery.trim().length > 0) params.searchQuery = query.searchQuery;
		if (query.queryAllCategory) params.queryAllCategory = query.queryAllCategory;

		return this.httpClient.get<{total:number, applications: CatalogApplicationOverview[]}>(`${this.serviceUrl(tenantId)}/_search`,
			{ params, headers: SecurityUtils.getAuthHttpHeaders()});
	}
}
