@if (!_initializing && !_loading) {
	@if (organizationTree.children.length > 0) {
		<div class="pl-xl pr-xl mt-xl">
			<!-- HEADER -->
			<div class="w-100 header-container">
				<div class="rounded-elt pl-xl disp-f a-i-c w-100" style="min-width: 223px;">
					<img src="assets/icons/building-black.svg">
					<div class="font-L fw-700 text-overflow-ellipsis ml-s">
						<span *ngIf="organizationTree">{{ organizationTree.organization.name }}</span>
						<span *ngIf="!organizationTree">-</span>
					</div>
				</div>
				<div style="width: 250px; flex-shrink: 0;">
					<app-text-button color="accent" type="flat" [panelClass]="'h-100-imp b-radius-9'" (click)="onCreate(organizationTree)">
						{{ 'page.organization.addATeamGroup' | translate }}
					</app-text-button>
				</div>
			</div>
		</div>
	}

	<!-- BODY -->
	<app-snackbar></app-snackbar>
	<div class="pl-xl pr-xl mt-xl" [style.height]="organizationTree.children.length <= 0 ? 'calc(100% - 130px)' : ''">
		<div class="w-100 mt-l" style="min-width: 520px;" [ngClass]="{ 'h-100': organizationTree.children.length <= 0 }">
			@for (structure of organizationTree.children; track structure.organization.organizationId) {
				<div class="rounded-elt mb-l pad-XL disp-f j-c-sb a-i-c">
					<!-- LEFT -->
					<div class="disp-f a-i-c gap-XL overflow-hidden">
						<div class="btn-arrow disp-f a-i-c j-c-c" style="margin-right: -15px; flex-shrink: 0;"
							 (click)="onOpenOrganization(structure)">
							<img ngSrc="assets/icons/arrow-right-black.svg" class="filter-grey" height="12" width="8" alt=""
								 [style.transform]="findStructureInOpenedOrganizations(structure) ? 'rotate(90deg)' : ''"
								 [style.margin-top]="findStructureInOpenedOrganizations(structure) ? '4px' : ''">
						</div>
						<!-- STRUCTURE -->
						<div>
							<div class="disp-f a-i-c">
								<div class="icon-size square-icon-accent">
									<img ngSrc="assets/icons/folder-empty.svg" class="filter-accent" height="20" width="20"
										 alt="" style="width: 20px;height: 20px;">
								</div>
								<div class="ml-s flex-column gap-XS overflow-hidden" style="white-space: nowrap">
									<div class="font-L fw-700">{{ structure.organization.name }}</div>
									<div class="font-M fw-500">{{ 'page.organization.teamGroup' | translate }}</div>
								</div>
							</div>
						</div>
						<!-- USERS -->
						<div>
							<app-chips-count [value]="structure.usage ?? 0" type="user" color="accent"></app-chips-count>
						</div>
					</div>
					<!-- RIGHT -->
					<div class="disp-f a-i-c gap-M">
						<div class="mini-btn-orga btn-accent" (click)="onCreate(structure)">
							<img ngSrc="assets/icons/users-plus.svg" height="20" width="20" alt="" class="filter-accent">
						</div>
						<!--<div class="mini-btn-orga btn-normal">
							<img ngSrc="assets/icons/map-outline.svg" alt="" height="20" width="20" class="filter-grey">
						</div>-->
						<div class="mini-btn-orga btn-normal" (click)="onOpenStructureDetails(structure)">
							<img ngSrc="assets/icons/layout-alt.svg" alt="" height="20" width="20" class="filter-grey">
						</div>
					</div>
				</div>
				@if (findStructureInOpenedOrganizations(structure)) {
					<div class="w-100 mt-l mb-l disp-f pl-xl gap-XL">
						<div class="folded-arrow">
							<img ngSrc="assets/icons/arrow-unfolded.svg" class="filter-lightgrey" width="28" height="37"
								 alt="">
						</div>
						@if (structure.children.length > 0) {
							<div class="disp-f a-i-c gap-XL flex-wrap teams-container">
								@for (team of structure.children; track team.organization.organizationId) {
									<div class="rounded-elt single-mini-widget pad-L c-p" (click)="onOpenBusinessUnitDetails(structure , team)">
										<div class="disp-f a-i-c mb-xl">
											<div class="icon-size square-icon-accent">
												<img width="20" height="20" style="height: 20px; width: 20px;"
													 [src]="!!team.organization.icon ? ('/assets/icons/team_icon/' + team.organization.icon + '.svg') : 'assets/icons/team.svg'"/>
											</div>
											<div class="ml-s flex-column gap-XS">
												<div class="font-L fw-700">{{ team.organization.name }}</div>
												<div class="font-M fw-500">{{ structure.organization.name }}</div>
											</div>
										</div>
										<hr class="mini-card-hr">
										<div class="mt-xl disp-f a-i-c j-c-sb">
											<div class="disp-f f-d-col ml-l">
												<span class="font-XL fw-700">{{ team.appCount }}</span>
												<span class="font-M fw-500 grey">{{ 'page.organization.applicationsWidget' | translate }}</span>
											</div>
											<div class="disp-f f-d-col">
												<span class="font-XL fw-700">{{ team.usage ?? 0 }}</span>
												<span class="font-M fw-500 grey">{{ 'page.organization.usersWidget' | translate }}</span>
											</div>
											<div class="disp-f f-d-col">
												<span class="font-XL fw-700">{{ team.satisfaction ?? '-' }}</span>
												<span class="font-M fw-500 grey">{{ 'page.organization.satisfactionWidget' | translate }}</span>
											</div>
										</div>
									</div>
								}
							</div>
						} @else {
							<div class="w-100">
								<div class="rounded-elt-no-team disp-f f-d-col a-i-c j-c-c">
									<img ngSrc="assets/icons/info-circle.svg" height="35" width="35">
									<span class="font-M fw-500 grey">{{ 'page.organization.createATeam' | translate }}</span>
								</div>
							</div>
						}
						<div>

						</div>
					</div>
				}
			} @empty {
				<div class="w-100 h-100 flex-justify-center flex-align-center flex-column gap-20">
					<img src="assets/illustrations/no-organization.svg" class="w-100 h-auto" style="max-width: 800px;">
					<span class="font-XL fw-700 text-center primary">{{ 'page.organization.noOrganization.1' | translate }}</span>
					<span class="fw-500 text-center font-L primary" style="line-height: 1.6rem;">{{ 'page.organization.noOrganization.2' | translate }}<br>
						{{ 'page.organization.noOrganization.3' | translate }}</span>
					<app-text-button color="accent" type="flat" class="mt-l w-20" style="min-width: 200px;"
                                     (click)="onCreate(organizationTree)">
						{{ 'global.letsGo' | translate }}
					</app-text-button>
				</div>
			}
		</div>
	</div>
} @else {
	<div class="pl-xl pr-xl mt-xl">
		<app-list-loader *ngIf="_initializing || _loading"></app-list-loader>
	</div>
}

<app-snackbar></app-snackbar>
<div class="pl-xl pr-xl mt-xl">

</div>
