import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {SecurityUtils} from './security.utils';
import {map} from 'rxjs/operators';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthenticationService {
	constructor(protected httpClient: HttpClient) {
	}

	signin(createToken: CreateToken): Observable<boolean> {
		return this.httpClient.post<CreateTokenResponse>(environment.engineURL + '/authenticate', JSON.stringify(createToken), {headers: SecurityUtils.getHttpHeaders()})
			.pipe(map(tokenResponse => {
				return SecurityUtils.setAuthToken(tokenResponse.accessToken);
			}));
	}

	checkPin(createTokenPin: CreateTokenPin): Observable<boolean> {
		return this.httpClient.post<CreateTokenResponse>(environment.engineURL + '/authenticate-pin', JSON.stringify(createTokenPin), {headers: SecurityUtils.getHttpHeaders()})
			.pipe(map(tokenResponse => {
				return SecurityUtils.setAuthToken(tokenResponse.accessToken);
			}));
	}

	microsoftSigning(idToken: string, expiresAt: Date | null): Observable<any> {
		return this.httpClient.post<CreateTokenResponse>(environment.engineURL + '/authenticate/microsoft', JSON.stringify({
			'idToken': idToken,
			'expiresAt': expiresAt
		}), {headers: SecurityUtils.getHttpHeaders()})
			.pipe(map(tokenResponse => {
				return SecurityUtils.setAuthToken(tokenResponse.accessToken);
			}));
	}

	isAuthenticatedSnapshot(): boolean {
		const token = SecurityUtils.getAuthToken();

		if (token !== null) {
			// TODO check token validity and renew if needed
			return true;
		} else {
			return false;
		}
	}

	logout(): boolean {
		if (environment.production) {
			// Remove intercom cookie
			(<any>window).Intercom('shutdown');
		}
		return SecurityUtils.removeAuthToken();
	}

	getUserInfo(): UserInfo | null {
		const token = SecurityUtils.getAuthToken();

		if (token) {
			const helper = new JwtHelperService();
			const decodedToken = helper.decodeToken(token);
			return {
				id: decodedToken.id,
				firstName: decodedToken.firstname,
				lastName: decodedToken.lastname,
				email: decodedToken.upn
			};
		} else {
			return null;
		}
	}
}

export interface CreateToken {
	email: string;
	password: string;
}

export interface CreateTokenPin {
	email: string;
	pin: string;
}

export interface CreateMicrosoftToken {
	accessToken: string;
	refreshToken: string;
	refreshTokenExpiration: string;
	mail: string;
	oid: string;
}

export interface CreateTokenResponse {
	accessToken: string;
}

export interface UserInfo {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
}
