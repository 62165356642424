import {Component, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {environment} from "src/environments/environment";
import {DesignSystemModule} from "../../design-system/design-system.module";
import {OnboardingStep1Component} from "./onboarding-step-1/onboarding-step-1.component";
import {OnboardingStep2Component} from "./onboarding-step-2/onboarding-step-2.component";
import {animate, style, transition, trigger} from "@angular/animations";
import {OnboardingStep3Component} from "./onboarding-step-3/onboarding-step-3.component";
import {OnboardingStep4Component} from "./onboarding-step-4/onboarding-step-4.component";
import {OnboardingStep5Component} from "./onboarding-step-5/onboarding-step-5.component";
import {OnboardingStep6Component} from "./onboarding-step-6/onboarding-step-6.component";
import {FormGroup} from "@angular/forms";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {TenantService} from "src/app/services/tenant.service";
import {Router} from "@angular/router";
import {APPLICATIONS_URL, HOME_URL} from "src/app/models/home/navigation.model";
import {switchMap, tap} from "rxjs/operators";
import {Subscription} from "rxjs";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyMenuModule} from "@angular/material/legacy-menu";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import * as Bowser from 'bowser';

@Component({
	selector: 'app-onboarding',
	standalone: true,
	imports: [CommonModule, NgOptimizedImage, DesignSystemModule, OnboardingStep1Component, OnboardingStep2Component, OnboardingStep3Component, OnboardingStep4Component, OnboardingStep5Component, OnboardingStep6Component, MatIconModule, MatLegacyButtonModule, MatLegacyMenuModule, TranslateModule],
	animations: [
		trigger(
			'inOutAnimation',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(150%)' }),
						animate('500ms 500ms ease-out',
							style({ transform: 'translateY(0)'}))
					]
				),
				transition(
					':leave',
					[
						style({ transform: 'translateY(0)' }),
						animate('500ms ease-out',
							style({ transform: 'translateY(-150%)'}))
					]
				)
			]
		)
	],
	templateUrl: './onboarding.component.html',
	styleUrl: './onboarding.component.scss'
})
export class OnboardingComponent implements OnInit {
	constructor(private tenantService: TenantService, private router: Router, private currentTenantService: CurrentTenantService, public translateService: TranslateService) {
	}

	protected readonly environment = environment;

	otherTenantsExisting: boolean

	currentStep = 'Step 1';
	secondStepForm: FormGroup;
	isMobile: boolean;

	_loading = true;

	subscriptions: Subscription = new Subscription()

	ngOnInit() {
		this._loading = true;
		this.isMobile = Bowser.getParser(window.navigator.userAgent).getPlatform().type === PlatformType.mobile;
		this.subscriptions.add(this.tenantService.getTenants()
			.pipe(
				tap(tenants => {
					if (tenants.length > 0) {
						const currentTenant = tenants.find(tenant => tenant.configuration.isDefault);
						if (!currentTenant) {
							this.otherTenantsExisting = true;
						} else if (currentTenant && currentTenant.configuration.onboardingCompleted) {
							this.otherTenantsExisting = true;
						} else {
							this.currentStep = 'Step 4';
							this.otherTenantsExisting = false;
						}
					} else {
						this.otherTenantsExisting = false;
					}
					this._loading = false;
				})
			)
			.subscribe());
	}

	scrollToStep(step: string) {
		this.currentStep = step;
	}

	setSecondStepForm(form: FormGroup) {
		this.secondStepForm = form;
		this.currentStep = 'Step 3';
	}

	onComplete(event: string | void) {
		this.subscriptions.add(this.currentTenantService.getCurrentTenant()
			.pipe(
				switchMap(tenant => this.tenantService.setOnboardingCompleted(tenant.configuration.id)),
			).subscribe(async () => {
				if (event === 'apps') {
					await this.router.navigate([APPLICATIONS_URL]);
					return window.location.reload();
				} else if (event) {
					this.currentTenantService.initialize(event, false);
					await this.router.navigate([HOME_URL]);
					return window.location.reload();
				} else {
					await this.router.navigate([HOME_URL]);
					return window.location.reload();
				}
			}));
	}

	onChangeLang(lang: string) {
		localStorage.setItem('lang', lang);
		this.translateService.setDefaultLang(lang);
	}

	onClose() {
		this.router.navigate([HOME_URL]);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe()
	}
}

enum PlatformType {
	// Retrieved from bowser project on github.com
	tablet = 'tablet',
	mobile = 'mobile',
	desktop = 'desktop',
	tv = 'tv',
	bot = 'bot',
}
