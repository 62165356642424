import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(protected router: Router, protected authService: AuthenticationService) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return of(this.authService.isAuthenticatedSnapshot()).pipe(map(isAuth => {
			if (isAuth) {
				return true;
			} else {
				return this.router.createUrlTree(['login']);
			}
		}));
	}
}
