import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Organization, OrganizationTree} from "src/app/services/organization.service";
import {TranslateService} from "@ngx-translate/core";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {OrganizationType} from "../../../services/model/autodiscover.model";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

	constructor(private translate: TranslateService) {
	}

	private _organizationTree: OrganizationTree;

	@Input()
	set organizationTree(value: OrganizationTree) {
		this._organizationTree = value;
		if (value) {
			this.teamGroupList = this.organizationTree.children.map(structure => structure.organization);
			this.filteredTeamGroups = this.teamGroupList;
		}
	}

	get organizationTree(): OrganizationTree {
		return this._organizationTree;
	}

	@Input() filtersLoading: boolean = false;

	formGroup: FormGroup;

	teamGroupList: Organization[] = [];
	filteredTeamGroups: Organization[] = [];

	teamList: Organization[] = [];
	filteredTeams: Organization[] = [];

	searchTeamGroupControl: FormControl = new FormControl('');
	searchTeamControl: FormControl = new FormControl('');

	subscription: Subscription = new Subscription();

	@Output() changes: EventEmitter<Organization|null> = new EventEmitter<Organization|null>();

	ngOnInit() {
		this.createForm();

		this.subscription.add(this.searchTeamGroupControl.valueChanges.subscribe(value => {
			this.filteredTeamGroups = this.teamGroupList.filter(group => group.name.toLowerCase().includes(value.toLowerCase()));
		}));

		this.subscription.add(this.searchTeamControl.valueChanges.subscribe(value => {
			this.filteredTeams = this.teamList.filter(team => team.name.toLowerCase().includes(value.toLowerCase()));
		}));

		this.teamGroupFormControl.valueChanges.subscribe((value: Organization | undefined) => {
			if (value)
				this.refresh('team_group', value);
		})

		this.teamFormControl.valueChanges.subscribe((value: Organization | undefined) => {
			if (value)
				this.refresh('team', value);
		})
	}

	private createForm() {
		this.formGroup = new FormGroup({
			[Form.team_group]: new FormControl(undefined),
			[Form.team]: new FormControl(undefined),
		});
	}

	get teamGroupFormControl(): FormControl {
		return this.formGroup.get(Form.team_group) as FormControl;
	}

	get teamGroupFormValue(): Organization | undefined {
		return this.formGroup.get(Form.team_group)!.value;
	}

	get teamFormControl(): FormControl {
		return this.formGroup.get(Form.team) as FormControl;
	}

	get teamFormValue(): Organization | undefined {
		return this.formGroup.get(Form.team)!.value;
	}


	deleteFilters(): void {
		this.teamGroupFormControl.setValue(undefined);
		this.teamFormControl.setValue(undefined);
		this.teamList = [];
		this.filteredTeams = [];
		this.changes.emit(null);
	}

	refresh(type: 'team_group' | 'team', organization?: Organization): void {
		switch (type) {
			case "team_group":
				const teamGroup = this.organizationTree.children.find(structure => structure.organization.organizationId == organization?.organizationId);
				if (!teamGroup) {
					this.teamList = [];
					this.filteredTeams = [];
					this.teamFormControl.setValue(undefined, {emitEvent: false});
					this.teamGroupFormControl.setValue(undefined, {emitEvent: false});
					this.changes.emit(organization);
					break;
				}
				this.teamList = this.organizationTree.children
					.find(structure => structure.organization.organizationId == organization?.organizationId)!
					.children.map(team => team.organization);
				this.filteredTeams = this.teamList;
				this.teamFormControl.setValue(undefined, {emitEvent: false});
				this.changes.emit(organization);
				break;
			case "team":
				this.changes.emit(organization);
				break;
		}
	}
}

enum Form {
	team_group = 'team_group',
	team = 'team',
}
