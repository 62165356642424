import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChartData, ChartOptions} from 'chart.js';
import {ColorEnum} from 'src/_variables';
import {BaseChartDirective} from 'ng2-charts';
import {ApplicationCategory, TenantService} from 'src/app/services/tenant.service';
import {Category} from 'src/app/services/model/application-category.model';
import {TranslateService} from '@ngx-translate/core';
import {HostingType} from 'src/app/services/model/new-application.model';
import {BehaviorSubject, delayWhen, finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {filter, first} from "rxjs/operators";
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';

@Component({
	selector: 'app-application-type-distribution',
	templateUrl: './application-type-distribution.component.html',
	styleUrls: ['./application-type-distribution.component.scss']
})
export class ApplicationTypeDistributionComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() filter: Subject<Organization|null|undefined>;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	applications: ApplicationCategory[] = [];
	doughnutChartData: ChartData<'doughnut'>;
	chartOptions: ChartOptions<'doughnut'>;
	keys: string[] = [];
	count: Record<string, number>;
	width: Record<string, number>;
	label: Record<string, string>;
	topCategories: Category[] = [];
	filterType: 'hosting'|'category' = 'hosting';
	viewInit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	readonly KEY_OTHERS: string = 'others';
	readonly MAX_KEYS: number = 4;

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private tenantService: TenantService,
							private translate: TranslateService) {
	}

	ngOnInit() {
		this.createChartData();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	ngAfterViewInit() {
		this.viewInit.next(true);
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getAllApplicationCategoryByTenantId(this.tenantId, org?.organizationId)),
				delayWhen(() => this.viewInit.pipe(filter(init => init), first())),
				tap(data => this.setApplicationCategories(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private setApplicationCategories(applications: ApplicationCategory[]): void {
		this.applications = applications;
		this.setTopCategories();
		this.setComponentData();
		this.setChartData();
	}

	switchTypeOrCategory(): void {
		this.filterType = this.filterType === 'hosting' ? 'category' : 'hosting';
		this.setComponentData();
		this.setChartData();
		this.chart.update();
	}

	private createChartData(): void {
		this.doughnutChartData = {
			labels: [],
			datasets: [
				{
					data: [ 0, 0 ],
					backgroundColor: [ColorEnum.accent, ColorEnum.chart_yellow],
					borderColor: [ColorEnum.accent, ColorEnum.chart_yellow],
					borderRadius: 5,
					spacing: 2
				}
			],
		};
		this.chartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			events: [],
			cutout: '65%',
			elements: {
				arc: {
					borderWidth: 1,
				}
			}
		};
	}

	private setTopCategories(): void {
		const categories: Category[] = [];
		this.applications
			.filter(app => !!app.category)
			.forEach(app => {
				if (!categories.find(c => c.categoryId === app.category!.categoryId)) {
					categories.push(app.category!);
				}
		});
		const count: Record<string, number> = {};
		categories.forEach(c => {
			count[c.categoryId] = this.applications.filter(app => app.category?.categoryId === c.categoryId).length;
		});
		this.topCategories = categories
			.sort((a, b) => count[a.categoryId] > count[b.categoryId] ? -1 : 1)
			.slice(0, this.MAX_KEYS - 1);
	}

	private setComponentData(): void {
		this.label = {};
		this.count = {};
		this.width = {};
		this.keys = this.filterType === 'hosting'
			? Object.values(HostingType)
			: [...this.topCategories.map(c => c.categoryId), this.KEY_OTHERS];
		this.keys = this.keys.slice(0, this.MAX_KEYS);
		this.keys.forEach(k => this.label[k] = this.getLabel(k));
		this.keys.forEach(k => this.count[k] = this.filterType === 'hosting'
			? this.countApplicationTypePercent(k as HostingType)
			: this.countApplicationCategoryPercent(k));
		const total: number = Object.values(this.count)
			.reduce((a, b) => a + b, 0);
		Object.keys(this.count)
			.forEach(key => this.width[key] = (this.count[key] / Math.max(total, 1)) * 100);
	}

	private getLabel(key: string|HostingType): string {
		switch (key) {
			case HostingType.SAAS: return this.translate.instant('page.dashboard.typology.saas');
			case HostingType.HOSTED: return this.translate.instant('page.dashboard.typology.hosted');
			case HostingType.HOMEMADE: return this.translate.instant('page.dashboard.typology.home_made');
			case this.KEY_OTHERS: return this.translate.instant('page.dashboard.category.others');
			default: return this.topCategories.find(c => c.categoryId === key)!.name
		}
	}

	private countApplicationTypePercent(type: HostingType): number {
		return !this.applications.length
			? 0
			: Number(((this.applications.filter(a => a.application.hostingType === type).length / this.applications.length) * 100).toFixed(2));
	}

	private countApplicationCategoryPercent(categoryId?: string): number {
		return !this.applications.length
			? 0
			: (categoryId === this.KEY_OTHERS
				? Number(((this.applications.filter(a => !a.category || !this.topCategories.map(c => c.categoryId).includes(a.category.categoryId)).length / this.applications.length) * 100).toFixed(2))
				: Number(((this.applications.filter(a => a.category?.categoryId === categoryId).length / this.applications.length) * 100).toFixed(2)));
	}

	private setChartData(): void {
		this.doughnutChartData.datasets[0].data = [];
		this.doughnutChartData.datasets[0].backgroundColor = [];
		this.doughnutChartData.datasets[0].borderColor = [];
		this.keys.forEach((key, i) => {
			// @ts-ignore
			let color: `#${string}` = ColorEnum[`doughnut_colors_${i}`];
			this.addDataToChart(this.count[key], color);
			if (i < this.keys.length) {
				this.addDataToChart(0.3, '#FFFFFF');
			}
		});
	}

	private addDataToChart(data: number, color: `#${string}`): void {
		this.doughnutChartData.datasets[0].data.push(data);
		(this.doughnutChartData.datasets[0]?.backgroundColor as string[]).push(color);
		(this.doughnutChartData.datasets[0]?.borderColor as string[]).push(color);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}
