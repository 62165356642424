<div class="tabs-group-headers" [ngStyle]="{'grid-template-columns': 'repeat(' + tabCount + ', 1fr)'}">
	<div #selectedTabBackground class="tab-header-background"></div>
	<div class="tab-header-background-placeholder" *ngFor="let i of [].constructor(tabCount - 1)"></div>
	<div class="tab-header p-relative" [ngClass]="{'selected': tab.item.isVisible(), 'badge-background': tab.badge() }" (click)="displayTab(tab)" *ngFor="let tab of tabs">
		{{ tab.label() }}
		<div class="tab-badge flex-center" *ngIf="tab.badge() != undefined">
			<span class="font-S fw-500">{{ tab.badge() }}</span>
		</div>
	</div>
</div>

<ng-content></ng-content>
