
export interface FileLink {
  fileLinkId: string;
  type: FileType;
  name: string;
  link: string|null;
  size: number|null;
  createdAt: string;
}

export interface FileUploadForm {
  file: File;
}

export interface FileLinkForm {
  link: string;
}

export enum FileType {
  FILE = 'file',
  LINK = 'link'
}
