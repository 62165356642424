import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {finalize, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {TenantService} from "src/app/services/tenant.service";
import {OrganizationService, OrganizationTree} from "src/app/services/organization.service";
import {Team} from 'src/app/services/usage.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {ApplicationOrganizationListForm} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {PlaceholderActionEvent} from "../../../../../design-system/dialog-placeholder-card/dialog-placeholder-card.component";
import {ActivatedRoute, Router} from "@angular/router";
import {MainMenuPath} from "src/app/models/home/navigation.model";
import {RightSliderService} from "src/app/services/front/right-slider.service";
import {OrganizationType} from 'src/app/services/model/autodiscover.model';

@Component({
  templateUrl: './add-team-dialog.component.html',
  styleUrls: ['./add-team-dialog.component.scss']
})
export class AddTeamDialogComponent implements OnInit, OnDestroy {

	tenantId: string;

	isLoading: boolean = false;

	openedStructure: Map<string, OrganizationTree> = new Map<string, OrganizationTree>();
	selectedLeaf: Map<string, OrganizationTree> = new Map<string, OrganizationTree>();
	structures: OrganizationTree[] = [];

	subscription: Subscription = new Subscription();

	constructor(public dialogRef: MatDialogRef<AddTeamDialogComponent>,
							@Inject(MAT_DIALOG_DATA) public data: AddTeamDialogData,
							protected organizationService: OrganizationService,
							protected applicationService: NewApplicationService,
							protected currentTenantService: CurrentTenantService,
							protected rightSliderService: RightSliderService,
							protected router: Router,
							protected currentRoute: ActivatedRoute,
							protected tenantService: TenantService) {
	}

	ngOnInit(): void {
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => this.organizationService.getOrganizationTreeByTenantId(this.tenantId)),
				tap(organization => this.setOrganizationTree(organization)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	setOrganizationTree(organization: OrganizationTree): void {
		this.structures = organization.children.filter(c => c.children.length > 0);

		if (this.structures.length === 1) {
			this.openedStructure.set(this.structures[0].organization.organizationId, this.structures[0]);
		}

		// Set selected structures
		this.data.selectedTeams.forEach(team => {
			const structure: OrganizationTree|undefined = this.structures.find(structure => structure.organization.organizationId === team.structure);
			if (structure) {
				this.selectedLeaf.set(structure.organization.organizationId, structure);
			}

			this.structures.flatMap(structure => structure.children).forEach(child => {
				if (child.organization.organizationId === team.businessUnit) {
					this.selectedLeaf.set(child.organization.organizationId, child);
				}
			});
		});
	}

	isOpen(structureId: string): boolean {
		return this.openedStructure.has(structureId);
	}

	toggleOpen(structure: OrganizationTree): void {
		if (this.isOpen(structure.organization.organizationId)) {
			this.openedStructure.delete(structure.organization.organizationId);
		} else {
			this.openedStructure.set(structure.organization.organizationId, structure);
		}
	}

	onStructureChange(structure: OrganizationTree, isSelected: boolean): void {
		if (isSelected) {
			this.selectedLeaf.set(structure.organization.organizationId, structure);
			structure.children.forEach(child => this.selectedLeaf.set(child.organization.organizationId, child));
		} else {
			this.selectedLeaf.delete(structure.organization.organizationId);
			structure.children.forEach(child => this.selectedLeaf.delete(child.organization.organizationId));
		}
	}

	onTeamChange(structure: OrganizationTree, team: OrganizationTree, isSelected: boolean): void {
		if (isSelected) {
			this.selectedLeaf.set(structure.organization.organizationId, structure);
			this.selectedLeaf.set(team.organization.organizationId, team);
		} else {
			this.selectedLeaf.delete(team.organization.organizationId);

			// Check if all teams are unselected
			if (this.selectedLeaf.has(structure.organization.organizationId)) {
				const teams = structure.children.filter(child => child.organization.type === OrganizationType.BUSINESS_UNIT);
				if (teams && teams.every(team => !this.selectedLeaf.has(team.organization.organizationId))) {
					this.selectedLeaf.delete(structure.organization.organizationId);
				}
			}
		}
	}

	isSelected(structureId: string): boolean {
		return this.selectedLeaf.has(structureId);
	}

	isConfirming = false;

	onConfirm(): void {
		this.isConfirming = true;

		const selectedTeams = Array.from(this.selectedLeaf.values()).filter(leaf => leaf.organization.type === OrganizationType.BUSINESS_UNIT);
		const form: ApplicationOrganizationListForm = {
			organizations: selectedTeams.map(team => ({organizationId: team.organization.organizationId}))
		}
		this.applicationService.updateApplicationOrganizationList(this.tenantId, this.data.applicationId, form).subscribe({
			next: () => {
				this.isConfirming = false
				this.dialogRef.close(true);
			},
			error: () => {
				this.isConfirming = false
			}
		})
	}

	onDismiss(): void {
		this.dialogRef.close(false);
	}

	handlePlaceholderEvent(event: PlaceholderActionEvent): void {
		if (event.reason === 'close' || event.reason === 'cancel') {
			this.dialogRef.close(false);
		} else {
			this.router.navigate([MainMenuPath.ORGANIZATION], {relativeTo: this.currentRoute, queryParams: this.currentRoute.snapshot.queryParams});
			this.dialogRef.afterClosed()
				.pipe(tap(() => this.rightSliderService.close()))
				.subscribe()
			this.dialogRef.close()
		}
	}

	isAutoDiscovered(tree: OrganizationTree): boolean {
		return this.data.autodiscoveredTeams.some(t => t.businessUnit === tree.organization.organizationId)
			|| tree.children.some(child => this.data.autodiscoveredTeams.find(t => t.businessUnit === child.organization.organizationId));
	}

	switchLoading(): Observable<{}> {
		this.isLoading = !this.isLoading;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface AddTeamDialogData {
	applicationId: string;
	selectedTeams: Team[];
	autodiscoveredTeams: Team[];
}
