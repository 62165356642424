<div class="font-MM fw-700">{{ 'page.user.title' | translate }}</div>


<div class="flex-space-between gap-L mt-m">
	<div class="font-M fw-500 l-h-n">{{ 'page.user.userList' | translate }}</div>
	<app-mini-button  [disable]="!isAdmin" (onClick)="openInviteUsersPopup()">
		<img  src="/assets/icons/add.svg" height="13px" class="filter-accent rotate">
	</app-mini-button>
</div>

<!-- Loader -->
<div style="height: 400px;" class="pl-l mt-xl" *ngIf="isLoadingUsers">
	<app-organization-loader [rowCount]="3"></app-organization-loader>
</div>

<!-- User list -->
<div *ngFor="let user of users; let index = index" class="flex flex-space-between table-line mt-m">
	<div class="flex-align-center w-50">
		<app-display-user [user]="user" bold email></app-display-user>
	</div>

	<div class="flex flex-justify-end gap-L">
		<!-- Role -->
		<div class="flex-align-center ">
			@if(!user.pending) {
				<app-multi-select [disabled]="!user.pending && (!isAdmin || currentUser?.id === user.account.accountId)" [inputControl]="getUserRoleControlForm(user.account.accountId)">
					<app-select-trigger appearance="chips">{{ 'global.role.' + user.role | translate }}</app-select-trigger>

					<app-select-option *ngFor="let role of roles" [value]="role.id">
						<div class="pad-S-0">
							<div class="fw-600">{{ 'global.' + role.value | translate }}</div>
							<div class="grey mt-xs">{{ 'page.user.' + role.value | translate }}</div>
						</div>
					</app-select-option>
				</app-multi-select>
			}
		</div>

		<!-- Status -->
		<div class="flex-align-center">
			<app-chips-text *ngIf="!user.pending" size="s" color="success">{{ 'page.user.active' | translate }}</app-chips-text>
			<app-chips-text *ngIf="user.pending" size="s" color="grey">{{ 'page.user.waiting' | translate }}</app-chips-text>
		</div>

		<!-- Delete -->
		<div class="flex-center">
			@if(isAdmin && currentUser?.id !== user.account.accountId) {
				<app-icon-button color="danger" [img]="'assets/icons/trash.svg'" (click)="onOpenUserPopup(user, $event)"></app-icon-button>
			} @else {
				<!-- Placeholder -->
				<div style="width: 37px"></div>
			}
		</div>
	</div>
</div>
