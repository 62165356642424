import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {AutodiscoverComponent} from "./autodiscover.component";
import {ApplicationIconModule} from "../../../global/application-icon/application-icon.module";
import {MiniButtonModule} from "../../../global/button/mini-button/mini-button.module";
import {MatDividerModule} from "@angular/material/divider";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    ApplicationIconModule,
    MiniButtonModule,
    MatDividerModule,
    DesignSystemModule,
  ],
	providers: [
	],
	declarations: [
		AutodiscoverComponent
	],
	exports: [
		AutodiscoverComponent
	]
})
export class AutodiscoverModule {
}
