import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {distinctUntilChanged, finalize, mergeMap, Observable, of, Subscription, takeWhile, tap} from 'rxjs';
import {SETTINGS_ROVERS_URL} from 'src/app/models/home/navigation.model';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {filter, first, map} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {Router} from '@angular/router';
import {ApplicationSettingUsageForm, ApplicationInstance} from 'src/app/services/model/new-application.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';

@Component({
	selector: 'app-application-setting-usage',
	templateUrl: './application-setting-usage.component.html',
	styleUrls: ['../application-settings.component.scss']
})
export class ApplicationSettingUsageComponent implements OnInit, OnDestroy {

	tenantId: string;
	isEditor: boolean;
	application: ApplicationInstance;

	_initializing: boolean;
	_loading: boolean;
	initialized = false;

	usageForm: FormGroup;
	updateUsageOnBlur = new EventEmitter<Form>();

	formName = Form;

	subscription: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationService: NewApplicationService,
							private translate: TranslateService,
							private snackBar: SnackbarService,
							private router: Router) {
	}

	ngOnInit() {
		this.buildForm();
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				tap(() => this.setDefaultData()),
				tap(() => this.fixMarkAsTouched()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private buildForm(): void {
		this.usageForm = new FormGroup({
			[Form.usagePath]: new FormControl(undefined, []),
			[Form.usageDomain]: new FormControl(undefined, []),
			[Form.usageCheck]: new FormControl(false, []),
		});
		this.usageForm.disable();
		this.subscription.add(this.usageForm.get(Form.usageDomain)!.valueChanges
			.pipe(distinctUntilChanged())
			.subscribe(() => this.checkUsageFormDisabled()));
		this.subscription.add(this.updateUsageOnBlur
			.pipe(filter(() => !this.isUsageFormValid()))
			.subscribe(() => this.setUsageFormDefaultData(this.application)));
		this.updateUsageOnBlur
			.pipe(
				filter(formName => this.isUsageFormValid() && this.checkUsageFormChanged(formName)),
				map(() => this.buildApplicationSettingUsageForm()),
				mergeMap(form => this.applicationService.updateApplicationSettingUsage(this.tenantId, this.application.applicationId, form)),
				tap(updateSucceed => this.displayUpdatedSucceed(updateSucceed)),
				tap(() => this.applicationDetailService.refreshApplicationInstance()),
				takeWhile(() => !this.subscription.closed))
			.subscribe();
	}

	private fixMarkAsTouched(): void {
		Object.values(Form)
			.map(name => this.usageForm.get(name)!)
			.filter(control => !!control.validator)
			.forEach(control => control.valueChanges.pipe(first()).subscribe(() => control.markAsTouched()));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.application = data.instance;
	}

	private setDefaultData(): void {
		this.setUsageFormDefaultData(this.application);
		this.checkUsageFormDisabled();
	}

	private setUsageFormDefaultData(data: ApplicationInstance): void {
		this.usageForm.get(Form.usagePath)!.setValue(data.usagePath);
		this.usageForm.get(Form.usageDomain)!.setValue(data.usageDomain);
		this.usageForm.get(Form.usageCheck)!.setValue(data.usageActivated);
	}

	checkUsageFormDisabled(): void {
		if (!this.isEditor) {
			this.usageForm.disable();
		} else {
			this.usageForm.enable();
			if (!this.usageForm.get(Form.usageDomain)!.value || !this.usageForm.get(Form.usageDomain)!.valid) {
				this.usageForm.get(Form.usagePath)!.disable();
				this.usageForm.get(Form.usageCheck)!.setValue(false);
				this.usageForm.get(Form.usageCheck)!.disable();
				this.usageForm.get(Form.usagePath)!.setValue(undefined);
			} else {
				this.usageForm.get(Form.usagePath)!.enable();
				this.usageForm.get(Form.usageCheck)!.enable();
			}
		}
	}

	isUsageFormValid(): boolean {
		return this.usageForm.dirty && this.usageForm.valid && this.usageForm.enabled;
	}

	checkUsageFormChanged(formName?: Form): boolean {
		return !formName
			?  Object.values(Form).map(formName => this.checkUsageForm(formName)).reduce((a, b) => a || b)
			: this.checkUsageForm(formName);
	}

	private checkUsageForm(formName: Form): boolean {
		switch (formName) {
			case Form.usageCheck: return this.usageForm.get(Form.usageCheck)!.value !== this.application.usageActivated;
			case Form.usageDomain: return this.usageForm.get(Form.usageDomain)!.value !== this.application.usageDomain;
			case Form.usagePath: return this.usageForm.get(Form.usagePath)!.value !== this.application.usagePath;
		}
	}

	private buildApplicationSettingUsageForm(): ApplicationSettingUsageForm {
		return {
			usageActivated: this.usageForm.get(Form.usageCheck)!.value,
			usageDomain: this.usageForm.get(Form.usageDomain)!.value,
			usagePath: this.usageForm.get(Form.usagePath)!.value,
		};
	}

	displayUpdatedSucceed(updateSucceed: boolean): void {
		if (updateSucceed) {
			this.snackBar.show(this.translate.instant('page.application.detail.update.success'));
		} else {
			this.snackBar.show(this.translate.instant('page.application.detail.update.fail'), 'danger-snack');
		}
	}

	goToSettings() {
		this.router.navigate([SETTINGS_ROVERS_URL], { queryParams: { tenant: this.tenantId } });
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

enum Form {
	usageDomain = 'usageDomain',
	usagePath = 'usagePath',
	usageCheck = 'usageCheck',
}
