<div *ngIf="elements.length > 0">
	<div *ngFor="let elt of elements" class="border pl-s pr-s pt-s pb-s mb-s">
		<div class="disp-f f-d-r gap-S">
			<div *ngIf="elt.type === types.BUSINESS_UNIT" class="list-icon disp-f justify-center a-i-c">
				<img [src]="!!elt.icon ? ('/assets/icons/team_icon/' + elt.icon + '.svg') : 'assets/icons/team.svg'" class="filter-accent">
			</div>
			<div *ngIf="elt.type === types.PROCESS" class="list-icon disp-f justify-center a-i-c">
				<img src="assets/icons/process_gear_new.svg" class="filter-accent">
			</div>
			<div *ngIf="subtitle" class="flex-column flex-space-evenly">
				<div class="font-M fw-700">
					{{ elt.name }}
				</div>
				<div class="font-S fw-500">
					{{ subtitle }}
				</div>
			</div>
			<div *ngIf="!subtitle" class="flex-align-center">
				<div class="font-M fw-700">
					{{ elt.name }}
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="elements.length <= 0" style="height: 150px;">
	<div class="disp-f a-i-c j-c-c f-d-col" style="gap: 15px; margin-top: 60px; margin-bottom: 60px;">
		<div class="disp-f a-i-c j-c-c grey">
			<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9.87533 16.9993L14.6253 21.7493L24.1253 12.2493M32.8337 16.9993C32.8337 25.7439
							25.7448 32.8327 17.0003 32.8327C8.25582 32.8327 1.16699 25.7439 1.16699 16.9993C1.16699
							8.25484 8.25582 1.16602 17.0003 1.16602C25.7448 1.16602 32.8337 8.25484 32.8337 16.9993Z"
					  stroke="#B5B5C3"
					  stroke-width="2"
					  stroke-linecap="round"
					  stroke-linejoin="round"/>
			</svg>
		</div>
		<div class="font-M fw-500 grey">
			{{ placeholder }}
		</div>
	</div>
</div>
