<div class="flex-align-center gap-S w-100 h-100 bkg-white b-radius-9 border-box diagram-breadcrumb">
    <div class="flex-center icon-container">
        <img class="filter-grey" alt="diagram icon" height="18" src="assets/icons/map-outline.svg">
    </div>

    <div class="flex-align-center gap-S">
        <span class="font-L fw-700 c-p underlined-hover" (click)="back()">{{'page.diagram.breadcrumb.root'|translate}}</span>
        @if (diagramLabel) {
            <img ngSrc="/assets/icons/arrow-right-black.svg" alt="" height="10" width="6">
            <span class="font-L fw-500">{{diagramLabel|translate}}</span>
        }
    </div>
</div>
