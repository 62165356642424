<div class="flex-wrap gap-L pad-XL">
    <div matRipple [routerLink]="path.APP_MAPPING" class="schema-card c-p">
        <div class="card-content pad-L">
            <img width="330" alt="mapping background" src="assets/illustrations/mapping-background.svg">
        </div>
        <div class="card-footer flex-column gap-S">
            <div class="font-L fw-700">{{ 'page.diagram.flowDiagram.title' | translate}}</div>
            <div class="font-S fw-500 grey l-h-n">{{ 'page.diagram.flowDiagram.desc' | translate}}</div>
        </div>
    </div>

    <div matRipple [routerLink]="path.APP_MATRIX" class="schema-card c-p">
        <div class="card-content pad-L">
            <img width="330" alt="mapping background" src="assets/illustrations/matrice-background.svg">
        </div>
        <div class="card-footer flex-column gap-S">
            <div class="font-L fw-700">{{ 'page.diagram.applicationMatrix.title' | translate}}</div>
            <div class="font-S fw-500 grey l-h-n">{{ 'page.diagram.applicationMatrix.desc' | translate}}</div>
        </div>
    </div>

    <div matRipple [routerLink]="path.APP_LIFE_CYCLE" class="schema-card c-p">
        <div class="flex card-content pad-L">
            <img width="330" alt="mapping background" src="assets/illustrations/life-cycle-calendar.svg">
        </div>
        <div class="card-footer flex-column gap-S">
            <div class="font-L fw-700">{{ 'page.diagram.lifeCycle.title' | translate}}</div>
            <div class="font-S fw-500 grey l-h-n">{{ 'page.diagram.lifeCycle.desc' | translate}}</div>
        </div>
    </div>

	<div matRipple [routerLink]="path.APP_COMPARISON" class="schema-card c-p">
		<div class="flex card-content pad-L">
			<img width="330" alt="mapping background" src="assets/illustrations/app-comparison.svg">
		</div>
		<div class="card-footer flex-column gap-S">
			<div class="font-L fw-700">{{ 'page.diagram.appComparison.title' | translate}}</div>
			<div class="font-S fw-500 grey l-h-n">{{ 'page.diagram.appComparison.desc' | translate}}</div>
		</div>
	</div>
</div>
