import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {UsageHistory} from "src/app/services/usage.service";
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {ColorEnum} from "src/_variables";
import {BaseChartDirective} from "ng2-charts";
import {TranslateService} from "@ngx-translate/core";
import {ApplicationUrlService} from "src/app/services/front/application-url.service";
import {AppSettingsMode} from "../../application-settings/application-settings.component";
import {ApplicationInstance} from 'src/app/services/model/new-application.model';

@Component({
  selector: 'app-usage-histogram',
  templateUrl: './usage-histogram.component.html',
  styleUrls: ['./usage-histogram.component.scss']
})
export class UsageHistogramComponent implements OnInit {

	@Input() usagePerHour: UsageHistory[];
	@Input() usagePerDay: UsageHistory[];
	@Input() application: ApplicationInstance;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	monthDaysNumber: Date[] = [];
	dayHoursNumber: Date[] = [];
	histogramRange: 'day' | 'month' = 'month';
	tooltip = { display: "none", top: "0px", left: "0px", day: 0, month: 0, year: 0, hour: 0, value: 0 };

	public barChartType: ChartType = 'line';
	public barOptions: ChartOptions = {
		interaction: {
			intersect: false,
			mode: 'index',
		},
		parsing: {
			xAxisKey: 'id',
			yAxisKey: 'value'
		},
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				enabled: false,
				external: context => {

					const {chart, tooltip} = context;

					if (tooltip.opacity === 0) {
						if (this.tooltip.display !== "none") {
							this.tooltip.display = "none"
							this.cdr.detectChanges()
						}
						return
					}

					const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;



					const canvasWidth = chart.canvas.getBoundingClientRect().width;

					let xPosition = positionX + tooltip.caretX;

					if (xPosition + 180 > canvasWidth) {
						xPosition = positionX + tooltip.caretX + 180 - canvasWidth
						xPosition = positionX + tooltip.caretX - xPosition
					}

					// @ts-ignore
					const date: Date  = tooltip.dataPoints[0].raw.date

					const hour = date.getHours()

					const day = date.getDate()

					const month = date.getMonth() + 1

					const year = date.getFullYear()

					// @ts-ignore
					const value = /*Math.floor(tooltip.dataPoints[0].parsed.y * 100) / 100//*/tooltip.dataPoints[0].raw.value;

					this.tooltip = {
						display: "block",
						left: xPosition + 'px',
						top: positionY + tooltip.caretY + 6 + 'px',
						day: day,
						month: month,
						year: year,
						hour: hour,
						value: value
					}

					this.cdr.detectChanges()
				},
			}
		},
		scales: {
			y: {
				suggestedMin: 0,
				suggestedMax: 4,
				display: true,
				grid: {
					lineWidth: 2,
					borderDash: [2,2],
					drawTicks: false,
					drawBorder: false,
					color: ColorEnum.medium_grey,
				},
				ticks: {
					crossAlign:"center",
					callback: function(value) {if (typeof value == "number" && value % 1 === 0) {return value} else {return undefined}},
					padding: 6,
					font: {
						family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
					},
					color: "#1D3B58"
				}
			},
			x: {
				ticks: {
					font: {
						family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
						size: 9
					},
					color: "#B5B5C3"
				},
				grid: {
					lineWidth: 0,
					drawBorder: false
				}
			},

		}
	};
	public barChartData: ChartData<'line', {id: number, date: Date, value: number}[]> = {
		labels: [],
		datasets: []
	};

	constructor(private translate: TranslateService,
              private applicationUrlService: ApplicationUrlService,
              private cdr: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.getAllMonthDaysNumber();
		this.getAllDayHoursNumber();
		this.monthDaysNumber.reverse();
		this.dayHoursNumber.reverse();
		this.histogramDataPreparation()
	}

	histogramRangeChange(): void {
		this.histogramRange = this.histogramRange == 'day' ? 'month' : 'day';
		this.histogramDataPreparation();
	}

	getGradient(ctx: any, chartArea: any): any {
		let width, height, gradient;
		const chartWidth = chartArea.right - chartArea.left;
		const chartHeight = chartArea.bottom - chartArea.top;
		if (gradient === null || width !== chartWidth || height !== chartHeight) {
			gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
			gradient.addColorStop(0, 'rgba(61, 192, 255, 0)');
			gradient.addColorStop(0.5, 'rgba(61, 192, 255, 0.2)');
			gradient.addColorStop(1, 'rgba(61, 192, 255, 0.6)');
		}

		return gradient;
	}

	getAllDayHoursNumber(): void {
		this.dayHoursNumber = [];
		for (let i = 0; i < 24; i++) {
			let date = new Date();
			date.setHours(date.getHours() - i);
			this.dayHoursNumber.push(date);
		}
	}
	getAllMonthDaysNumber(): void {
		this.monthDaysNumber = [];
		for (let i = 0; i < 30; i++) {
			let date = new Date();
			date.setDate(date.getDate() - i);
			this.monthDaysNumber.push(date);
		}
	}

	histogramDataPreparation(): void {
		let dataset: UsageHistory[] = [];
		let labels: Date[] = [];
		switch (this.histogramRange) {
			case "day":
				dataset = this.usagePerHour;
				labels = this.dayHoursNumber;
				break;
			case "month":
				dataset = this.usagePerDay;
				labels = this.monthDaysNumber;
				break;
		}
		let data = dataset.map(it => {
			return {
				id: this.histogramRange == "day" ? new Date(it.date).getHours() : new Date(it.date).getDate(),
				date: new Date(it.date),
				value: it.usage.value ?? 0
			};
		});
		labels.forEach((it) => {
			if (!data.find(el => el.id == (this.histogramRange == "day" ? it.getHours() : it.getDate()))) {
				data.push({id: (this.histogramRange == "day" ? it.getHours() : it.getDate()), date: it, value: 0});
			}
		});

		data.sort((a, b) => a.date.getTime() - b.date.getTime());
		if ((this.histogramRange == "day" && data.length > 24) || (this.histogramRange == "month" && data.length > 30)) {
			data.splice(0, 1);
		}

		this.barChartData = {
			labels: this.histogramRange == "day" ? labels.map(it => it.getHours()) : labels.map(it => it.getDate()),
			datasets: [
				{
					label: this.translate.instant('page.dashboard.documentation.update'),
					// @ts-ignore
					data: data,
					backgroundColor: (context) => {
						const chart = context.chart;
						const {ctx, chartArea} = chart;

						if (!chartArea) {
							return;
						}
						return this.getGradient(ctx, chartArea);
					},
					tension: 0.3,
					borderColor: "#3DC0FF",
					hoverBackgroundColor: "#3DC0FF",
					fill: true,
					pointStyle: "circle",
					pointRadius: 0.01,
					pointBorderColor: "#3DC0FF",
					pointBorderWidth: 1.5,
					pointBackgroundColor: "#FFFFFF",
					pointHoverBackgroundColor: "#3DC0FF",
					pointHoverRadius: 6,
					pointHoverBorderColor: "#3DC0FF",
					borderWidth: 2,
					cubicInterpolationMode: "monotone"
				}
			]
		};
	}

	navigateToUsageParameter(): void {
		if (!this.application.usageActivated) {
			this.applicationUrlService.navigateInAndOutOfParameter(AppSettingsMode.USAGE_URL);
		}
	}
}
