import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-chips-button',
  templateUrl: './chips-button.component.html',
  styleUrls: ['./chips-button.component.scss']
})
export class ChipsButtonComponent {
  @Input() disabled: boolean = false;
}
