<div class="h-100">
    <div  class="flex-space-between pad-L-0 pad-0-XL border-grey-bottom bkg-white">
        <div class="flex-align-center gap-S w-100">
            <div class="flex-center bkg-accent-light b-radius-9" style="height: 45px; width: 45px">
                <img class="filter-accent" src="/assets/icons/user-up-01.svg" height="24" alt="">
            </div>
            <div class="flex-column gap-XS">
                <div class="font-L fw-700">{{'page.drawer.usageTitle' | translate}}</div>
                <div class="font-M fw-300">{{'page.drawer.usageSubtitle' | translate}}</div>
            </div>
        </div>
        <div class="flex-align-center gap-S">
            <app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
                             (click)="close()">
            </app-icon-button>
        </div>
    </div>

    <div class="flex-column pad-XL gap-XL bkg-light-grey-2 overflow-y" style="height: calc(100% - 136px);">
        <div class="flex gap-M">
            <div class="flex-align-center bkg-white border-grey b-radius-9 pad-0-M gap-M" style="height: 50px; width: calc(100% - 98px); border-width: 0.5px !important;">
                <div class="flex-center">
                    <img src="assets/icons/search-md.svg" width="18" height="18" alt=""/>
                </div>
                <input class="search-input" [formControl]="search"
                       [placeholder]="'page.application.table.filters.search'|translate">
            </div>
            <div class="flex-align-center border-grey bkg-white b-radius-9" style="padding: 3px; border-width: 0.5px !important;">
                <app-text-button type="default" color="primary"  style="width: 43px; height: 43px;" panelClass="h-100-imp pad-0-imp"
                                 [matMenuTriggerFor]="selectMenu">
                    <div class="flex-center">
                        <img src="assets/icons/dots-vertical.svg" height="18" alt=""/>
                    </div>
                </app-text-button>
                <mat-menu #selectMenu class="mat-menu-custom"
                          xPosition="before" yPosition="below">
                    <div class="flex-align-center gap-M greyed-hover b-radius-6 c-p" style="padding: 6px;"
                         (click)="export.next(type.CSV)">
                        <img src="assets/icons/share.svg" width="18" height="18" alt=""/>
                        <div class="font-M fw-500">{{'page.application.table.filters.export'|translate}}</div>
                    </div>
                    <div class="flex-align-center gap-M greyed-hover b-radius-6 c-p" style="padding: 6px;"
                         (click)="export.next(type.XLSX)">
                        <img src="assets/icons/layout-grid-02.svg" width="18" height="18" alt=""/>
                        <div class="font-M fw-500">{{'page.application.table.filters.exportX'|translate}}</div>
                    </div>
                </mat-menu>
            </div>
        </div>

        <div class="bkg-white border-grey b-radius-14" style="border-width: 0.5px !important;">
            <app-most-used-apps [defaultFilter]="data.filter" [search]="search.valueChanges" [export]="export" type="drawer"></app-most-used-apps>
        </div>
    </div>
</div>
