import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable()
export class SnackbarService {

	private snackbarSubject = new Subject<any>();
	public snackbarState = this.snackbarSubject.asObservable();

	constructor() { }

	show(message: string, type?: 'success-snack' | 'danger-snack') {
		this.snackbarSubject.next({
			show: true,
			message,
			type
		});
	}
}
