import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from '../security.utils';
import {ApplicationMapping, ApplicationMappingData, ApplicationMappingDataForm, ApplicationMappingForm} from '../model/application-mapping.model';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';

@Injectable()
export class ApplicationMappingService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/application-mapping`;

	constructor(protected httpClient: HttpClient) {
	}

	getAllApplicationMappingByApplicationId(tenantId: string, applicationId: string): Observable<ApplicationMapping[]> {
		return this.httpClient.get<ApplicationMapping[]>(`${this.serviceUrl(tenantId)}/applications/${applicationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getAllUsedPortByTenantId(tenantId: string): Observable<string[]> {
		return this.httpClient.get<string[]>(`${this.serviceUrl(tenantId)}/applications/ports`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	createApplicationMapping(tenantId: string, form: ApplicationMappingForm): Observable<string> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateApplicationMapping(tenantId: string, flowId: string, form: ApplicationMappingForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${flowId}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	deleteApplicationMapping(tenantId: string, flowId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/${flowId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getAllApplicationMappingDataByTenantId(tenantId: string): Observable<ApplicationMappingData[]> {
		return this.httpClient.get<ApplicationMappingData[]>(`${this.serviceUrl(tenantId)}/mapping-data`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	createApplicationMappingData(tenantId: string, form: ApplicationMappingDataForm): Observable<string> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}/mapping-data`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}
}
