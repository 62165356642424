import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DiagramOverviewComponent} from 'src/app/modules/home/diagrams/diagram-overview/diagram-overview.component';
import {MatRippleModule} from '@angular/material/core';
import {RouterLink} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    DiagramOverviewComponent
  ],
  imports: [
    CommonModule,
    MatRippleModule,
    RouterLink,
    TranslateModule,
  ],
  exports: [
    DiagramOverviewComponent
  ]
})
export class DiagramOverviewModule {
}
