import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {finalize, forkJoin, interval, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {CriticalityLevel, DiagramApplicationLifeCycle, DiagramApplicationLifeCycleFilterForm, TenantService} from 'src/app/services/tenant.service';
import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {rangeIterable} from 'src/app/utils/html.utils';
import {filter, first, map} from 'rxjs/operators';
import {Organization, OrganizationService, OrganizationTree} from 'src/app/services/organization.service';
import {FormControl, FormGroup} from '@angular/forms';
import {ApplicationDetailComponent, ApplicationDetailInput} from 'src/app/modules/home/applications/application-detail/application-detail.component';
import {RightSliderService} from 'src/app/services/front/right-slider.service';
import {Category} from 'src/app/services/model/application-category.model';
import {ApplicationCategoryService} from 'src/app/services/back/application-category.service';

@Component({
  selector: 'app-diagram-life-cycle',
  templateUrl: './diagram-life-cycle.component.html',
  styleUrls: ['./diagram-life-cycle.component.scss']
})
export class DiagramLifeCycleComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('backgroundPanel') backgroundPanel: ElementRef;
  @ViewChild('lifeCyclePanel') lifeCyclePanel: ElementRef;
  @ViewChild('applicationPanel') applicationPanel: ElementRef;

  tenantId: string;
  lifeCycles: LifeCycleRow[] = [];
  monthScales: Date[] = [];
  now: Date = new Date();
  nowIndex: number;

  _initializing: boolean;
  _loading: boolean;
  _loadingFilter: boolean;
  _exporting: boolean;

  unit: number = 50;
  scrollOffsetX: number = 0;
  cursorOffsetX: number = 0;
  zoomOffsetPercent: number = 0;
  readonly STEP_SIZE: number = 50;
  readonly INITIAL_ZOOM: number = 5;

  filterForm: FormGroup;
  searchOrganizationControl: FormControl;
  organizations: OrganizationTree[] = [];
  searchCategoryControl: FormControl;
  categories: Category[] = [];
  filterData: {
    organization: OrganizationTree[],
    criticality: CriticalityLevel[],
    category: Category[],
  };
  formName: typeof Form = Form;
  activeFilters: number = 0;

  subscription: Subscription = new Subscription();

  constructor(private applicationCategoryService: ApplicationCategoryService,
              private currentTenantService: CurrentTenantService,
              private organizationService: OrganizationService,
              private rightSliderService: RightSliderService,
              private tenantService: TenantService,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.createFilterForm();
    this.subscription.add(this.currentTenantService.getInitializingChanges()
      .subscribe(initializing => this._initializing = initializing));
    this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
      .pipe(tap(tenantId => this.tenantId = tenantId))
      .subscribe(() => this.initialize()));
    this.renderer.addClass(document.body, 'disable-two-finger-back');
  }

  ngAfterViewInit(): void {
    this.addCommonScrollListener();
    this.addZoomTargetListener();
    this.addGrabbingListener();
  }

  createFilterForm(): void {
    this.filterForm = new FormGroup({
      [Form.organization]: new FormControl([]),
      [Form.criticality]: new FormControl([]),
      [Form.category]: new FormControl([]),
      [Form.withNoData]: new FormControl(false),
    });
    this.subscription.add(this.filterForm.valueChanges
      .pipe(
        tap(() => this.setActiveFilters()),
        switchMap(() => this.fetchApplicationLifeCycle()))
      .subscribe());
    this.searchOrganizationControl = new FormControl('');
    this.subscription.add(this.searchOrganizationControl.valueChanges
      .subscribe(search => this.searchOrganizationFilter(search)));
    this.searchCategoryControl = new FormControl('');
    this.subscription.add(this.searchCategoryControl.valueChanges
      .subscribe(search => this.searchCategoryFilter(search)));
  }

  initialize(): void {
    this.subscription.add(this.fetchApplicationLifeCycle()
      .subscribe());
    this.subscription.add(this.fetchDiagramFilterData()
      .subscribe());
  }

  fetchApplicationLifeCycle(): Observable<{}> {
    return this.switchLoading().pipe(
      map(() => this.buildTenantApplicationLifeCycleForm()),
      switchMap(form => this.tenantService.getAllDiagramApplicationLifeCycle(this.tenantId, form)),
      tap(apps => this.setLifeCycles(apps)),
      finalize(() => this.switchLoading()));
  }

  private buildTenantApplicationLifeCycleForm(): DiagramApplicationLifeCycleFilterForm {
    return {
      organizations: this.organizationFormValue.map(o => o.organizationId),
      categories: this.categoryFormValue.map(c => c.categoryId),
      criticality: this.criticalityFormValue,
      withNoData: this.withNoDataFormValue,
    }
  }

  fetchDiagramFilterData(): Observable<{}> {
    return this.switchLoadingFilter().pipe(
      switchMap(() => forkJoin([
        this.organizationService.getOrganizationTreeByTenantId(this.tenantId),
        this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId),
      ])),
      tap(([organizations, categories]) => this.setFilterData(organizations, categories)),
      finalize(() => this.switchLoadingFilter()));
  }

  private setFilterData(organization: OrganizationTree, categories: Category[]): void {
    this.organizations = organization.children
      .filter(c => c.children.length > 0)
      .sort((a, b) => a.organization.name.localeCompare(b.organization.name));
    this.organizations.forEach(o => o.children.sort((a, b) => a.organization.name.localeCompare(b.organization.name)));
    this.categories = categories
      .sort((a, b) => a.name.localeCompare(b.name));
    this.filterData = {
      organization: this.organizations,
      category: this.categories,
      criticality: [CriticalityLevel.HIGH, CriticalityLevel.MEDIUM, CriticalityLevel.LOW]
    };
  }

  private addCommonScrollListener(): void {
    this.applicationPanel.nativeElement.addEventListener('scroll', (event: { target: { scrollLeft: number, scrollTop: number } }) => {
      this.backgroundPanel.nativeElement.scrollLeft = event.target.scrollLeft;
      this.lifeCyclePanel.nativeElement.scrollLeft = event.target.scrollLeft;
      this.lifeCyclePanel.nativeElement.scrollTop = event.target.scrollTop;
    }, { passive: true });
  }

  private addZoomTargetListener(): void {
    this.applicationPanel.nativeElement.addEventListener('mousemove', (e: { offsetX: number }) => {
      this.scrollOffsetX = this.applicationPanel.nativeElement.scrollLeft;
      this.cursorOffsetX = e.offsetX;
      this.zoomOffsetPercent = e.offsetX / (this.monthScales.length * this.unit);
    });
  }

  private addGrabbingListener(): void {
    let basePosition: BasePosition = { top: 0, left: 0, x: 0, y: 0, time: 0 };
    let lastPosition: LastPosition = { x: 0, y: 0, time: 0, holding: [0, 0, 0], velocity: [0, 0, 0] };
    let inertiaSub: Subscription = new Subscription();
    const mouseDownHandler = (e: { clientX: number, clientY: number }) => {
      inertiaSub.unsubscribe();
      this.applicationPanel.nativeElement.style.cursor = 'grabbing';
      this.applicationPanel.nativeElement.style.userSelect = 'none';
      basePosition = {
        left: this.applicationPanel.nativeElement.scrollLeft,
        top: this.applicationPanel.nativeElement.scrollTop,
        x: e.clientX,
        y: e.clientY,
        time: new Date().getTime()
      };
      this.applicationPanel.nativeElement.addEventListener('mousemove', mouseMoveHandler);
      this.applicationPanel.nativeElement.addEventListener('mouseup', mouseUpHandler);
      this.applicationPanel.nativeElement.addEventListener('mouseleave', mouseUpHandler);
    };
    const mouseMoveHandler = (e: { clientX: number, clientY: number }) => {
      this.applicationPanel.nativeElement.scrollTop = basePosition.top - (e.clientY - basePosition.y);
      this.applicationPanel.nativeElement.scrollLeft = basePosition.left - (e.clientX - basePosition.x);
      const velocity: number = inertiaSub.closed
        ? Math.sqrt(Math.pow(e.clientX - basePosition.x, 2) + Math.pow(e.clientY - basePosition.y, 2)) / (new Date().getTime() - lastPosition.time)
        : 0;
      const time: number = new Date().getTime();
      lastPosition = {
        x: e.clientX,
        y: e.clientY,
        time: time,
        holding: [...lastPosition.holding, time - lastPosition.time].slice(-3),
        velocity: [...lastPosition.velocity, Math.trunc(velocity)].slice(-3)
      };
    };
    const mouseUpHandler = (e: { clientX: number, clientY: number }) => {
      this.applicationPanel.nativeElement.removeEventListener('mousemove', mouseMoveHandler);
      this.applicationPanel.nativeElement.removeEventListener('mouseup', mouseUpHandler);
      this.applicationPanel.nativeElement.removeEventListener('mouseleave', mouseUpHandler);
      this.applicationPanel.nativeElement.style.cursor = 'grab';
      this.applicationPanel.nativeElement.style.removeProperty('user-select');
      /* TODO need improvements before going on PROD
      const holding: number = Math.max(...lastPosition.holding, new Date().getTime() - lastPosition.time);
      const velocity: number = Math.min(...lastPosition.velocity);
      if (holding < 50 && velocity > 15) {
        const tickRate: number = 10;
        const nbTicks: number = 100;
        const stepSizeX: number = (tickRate / nbTicks) * (e.clientX - basePosition.x);
        const stepSizeY: number = (tickRate / nbTicks) * (e.clientY - basePosition.y);
        inertiaSub = interval(tickRate)
          .pipe(take(nbTicks))
          .subscribe(() => mouseMoveHandler({clientX: lastPosition.x + stepSizeX, clientY: lastPosition.y + stepSizeY}));
      }*/
    };
    this.applicationPanel.nativeElement.addEventListener('mousedown', mouseDownHandler);
  }

  private setLifeCycles(lifeCycles: DiagramApplicationLifeCycle[]): void {
    const allDates: number[] = lifeCycles
      .map(lc => [lc.lifeCycle?.phaseInDate, lc.lifeCycle?.deployedDate, lc.lifeCycle?.phaseOutDate, lc.lifeCycle?.retiredDate])
      .flat()
      .filter(date => !!date)
      .map(date => new Date(date!).getTime());
    const minDate: number = allDates
      .reduce((acc, date) => date < acc ? date : acc, this.now.getTime());
    const maxDate: number = allDates
      .reduce((acc, date) => date > acc ? date : acc, this.now.getTime());
    // TODO Fix margin left and right (30 months currently) to have a minimum width when no enough data
    const firstAxisDate: Date = new Date(minDate);
    firstAxisDate.setMonth(firstAxisDate.getMonth() - 30);
    const lastAxisDate: Date = new Date(maxDate);
    lastAxisDate.setMonth(lastAxisDate.getMonth() + 30);

    this.monthScales = [];
    rangeIterable(firstAxisDate.getFullYear(), lastAxisDate.getFullYear()).forEach(year => {
      const from: number = year === firstAxisDate.getFullYear() ? firstAxisDate.getMonth() : 0;
      const to: number = year === lastAxisDate.getFullYear()? lastAxisDate.getMonth() : 11;
      rangeIterable(from, to).forEach(month => {
        this.monthScales.push(new Date(year, month, 1));
      });
    });
    this.nowIndex = this.monthScales.findIndex(d => d.getFullYear() === this.now.getFullYear() && d.getMonth() === this.now.getMonth());

    this.lifeCycles = lifeCycles.map(lc => {
      const firstPhase: Date|null = !lc.lifeCycle?.phaseInDate ? null : new Date(lc.lifeCycle!.phaseInDate);
      const secondPhase: Date|null = !lc.lifeCycle?.deployedDate
        ? (!lc.lifeCycle?.phaseInDate && !lc.lifeCycle?.phaseOutDate && !lc.lifeCycle?.retiredDate
          ? new Date(new Date(firstAxisDate.getTime()).setMonth(firstAxisDate.getMonth() + 1))
          : null)
        : new Date(lc.lifeCycle!.deployedDate);
      const thirdPhase: Date|null = !lc.lifeCycle?.phaseOutDate ? null : new Date(lc.lifeCycle!.phaseOutDate);
      const fourthPhase: Date|null = !lc.lifeCycle?.retiredDate ? null : new Date(lc.lifeCycle!.retiredDate);
      return {
        application: lc.application,
        deltaLeftWidth: this.countMonthBetween(firstAxisDate, firstPhase ?? secondPhase ?? thirdPhase ?? fourthPhase ?? lastAxisDate),
        phaseInWidth: !firstPhase ? 0 : this.countMonthBetween(firstPhase, secondPhase ?? thirdPhase ?? fourthPhase ?? lastAxisDate),
        deployedWidth: !secondPhase ? 0 : this.countMonthBetween(secondPhase, thirdPhase ?? fourthPhase ?? lastAxisDate),
        phaseOutWidth: !thirdPhase ? 0 : this.countMonthBetween(thirdPhase, fourthPhase ?? lastAxisDate),
        retiredWidth: !fourthPhase ? 0 : 2 * 18.5
      };
    }).sort((a, b) => a.deltaLeftWidth < b.deltaLeftWidth ? -1 : 1);
    this.setScrollPositionOnToday();
  }

  private countMonthBetween(from: Date, to: Date): number {
    return ((to.getFullYear() - from.getFullYear()) * 12) + (to.getMonth() - from.getMonth());
  }

  private searchOrganizationFilter(search?: string): void {
    const organizationGroups: OrganizationTree[] = JSON.parse(JSON.stringify(this.organizations.filter((group: OrganizationTree) => !search || group.organization.name.toLowerCase().includes(search.toLowerCase()) || group.children.some((option: OrganizationTree) => option.organization.name.toLowerCase().includes(search.toLowerCase())))));
    organizationGroups.forEach((group: OrganizationTree) => group.children = group.children.filter((option: OrganizationTree) => !search || option.organization.name.toLowerCase().includes(search.toLowerCase())));
    this.filterData.organization = organizationGroups;
  }

  private searchCategoryFilter(search?: string): void {
    this.filterData.category = this.categories.filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()));
  }

  setScrollPositionOnToday(): void {
    interval(10)
      .pipe(
        map(() => document.getElementById('today')),
        filter(element => !!element),
        first())
      .subscribe(element => this.applicationPanel.nativeElement.scrollLeft = element!.offsetLeft - (this.applicationPanel.nativeElement.clientWidth / 2));
  }

  onZoom(zoomLevel: number): void {
    const unit: number = Math.trunc(this.STEP_SIZE * (zoomLevel / this.INITIAL_ZOOM));
    this.unit = Math.min(Math.max(unit, this.unit * 0.8), this.unit * 1.2);
    // TODO Fix zooming on the max right
    this.applicationPanel.nativeElement.scrollLeft = (this.zoomOffsetPercent * this.monthScales.length * this.unit) - (this.cursorOffsetX - this.scrollOffsetX);
  }

  export(): void {
    this.switchExporting()
      .pipe(
        // TODO
        finalize(() => this.switchExporting()))
      .subscribe();
  }

  setActiveFilters(): void {
    this.activeFilters = this.organizationFormValue.length
      + this.criticalityFormValue.length
      + this.categoryFormValue.length
      + (this.withNoDataFormValue ? 1 : 0);
  }

  resetFilters(): void {
    this.filterForm.setValue({
      [Form.organization]: [],
      [Form.criticality]: [],
      [Form.category]: [],
      [Form.withNoData]: false,
    });
    this.filterForm.markAsPristine();
  }

  openApplicationDrawer(applicationId: string): void {
    const data: ApplicationDetailInput = {
      applicationId: applicationId
    };
    this.rightSliderService.openComponent(ApplicationDetailComponent, data)
      .pipe(switchMap(() => this.fetchApplicationLifeCycle()))
      .subscribe();
  }

  get organizationFormValue(): Organization[] {
    return this.filterForm.get(Form.organization)!.value;
  }

  get criticalityFormValue(): CriticalityLevel[] {
    return this.filterForm.get(Form.criticality)!.value;
  }

  get categoryFormValue(): Category[] {
    return this.filterForm.get(Form.category)!.value;
  }

  get withNoDataFormValue(): boolean {
    return this.filterForm.get(Form.withNoData)!.value;
  }

  private switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  private switchLoadingFilter(): Observable<{}> {
    this._loadingFilter = !this._loadingFilter;
    return of({});
  }

  private switchExporting(): Observable<{}> {
    this._exporting = !this._exporting;
    return of({});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.renderer.removeClass(document.body, 'disable-two-finger-back');
  }
}

interface LifeCycleRow {
  application: ApplicationGeneric;
  deltaLeftWidth: number;
  phaseInWidth: number;
  deployedWidth: number;
  phaseOutWidth: number;
  retiredWidth: number;
}

interface BasePosition {
  left: number;
  top: number;
  x: number;
  y: number;
  time: number;
}

interface LastPosition {
  x: number;
  y: number;
  time: number;
  holding: number[];
  velocity: number[];
}

enum Form {
  organization = 'organization',
  criticality = 'criticality',
  category = 'category',
  withNoData = 'withNoData',
}
