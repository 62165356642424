import {Component, EventEmitter, Input, Output} from "@angular/core";
import {Size} from '../../../utils/size';

@Component({
	selector: 'app-tags',
	templateUrl: './tags.component.html',
	styleUrls: ['./tags.component.scss']
})
export class TagsComponent {
	@Input() color: 'accent' | 'high-priority' | 'medium-priority' | 'low-priority' | 'gold' | 'silver' | 'bronze' | 'production' | 'staging' | 'integration' | 'secondary' = 'secondary';
	@Input() size: Size = 'm';
	@Input() icon: string = '';
	@Input() canBeDeleted: boolean = false;

	@Output() onDelete: EventEmitter<void> = new EventEmitter();

	onHover: boolean = false;

	getSizeClass() {
		return "tags-" + this.size;
	}
}
