import { Directive, HostListener, Input, OnInit, Output } from '@angular/core';
import {Subject, tap, throttleTime} from 'rxjs';
import {map} from 'rxjs/operators';

@Directive({
  selector: '[appPinchZoom]',
})
export class PinchZoomDirective implements OnInit {
  @Input() scaleFactor: number = 0.1;
  @Input() zoomThreshold: number = 9;
  @Input() initialZoom: number = 5;

  @Output() onZoom: Subject<number> = new Subject<number>();
  _onZoom: Subject<number> = new Subject<number>();

  scale: number;

  constructor() {}

  ngOnInit(): void {
    this.scale = this.initialZoom;
    this._onZoom
      .pipe(
        throttleTime(10),
        map(deltaY => this.scale - deltaY * this.scaleFactor),
        tap(scale => this.scale = this.clamp(scale, 1, this.zoomThreshold)))
      .subscribe(() => this.onZoom.next(this.scale));
  }

  @HostListener('wheel', ['$event'])
  onWheel($event: WheelEvent): void {
    if (!$event.ctrlKey) return;
    $event.preventDefault();
    this._onZoom.next($event.deltaY);
  }

  clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
  }
}