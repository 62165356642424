import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm.component.html',
	styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

	constructor(public dialogRef: MatDialogRef<ConfirmComponent>,
	            @Inject(MAT_DIALOG_DATA) public data: ConfirmModel) {
	}

	onConfirm(): void {
		this.dialogRef.close(true);
	}

	onDismiss(): void {
		this.dialogRef.close(false);
	}
}

export interface ConfirmModel {
	message: string,
	confirmButton: string,
	closeButton: string
}
