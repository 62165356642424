import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrl: '../multi-select.component.scss'
})
export class SelectSearchComponent {
  @Input() searchControl: FormControl;
}
