import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PinchZoomDirective} from 'src/app/directives/pinch-zoom/pinch-zoom.directive';
import {TextareaAutoresizeDirective} from 'src/app/directives/textarea-autoresize/textarea-autoresize.directive';
import {NgVar} from 'src/app/directives/ng-var/ng-var.directive';
import {ContenteditableValueAccessor} from 'src/app/directives/content-editable/content-editable.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ContenteditableValueAccessor,
    NgVar,
    TextareaAutoresizeDirective,
    PinchZoomDirective
  ],
  exports: [
    ContenteditableValueAccessor,
    NgVar,
    TextareaAutoresizeDirective,
    PinchZoomDirective
  ]
})
export class DirectivesModule {}
