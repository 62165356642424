<div class="flex-column gap-L">
    <div class="flex-align-center gap-S">
        <div class="font-MM fw-700">{{'page.appDetails.overview.contactSupport'|translate}}</div>
        <div *ngIf="advancementPercent < 100" [style.background-color]="advancementPercent > 0 ? '#F2F2F8' : '#DADADA'" style=" height: 5px; border-radius: 2px; width: 30px;">
            <div class="bkg-accent" style="height: 5px; border-radius: 2px;" [style.width.%]="advancementPercent"></div>
        </div>
        <img [src]="advancementPercent >= 100 ? 'assets/icons/check-circle-green.svg' : 'assets/icons/check-circle.svg'" height="14px" alt="">
        <button autofocus style="opacity: 0"></button>
    </div>
    <div class="flex-center gap-L">
        <a class="bubble-elt gap-S" [class.bubble-elt-disabled]="_initializing"
           [attr.href]="!phone ? null : 'tel:' + phone"
           (click)="!phone ? navigateToGeneralParameter() : {}">
            <div class="flex-center icon-elt">
                <img src="assets/icons/phone.svg" height="16" alt="">
            </div>
            <div class="flex-column flex-space-around h-100" style="max-width: 158px;">
                <span class="font-M fw-500">{{'page.appDetails.overview.phoneNumber'|translate}}</span>
                <span class="font-M fw-500 text-overflow-ellipsis grey">{{phoneLabel}}</span>
            </div>
        </a>
        <a class="bubble-elt gap-S" [class.bubble-elt-disabled]="_initializing"
           [attr.href]="!email ? null : 'mailto:' + email"
           (click)="!email ? navigateToGeneralParameter() : {}">
            <div class="flex-center icon-elt">
                <img src="assets/icons/email.svg" height="16" alt="">
            </div>
            <div class="flex-column flex-space-around h-100" style="max-width: 158px;">
                <span class="font-M fw-500">{{'page.appDetails.overview.email'|translate}}</span>
                <span class="font-M fw-500 text-overflow-ellipsis grey">{{emailLabel}}</span>
            </div>
        </a>
        <a class="bubble-elt gap-S" [class.bubble-elt-disabled]="_initializing"
           [attr.href]="!website ? null : website"
           [attr.target]="!website ? null : '_blank'"
           (click)="!website ? navigateToGeneralParameter() : {}">
            <div class="flex-center icon-elt">
                <img src="assets/icons/website.svg" height="16" alt="">
            </div>
            <div class="flex-column flex-space-around h-100" style="max-width: 158px;">
                <span class="font-M fw-500">{{'page.appDetails.overview.website'|translate}}</span>
                <span class="font-M fw-500 text-overflow-ellipsis grey">{{websiteLabel}}</span>
            </div>
        </a>
    </div>
</div>
