<div class="mt-xl disp-f gap-L ">
	<div class="menu-container ml-m mr-m">
		<div class="menu pad-M">
			@for (item of menuItems; track item.id; let first = $first) {
				@if (item.type === 'divider') {
					<div class="menu-separator"></div>
				} @else if (item.type === 'item') {
					<div (click)="onItemClick(item)" matRipple class="menu-item font-M" [class.mt-xs]="!first" [class.active]="item.isSelected">{{ item.title ?? '' |translate }}</div>
				}
			}
		</div>
	</div>

	<div class="settings">
		<div #section id="item1" class="empty-block"></div>
		<div  class="settings-section" style="padding-bottom: 5px">
			<app-settings-account></app-settings-account>
		</div>

		<div #section id="item3" class="empty-block" ></div>
		<div  class="settings-section mt-l" style="padding-bottom: 5px">
			<app-settings-tenant></app-settings-tenant>
		</div>

		<div #section id="item4" class="empty-block"></div>
		<div  class="settings-section mt-l mb-l">
			<app-settings-plan></app-settings-plan>
		</div>
		<app-snackbar></app-snackbar>
	</div>
</div>
