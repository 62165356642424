<div class="flex gap-M pad-XL-XL-0 w-100 border-box mb-xl" style="margin-bottom: 100px;">
	<app-diagram-breadcrumb class="w-100"></app-diagram-breadcrumb>
	<div style="min-width: 100px;">
		<app-text-button type="flat" panelClass="h-100-imp" [tooltip]="'Coming soon...'" [disabled]="true">
			<img src="assets/icons/share.svg" alt="share">
		</app-text-button>
	</div>
</div>
<div class="flex-center gap-S p-relative inner-wrapper" id="canvas" style="height: calc(100% - 350px); flex-wrap: wrap;">
	<!-- First app -->
	<div class="radar-circle flex-center p-relative" style="margin-bottom: auto; margin-top: auto; width: 556px; height: 556px; min-width: 556px; min-height: 556px; background: rgba(168, 144, 243, 0.10);">
		<app-multi-select style="position: absolute; transform: translateY(-1100%); z-index: -1; max-width: 150px; min-width: 120px; width: 120px;" [@appSelectIn] *ngIf="appA" [customTrigger]="true" class="w-100" [inputControl]="appAFormControl">
			<div custom-trigger class="select-app flex flex-center gap-M c-p">
				<span class="fw-700 text-overflow-ellipsis">{{ appA.instance.name }}</span>
				<img width="10" src="assets/icons/card-arrow.svg">
			</div>
			<app-select-search [searchControl]="searchAppAControl"></app-select-search>
			<app-select-option *ngFor="let appA of appAFilteredList" [value]="appA">
				<app-display-application [application]="appA"></app-display-application>
			</app-select-option>
		</app-multi-select>

		<div class="radar-circle flex-center" style="width: 500px; height: 500px; background: rgba(168, 144, 243, 0.10);">
			<div class="radar-circle flex-center" style="width: 437px; height: 437px; background: rgba(168, 144, 243, 0.10);">

				<!-- When loading -->
				<div class="app-overview-loading flex-center" *ngIf="_loading || _initializing || _appALoading">
					<app-spinner color="accent"></app-spinner>
				</div>

				<!-- When no appA selected -->
				<div *ngIf="!_loading && !_initializing && !_appALoading && !appA && !_appASearching" class="h-100 w-100 flex-center app-overview-not-selected app-a" (click)="_appASearching = true">
					<div class="w-100 h-100 flex-center flex-column gap-XL c-p">
						<img src="assets/icons/add-circle.svg" alt="add" class="add-circle-a">
						<span class="text-center font-MM">{{ 'page.diagram.appComparison.chooseAnApp' | translate }}</span>
					</div>
				</div>

				<!-- When no appB selected, searching state -->
				<div *ngIf="!_loading && !_initializing && !_appALoading && !appA && _appASearching" class="w-100 app-overview pad-M" style="height: 567px; width: 383px;">
					<div class="bkg-white mb-m">
						<app-text-input class="flex w-100"
										[input]="searchAppAControl"
										[placeholder]="'Search'">
							<app-icon-button icon-suffix [img]="'assets/icons/close.svg'" size="xs" color="primary" [panelClass]="'img-size-30'"
											 *ngIf="searchAppAControl.value?.length > 0"
											 (click)="searchAppAControl.reset('')"></app-icon-button>
						</app-text-input>
					</div>
					<div style="height: 520px; overflow: auto">
						<div *ngFor="let appA of appAFilteredList" class="flex-align-center flex-space-between c-p greyed-hover radius-S pad-S gap-S border-box-full overflow-hidden"
							 style="height: 50px;" [style.width.%]="appAFilteredList.length > 10 ? 98 : 100"
							 (click)="changeFormAppValue(appA, 'a')">
							<div class="flex-align-center font-M fw-500 text-overflow-ellipsis" style="width: calc(100% - 30px); user-select: none;">
								<app-display-application [application]="appA"></app-display-application>
							</div>
						</div>
						<div *ngIf="appAFilteredList.length === 0" class="flex-center h-100">
							<span class="font-M fw-500 grey text-center">{{'dropdown.noResult'|translate}}</span>
						</div>
					</div>
				</div>

				<!-- When appA selected -->
				<div class="app-overview" *ngIf="!_loading && !_initializing && !_appALoading && appA">
					<div style="z-index: 1" class="flex-column flex-space-between flex-align-center">
						<app-display-logo class="mt-xxl" [logo]="appA.instance.logo" [size]="50"></app-display-logo>
						<span class="font-L fw-700 mt-m">{{ appA.instance.name }}</span>
						<app-chips class="mt-m" [bold]="false">{{ appA.category?.name || '-' }}</app-chips>
						<div class="w-90">
							<hr class="w-100 hr-document mb-m" style="margin-top: 40px;">
							<div class="w-100 flex-space-between flex-align-center">
								<div class="flex-column gap-XS">
									<span class="font-M fw-700">{{ 'page.diagram.appComparison.health' | translate }}</span>
									<span class="grey font-S">{{ 'page.diagram.appComparison.onLast30Days' | translate }}</span>
								</div>
								<div>
									<app-chips-health [health]="appA.health"></app-chips-health>
								</div>
							</div>
							<hr class="w-100 hr-document mt-m mb-m">
							<div class="w-100 flex-space-between flex-align-center">
								<div class="flex-column gap-XS">
									<span class="font-M fw-700">{{ 'page.diagram.appComparison.performances.title' | translate }}</span>
									<span class="grey font-S">{{ 'page.diagram.appComparison.onLast30Days' | translate }}</span>
								</div>
								<div>
									<div *ngIf="!appA.performance" class="comparison-chip comparison-chip-no-data flex flex-center">
										<span>-</span>
									</div>
									<div *ngIf="appA.performance" class="comparison-chip flex flex-center" [class.comparison-chip-success]="appA.performance === 'GOOD'" [class.comparison-chip-warning]="appA.performance === 'NEEDS_IMPROVEMENT'" [class.comparison-chip-danger]="appA.performance === 'POOR'">
										<span>{{ 'page.diagram.appComparison.performances.' + appA.performance | translate }}</span>
									</div>
								</div>
							</div>
							<hr class="w-100 hr-document mt-m mb-m">
							<div class="w-100 flex-space-between flex-align-center">
								<div class="flex-column gap-XS">
									<span class="font-M fw-700">{{ 'page.diagram.appComparison.users' | translate }}</span>
									<span class="grey font-S">{{ 'page.diagram.appComparison.onLast30Days' | translate }}</span>
								</div>
								<div>
									<app-chips-count [value]="appA.usage.value" type="user" bold></app-chips-count>
								</div>
							</div>
							<hr class="w-100 hr-document mt-m mb-m">
							<div class="w-100 flex-space-between flex-align-center">
								<div class="flex-column gap-XS">
									<span class="font-M fw-700">{{ 'page.diagram.appComparison.satisfaction' | translate }}</span>
									<span *ngIf="appA.satisfaction.rating.value" class="grey font-S">{{ appA.satisfaction.votersCount }} {{ 'page.diagram.appComparison.feedback' | translate }}</span>
									<span class="grey font-S" *ngIf="!appA.satisfaction.rating.value">-</span>
								</div>
								<div>
									<app-chips-count [value]="appA.satisfaction.rating.value?.toFixed(2)" type="star" bold></app-chips-count>
								</div>
							</div>
							<hr class="w-100 hr-document mt-m mb-m">
							<div class="w-100 flex-space-between flex-align-center">
								<div class="flex-column gap-XS">
									<span class="font-M fw-700">{{ 'page.diagram.appComparison.cost' | translate }}</span>
									<span class="grey font-S">{{ 'page.diagram.appComparison.totalCostFor' | translate }} {{ currentYear }}</span>
								</div>
								<div>
									{{ appA.cost.value|cost }}
								</div>
							</div>
							<hr class="w-100 hr-document mt-m mb-m">
							<div class="w-100 flex-space-between flex-align-center">
								<div class="flex-column gap-XS">
									<span class="font-M fw-700">{{ 'page.diagram.appComparison.complexity' | translate }}</span>
									<span class="grey font-S">-</span>
								</div>
								<div class="comparison-chip flex flex-center" [class.comparison-chip-success]="appA.complexity.level === 'A'" [class.comparison-chip-warning]="appA.complexity.level === 'B' || appA.complexity.level === 'C'" [class.comparison-chip-danger]="appA.complexity.level !== 'A' && appA.complexity.level !== 'B' && appA.complexity.level !== 'C'">
									<span>{{ appA.complexity.level }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="flex flex-align-center gap-S wrapper" style="margin-top: auto; margin-bottom: auto; z-index: 0;">
		<!-- Trophy -->
		<div class="flex p-relative">
			<svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
				<!-- Premier arc de cercle de base (gris) -->
				<path d="M 32,2 A 30,30 0 0,1 32,62" fill="none" stroke="#D5D6E0" stroke-width="1.5" />

				<!-- Deuxième arc de cercle de base (gris) -->
				<path d="M 32,62 A 30,30 0 0,1 32,2" fill="none" stroke="#D5D6E0" stroke-width="1.5" />

				<svg x="20" y="20" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path d="M12 15C8.68629 15 6 12.3137 6 9V3.44444C6 3.0306 6 2.82367 6.06031 2.65798C6.16141 2.38021 6.38021 2.16141 6.65798 2.06031C6.82367 2 7.0306 2 7.44444 2H16.5556C16.9694 2 17.1763 2 17.342 2.06031C17.6198 2.16141 17.8386 2.38021 17.9397 2.65798C18 2.82367 18 3.0306 18 3.44444V9C18 12.3137 15.3137 15 12 15ZM12 15V18M18 4H20.5C20.9659 4 21.1989 4 21.3827 4.07612C21.6277 4.17761 21.8224 4.37229 21.9239 4.61732C22 4.80109 22 5.03406 22 5.5V6C22 6.92997 22 7.39496 21.8978 7.77646C21.6204 8.81173 20.8117 9.62038 19.7765 9.89778C19.395 10 18.93 10 18 10M6 4H3.5C3.03406 4 2.80109 4 2.61732 4.07612C2.37229 4.17761 2.17761 4.37229 2.07612 4.61732C2 4.80109 2 5.03406 2 5.5V6C2 6.92997 2 7.39496 2.10222 7.77646C2.37962 8.81173 3.18827 9.62038 4.22354 9.89778C4.60504 10 5.07003 10 6 10M7.44444 22H16.5556C16.801 22 17 21.801 17 21.5556C17 19.5919 15.4081 18 13.4444 18H10.5556C8.59188 18 7 19.5919 7 21.5556C7 21.801 7.19898 22 7.44444 22Z" stroke="" [attr.stroke]="appA && appB ? '#A890F3' : '#D5D6E0'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
				</svg>

				<!-- Premier arc de cercle animé (violet) -->
				<path *ngIf="appB" class="loadingArc" d="M 32,2 A 30,30 0 0,1 32,62" fill="none" stroke="#A890F3" stroke-width="1.5"
					  stroke-dasharray="94.2" stroke-dashoffset="94.2">
					<animate attributeName="stroke-dashoffset" from="94.2" to="0" fill="freeze" />
				</path>

				<!-- Deuxième arc de cercle animé (violet) -->
				<path *ngIf="appA" class="loadingArc" d="M 32,62 A 30,30 0 0,1 32,2" fill="none" stroke="#A890F3" stroke-width="1.5"
					  stroke-dasharray="94.2" stroke-dashoffset="94.2">
					<animate attributeName="stroke-dashoffset" from="94.2" to="0" fill="freeze" />
				</path>
			</svg>
		</div>

		<div class="radar-circle flex-center p-relative" style="margin-bottom: auto; margin-top: auto; width: 556px; height: 556px; min-width: 556px; min-height: 556px; background: rgba(168, 144, 243, 0.10);">
			<app-multi-select style="position: absolute; transform: translateY(-1100%); z-index: -1; max-width: 200px; min-width: 120px; width: 120px;" [@appSelectIn] *ngIf="appB" [customTrigger]="true" class="w-100" [inputControl]="appBFormControl">
				<div custom-trigger class="select-app flex flex-center gap-M c-p">
					<span class="fw-700 text-overflow-ellipsis">{{ appB.instance.name }}</span>
					<img width="10" src="assets/icons/card-arrow.svg">
				</div>
				<app-select-search [searchControl]="searchAppBControl"></app-select-search>
				<app-select-option *ngFor="let appB of appBFilteredList" [value]="appB">
					<app-display-application [application]="appB"></app-display-application>
				</app-select-option>
			</app-multi-select>

			<div class="radar-circle flex-center" style="width: 500px; height: 500px; background: rgba(168, 144, 243, 0.10);">
				<div class="radar-circle flex-center" style="width: 437px; height: 437px; background: rgba(168, 144, 243, 0.10);">

					<!-- When loading -->
					<div class="app-overview-loading flex-center" *ngIf="_loading || _initializing || _appBLoading">
						<app-spinner color="accent"></app-spinner>
					</div>

					<!-- When no appB selected -->
					<div *ngIf="!_loading && !_initializing && !_appBLoading && !appB && !_appBSearching" class="h-100 w-100 flex-center app-overview-not-selected app-b" (click)="_appBSearching = true">
						<div class="w-100 h-100 flex-center flex-column gap-XL c-p">
							<img src="assets/icons/add-circle.svg" alt="add" class="add-circle-b">
							<span class="text-center font-MM">{{ 'page.diagram.appComparison.chooseAnApp' | translate }}</span>
						</div>
					</div>

					<!-- When no appB selected, searching state -->
					<div *ngIf="!_loading && !_initializing && !_appBLoading && !appB && _appBSearching" class="w-100 app-overview pad-M" style="height: 567px; width: 383px;">
						<div class="bkg-white mb-m">
							<app-text-input class="flex w-100"
											[input]="searchAppBControl"
											[placeholder]="'Search'">
								<app-icon-button icon-suffix [img]="'assets/icons/close.svg'" size="xs" color="primary" [panelClass]="'img-size-30'"
												 *ngIf="searchAppBControl.value?.length > 0"
												 (click)="searchAppBControl.reset('')"></app-icon-button>
							</app-text-input>
						</div>
						<div style="height: 520px; overflow: auto">
							<div *ngFor="let appB of appBFilteredList" class="flex-align-center flex-space-between c-p greyed-hover radius-S pad-S gap-S border-box-full overflow-hidden"
								 style="height: 50px;" [style.width.%]="appBFilteredList.length > 10 ? 98 : 100"
								 (click)="changeFormAppValue(appB, 'b')">
								<div class="flex-align-center font-M fw-500 text-overflow-ellipsis" style="width: calc(100% - 30px); user-select: none;">
									<app-display-application [application]="appB"></app-display-application>
								</div>
							</div>
							<div *ngIf="appBFilteredList.length === 0" class="flex-center h-100">
								<span class="font-M fw-500 grey text-center">{{'dropdown.noResult'|translate}}</span>
							</div>
						</div>
					</div>

					<!-- When appB selected -->
					<div *ngIf="!_loading && !_initializing && !_appBLoading && appB" class="app-overview">
						<div style="z-index: 1" class="flex-column flex-space-between flex-align-center">
							<app-display-logo class="mt-xxl" [logo]="appB.instance.logo" [size]="50"></app-display-logo>
							<span class="font-L fw-700 mt-m">{{ appB.instance.name }}</span>
							<app-chips class="mt-m" [bold]="false">{{ appB.category?.name || '-' }}</app-chips>
							<div class="w-90">
								<hr class="w-100 hr-document mb-m" style="margin-top: 40px;">
								<div class="w-100 flex-space-between flex-align-center">
									<div class="flex-column gap-XS">
										<span class="font-M fw-700">{{ 'page.diagram.appComparison.health' | translate }}</span>
										<span class="grey font-S">{{ 'page.diagram.appComparison.onLast30Days' | translate }}</span>
									</div>
									<div>
										<app-chips-health [health]="appB!.health"></app-chips-health>
									</div>
								</div>
								<hr class="w-100 hr-document mt-m mb-m">
								<div class="w-100 flex-space-between flex-align-center">
									<div class="flex-column gap-XS">
										<span class="font-M fw-700">{{ 'page.diagram.appComparison.performances.title' | translate }}</span>
										<span class="grey font-S">{{ 'page.diagram.appComparison.onLast30Days' | translate }}</span>
									</div>
									<div>
										<div *ngIf="!appB!.performance" class="comparison-chip comparison-chip-no-data flex flex-center">
											<span>-</span>
										</div>
										<div *ngIf="appB!.performance" class="comparison-chip flex flex-center" [class.comparison-chip-success]="appB!.performance === 'GOOD'" [class.comparison-chip-warning]="appB!.performance === 'NEEDS_IMPROVEMENT'" [class.comparison-chip-danger]="appB!.performance === 'POOR'">
											<span>{{ 'page.diagram.appComparison.performances.' + appB!.performance | translate }}</span>
										</div>
									</div>
								</div>
								<hr class="w-100 hr-document mt-m mb-m">
								<div class="w-100 flex-space-between flex-align-center">
									<div class="flex-column gap-XS">
										<span class="font-M fw-700">{{ 'page.diagram.appComparison.users' | translate }}</span>
										<span class="grey font-S">{{ 'page.diagram.appComparison.onLast30Days' | translate }}</span>
									</div>
									<div>
										<app-chips-count [value]="appB!.usage.value" type="user" bold></app-chips-count>
									</div>
								</div>
								<hr class="w-100 hr-document mt-m mb-m">
								<div class="w-100 flex-space-between flex-align-center">
									<div class="flex-column gap-XS">
										<span class="font-M fw-700">{{ 'page.diagram.appComparison.satisfaction' | translate }}</span>
										<span *ngIf="appB!.satisfaction.rating.value" class="grey font-S">{{ appB!.satisfaction.votersCount }} {{ 'page.diagram.appComparison.feedback' | translate }}</span>
										<span class="grey font-S" *ngIf="!appB!.satisfaction.rating.value">-</span>
									</div>
									<div>
										<app-chips-count [value]="appB!.satisfaction.rating.value?.toFixed(2)" type="star" bold></app-chips-count>
									</div>
								</div>
								<hr class="w-100 hr-document mt-m mb-m">
								<div class="w-100 flex-space-between flex-align-center">
									<div class="flex-column gap-XS">
										<span class="font-M fw-700">{{ 'page.diagram.appComparison.cost' | translate }}</span>
										<span class="grey font-S">{{ 'page.diagram.appComparison.totalCostFor' | translate }} {{ currentYear }}</span>
									</div>
									<div>
										{{ appB!.cost.value|cost }}
									</div>
								</div>
								<hr class="w-100 hr-document mt-m mb-m">
								<div class="w-100 flex-space-between flex-align-center">
									<div class="flex-column gap-XS">
										<span class="font-M fw-700">{{ 'page.diagram.appComparison.complexity' | translate }}</span>
										<span class="grey font-S">-</span>
									</div>
									<div class="comparison-chip flex flex-center" [class.comparison-chip-success]="appB!.complexity.level === 'A'" [class.comparison-chip-warning]="appB!.complexity.level === 'B' || appB!.complexity.level === 'C'" [class.comparison-chip-danger]="appB!.complexity.level !== 'A' && appB!.complexity.level !== 'B' && appB!.complexity.level !== 'C'">
										<span>{{ appB!.complexity.level }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
