import {Injectable} from '@angular/core';

@Injectable()
export class SidenavService {

	protected openedState = false;

	isOpened(): boolean {
		return this.openedState;
	}

	open() {
		this.openedState = true;
	}

	close() {
		this.openedState = false;
	}
}
