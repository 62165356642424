<form *ngIf="!_initializing" class="flex-column gap-S" [formGroup]="generalForm">
	<div class="flex-column flex-align-center gap-S mb-m">
		<app-display-logo [loading]="uploading" [logo]="application.logo" [size]="65"></app-display-logo>
		<div (click)="uploadImage = !uploadImage" class="grey c-p">{{ 'page.organization.creationPopup.iconSwap' | translate }}</div>
	</div>
	<div *ngIf="uploadImage" class="flex-column flex-align-center gap-S mb-m">
		<div class="upload-file-container c-p"
			 (click)="selectFile()" (drop)="onDrop($event)" (dragover)="$event.preventDefault()" (dragleave)="$event.preventDefault()">
			<img ngSrc="assets/icons/upload-image.svg" class="filter-accent" height="35" width="35" alt="">
			<div class="font-S fw-500 mt-s">{{ 'page.appDetails.settings.dropYourImageHereOr' | translate }} <span class="font-S fw-700 accent">{{ 'page.appDetails.finance.contracts.browse' | translate }}</span></div>
			<div class="font-S fw-500 grey">{{ 'page.appDetails.settings.imageSupports' | translate }}</div>
			<div class="font-S fw-500 grey">{{ 'page.appDetails.settings.10MoMax' | translate }}</div>
		</div>
	</div>
	<div class="flex-column">
		<div class="flex gap-M w-100">
			<div class="flex-column w-100">
				<div class="grey">{{'page.customApp.form.name'|translate}}</div>
				<mat-form-field color="primary" appearance="outline" class="w-100">
					<input matInput type="text" [placeholder]="application.name" #ig1
                           [formControlName]="formName.name"
                           (blur)="updateGeneralOnBlur.emit(formName.name)" (keydown.enter)="ig1.blur()">
				</mat-form-field>
			</div>
		</div>
		<div class="flex-column">
			<div class="grey">{{'page.appDetails.description'|translate}}</div>
			<mat-form-field color="primary" appearance="outline" class="w-100">
				<input matInput type="text" [placeholder]="'page.appDetails.descriptionHint'|translate" #ig3
                       [formControlName]="formName.description"
                       (blur)="updateGeneralOnBlur.emit(formName.description)" (keydown.enter)="ig3.blur()">
			</mat-form-field>
		</div>
		<div class="flex-column">
			<div class="grey">{{'page.appDetails.accessUrl'|translate}}</div>
			<mat-form-field color="primary" appearance="outline" class="w-100">
				<input matInput type="text" [placeholder]="'page.appDetails.accessUrlPlaceholder'|translate" #ig4
                       [formControlName]="formName.accessUrl"
                       (blur)="updateGeneralOnBlur.emit(formName.accessUrl)" (keydown.enter)="ig4.blur()">
			</mat-form-field>
		</div>
		<div class="flex gap-M w-100">
			<div class="flex-column w-100">
				<div class="grey">{{'page.appDetails.settings.supportPhone'|translate}}</div>
				<mat-form-field color="primary" appearance="outline" class="w-100">
					<input matInput type="text" [placeholder]="'page.appDetails.settings.supportPhonePlaceholder'|translate" #ig5
                           [formControlName]="formName.supportPhone"
                           (blur)="updateGeneralOnBlur.emit(formName.supportPhone)" (keydown.enter)="ig5.blur()">
				</mat-form-field>
			</div>
			<div class="flex-column w-100">
				<div class="grey">{{'page.appDetails.settings.supportEmail'|translate}}</div>
				<mat-form-field color="primary" appearance="outline" class="w-100">
					<input matInput type="text" [placeholder]="'page.appDetails.settings.supportEmailPlaceholder'|translate" #ig6
                           [formControlName]="formName.supportEmail"
                           (blur)="updateGeneralOnBlur.emit(formName.supportEmail)" (keydown.enter)="ig6.blur()">
				</mat-form-field>
			</div>
			<div class="flex-column w-100">
				<div class="grey">{{'page.appDetails.settings.supportUrl'|translate}}</div>
				<mat-form-field color="primary" appearance="outline" class="w-100">
					<input matInput type="text" [placeholder]="'page.appDetails.settings.supportUrlPlaceholder'|translate" #ig7
                           [formControlName]="formName.supportUrl"
                           (blur)="updateGeneralOnBlur.emit(formName.supportUrl)" (keydown.enter)="ig7.blur()">
				</mat-form-field>
			</div>
		</div>
	</div>
</form>
