import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Organization} from 'src/app/services/organization.service';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {ExportType} from 'src/app/services/front/export.service';

@Component({
  templateUrl: './most-complex-application-drawer.component.html',
  styleUrls: ['./most-complex-application-drawer.component.scss']
})
export class MostComplexApplicationDrawerComponent {

  @Input() data: MostComplexApplicationDrawerData;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  search: FormControl = new FormControl();
  export: Subject<ExportType> = new Subject<ExportType>();
  type: typeof ExportType = ExportType;

  close(): void {
    this.onClose.emit('');
  }
}

export interface MostComplexApplicationDrawerData {
  filter: Organization|null|undefined;
}
