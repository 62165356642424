<div class="flex-center h-100">
	<div class="flex-justify-center flex-align-center onboarding-step-container h-100 w-100">
		<div class="flex-justify-center gap-M h-100 w-100 h-auto m-auto">
			<div *ngIf="!isMobile" class="onboarding-stepper-container flex-column gap-M mt-l">
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper active-step"></div>
			</div>
			<div class="onboarding-step flex-column flex-space-between w-30 gap-L border-box pad-L">
				<div class="h-100 p-relative">
					<div class="flex-justify-end mb-xl">
						<app-icon-button (click)="closeLoading = true;onDismiss.emit()" img='assets/icons/close.svg' size='s' color='accent' type='stroked'
										 shape='default' [disabled]='false' [loading]='closeLoading' [focused]='false'
										 [background]='true' panelClass='img-size-30'></app-icon-button>
					</div>
					<div class="fw-700 font-XXL text-center mb-xxl onboarding-title" style="white-space: nowrap">{{ 'page.onboarding.step6.st1' | translate }}</div>
					<div class="flex-justify-center text-center font-L fw-300 mb-l">
						<span class="onboarding-subtitle" [style.line-height.px]="panelClass === 'big-button' ? 27 : 23">{{ 'page.onboarding.step6.st2' | translate }}</span>
					</div>
					<div class="p-absolute flex-align-center flex-column gap-L w-100 apps-container">
						<div *ngFor="let application of notDeletedApplications | slice:0:3" [@listAnimation] class="onboarding-app w-80 flex-align-center flex-space-between" [class.pad-XS]="panelClass !== 'big-button'" [class.pad-M]="panelClass === 'big-button'">
							<!-- app -->
							<div class="flex-align-center flex-justify-center gap-L">
								<app-application-icon
									[logo]="application.application.application.logo"
									[width]="panelClass !== 'big-button' ? 20 : 30"
									[height]="panelClass !== 'big-button' ? 20 : 30"
									[tileHeight]="panelClass !== 'big-button' ? '35px' : '45px'"
									[tileWidth]="panelClass !== 'big-button' ? '35px' : '45px'"></app-application-icon>
								<div class="fw-700 font-L text-overflow-ellipsis" [style.max-width.px]="panelClass !== 'big-button' ? 192 : 300">
									{{ application.application.application.name }}
								</div>
							</div>
							<!-- delete app -->
							<div>
								<app-icon-button (click)="onDelete(application)" img='assets/icons/close.svg' [size]="panelClass !== 'big-button' ? 's' : 'm'" color='danger' type='default' shape='default' [disabled]='false' [loading]='false' [focused]='false' [background]='false' panelClass='img-size-30'></app-icon-button>
							</div>
						</div>
					</div>

					<div class="flex-center flex-align-center w-100 p-absolute">
						<app-radar-loader [scale]="panelClass === 'big-button' ? 1.2 : 0.69"></app-radar-loader>
					</div>
				</div>
				<div class="flex-center w-100 pb-l">
					<app-text-button (click)="addTenantApplications()" class="w-90" color='accent' type='flat'
									 shape='default' [loading]='submitLoading' [disabled]="notDeletedApplications.length <= 0" badge=''
									 badgePosition='above after' [panelClass]="panelClass">{{ 'page.onboarding.step6.st3' | translate }}
					</app-text-button>
				</div>
			</div>
		</div>
	</div>
</div>
