import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {DropdownComponent} from "./dropdown.component";

@Directive({
	standalone: true,
	selector: '[dropdownTrigger]'
})
export class DropdownTriggerDirective {
	@Input() dropdownTrigger: DropdownComponent;

	constructor(private el: ElementRef) {}

	get elementRef(): ElementRef {
		return this.el;
	}

	getWidth(): number {
		return this.el.nativeElement.offsetWidth;
	}

	openDropdown(): void {
		this.dropdownTrigger.open(this);
	}

	closeDropdown(): void {
		this.dropdownTrigger.close(this);
	}

	@HostListener('click') onClick(){
		this.dropdownTrigger.toggle(this);
	}
}
