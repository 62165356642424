<div class="primary disp-f f-d-col j-c-sb w-100 organizationCreationWrapper">
	<div class="pad-L">
		<div class="disp-f a-i-c j-c-s f-d-r mb-xl">
			<app-chips [square]="true" [size]="'xl'" >
				<img src="assets/icons/building.svg" class="filter-grey" height="20px" alt="">
			</app-chips>
			<div class="flex-column gap-XS ml-s">
				<div class="font-L fw-700">{{ 'page.organization.creationPopup.newAffiliatePlaceholder' | translate }}</div>
				<div class="font-M fw-300">{{ 'page.organization.creationPopup.organizationModel' | translate }}</div>
			</div>
		</div>
		<div class="flex-column gap-M">
			<div class="flex-column gap-XS">
				<div class="font-M fw-500 grey">Nom</div>
				<app-text-input [input]="nameFormControl" [placeholder]="'Mon groupe d&rsquo;équipe'"></app-text-input>
			</div>
		</div>
	</div>
	<div class="disp-f f-d-r j-c-sb a-i-c gap-M pad-L" style="width: auto">
		<app-text-button class="w-50" type="stroked"
						 (click)="onNoClick()">{{ 'button.cancel' | translate }}</app-text-button>
		<app-text-button class="w-50" type="flat" [disabled]="formGroup.invalid"
						 (click)="onCreate()">{{ 'button.add' | translate }}</app-text-button>
	</div>
</div>
