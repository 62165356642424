import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable()
export class InfrastructureUrlService {
	constructor(protected activatedRoute: ActivatedRoute, protected router: Router) {
	}


	public changeCurrentInstance(id: string | null) {
		if (id!= null)
			this.router.navigate([this.router.url.split('?')[0]], {queryParams: {'infrastructureId': id}, queryParamsHandling: "merge"})
		else
			this.router.navigate([this.router.url.split('?')[0]], {queryParams: {'infrastructureId': null, 'infrastructureTab': null}, queryParamsHandling: "merge"})
	}

	public getCurrentInstanceTab(): number {
		let index = this.activatedRoute.snapshot.queryParamMap.get('infrastructureTab');
		if (index != null)
			return parseInt(index)
		return 0;
	}
}
