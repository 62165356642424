@if (_initializing || _loading) {
<div class="border-box-full">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
} @else {
<div class="pl-m pr-m pt-m pb-m">
	<div class="flex-align-center">
        <span class="font-MM fw-700">{{ 'menu.overlap' | translate }}</span>
	</div>
	<div class="flex-column flex-space-between" style="height: 248px;">
		<div class="flex-center pt-l pb-xs">
			<div class="flex-center circle-overlap">
				<img src="assets/icons/picto-overlap.svg" height="40px" width="40px"
					 class="mr-xs filter-white"/>
			</div>
			<div style="position: relative">
				<img class="filter-primary" style="margin-left: -35px" src="assets/pointed-circle.svg" width="130px" height="130px">
				<h1 class="font-XXL fw-500" style="position: absolute; top: 48px;" [style]="getRightPercent()">
					{{ data.overlapPercent }}%
				</h1>
			</div>
		</div>
		<div>
			<div class="flex-space-between font-M fw-500 pb-s">
				<div>{{'page.dashboard.functionalDuplicates'|translate}}</div>
				<div>{{data.nbDuplicates}}</div>
			</div>
			<hr>
			<div class="flex-space-between font-M fw-700 pt-s">
				<div>{{'page.financeDashboard.potentialEarnings'|translate}}</div>
				<div>{{data.potentialEarnings.value|cost:'default':'year'}}</div>
			</div>
		</div>
	</div>
</div>
}
