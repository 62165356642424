import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject} from '@angular/core';
import {ApplicationTechnologyFormData} from '../application-technology-form.component';

@Component({
	templateUrl: './application-technology-form-dialog.component.html',
	styleUrls: ['./application-technology-form-dialog.component.scss']
})
export class ApplicationTechnologyFormDialogComponent {

	constructor(@Inject(MAT_DIALOG_DATA) public data: ApplicationTechnologyFormData,
							public dialogRef: MatDialogRef<ApplicationTechnologyFormDialogComponent>) {
		dialogRef.disableClose = true;
	}

	close(updated?: boolean): void {
		this.dialogRef.close(updated);
	}
}
