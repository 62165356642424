<div class="flex-column select-option-group-container">
    <div class="flex-align-center flex-space-between radius-S pad-XS gap-S border-box-full" style="min-width: 100px;"
         [class.greyed-out]="disabled" [class.select-disabled]="disabled"
         [class.c-p]="multiple()" [class.greyed-hover]="multiple()"
         (click)="!multiple() || disabled ? $event.stopPropagation() : onSelect()">
        <div class="font-M fw-500 grey text-overflow-ellipsis" style="width: calc(100% - 30px); user-select: none;">
            {{label}}
        </div>
        <div class="flex-center" style="width: 20px; height: 20px;">
            @if (multiple() && !disabled) {
                <mat-checkbox [checked]="selected" [indeterminate]="indeterminate" class="custom-checkbox"></mat-checkbox>
            }
        </div>
    </div>
    <ng-content select="app-select-option"></ng-content>
</div>
