import {NgModule} from "@angular/core";
import {MatIconModule} from "@angular/material/icon";
import {SearchComponent} from "./search.component";
import {MatLegacyFormFieldModule as MatFormFieldModule} from "@angular/material/legacy-form-field";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
	imports: [
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule
	],
	exports: [
		SearchComponent
	],
	declarations: [
		SearchComponent
	]
})
export class SearchModule {
}
