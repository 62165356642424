import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {NotSetComponent} from "./not-set.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		NotSetComponent
	],
	exports: [
		NotSetComponent
	]
})
export class NotSetModule {
}
