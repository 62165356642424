<div class="flex-column flex-align-start flex-justify-center gap-M w-100">
	<div class="flex-space-between w-100">
		<div class="font-MM fw-700 mb-s">
            {{'page.appDetails.performance'|translate}}
		</div>
		<div class="font-MM fw-700">
			<span style="color: rgba(74, 176, 74, 1)" *ngIf="latestPerformance && latestPerformance.globalRating && latestPerformance.globalRating == 'GOOD'">{{ 'global.performances.good' | translate }}</span>
			<span style="color: rgba(255, 153, 0, 1)" *ngIf="latestPerformance && latestPerformance.globalRating && latestPerformance.globalRating == 'NEEDS_IMPROVEMENT'">{{ 'global.performances.needsImprovement' | translate }}</span>
			<span style="color: rgba(255, 85, 85, 1)" *ngIf="latestPerformance && latestPerformance.globalRating && latestPerformance.globalRating == 'POOR'">{{ 'global.performances.poor' | translate }}</span>
			<span style="color: #B5B5C3" *ngIf="!latestPerformance || !latestPerformance.globalRating">-</span>
		</div>
	</div>

	<div class="flex-space-between flex-align-end w-100">
		<div *ngFor="let perf of performanceBarChartData" class="flex-column flex-align-center gap-S">
			<div class="bar-container" #div (mouseenter)="displayTooltip(perf, div)" (mouseleave)="hideTooltip()">
				<div *ngIf="perf.overallRating && perf.overallRating === 'GOOD'" class="bar good"></div>
				<div *ngIf="perf.overallRating && perf.overallRating === 'NEEDS_IMPROVEMENT'" class="bar needs-improvement"></div>
				<div *ngIf="perf.overallRating && perf.overallRating === 'POOR'" class="bar poor"></div>
			</div>
			<div class="font-S fw-500 bar-label">
				{{ perf.day.getDate() }}
			</div>
		</div>
	</div>

	<div *ngIf="tooltip.metrics && tooltip.metrics.length > 0"  class="tooltip" style="padding: 3px 7px; pointer-events: none;" [ngStyle]="{'top': tooltip.top, 'left': tooltip.left, 'display': tooltip.display}" >
		<div>
			<div *ngFor="let metric of tooltip.metrics" class="flex-space-between font-M fw-700">
				<div>
					<span>{{ metric.type }}</span>
				</div>
				<div>
					<span *ngIf="metric.type === 'FCP'" [ngStyle]="{ 'color': metric.value <= 1.8 ? 'rgba(74, 176, 74, 1)' : metric.value <= 3 ? 'rgba(255, 153, 0, 1)' : 'rgba(255, 85, 85, 1)' }">{{ metric.value }}s</span>
					<span *ngIf="metric.type === 'LCP'" [ngStyle]="{ 'color': metric.value <= 2.5 ? 'rgba(74, 176, 74, 1)' : metric.value <= 4 ? 'rgba(255, 153, 0, 1)' : 'rgba(255, 85, 85, 1)' }">{{ metric.value }}s</span>
				</div>
			</div>
		</div>
	</div>
</div>
