<div class="confirm-modal-container">

	<img src="assets/icons/caution.svg" class="confirm-img" height="35"/>

	<div [hidden]="!data.message.length" class="confirm-message" [innerHTML]="data.message"></div>

	<div class="confirm-button-group">
		<app-text-button class="confirm-button" type="stroked"
                         (click)="onDismiss()">{{ data.closeButton }}</app-text-button>
		<app-text-button class="confirm-button" type="flat"
                         (click)="onConfirm()">{{ data.confirmButton }}</app-text-button>
	</div>
</div>
