import {ApplicationOverview, DrawableObject, Point} from "../../common/diagram";
import {ApplicationCard} from "../../common/shapes/application-card";
import {Layers} from "../../common/layer";

type GroupState = { position?: Point, applications?: ApplicationOverview[]};

export class ApplicationGroup implements DrawableObject<GroupState> {
	protected static readonly APPLICATION_PADDING_TOP_BOTTOM: number = 30;
	protected static readonly APPLICATION_PADDING_LEFT_RIGHT: number = 30;
	public static readonly WIDTH: number = ApplicationGroup.APPLICATION_PADDING_LEFT_RIGHT + ApplicationCard.WIDTH;
	protected static readonly BACKGROUND_COLOR = [
		{backgroundColor: "rgba(168, 144, 243, 0.1)", font: "rgba(168, 144, 243, 1)"}
	];

	protected coordinates: Point = {x: 0, y: 0};
	protected applicationCards: ApplicationCard[] = [];
	protected circle: any;
	protected color = ApplicationGroup.BACKGROUND_COLOR[this.groupName.length % (ApplicationGroup.BACKGROUND_COLOR.length)];
	protected mouseOver: boolean = false;

	constructor(
		protected ctx: any,
		protected groudId: string,
		protected groupName: string,
		protected applications: ApplicationOverview[]) {}

	update(state: GroupState = {}) : void {
		if (state.applications) {
			this.applications = state.applications;
			// Remove deleted applications
			this.applicationCards = this.applicationCards.filter(application => this.applications.find(app => app.applicationId === application.getId()));

			// Create new applications
			this.applications.forEach(application => {
				if (!this.applicationCards.find(app => app.getId() === application.applicationId)) {
					this.applicationCards.push(new ApplicationCard(this.ctx,
						{
							x: this.coordinates.x + ApplicationGroup.APPLICATION_PADDING_LEFT_RIGHT,
							y: this.coordinates.y + (ApplicationCard.HEIGHT * this.applicationCards.length + ApplicationGroup.APPLICATION_PADDING_TOP_BOTTOM * (this.applicationCards.length + 2.5))
						},
						application.applicationId,
						application.applicationLogo,
						application.applicationName,
						application.applicationDesc,
						application.applicationCriticality,
						this.color.font,
						false
					));
				}
			});
		}

		if (state.position) {
			this.moveTo(state.position);
			this.applicationCards.forEach((application, index) => application.update({ position: {
				x: this.coordinates.x + ApplicationGroup.APPLICATION_PADDING_LEFT_RIGHT,
				y: this.coordinates.y + (ApplicationCard.HEIGHT * index + ApplicationGroup.APPLICATION_PADDING_TOP_BOTTOM * (index + 2.5))
			}}));
		}

		if (this.applicationCards.length !== this.applications.length) {
			this.applicationCards = this.applications.map( (application, index) =>
				new ApplicationCard(this.ctx,
					{
						x: this.coordinates.x + ApplicationGroup.APPLICATION_PADDING_LEFT_RIGHT,
						y: this.coordinates.y + (ApplicationCard.HEIGHT * index + ApplicationGroup.APPLICATION_PADDING_TOP_BOTTOM * (index + 2.5))
					},
					application.applicationId,
					application.applicationLogo,
					application.applicationName,
					application.applicationDesc,
					application.applicationCriticality,
					this.color.font,
					false
				));
		}
	}

	draw(layers: Layers): void {
		layers.getLayer(1).addObject(this.drawBackground());
		layers.getLayer(1).addObject(this.drawTitle());
		this.applicationCards.forEach( application => application.draw(layers));
	}

	getId(): string {
		return this.groudId;
	}

	getApplicationCards() : ApplicationCard[] {
		return this.applicationCards;
	}

	getHeight(): number {
		return this.applications.length * ApplicationCard.HEIGHT + (ApplicationGroup.APPLICATION_PADDING_TOP_BOTTOM * (this.applications.length + 2));
	}

	getWidth(): number {
		return ApplicationCard.WIDTH + ApplicationGroup.APPLICATION_PADDING_LEFT_RIGHT * 2;
	}

	getName(): string {
		return this.groupName;
	}

	getPosition(): {coordinates: Point, height: number} {
		return { coordinates: this.coordinates, height: this.applications.length};
	}

	getApplications(): ApplicationOverview[] {
		return this.applications;
	}

	isAtCoordinates(mouseCoordinates: Point): boolean {
		return  mouseCoordinates.x >= this.coordinates.x && mouseCoordinates.x <= this.coordinates.x + ApplicationCard.WIDTH &&
			mouseCoordinates.y >= this.coordinates.y && mouseCoordinates.y <= this.coordinates.y + ApplicationCard.HEIGHT;
	}

	setMouseOver() {
		this.mouseOver = true;
	}

	unsetMouseOver() {
		this.mouseOver = false;
	}

	moveTo(coordinates: Point): void {
		this.coordinates = coordinates;
	}

	protected drawTitle(): ()=>void {
		return () => {
			this.ctx.beginPath()
			const transform = this.ctx.getTransform();
			let fontSize = 24 * 1 / transform.d;

			this.ctx.font = "bold "+ (fontSize > 20 ? fontSize : 20) + "px proxima-nova";

			const maxHeight = Math.min(Math.max(20 * 2 / transform.d, 25), 35)
			const marginTop = maxHeight + ApplicationGroup.APPLICATION_PADDING_TOP_BOTTOM * 0.5;
			this.ctx.fillStyle = this.color.font;

			const textWidth = this.ctx.measureText(this.groupName).width;
			if (textWidth < ApplicationCard.WIDTH || this.mouseOver) {
				const x = this.coordinates.x + ApplicationGroup.APPLICATION_PADDING_LEFT_RIGHT;
				this.ctx.fillText(this.groupName, x, this.coordinates.y + marginTop);
			} else {
				let truncatedName = '';
				while (truncatedName.length <= this.groupName.length && this.ctx.measureText(truncatedName).width < ApplicationCard.WIDTH - 15) {
					truncatedName = this.groupName.slice(0, truncatedName.length + 1);
				}
				const x = this.coordinates.x + ApplicationGroup.APPLICATION_PADDING_LEFT_RIGHT;
				this.ctx.fillText(truncatedName + '...', x, this.coordinates.y + marginTop);
			}
		}
	}

	protected drawBackground(): ()=>void {
		return () => {
			this.ctx.beginPath()
			this.ctx.lineWidth = 1;
			this.ctx.setLineDash([5, 3])
			this.ctx.strokeStyle = this.color.font;
			this.ctx.roundRect(this.coordinates.x, this.coordinates.y, ApplicationCard.WIDTH + ApplicationGroup.APPLICATION_PADDING_LEFT_RIGHT * 2, (this.applicationCards.length * ApplicationCard.HEIGHT) + (this.applicationCards.length * ApplicationGroup.APPLICATION_PADDING_TOP_BOTTOM + ApplicationGroup.APPLICATION_PADDING_TOP_BOTTOM * 3), 15);
			this.ctx.fillStyle = this.color.backgroundColor;
			this.ctx.fill();
			this.ctx.stroke()
			this.ctx.setLineDash([])
		}
	}
}
