import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-application-settings',
  templateUrl: './application-settings.component.html',
  styleUrls: ['./application-settings.component.scss']
})
export class ApplicationSettingsComponent implements OnInit {

	@Input() settings: AppSettingsMode = AppSettingsMode.NONE;

	openGeneral = false;
	openUsageUrl = false;

	ngOnInit(): void {
		this.checkOpenPanels();
	}

	private checkOpenPanels(): void {
		this.openGeneral = [AppSettingsMode.GENERAL, AppSettingsMode.ALL].includes(this.settings);
		this.openUsageUrl = [AppSettingsMode.USAGE_URL, AppSettingsMode.ALL].includes(this.settings);
	}
}

export enum AppSettingsMode {
	NONE = 0,
	GENERAL = 1,
	HTTP_CHECKS = 2,
	USAGE_URL = 4,
	ALL = 7
}
