import {NgModule} from '@angular/core';
import {ConfirmComponent} from './confirm.component';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
  imports: [
    MatDialogModule,
    TranslateModule,
    CommonModule,
    MatButtonModule,
    DesignSystemModule
  ],
	declarations: [
		ConfirmComponent
	],
	exports: [
		ConfirmComponent,
	]
})
export class ConfirmModule {}
