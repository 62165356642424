<form class="flex-column gap-M" [formGroup]="formGroup">
	<app-text-input [input]="searchFormControl" [placeholder]="'page.appDetails.technology'|translate">
		<!--img icon-prefix class="filter-grey" src="assets/icons/search-md.svg" height="16px" alt=""/-->
		<app-icon-button icon-suffix [img]="'assets/icons/close.svg'" size="xs" color="primary" [panelClass]="'img-size-30'"
						 *ngIf="searchFormControl.value?.length > 0"
						 (click)="searchFormControl.reset('')"></app-icon-button>
	</app-text-input>
	<div class="overflow-y form-array-height radius-L border-light-grey" [formArrayName]="formName.technologies">
		<div *ngFor="let techGroup of technologyFormGroups; let i = index" class="flex-center pad-M gap-M border-light-grey-bottom"
			 [formGroupName]="i" [ngClass]="{'greyed-out': filteredOut(techGroup)}">
            <app-application-icon [logo]="getTechnology(techGroup).technology.logo"></app-application-icon>
			<div class="flex w-70">
				<span class="font-L fw-500">{{getTechnology(techGroup).technology.name}}</span>
			</div>
			<app-multi-select class="w-30 pad-right-M" [inputControl]="getVersionFormControl(i)"
							  [disabled]="_initializing || _saving">
				<app-select-trigger>{{getVersionFormControl(i).value?.name || '-'}}</app-select-trigger>
				<app-select-option [value]="null">{{'-'}}</app-select-option>
				<app-select-option *ngFor="let version of getTechnology(techGroup).versions" [value]="version">{{version.name}}</app-select-option>
			</app-multi-select>
			<mat-checkbox [formControlName]="formElementName.selected" style="margin-top: -3px;"></mat-checkbox>
		</div>
	</div>
</form>
<div class="flex gap-M pad-top-L">
    <app-text-button type="stroked" class="w-50" [disabled]="_saving"
                     (click)="cancel.emit()">
        {{'button.cancel'|translate}}
    </app-text-button>
    <app-text-button type="flat" class="w-50" [disabled]="_initializing || formGroup.invalid" [loading]="_saving"
                     (click)="save()">
        {{(!data.defaultData ? 'button.add' : 'button.edit')|translate}}
    </app-text-button>
</div>
