export class ImageUtils {
	public static getAverageRGB(imgEl: HTMLImageElement): string[] {
		let blockSize = 5, // only visit every 5 pixels
			defaultBgRGB = "rgba(0,0,0,0.1)", // for non-supporting envs
			defaultFontRGB = "rgba(0,0,0,1)",
			canvas = document.createElement('canvas'),
			context = canvas.getContext && canvas.getContext('2d'),
			data, width, height,
			i = -4,
			length,
			rgb = {r: 0, g: 0, b: 0},
			count = 0;


		if (!context) {
			return [defaultBgRGB, defaultFontRGB];
		}

		height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
		width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

		context.drawImage(imgEl, 0, 0);

		try {
			data = context.getImageData(0, 0, width, height);
		} catch (e) {
			/* security error, img on diff domain */
			return [defaultBgRGB, defaultFontRGB];
		}

		length = data.data.length;

		while ((i += blockSize * 4) < length) {
			if (data.data[i] + data.data[i + 1] + data.data[i + 2] <= 700 && data.data[i] + data.data[i + 1] + data.data[i + 2] > 0) {
				++count;
				rgb.r += data.data[i];
				rgb.g += data.data[i + 1];
				rgb.b += data.data[i + 2];
			}
		}

		// ~~ used to floor values
		rgb.r = ~~(rgb.r / count);
		rgb.g = ~~(rgb.g / count);
		rgb.b = ~~(rgb.b / count);
		return [`rgba(${rgb.r},${rgb.g},${rgb.b},0.1)`, `rgba(${rgb.r},${rgb.g},${rgb.b},1)`];
	}

	public static getRandomColors(): {backgroundColor: string, fontColor: string} {
		const colorsArray = [
			["rgba(114, 235, 54, 0.1)", "rgba(114, 235, 54, 1)"],
			["rgba(54, 235, 144, 0.1)", "rgba(54, 235, 144, 1)"],
			["rgba(54, 176, 235, 0.1)", "rgba(54, 176, 235, 1)"],
			["rgba(85, 54, 237, 0.1)", "rgba(85, 54, 237, 1)"],
			["rgba(237, 54, 237, 0.1)", "rgba(237, 54, 237, 1)"],
			["rgba(237, 54, 85, 0.1)", "rgba(237, 54, 85, 1)"]
		];
		const randomColor = colorsArray[Math.floor(Math.random() * colorsArray.length)];
		return {backgroundColor: randomColor[0], fontColor: randomColor[1]};
	}
}
