import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-progress-bar-indicator',
	templateUrl: './progress-bar-indicator.component.html',
	styleUrls: ['./progress-bar-indicator.component.scss']
})
export class ProgressBarIndicatorComponent {
	@Input() label: string = 'Niveau';
	@Input() color: 'auto'|'accent' = 'auto';
	@Input() unit: 'percent'|'letter' = 'percent';
	@Input() size: Size = 'M';
	@Input() lowerBest: boolean = false;

	private _levelValue: number|undefined;
	@Input()
	set levelPercent(value: number|undefined|null) {
		if (value !== undefined && value !== null) {
			this._levelValue = Math.min(value, 100);
			this._levelValue = Math.max(this._levelValue, 0);
		}
	}
	get levelPercent(): number|undefined|null {
		return this._levelValue;
	}

	get indicatorLabel(): string {
		if (this.unit === 'letter') {
			switch (this.getIndicatorStatus()) {
				case IndicatorStatus.A: return 'A';
				case IndicatorStatus.B: return 'B';
				case IndicatorStatus.C: return 'C';
				case IndicatorStatus.D: return 'D';
				case IndicatorStatus.UNKNOWN: return '-';
			}
		} else if (this.unit === 'percent') {
			return (!this.levelPercent ? '-' : this.levelPercent) + '%';
		} else {
			return '';
		}
	}

	get indicatorColorClass(): string {
		if (this.color === 'auto') {
			const status = this.getIndicatorStatus();
			return this.getIndicatorColorClass(status);
		} else if (this.color === 'accent') {
			return 'accent';
		} else {
			return '';
		}
	}

	private getIndicatorColorClass(status: IndicatorStatus): string {
		switch (status) {
			case IndicatorStatus.A: return 'success';
			case IndicatorStatus.B: return 'warning';
			case IndicatorStatus.C: return 'warning';
			case IndicatorStatus.D: return 'danger';
			case IndicatorStatus.UNKNOWN: return 'grey';
		}
	}

	private getIndicatorStatus(): IndicatorStatus {
		if (this.levelPercent === undefined || this.levelPercent === null) {
			return IndicatorStatus.UNKNOWN;
		}
		const percent = this.lowerBest ? 100 - this.levelPercent : this.levelPercent;
		if (percent < 25) {
			return IndicatorStatus.D;
		} else if (percent < 50) {
			return IndicatorStatus.C;
		} else if (percent < 75) {
			return IndicatorStatus.B;
		} else {
			return IndicatorStatus.A;
		}
	}

	get indicatorFontSize(): `${string}px` {
		switch (this.size) {
			case 'XS': return '12px';
			case 'S': return '14px';
			case 'M': return '20px';
			case 'L': return '26px';
			case 'XL': return '34px';
			case 'XXL': return '42px';
		}
	}

	get fontWeight(): string {
		switch (this.size) {
			case 'XS': return '500';
			case 'S': return '500';
			case 'M': return '600';
			case 'L': return '600';
			case 'XL': return '700';
			case 'XXL': return '700';
		}
	}

	get progressBarHeight(): `${string}px` {
		switch (this.size) {
			case 'XS': return '5px';
			case 'S': return '6px';
			case 'M': return '7px';
			case 'L': return '8px';
			case 'XL': return '9px';
			case 'XXL': return '10px';
		}
	}

	get progressBarWidth(): `${string}%` {
		return this.levelPercent === undefined || this.levelPercent === null
			? '0%'
			: `${this.levelPercent + 1}%`
	}
}

type Size = 'XS'|'S'|'M'|'L'|'XL'|'XXL';

enum IndicatorStatus {
	A,
	B,
	C,
	D,
	UNKNOWN
}
