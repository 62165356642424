import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {PlanComponent} from "./plan.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatLegacyProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MiniButtonModule} from "../../../global/button/mini-button/mini-button.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
	declarations: [
		PlanComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		MatLegacyProgressSpinnerModule,
		MiniButtonModule,
		DesignSystemModule
	],
    exports: [
        PlanComponent
    ]
})
export class PlanModule { }
