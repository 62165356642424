<div>
	<div class="flex-space-between pl-m pr-m pt-m">
		<div class="mb-s">
            <span class="font-MM fw-700">{{ 'page.extension.extensionsDeployed' | translate }}</span>
		</div>
	</div>
	<div class="pl-m pr-m pb-m" (mouseout)="tooltip.display = 'none'">
		<canvas [height]="240"
				baseChart
				[data]="chartData"
				[type]="chartType"
				[options]="chartOptions"
		></canvas>
		<div class="tooltip" style="padding: 3px 7px;" [style.top]="tooltip.top" [style.left]="tooltip.left" [style.display]="tooltip.display">
			<div class="flex-space-between" style="font-size: 14px; font-weight: 700;">
				<div>
					<span>{{ tooltip.day }} {{ 'global.monthShort.' + tooltip.month | translate }}</span>
				</div>
				<div>
					<span class="accent">{{ tooltip.value }} extension(s)</span>
				</div>
			</div>
		</div>
	</div>
</div>
