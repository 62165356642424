import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ElementListComponent} from "./element-list.component";
import {ContentLoaderModule} from "@ngneat/content-loader";



@NgModule({
	declarations: [
		ElementListComponent
	],
	exports: [
		ElementListComponent
	],
	imports: [
		CommonModule,
		ContentLoaderModule
	]
})
export class ElementListModule { }
