import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {OrganizationService} from "../../../../services/organization.service";
import {DeckContentLoaderModule} from "../../../global/title-content-loader/deck-content-loader.module";
import {MatIconModule} from "@angular/material/icon";
import {MatRippleModule} from "@angular/material/core";
import {LineCardModule} from "../../../global/cards/line-card/line-card.module";
import {CardModule} from "../../../global/cards/card/card.module";
import {DoughnutModule} from "../../../global/charts/doughnut/doughnut.module";
import {BadgeModule} from "../../../global/badge/badge.module";
import {MiniButtonModule} from "../../../global/button/mini-button/mini-button.module";
import {AlertModule} from "../../../global/alert/alert.module";
import {BusinessUnitComponent} from "./business-unit.component";
import {RouterModule} from "@angular/router";
import {ApplicationMiniWidgetModule} from "../../../global/application-mini-widget/application-mini-widget.module";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {ApplicationTableModule} from "../../../global/application-table/application-table.module";
import {ElementListModule} from "../element-list/element-list.module";
import {OrganizationSettingModule} from "../organization-setting/organization-setting.module";
import { TeamApplicationsComponent } from './team-applications/team-applications.component';
import {ApplicationIconModule} from "../../../global/application-icon/application-icon.module";
import {ChipsModule} from "../../../global/chips/chips/chips.module";
import {TagsModule} from "../../../global/tags/tags.module";
import { AddAppsToTeamComponent } from './add-apps-to-team/add-apps-to-team.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {SnackbarModule} from "../../../global/snackbar/snackbar.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
  imports: [
    MatIconModule,
    TranslateModule,
    CommonModule,
    DeckContentLoaderModule,
    MatRippleModule,
    LineCardModule,
    CardModule,
    DoughnutModule,
    BadgeModule,
    MiniButtonModule,
    AlertModule,
    RouterModule,
    ApplicationMiniWidgetModule,
    ContentLoaderModule,
    ApplicationTableModule,
    ElementListModule,
    OrganizationSettingModule,
    ApplicationIconModule,
    ChipsModule,
    TagsModule,
    MatCheckboxModule,
    SnackbarModule,
    DesignSystemModule,
  ],
	providers: [
		OrganizationService
	],
	declarations: [
		BusinessUnitComponent,
  		TeamApplicationsComponent,
    AddAppsToTeamComponent
	],
	exports: [
		BusinessUnitComponent
	]
})
export class BusinessUnitModule {
}
