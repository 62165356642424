import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ContentLoaderComponent} from '@ngneat/content-loader';


@Component({
	selector: 'app-home-page-history-loader',
	template: `
    <content-loader viewBox="0 0 630 270">
		<svg:rect x="0" y="0" width="630" height="38" rx="10" />
		<svg:rect x="0" y="58" width="630" height="38" rx="10" />
		<svg:rect x="0" y="116" width="630" height="38" rx="10" />
		<svg:rect x="0" y="174" width="630" height="38" rx="10" />
		<svg:rect x="0" y="232" width="630" height="38" rx="10" />
	</content-loader>
  `,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageHistoryLoaderComponent extends ContentLoaderComponent {}
