import {ClickableObject, DrawableObject, Point} from "../diagram";
import {Layers} from "../layer";
import {ApplicationCard} from "./application-card";

export interface ColumnState {

}

export class TableColumn implements DrawableObject<ColumnState> {

	constructor(
		protected ctx: CanvasRenderingContext2D,
		protected title: string,
		protected subtitle: string,
		protected origin: Point,
		protected width: number,
		protected height: number) {
	}

    draw(layers: Layers): void {
		layers.getLayer(0).addObject(this.drawBackground());
		layers.getLayer(1).addObject(this.drawTitle());
		layers.getLayer(1).addObject(this.drawSubtitle());
	}

    update(state: ColumnState): void {
    }

	protected drawBackground(): ()=>void {
		return () => {
			this.ctx.beginPath()

			this.ctx.fillStyle = '#DCDCE9';
			this.ctx.roundRect(this.origin.x, this.origin.y, 1, this.height, 1);
			this.ctx.fill()

			//this.ctx.strokeStyle = (this.isSelected || this.mouseOver) ? '#36B0EB' : '#ececee';
			//this.ctx.lineWidth = 1;
			//this.ctx.stroke();
		}
	}

	protected drawTitle(): ()=>void {
		return () => {
			this.ctx.beginPath()
			let fontSize = 16;

			this.ctx.font = "bold 16px proxima-nova";

			const marginTop = 20;
			this.ctx.fillStyle = "#0C2633";

			this.ctx.fillText(this.title, this.origin.x + 20, this.origin.y + marginTop);
		}
	}

	protected drawSubtitle(): ()=>void {
		return () => {
			this.ctx.beginPath()

			this.ctx.font = "normal 14px proxima-nova";
			const marginTop = 40;
			this.ctx.fillStyle = "#0C2633";

			this.ctx.fillText(this.subtitle, this.origin.x + 20, this.origin.y + marginTop);
		}
	}


}
