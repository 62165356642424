import {Component, Input, OnInit} from '@angular/core';

/**
 * Colors :
 *  - primary
 *  - danger
 *  - warning
 *  - success
 *  - secondary
 */
@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
	@Input() message: string = "";
	@Input() color : 'primary' | 'danger' | 'warning' | 'orange-warning' | 'success' | 'secondary' | 'transparent' = 'secondary';
	@Input() width: string = "";
	@Input() autoclose: number = 0;
	@Input() closable: boolean = false;
	@Input() size: 'm' | 's' = 'm';

	hide = false;

	constructor() {
		this.progressTime = 1;
		this.progressPercent = 0;
	}

	ngOnInit(): void {
		if (this.autoclose && this.autoclose > 0) {
			this.startTimer();
		}
	}

	// Auto hide
	protected progressTime: number;
	progressPercent: number;

	protected startTimer(): void {
		let intervalSubscription = setInterval(() => {
			if (this.progressPercent > 100) {
				clearInterval(intervalSubscription);
				this.closeAlert();
			}

			this.progressPercent = (this.progressTime * 100) / this.autoclose;
			// 40 is 24 FPS to have smooth loading
			this.progressTime += 0.04;
		}, 40);
	}

	closeAlert(): void {
		this.hide = true;
	}

	getStyle(): any {
		if (this.hide) {
			return {display: 'none'};
		} else {
			if (this.width && this.width.length > 0) {
				return {width: this.width};
			} else {
				return {};
			}
		}
	}

	getClass(): string {
		if (this.color) {
			return 'alert-' + this.color;
		} else {
			return '';
		}
	}
}
