import {BezierCurve, DrawableObject, Point, Rectangle} from "../diagram";
import {RectangleUtils} from "../geometry";
import {Layers} from "../layer";

type CurvedLineState = {};

export class CurvedLine implements DrawableObject<CurvedLineState> {
	public type = 'curved';
	protected isActiveHover: boolean = false;
	protected animationStartedAt: number = new Date().getTime() + Math.floor(Math.random() * 5000);
	protected lastAnimationUpdate= 0;
	protected animationPosition: number = 0;

	constructor(
		protected ctx: any,
		protected from: Point,
		protected to: Point,
		protected sourceId: string,
		protected targetId: string,
		protected data: string[] = []) {}

	protected getLeadingCoefficient(): number {
		if (this.to.x === this.from.x) {
			return 0;
		}
		return (this.to.y - this.from.y) / (this.to.x - this.from.x);
	}

	getSourceId(): string {
		return this.sourceId;
	}

	getTargetId(): string {
		return this.targetId;
	}

	activate() : void {
		this.isActiveHover = true;
	}

	deactivate() : void {
		this.isActiveHover = false;
	}

	protected bezierCurveParameters(): { p1: Point, p2: Point } {
		const leadingCoefficient = this.getLeadingCoefficient();
		const a = (this.to.x - this.from.x) < 0 ? -160 : 160;

		if (leadingCoefficient !== 0) {
			return { p1: { x: this.from.x + a, y: this.from.y }, p2: { x: this.to.x - a, y: this.to.y } };
		} else {
			return { p1: { x: this.from.x + 100, y: this.from.y }, p2: { x: this.to.x + 100, y: this.to.y  } };
		}
	}

	update(state: CurvedLineState = {}) {
		// Update bubble animation
		if (this.lastAnimationUpdate + 10 < new Date().getTime()) {
			this.animationPosition = this.animationPosition + 1;
			this.lastAnimationUpdate = new Date().getTime();
		}
	}

	draw(layers: Layers): void {
		const {p1, p2} = this.bezierCurveParameters();
		layers.getLayer(1).addObject(this.drawDeactivated(p1, p2));
		layers.getLayer(3).addObject(this.drawActivated(p1, p2));
		layers.getLayer(6).addObject(this.drawDataBubble(p1, p2));
	}

	protected drawDeactivated(p1: Point, p2: Point) : ()=>void {
		if (!this.isActiveHover) {
			return () => {
				this.ctx.beginPath();
				this.ctx.strokeStyle = 'rgba(0, 0, 0, 0.05)';
				this.ctx.lineWidth = 4;
				this.ctx.moveTo(this.from.x, this.from.y);
				this.ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				this.ctx.stroke();
				this.ctx.lineWidth = 1;
			}
		} else {
			return () => {};
		}
	}

	protected drawActivated(p1: Point, p2: Point): ()=>void {
		if (this.isActiveHover) {
			return () => {
				this.ctx.beginPath();
				this.ctx.strokeStyle = 'rgba(54, 176, 235, 0.3)';
				this.ctx.lineWidth = 8;
				this.ctx.moveTo(this.from.x, this.from.y);
				this.ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				this.ctx.stroke();

				this.ctx.beginPath();
				this.ctx.strokeStyle = 'rgba(54, 176, 235, 1)';
				this.ctx.lineWidth = 1;
				this.ctx.moveTo(this.from.x, this.from.y);
				this.ctx.bezierCurveTo(p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				this.ctx.stroke();

				if (this.animationStartedAt < new Date().getTime()) {
					const t = ((this.animationPosition) % 1000) / 1000;
					const bubble = this.getBezierXY(t, this.from.x, this.from.y, p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);

					this.ctx.beginPath();
					this.ctx.arc(bubble.x, bubble.y, 4, 0, 2 * Math.PI);
					this.ctx.fillStyle = 'rgba(54, 176, 235, 1)';
					this.ctx.fill();
				}
			}
		} else {
			return () => {};
		}
	}

	protected drawDataBubble(p1: Point, p2: Point) : ()=>void {
		if (this.isActiveHover && this.data.length > 0) {
			return () => {
				let center: Point = {x: 0, y: 0};
				for (let i = 0.4; i < 0.8; i += 0.1) {
					center = this.getBezierXY(i, this.from.x, this.from.y, p1.x, p1.y, p2.x, p2.y, this.to.x, this.to.y);
				}

				this.drawDataTooltip(this.data.join(' - '), center, this.isActiveHover ? "rgba(54, 176, 235, 1)" : "rgba(0, 0, 0, 0.1)");
			}
		} else {
			return () => {};
		}
	}

	protected drawDataTooltip(content: string, coordinate: Point, color: string): void {
		let fontSize = Math.max(Math.min(24, 24 * 1/this.ctx.getTransform().d), 14);
		this.ctx.font = "bold "+ (fontSize > 14 ? fontSize : 14) + "px proxima-nova";

		const metrics = this.ctx.measureText(content);

		let fontHeight;
		if (metrics.fontBoundingBoxDescent && metrics.fontBoundingBoxAscent) {
			fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
		} else {
			fontHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
		}

		// Tooltip background
		this.ctx.beginPath()
		this.ctx.fillStyle = color;
		const width = metrics.width + 40;
		const height = 30;
		this.ctx.roundRect(coordinate.x - width / 2, coordinate.y - height / 2, width, height, 15);
		this.ctx.fill()

		this.ctx.beginPath();
		this.ctx.textAlign = "center";
		this.ctx.fillStyle = "white";
		this.ctx.fillText(content, coordinate.x, coordinate.y + fontHeight / 5);

		// Reset text
		this.ctx.textAlign = "left";
	}

	protected getBezierXY(t: number, sx: number, sy: number, cp1x: number, cp1y: number, cp2x: number, cp2y: number, ex: number, ey: number): Point {
		return {
			x: Math.pow(1-t,3) * sx + 3 * t * Math.pow(1 - t, 2) * cp1x
				+ 3 * t * t * (1 - t) * cp2x + t * t * t * ex,
			y: Math.pow(1-t,3) * sy + 3 * t * Math.pow(1 - t, 2) * cp1y
				+ 3 * t * t * (1 - t) * cp2y + t * t * t * ey
		};
	}

	protected getBezierSegments(curve: BezierCurve, numSegments = 20) {
		var segments = [];
		for (var i = 0; i < numSegments; i++) {
			var t1 = i / numSegments;
			var t2 = (i + 1) / numSegments;
			var p1 = this.getBezierPoint(curve, t1);
			var p2 = this.getBezierPoint(curve, t2);
			segments.push([p1, p2]);
		}
		return segments;
	}

	protected getBezierPoint(curve: BezierCurve, t:any) {
		var x =
			Math.pow(1 - t, 3) * curve.start.x +
			3 * Math.pow(1 - t, 2) * t * curve.handle1.x +
			3 * (1 - t) * Math.pow(t, 2) * curve.handle2.x +
			Math.pow(t, 3) * curve.end.x;
		var y =
			Math.pow(1 - t, 3) * curve.start.y +
			3 * Math.pow(1 - t, 2) * t * curve.handle1.y +
			3 * (1 - t) * Math.pow(t, 2) * curve.handle2.y +
			Math.pow(t, 3) * curve.end.y;
		return { x: x, y: y };
	}
}
