import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ResourceMappingCardComponent} from 'src/app/modules/global/resource-mapping-card/resource-mapping-card.component';
import {ChipsModule} from '../chips/chips/chips.module';
import {ApplicationIconModule} from '../application-icon/application-icon.module';
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
  imports: [
    CommonModule,
    ChipsModule,
    ApplicationIconModule,
    DesignSystemModule,
  ],
	declarations: [
		ResourceMappingCardComponent
	],
	exports: [
		ResourceMappingCardComponent
	]
})
export class ResourceMappingCardModule {
}
