import {Component, OnDestroy, OnInit} from '@angular/core';
import {distinctUntilChanged, finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {filter, map} from 'rxjs/operators';
import {CriticalityLevel, TenantService} from 'src/app/services/tenant.service';
import {TranslateService} from '@ngx-translate/core';
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {ApplicationCategoryApplicationListForm, ApplicationCriticalityForm, ApplicationOverview, ApplicationResponsibleListForm, ApplicationStatus, ApplicationTypeForm, HostingType} from 'src/app/services/model/new-application.model';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {ApplicationCategoryService} from 'src/app/services/back/application-category.service';
import {ApplicationCategoryForm, Category} from 'src/app/services/model/application-category.model';
import {FormControl} from '@angular/forms';
import {TenantAccountService} from 'src/app/services/back/tenant-account.service';
import {TenantAccount} from 'src/app/services/model/account.model';
import {ApplicationLifeCycle, ApplicationLifeCycleForm} from 'src/app/services/model/application-life-cycle.model';
import {ApplicationLifeCycleService} from 'src/app/services/back/application-life-cycle.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-application-information-form',
  templateUrl: './application-information-form.component.html',
  styleUrls: ['./application-information-form.component.scss']
})
export class ApplicationInformationFormComponent implements OnInit, OnDestroy {

  tenantId: string;
  isEditor: boolean;
  applicationId: string;
  overview: ApplicationOverview;
  lifeCycle: ApplicationLifeCycle|null;

  _initializing: boolean;
  _refreshing: boolean;
  _loading: boolean;
  _saving: boolean;
  _savingNewCategory: boolean;
  initialized: boolean = false;

  advancementPercent: number = 0;

  userSelectList: TenantAccount[] = [];
  userSelected: TenantAccount|undefined;

  dateControl: FormControl<Date|null>;

  criticalityControl: FormControl<CriticalityLevel|null>;
  criticalityList: CriticalityLevel[] = [];

  appStatus: ApplicationStatus|null = null;
  status: typeof ApplicationStatus = ApplicationStatus;

  typeControl: FormControl<HostingType>;
  typeList: HostingType[] = [];

  categoryControl: FormControl<Category|null>;
  categoryList: Category[] = [];
  filteredCategoryList: Category[] = [];
  searchCategoryControl: FormControl<string|null>;

  subscription = new Subscription();

  constructor(private applicationDetailService: ApplicationDetailService,
              private applicationCategoryService: ApplicationCategoryService,
              private applicationLifeCycleService: ApplicationLifeCycleService,
              private newApplicationService: NewApplicationService,
              private tenantAccountService: TenantAccountService,
              private translateService: TranslateService,
              private snackBarService: SnackbarService,
              private tenantService: TenantService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.createForms();
    this.subscription.add(this.applicationDetailService.getInitializingChanges()
      .subscribe(initializing => this._initializing = initializing));
    this.subscription.add(this.applicationDetailService.getRefreshingChanges()
      .subscribe(refreshing => this._refreshing = refreshing));
    this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
      .pipe(tap(data => this.setApplicationDetailData(data)))
      .subscribe(() => this.initialize()));
  }

  private setApplicationDetailData(data: ApplicationDetailData): void {
    this.tenantId = data.tenantId;
    this.isEditor = data.isEditor;
    this.applicationId = data.instance.applicationId;
  }

  initialize(): void {
    this.subscription.add(this.switchLoading()
      .pipe(
        switchMap(() => this.buildDropdowns()),
        // TODO @TAN rework using applicationDetailService
        switchMap(() => forkJoin([
          this.newApplicationService.getApplicationOverview(this.tenantId, this.applicationId),
          this.applicationLifeCycleService.getApplicationLifeCycle(this.tenantId, this.applicationId)
        ])),
        tap(([overview, lifeCycle]) => this.setDefaultData(overview, lifeCycle)),
        finalize(() => this.switchLoading()))
      .subscribe(() => this.initialized = true));
  }

  private createForms(): void {
    this.searchCategoryControl = new FormControl('');
    this.subscription.add(this.searchCategoryControl.valueChanges.pipe(distinctUntilChanged())
      .subscribe(text => this.filterCategoryList(text)));
    this.dateControl = new FormControl(null);
    this.subscription.add(this.dateControl.valueChanges.pipe(distinctUntilChanged())
      .subscribe(date => this.updateApplicationLifeCycle(date)));
    this.criticalityControl = new FormControl(null);
    this.subscription.add(this.criticalityControl.valueChanges.pipe(distinctUntilChanged())
      .subscribe(criticality => this.updateApplicationCriticality(criticality)));
    this.categoryControl = new FormControl(null);
    this.subscription.add(this.categoryControl.valueChanges.pipe(distinctUntilChanged())
      .subscribe(category => this.updateApplicationCategory(category?.categoryId)));
    this.typeControl = new FormControl();
    this.subscription.add(this.typeControl.valueChanges.pipe(distinctUntilChanged())
      .subscribe(type => this.updateApplicationType(type)));
  }

  setDefaultData(overview: ApplicationOverview, lifeCycle: ApplicationLifeCycle|null): void {
    this.overview = overview;
    this.lifeCycle = lifeCycle;
    const values: any[] = [this.overview.responsible, this.overview.instance.criticality, this.overview.category, this.lifeCycle?.deployedDate];
    this.advancementPercent = (values.filter(v => !!v).length / values.length) * 100;
    this.dateControl.setValue(!this.lifeCycle?.deployedDate ? null : new Date(this.lifeCycle.deployedDate), { emitEvent: false });
    const criticality: CriticalityLevel|undefined = this.criticalityList.find(c => c === this.overview.instance.criticality);
    this.criticalityControl.setValue(!criticality ? null : criticality, { emitEvent: false });
    const category: Category|undefined = this.categoryList.find(c => c.categoryId === this.overview.category?.categoryId);
    this.categoryControl.setValue(!category ? null : category, { emitEvent: false });
    const type: HostingType = this.typeList.find(c => c === this.overview.instance!.hostingType)!;
    this.typeControl.setValue(type, { emitEvent: false });
    this.userSelected = this.overview.responsible?.user;
    this.appStatus = this.overview.status;
  }

  private buildDropdowns(): Observable<{}> {
    if (!this.initialized) {
      return forkJoin([
        this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId),
        this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId)
      ]).pipe(tap(([categories, users]) => this.setDropdownData(categories, users)));
    } else {
      return of({});
    }
  }

  private setDropdownData(categories: Category[], users: TenantAccount[]): void {
    this.categoryList = categories;
    this.filterCategoryList();
    this.userSelectList = users
      .sort((a, b) => `${a.account.firstName} ${a.account.lastName}`.localeCompare(`${b.account.firstName} ${b.account.lastName}`))
      .sort((a, b) => a.pending ? 1 : -1);
    this.criticalityList = Object.values(CriticalityLevel);
    this.typeList = Object.values(HostingType);
  }

  filterCategoryList(filter?: string|null): void {
    this.filteredCategoryList = this.categoryList
      .filter(value => !filter || value.name.toLowerCase().includes(filter.toLowerCase()))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  private updateApplicationLifeCycle(deployedDate: Date|null): void {
    const form: ApplicationLifeCycleForm = {
      phaseInDate: !this.lifeCycle?.phaseInDate
        ? undefined
        : new Date(this.datePipe.transform(this.lifeCycle.phaseInDate, "yyyy-MM-dd")!),
      deployedDate: !deployedDate
        ? undefined
        : new Date(this.datePipe.transform(deployedDate, "yyyy-MM-dd")!),
      phaseOutDate: !this.lifeCycle?.phaseOutDate
        ? undefined
        : new Date(this.datePipe.transform(this.lifeCycle.phaseOutDate, "yyyy-MM-dd")!),
      retiredDate: !this.lifeCycle?.retiredDate
        ? undefined
        : new Date(this.datePipe.transform(this.lifeCycle.retiredDate, "yyyy-MM-dd")!),
    };
    this.switchSaving()
      .pipe(
        switchMap(() => this.applicationLifeCycleService.updateApplicationLifeCycle(this.tenantId, this.applicationId, form)),
        filter(success => !!success),
        tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
        finalize(() => this.switchSaving()))
      .subscribe(() => this.applicationDetailService.refreshApplicationInstance());
  }

  private updateApplicationCriticality(criticality: CriticalityLevel|null): void {
    const form: ApplicationCriticalityForm = {
      criticality: criticality
    };
    this.switchSaving()
      .pipe(
        switchMap(() => this.newApplicationService.updateApplicationCriticalityForm(this.tenantId, this.applicationId, form)),
        filter(success => !!success),
        tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
        finalize(() => this.switchSaving()))
      .subscribe(() => this.applicationDetailService.refreshApplicationInstance());
  }

  private updateApplicationType(type: HostingType): void {
    const form: ApplicationTypeForm = {
      type: type
    };
    this.switchSaving()
      .pipe(
        switchMap(() => this.newApplicationService.updateApplicationTypeForm(this.tenantId, this.applicationId, form)),
        filter(success => !!success),
        tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
        finalize(() => this.switchSaving()))
      .subscribe(() => this.applicationDetailService.refreshApplicationInstance());
  }

  onInviteResponsible(email: string): void {
    const existingUser = this.userSelectList.find(user => user.account.email === email);
    if (!existingUser) {
      this.tenantService.inviteUsers(this.tenantId, [email])
        .pipe(
          switchMap(() => this.tenantAccountService.getAllTenantAccountByTenantId(this.tenantId)),
          tap(users => this.userSelectList = users
            .sort((a, b) => `${a.account.firstName} ${a.account.lastName}`.localeCompare(`${b.account.firstName} ${b.account.lastName}`))
            .sort((a, b) => a.pending ? 1 : -1)),
          map(() => this.userSelectList.find(user => user.account.email === email)),
          filter(user => !!user),
          tap(user => this.onResponsibleChanges(user!)))
        .subscribe();
    } else {
      this.onResponsibleChanges(existingUser);
    }
  }

  onResponsibleChanges(user: TenantAccount): void {
    this.updateApplicationResponsible(user.account.accountId);
  }

  private updateApplicationResponsible(accountId: string): void {
    const form: ApplicationResponsibleListForm = {
      responsibleUsers: [{ accountId: accountId }]
    };
    this.switchSaving()
      .pipe(
        switchMap(() => this.newApplicationService.updateApplicationResponsibleList(this.tenantId, this.applicationId, form)),
        filter(success => !!success),
        tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
        finalize(() => this.switchSaving()))
      .subscribe(() => this.applicationDetailService.refreshApplicationInstance());
  }

  createCategory(newCategory: string): void {
    const form: ApplicationCategoryForm = {
      name: newCategory
    };
    this.switchSavingNewCategory()
      .pipe(
        switchMap(() => this.applicationCategoryService.createApplicationCategoryForTenant(this.tenantId, form)),
        finalize(() => this.switchSavingNewCategory()))
      .subscribe(categoryId => {
        const newData: Category = {
          categoryId: categoryId,
          name: newCategory
        };
        this.categoryList.push(newData);
        this.categoryControl.setValue(newData);
        this.searchCategoryControl.reset('');
      });
  }

  private updateApplicationCategory(categoryId: string|undefined): void {
    const form: ApplicationCategoryApplicationListForm = {
      categories: !categoryId ? [] : [{ categoryId: categoryId }]
    };
    this.switchSaving()
      .pipe(
        switchMap(() => this.newApplicationService.updateApplicationCategoryList(this.tenantId, this.applicationId, form)),
        filter(success => !!success),
        tap(() => this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'))),
        finalize(() => this.switchSaving()))
      .subscribe(() => this.applicationDetailService.refreshApplicationInstance());
  }

  filterDeployed = (date: Date | null): boolean => {
    const phaseIn: number|undefined = !this.lifeCycle?.phaseInDate ? undefined : new Date(this.lifeCycle.phaseInDate).getTime();
    const phaseOut: number|undefined = !this.lifeCycle?.phaseOutDate ? undefined : new Date(this.lifeCycle.phaseOutDate).getTime();
    const retired: number|undefined = !this.lifeCycle?.retiredDate ? undefined : new Date(this.lifeCycle.retiredDate).getTime();
    return !!date
      && date.getTime() >= (phaseIn ?? Number.NEGATIVE_INFINITY)
      && date.getTime() <= (phaseOut ?? retired ?? Number.POSITIVE_INFINITY);
  }

  switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  switchSaving(): Observable<{}> {
    this._saving = !this._saving;
    return of({});
  }

  switchSavingNewCategory(): Observable<{}> {
    this._savingNewCategory = !this._savingNewCategory;
    return of({});
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
