<div class="flex-column border-box-full pad-M">
    <div class="flex-space-between">
        <span class="font-MM fw-700">{{'page.financeDashboard.tcoEvolution'|translate}}</span>
        <div class="flex-space-between flex-align-center gap-XXL">
            <div class="flex-space-between flex-align-center gap-M">
                <div class="flex-align-center gap-S">
                    <div class="bkg-accent border-accent b-radius-half" style="height: 18px; width: 18px;"></div>
                    <span class="font-M fw-500">{{'page.financeDashboard.past'|translate}}</span>
                </div>
                <div class="flex-align-center gap-S">
                    <div class="bkg-accent-light border-accent border-dashed b-radius-half" style="height: 18px; width: 18px; border-width: 1px !important;"></div>
                    <span class="font-M fw-500">{{'page.financeDashboard.future'|translate}}</span>
                </div>
            </div>
            <app-multi-select [inputControl]="rangeTypeControl" style="width: 100px;"
                              [disabled]="_initializing || _loading">
                <app-select-trigger appearance="field">{{rangeTypeControl.value.value}}</app-select-trigger>
                <app-select-option *ngFor="let type of rangeTypes" [value]="type">{{type.value}}</app-select-option>
            </app-multi-select>
        </div>
    </div>
    <div class="flex h-100">
        <div class="flex-column h-100" style="width: 50px;">
            <div class="flex-column h-90">
                @for (ordinate of ordinates.slice(1); track ordinate) {
                    <div class="flex-align-end h-20">
                        <span class="font-M fw-500" style="margin-bottom: -7px;">{{ordinate|cost:'shorten'}}</span>
                    </div>
                }
            </div>
            <div class="h-10"></div>
        </div>
        <div class="p-absolute h-100" style="width: calc(100% - 80px); height: calc(100% - 71px); margin-left: 50px; z-index: 0;">
            <div class="flex-column h-90">
                @for (ordinate of ordinates.slice(1); track ordinate) {
                    <div class="h-20 border-light-grey-bottom" style="border-bottom-style: dashed !important; border-width: 1px !important;"></div>
                }
            </div>
            <div class="h-10"></div>
        </div>
        <div class="p-absolute flex-align-end h-100" style="width: calc(100% - 80px); height: calc(100% - 71px); margin-left: 50px; z-index: 0;">
            <div class="flex w-100 h-90">
                @for (cost of costs; track cost; let i = $index) {
                    @if (cost.date.getMonth() === 0 && cost.dateDisplay !== 'year') {
                        <div class="h-100 border-grey-left" style="width: 8.33%; border-left-width: 1px !important; margin-left: -1px;">
                            <div class="p-absolute flex pad-left-XS">
                                <span class="font-M fw-500 grey">{{cost.date.getFullYear()}}</span>
                            </div>
                        </div>
                    } @else {
                        <div class="h-100" style="width: 8.33%;"></div>
                    }
                }
            </div>
        </div>
        <div class="flex-column h-100" style="width: calc(100% - 50px); z-index: 1;">
            <div class="flex w-100 h-90">
                @for (cost of costs; track cost; let i = $index) {
                    <div class="flex-align-end flex-justify-center h-100" style="width: 8.33%;">
                        <div class="flex vertical-bar-M border-accent pin-hover" style="border-width: 1px !important; min-height: 5px !important;"
                             [class]="i < 3 ? 'bkg-accent' : 'bkg-accent-light border-dashed'"
                             [style.height.%]="cost.percentage" [tooltip]="(cost.value|cost)" tooltipPosition="top" [tooltipOffset]="7">
                            <div class="p-absolute pin-marker bkg-accent border-white" style="border-width: 2px !important; width: 10px; height: 10px; border-radius: 50%; margin-top: -8px; margin-left: 11px;"></div>
                        </div>
                    </div>
                }
            </div>
            <div class="flex w-100 h-10">
                @for (cost of costs; track cost; let i = $index) {
                    <div class="flex-align-end flex-justify-center" style="width: 8.33%;">
                        <span class="font-M fw-500" [ngClass]="{'grey': i !== 2}">
                            @if (cost.dateDisplay === 'month') {
                                {{ cost.date|date:'MMM' }}
                            } @else if (cost.dateDisplay === 'quarter') {
                                {{ cost.date|customDate:'quarter':true}}
                            } @else if (cost.dateDisplay === 'year') {
                                {{ cost.date|date:'yyyy' }}
                            }
                        </span>
                    </div>
                }
            </div>
        </div>
    </div>
</div>
