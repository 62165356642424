import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {FormControl, FormGroup} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {HOME_URL} from "../../../../models/home/navigation.model";
import {Router} from "@angular/router";

@Component({
  selector: 'app-onboarding-step-2',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, NgOptimizedImage, TranslateModule],
  templateUrl: './onboarding-step-2.component.html',
	styleUrl: '../onboarding.component.scss'
})
export class OnboardingStep2Component implements OnInit {
	constructor(private breakpointObserver: BreakpointObserver, private router: Router) {
	}

	form: FormGroup;

	@Input() stepCount: number = 6;
	@Input() canClose: boolean = false;
	@Input() isMobile: boolean;

	@Output() onNextClick = new EventEmitter<FormGroup>();

	height: number = 60;
	panelClass: string = '';
	fontSize: 'S' | 'L' = 'L';

	ngOnInit() {
		this.breakpointObserver
			.observe(['(max-width: 1280px)'])
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
					this.panelClass = ''
					this.height = 35;
					this.fontSize = 'S'
				} else {
					this.height = 60;
					this.panelClass = 'big-button'
					this.fontSize = 'L'
				}
			});

		this.createForm();
	}

	createForm() {
		this.form = new FormGroup({
			'userKind': new FormControl('cio'),
			'workPlace': new FormControl('group'),
			'objectives': new FormControl('mapping'),
		});
	}

	goToNextStep() {
		this.onNextClick.emit(this.form);
	}

	goBackHome() {
		return this.router.navigate([HOME_URL])
	}
}
