import {Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-radar-loader',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './radar-loader.component.html',
  styleUrl: './radar-loader.component.scss'
})
export class RadarLoaderComponent {
	@Input() scale: number = 1;
}
