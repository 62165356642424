<app-snackbar></app-snackbar>
<!-- Title -->
<div class="pl-xl pr-xl pt-l pb-l slider-header" >
	<div class="disp-f j-c-s a-i-c w-100">
			<div>
				<div class="disp-f a-i-c j-c-c col bkg-accent-light radius-10" style="height: 45px; width: 45px">
					<img [src]="!!data.businessUnit.icon ? ('/assets/icons/team_icon/' + data.businessUnit.icon + '.svg') : 'assets/icons/team.svg'" class="svg-24 filter-accent">
				</div>
			</div>
			<div class="ml-s flex-column gap-XS">
				<div class="font-L fw-700 text-overflow-ellipsis" style="display:inline-block; max-width: 500px;">{{ data.businessUnit.name }}</div>
				<div class="font-M fw-300">{{ data.parent.name }}</div>
			</div>
	</div>

	<div class="flex-align-center gap-S">
		<app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
						 (click)="close()">
		</app-icon-button>
		<app-icon-button type="stroked" background [img]="'assets/icons/save.svg'"
						 *ngIf="!!settings"
						 (click)="onSaveSettings()">
		</app-icon-button>
		<app-icon-button type="stroked" background [img]="!settings ? 'assets/icons/cog-inactive.svg' : 'assets/icons/cog-active.svg'" [focused]="!!settings"
						 (click)="onOpenOrgSettings()">
		</app-icon-button>
		<app-icon-button type="stroked" background color="danger" [img]="'assets/icons/trash.svg'"
						 *ngIf="isEditor"
						 (click)="onDelete()">
		</app-icon-button>
	</div>
</div>

<div *ngIf="settings == null" class="pl-xl pr-l pt-m"  xmlns:svg="http://www.w3.org/2000/svg">
	<div class="widget-group">
		<div class="one">
			<div class="full-width-widget">
				<application-mini-widget [widget]="availabilityWidget" [type]="'availability'" [title]="'menu.applicationAvailability' | translate "></application-mini-widget>
			</div>
		</div>
		<div class="two">
			<div class="full-width-widget">
				<application-mini-widget [widget]="usersWidget" [type]="'users'" [title]="'menu.users' | translate"></application-mini-widget>
			</div>
		</div>
		<div class="three">
			<div class="full-width-widget">
				<application-mini-widget [widget]="tcoWidget" [type]="'tco'" [title]="'page.organization.tco'|translate"></application-mini-widget>
			</div>
		</div>
	</div>
	<div class="mt-l bg-rounded pl-m pr-m pt-m pb-m">
		<div class="flex-space-between mb-l">
			<div class="font-M fw-700">
				{{ 'page.title.applications' | translate }}
			</div>
			<app-mini-button [disable]="loading" size="s" color="accent"
                             (onClick)="openLinkAppToTeam()">
				<img src="assets/icons/add.svg" class="filter-accent">
			</app-mini-button>
		</div>
		<div *ngIf="loading" class=" pl-xl pr-xl pt-l pb-l">
			<list-content-loader></list-content-loader>
		</div>
		<app-team-applications *ngIf="!loading" [applicationInstances]="applications" [teamId]="data.businessUnit.organizationId"
                               (onDelete)="removeAppFromTeam($event)"></app-team-applications>
	</div>
</div>


<div *ngIf="data && settings != null" [ngStyle]="{ 'display': settings != null ? 'block' : 'none' }" class="mt-m">
	<div class="triangle"></div>
	<app-organization-setting
		[organization]="data.businessUnit"
		[isEditor]="isEditor"
		[settings]="settings">
	</app-organization-setting>
</div>
