import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

@Injectable()
export class TopbarService {

	constructor() {
	}

	protected title = new Subject<string>();

	protected title$ = this.title.asObservable();

	protected subtitle = new Subject<string>();

	protected subtitle$ = this.subtitle.asObservable();

	protected indicator = new Subject<string|undefined>();

	protected indicator$ = this.indicator.asObservable();

	getTitle(): Observable<string> {
		return this.title$;
	}

	getSubtitle(): Observable<string> {
		return this.subtitle$;
	}

	getIndicatorWithOtherNameThanIndicatorBecauseITriedToBeConsistentWithOldCodeButSonarDontLikeItSoIRenamedThisMethodToMakeHimHappy(): Observable<string|undefined> {
		return this.indicator$;
	}

	protected _icon: string | undefined;

	onTitleChange(title: string, subtitle: string, indicator?: string) {
		this.title.next(title);
		this.subtitle.next(subtitle);
		this.indicator.next(indicator);
	}

	eraseTitle() {
		this.title.next('');
	}
}
