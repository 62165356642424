import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from '../security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from '../../utils/http-client.utils';
import {CatalogTechnologySelect, CatalogTechnologyForm, CatalogTechnologyVersion, CatalogTechnologyVersionForm} from 'src/app/services/model/catalog-technology.model';

@Injectable()
export class CatalogTechnologyService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/catalog/technologies`;

	constructor(protected httpClient: HttpClient) {
	}

	getCatalogTechnology(tenantId: string, technologyId: string): Observable<CatalogTechnologySelect> {
		return this.httpClient.get<CatalogTechnologySelect>(`${this.serviceUrl(tenantId)}/${technologyId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllCatalogTechnologyByTenantId(tenantId: string): Observable<CatalogTechnologySelect[]> {
		return this.httpClient.get<CatalogTechnologySelect[]>(`${this.serviceUrl(tenantId)}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	createCatalogTechnologyForAll(tenantId: string, form: CatalogTechnologyForm): Observable<string> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}/for-all`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	createCatalogTechnologyForTenant(tenantId: string, form: CatalogTechnologyForm): Observable<string> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}/for-tenant`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateCatalogTechnology(tenantId: string, technologyId: string, form: CatalogTechnologyForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${technologyId}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	deleteCatalogTechnology(tenantId: string, technologyId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/${technologyId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getCatalogTechnologyVersion(tenantId: string, technologyId: string, versionId: string): Observable<CatalogTechnologyVersion> {
		return this.httpClient.get<CatalogTechnologyVersion>(`${this.serviceUrl(tenantId)}/${technologyId}/versions/${versionId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)))
	}

	getAllCatalogTechnologyVersionByTechnologyId(tenantId: string, technologyId: string): Observable<CatalogTechnologyVersion[]> {
		return this.httpClient.get<CatalogTechnologyVersion[]>(`${this.serviceUrl(tenantId)}/${technologyId}/versions`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	createCatalogTechnologyVersion(tenantId: string, technologyId: string, form: CatalogTechnologyVersionForm): Observable<string> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}/${technologyId}/versions`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateCatalogTechnologyVersion(tenantId: string, technologyId: string, versionId: string, form: CatalogTechnologyVersionForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${technologyId}/versions/${versionId}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	deleteCatalogTechnologyVersion(tenantId: string, technologyId: string, versionId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/${technologyId}/versions/${versionId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}
}
