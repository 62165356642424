import {Component, Inject} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DesignSystemModule} from "../design-system.module";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-delete-dialog',
  standalone: true,
	imports: [CommonModule, NgOptimizedImage, DesignSystemModule, TranslateModule],
  templateUrl: './delete-dialog.component.html',
  styleUrl: './delete-dialog.component.scss'
})
export class DeleteDialogComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DeleteDialogData,
		private dialogRef: MatDialogRef<DeleteDialogComponent>
	){}

	close() {
		this.dialogRef.close(false);
	}

	confirm() {
		this.dialogRef.close(true);
	}
}

export interface DeleteDialogData {
	icon: string;
	title: string;
	subtitle: string;
	warningMessage: string;
	deleteButton: string;
}
