import {FileLink} from 'src/app/services/model/tenant-file.model';
import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {Organization} from 'src/app/services/organization.service';
import {Category} from 'src/app/services/model/application-category.model';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

export enum DurationUnit {
  MONTH = 'month',
  YEAR = 'year',
}

export enum DurationOfCommitment {
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
  NO_COMMITMENT = 'no_commitment',
  CUSTOM = 'custom',
}

export enum ContractType {
  LICENSE = 'license',
  SERVICE = 'service',
  SUBSCRIPTION = 'subscription',
}

export enum ContractNature {
  APPLICATION = 'application',
  INFRASTRUCTURE = 'infrastructure'
}

export enum DurationOfUse {
  PERPETUAL = 'perpetual',
  ONE_YEAR = 'one_year',
  TWO_YEAR = 'two_year',
  THREE_YEAR = 'three_year',
  FOUR_YEAR = 'four_year',
  FIVE_YEAR = 'five_year',
}

export enum ServiceType {
  DEVELOPMENT = 'development',
  INTEGRATION = 'integration',
  MAINTENANCE = 'maintenance',
  SUPPORT = 'support',
  TRAINING = 'training',
}

export enum NoticePeriod {
  ONE_MONTH = 'one_month',
  TWO_MONTHS = 'two_months',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  NINE_MONTHS = 'nine_months',
  TWELVE_MONTHS = 'twelve_months',
}

export interface Contract {
  contractId: string;
  nature: ContractNature;
  startDate: string;
  endDate: string|null;
  paymentFrom: string;
  paymentTo: string|null;
  amountPerMonth: number;
  createdAt: string;
  updatedAt: string;
  organizations: Organization[];
  documents: FileLink[];
}

export interface ContractInfo {
  contract: Contract;
  type: ContractType;
  amount: number|null;
  paymentDurationValue: number;
  paymentDurationUnit: DurationUnit;
  isRecurringPayment: boolean;
  licensedUsers: number|null;
  noticePeriod: NoticePeriod|null;
  comment: string|null;
  subscriptionInfo: ContractInfoSubscription|null;
  serviceInfo: ContractInfoService|null;
  licenseInfo: ContractInfoLicense|null;
}

export interface ContractInfoSubscription {
  durationOfCommitment: DurationOfCommitment;
}

export interface ContractInfoService {
  serviceType: ServiceType;
}

export interface ContractInfoLicense {
  durationOfUse: DurationOfUse;
}

export interface Currency {
  code: string;
  name: string;
  symbol: string;
}

export interface ApplicationContract {
  application: ApplicationGeneric;
  contract: Contract[];
}

export interface ApplicationCostUsage {
  application: ApplicationGeneric;
  cost: Cost;
  usage: Usage;
}

export interface ApplicationContractDeadline {
  application: ApplicationGeneric;
  type: ContractType;
  expiryDate: string;
}

export interface ContractApplicationCategoryCost {
  category: Category;
  cost: Cost;
}

export interface ContractApplicationCost {
  application: ApplicationGeneric;
  cost: Cost;
  isUsedByOrganization: boolean;
}

export interface ContractMonthlyCost {
  month: string;
  cost: Cost;
}

export interface ContractOrganizationCost {
  organization: Organization;
  cost: Cost;
}

export interface ContractTypeCost {
  contractType: ContractType;
  cost: Cost;
}

export interface ApplicationContractForm {
  type: string;
  amount?: number;
  paymentDurationValue: number;
  paymentDurationUnit: DurationUnit;
  isRecurringPayment: boolean;
  startDate: Date;
  licensedUsers?: number;
  noticePeriod?: NoticePeriod;
  comment?: string;
  organizations: string[];
  documents: string[];
  licenseInfo?: ApplicationContractLicenseForm;
  serviceInfo?: ApplicationContractServiceForm;
  subscriptionInfo?: ApplicationContractSubscriptionForm;
}

export interface ApplicationContractLicenseForm {
  durationOfUse: DurationOfUse;
}

export interface ApplicationContractServiceForm {
  serviceType: ServiceType;
  expirationDate?: Date;
}

export interface ApplicationContractSubscriptionForm {
  durationOfCommitment: DurationOfCommitment;
  customEndDate?: Date;
}

export interface Usage {
  // TODO @TAN move to usage models
  value: number|null;
  queryType: QueryRangeType;
}

export interface Cost {
  value: number|null;
  queryType: QueryRangeType;
}
