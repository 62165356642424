import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {Currency} from 'src/app/services/model/application-contract.model';

@Injectable()
export class CurrencyService {

  private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/currency`;

  constructor(protected httpClient: HttpClient) {
  }

  getAllCurrency(tenantId: string): Observable<Currency[]> {
    return this.httpClient.get<Currency[]>(`${this.serviceUrl(tenantId)}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  updateTenantCurrency(tenantId: string, form: TenantCurrencyForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }
}

export interface TenantCurrencyForm {
  currencyCode: string;
}
