<div class="flex-align-center flex-space-between c-p greyed-hover radius-S pad-XS gap-S border-box-full overflow-hidden"
     [class.greyed-out]="disabled" [class.select-disabled]="disabled" style="min-width: 100px;"
     (click)="disabled ? $event.stopPropagation() : onSelect()">
    <div class="flex-align-center font-M fw-500 text-overflow-ellipsis" style="width: calc(100% - 30px); user-select: none;">
        @if (prefix()) {
            <div class="pad-0-XS">-</div>
        }
        <ng-content></ng-content>
    </div>
    <div class="flex-center" style="width: 20px; height: 20px;">
        @if (!disabled) {
            @if (multiple()) {
                <mat-checkbox [checked]="selected()" class="custom-checkbox"></mat-checkbox>
            } @else if (selected()) {
                <img src="assets/icons/blue-check.svg" height="16" alt=""/>
            }
        }
    </div>
</div>
