import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, Observable, of, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApplicationDocument, DocumentType, FileLinkApplicationForm} from 'src/app/services/model/application-document.model';
import {ApplicationDocumentService} from 'src/app/services/back/application-document.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-application-document-form',
  templateUrl: './application-document-form.component.html',
  styleUrls: ['./application-document-form.component.scss']
})
export class ApplicationDocumentFormComponent implements OnInit {

  @Input() data: ApplicationDocumentFormData;

  @Output() cancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<boolean> = new EventEmitter<boolean>();

  _saving: boolean = false;
  _uploading: boolean = false;

  formGroup: FormGroup;
  documentTypeChoices: DocumentTypeChoice[] = [];

  constructor(private applicationDocumentService: ApplicationDocumentService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
    this.createForm();
    this.buildSelectionLists();
    this.setDefaultData();
  }

  private createForm(): void {
    this.formGroup = new FormGroup({
      [Form.fileLinkId]: new FormControl(undefined, [Validators.required]),
      [Form.title]: new FormControl(undefined, [Validators.required]),
      [Form.documentType]: new FormControl(undefined, [Validators.required]),
      [Form.description]: new FormControl(undefined, [Validators.maxLength(200)]),
    });
  }

  private buildSelectionLists(): void {
    this.documentTypeChoices = [
      {id: DocumentType.DATA_POLICY , name: this.translate.instant('page.appDetails.files.data_policy')},
      {id: DocumentType.SECURITY_POLICY, name: this.translate.instant('page.appDetails.files.security_policy')},
      {id: DocumentType.TECHNICAL_DOCUMENTS, name: this.translate.instant('page.appDetails.files.technical_documents')}
    ];
  }

  private setDefaultData(): void {
    if (!!this.data.defaultData) {
      this.fileLinkIdFormControl.setValue(this.data.defaultData.fileLink.fileLinkId);
      this.documentTypeFormControl.setValue(this.documentTypeChoices.find(choice => choice.id === this.data.defaultData!.documentType)!, {emitEvent: false});
      this.titleFormControl.setValue(this.data.defaultData.title);
      this.descriptionFormControl.setValue(this.data.defaultData.description);
    } else {
      this.documentTypeFormControl.setValue(this.documentTypeChoices.find(choice => choice.id === DocumentType.TECHNICAL_DOCUMENTS)!, {emitEvent: false});
    }
  }

  save(): void {
    this.switchSaving()
      .pipe(
        map(() => this.buildApplicationDocumentForm()),
        switchMap(form => !this.data.defaultData
          ? this.applicationDocumentService.createApplicationDocument(this.data.tenantId, this.data.applicationId, form).pipe(map(() => true))
          : this.applicationDocumentService.updateApplicationDocument(this.data.tenantId, this.data.applicationId, this.data.defaultData.fileLink.fileLinkId, form)),
        finalize(() => this.switchSaving()))
      .subscribe(updated => this.saved.emit(updated));
  }

  private buildApplicationDocumentForm(): FileLinkApplicationForm {
    return {
      fileLinkId: this.fileLinkIdFormControl.value,
      documentType: (this.documentTypeFormControl.value as DocumentTypeChoice).id,
      title: this.titleFormControl.value,
      description: this.descriptionFormControl.value
    }
  }

  get fileLinkIdFormControl(): FormControl {
    return this.formGroup.get(Form.fileLinkId) as FormControl;
  }

  get titleFormControl(): FormControl {
    return this.formGroup.get(Form.title) as FormControl;
  }

  get documentTypeFormControl(): FormControl {
    return this.formGroup.get(Form.documentType) as FormControl;
  }

  get descriptionFormControl(): FormControl {
    return this.formGroup.get(Form.description) as FormControl;
  }

  private switchSaving(): Observable<{}> {
    this._saving = !this._saving;
    return of({});
  }
}

export interface ApplicationDocumentFormData {
  tenantId: string;
  applicationId: string;
  defaultData?: ApplicationDocument;
}

enum Form {
  fileLinkId = 'fileLinkId',
  title = 'title',
  documentType = 'documentType',
  description = 'description',
}

interface DocumentTypeChoice {
  id: DocumentType;
  name: string;
}
