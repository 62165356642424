<div class="mr-l ml-xl mt-xl mb-xl disp-f a-i-c j-c-s f-d-r">
	<app-chips [square]="true" [size]="'xl'" >
		<img [src]="miniWidget.iconUrl"  [classList]="miniWidget.iconSize + '-icon filter-grey'">
	</app-chips>
	<div class="ml-xl">
		<div class="disp-f a-i-s j-c-s f-d-r">
			<div *ngIf="miniWidget.numericIndicator === undefined" class="font-XXL fw-700 mr-s">
				-
			</div>
			<div *ngIf="(!miniWidget.type || (miniWidget.type != 'money' && miniWidget.type != 'user')) && miniWidget.numericIndicator != undefined" class="fw-700 mr-s" [ngClass]="{'font-XXL': !responsive, 'resp-numeric-indicator': responsive}">
				{{miniWidget.numericIndicator}}<span *ngIf="miniWidget.numericOutOf" class="out-of">/{{ miniWidget.numericOutOf }}</span>
			</div>
			<div *ngIf="(miniWidget.type && miniWidget.type == 'money') && miniWidget.numericIndicator != undefined" class="fw-700 mr-s" [ngClass]="{'font-XXL': !responsive, 'resp-numeric-indicator': responsive}">
				{{miniWidget.numericIndicator|cost}}<span *ngIf="miniWidget.numericOutOf" class="out-of">/{{ miniWidget.numericOutOf }}</span>
			</div>
			<div *ngIf="(miniWidget.type && miniWidget.type == 'user') && miniWidget.numericIndicator != undefined" class="fw-700 mr-s" [ngClass]="{'font-XXL': !responsive, 'resp-numeric-indicator': responsive}">
				{{ getUser(miniWidget.numericIndicator) }}<span *ngIf="miniWidget.numericOutOf" class="out-of">/{{ miniWidget.numericOutOf }}</span>
			</div>
			<app-tendency-chips [numericTendency]="miniWidget.numericTendency"></app-tendency-chips>
		</div>
		<div class="fw-300 mt-xxs" [ngClass]="{'font-L': !responsive, 'resp-numeric-subject': responsive}">
			{{miniWidget.numericSubject | translate}}
		</div>
	</div>
</div>
