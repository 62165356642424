import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'cost',
  standalone: true
})
export class CostPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  transform(value: number|undefined|null, type: 'default'|'shorten'|'full' = 'default', period: 'day'|'month'|'year'|'monthly'|'annually'|null = null): string {
    if (value === undefined || value === null) {
      return '-';
    }
    const result: string = this.formatValue(value, type);
    return !period ? result : `${result} / ${this.translateService.instant('global.timeUnit.' + period)}`;
  }

  private formatValue(value: number, type: 'default'|'shorten'|'full'): string {
    switch (type) {
      case 'default':
        return this.spacing(Math.round(value).toString())
          + this.nonBreakingSpace
          + this.currency;
      case 'shorten':
        // TODO @TAN /!\ no hardcoded '$' /!\
        return this.currency === '$'
          ? this.currency + this.shorten(value)
          : this.shorten(value) + this.currency;
      case 'full':
        const parts: string[] = value.toString().split('.');
        return this.spacing(parts[0])
          + (parts[1] ? '.' + parts[1] : '')
          + this.nonBreakingSpace
          + this.currency;
      default:
        return value.toString()
    }
  }

  private get currency(): string {
    return localStorage.getItem('currency')!;
  }

  private get nonBreakingSpace(): string {
    return '\xa0';
  }

  private shorten(value: number): string {
    let result: string = '';
    if (value >= 1_000_000_000) {
      result = (value / 1_000_000_000).toFixed(1) + 'B';
    } else if (value >= 1_000_000) {
      result = (value / 1_000_000).toFixed(1) + 'M';
    } else if (value >= 1_000) {
      result = (value / 1_000).toFixed(1) + 'K';
    } else {
      result = value.toString();
    }
    return result.replace('.0', '');
  }

  private spacing(value: string): string {
    return value.replace(/(?!^)(?=(?:\d{3})+$)/g, this.nonBreakingSpace);
  }
}
