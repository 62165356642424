import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "src/environments/environment";
import {Observable} from "rxjs";
import {SecurityUtils} from "../security.utils";
import {ApplicationGeneric, Health} from 'src/app/services/model/new-application.model';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';

@Injectable()
export class HealthService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/health`;

	constructor(protected httpClient: HttpClient) {
	}

	getAllDailyHealthByTenantId(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<DailyHealth[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (range) params = params.append('range', range);
		return this.httpClient.get<DailyHealth[]>(`${this.serviceUrl(tenantId)}/dailies`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getHealthHistoryByApplicationId(tenantId: string, applicationId: string, range?: QueryRangeType): Observable<HealthHistory|null> {
		let params: HttpParams = new HttpParams();
		if (range) params = params.append('range', range);
		return this.httpClient.get<HealthHistory|null>(`${this.serviceUrl(tenantId)}/applications/${applicationId}/history`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllErrorsByApplicationByTenantId(tenantId: string, organizationId?: string, limit?: number, range?: QueryRangeType): Observable<ErrorsByApplication[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (limit) params = params.append('limit', limit);
		if (range) params = params.append('range', range);
		return this.httpClient.get<ErrorsByApplication[]>(`${this.serviceUrl(tenantId)}/most-impacted-apps`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}
}

export interface HealthHistory {
	healthHistory: DailyHealth[];
	health: Health;
}

export interface DailyHealth {
	date: string;
	health: Health;
	errorCount: number;
}

export interface ErrorsByApplication {
	application: ApplicationGeneric;
	userCount: number;
	errorCount: number;
	userImpactedPercentage: number;
}
