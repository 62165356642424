import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PerformanceRepartition, PerformanceService} from 'src/app/services/back/performance.service';
import {BaseChartDirective, NgChartsModule} from 'ng2-charts';
import {ChartData, ChartOptions} from 'chart.js';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ColorEnum} from 'src/_variables';
import {PerformanceRating} from 'src/app/services/application-instance.service';
import {ApplicationIconModule} from '../../../global/application-icon/application-icon.module';
import {TagsModule} from '../../../global/tags/tags.module';
import {BehaviorSubject, delayWhen, finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {filter, first} from "rxjs/operators";
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {DeckContentLoaderModule} from 'src/app/modules/global/title-content-loader/deck-content-loader.module';

@Component({
  selector: 'app-performance-repartition-doughnut',
  standalone: true,
	imports: [CommonModule, ApplicationIconModule, TagsModule, TranslateModule, NgChartsModule, DeckContentLoaderModule],
  templateUrl: './performance-repartition-doughnut.component.html',
  styleUrl: './performance-repartition-doughnut.component.scss'
})
export class PerformanceRepartitionDoughnutComponent implements OnInit, AfterViewInit, OnDestroy {

	@Input() filter: Subject<Organization|null|undefined>;

	@ViewChild(BaseChartDirective) chart: BaseChartDirective;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	repartition: PerformanceRepartition;

	doughnutChartData: ChartData<'doughnut'>;
	chartOptions: ChartOptions<'doughnut'>;
	keys: string[] = [];
	count: Record<string, number>;
	width: Record<string, number>;
	label: Record<string, string>;
	overallRating: Record<string, string>;
	viewInit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private performanceService: PerformanceService,
							private translate: TranslateService) {
	}

	ngOnInit() {
		this.createChartData();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	ngAfterViewInit() {
		this.viewInit.next(true);
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.performanceService.getTenantPerformanceRepartition(this.tenantId, org?.organizationId, QueryRangeType.PAST_1_MONTH)),
				delayWhen(() => this.viewInit.pipe(filter(init => init), first())),
				tap(data => this.setPerformanceRepartition(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private setPerformanceRepartition(repartition: PerformanceRepartition): void {
		this.repartition = repartition;
		this.setComponentData();
		this.setChartData();
	}

	private createChartData(): void {
		this.doughnutChartData = {
			labels: [],
			datasets: [
				{
					data: [ 0 ],
					backgroundColor: [ColorEnum.medium_grey],
					borderColor: [ColorEnum.medium_grey],
					borderRadius: 5,
					spacing: 2
				}
			],
		};
		this.chartOptions = {
			responsive: true,
			maintainAspectRatio: true,
			events: [],
			cutout: '65%',
			elements: {
				arc: {
					borderWidth: 1,
				}
			}
		};
	}

	private setComponentData(): void {
		this.label = {};
		this.count = {};
		this.width = {};
		this.keys = Object.values(PerformanceRating)
		this.keys.forEach(k => this.label[k] = this.getLabel(k));
		this.keys.forEach(k => this.count[k] = this.countPerformanceTypePercent(k as PerformanceRating));
		// overall rating is the rating with the most apps
		this.overallRating = this.getOverallRating();
	}

	private getOverallRating(): Record<string, string> {
		const max = Math.max(this.repartition.countGoodRatingApps, this.repartition.countMediumRatingApps, this.repartition.countBadRatingApps);
		if (max === this.repartition.countGoodRatingApps) {
			// return a record with the overall rating and the label
			return {rating: this.translate.instant('page.dashboard.typology.good'), label: PerformanceRating.GOOD};
		} else if (max === this.repartition.countMediumRatingApps) {
			return {rating: this.translate.instant('page.dashboard.typology.needs_improvement'), label: PerformanceRating.NEEDS_IMPROVEMENT};
		} else {
			return {rating: this.translate.instant('page.dashboard.typology.poor'), label: PerformanceRating.POOR};
		}
	}

	private getLabel(key: string|PerformanceRating) {
		switch (key) {
			case PerformanceRating.GOOD: return this.translate.instant('page.dashboard.typology.good');
			case PerformanceRating.NEEDS_IMPROVEMENT: return this.translate.instant('page.dashboard.typology.needs_improvement');
			case PerformanceRating.POOR: return this.translate.instant('page.dashboard.typology.poor');
		}
	}

	private countPerformanceTypePercent(type: PerformanceRating): number {
		const total = this.repartition.countBadRatingApps + this.repartition.countGoodRatingApps + this.repartition.countMediumRatingApps;
		if (type === PerformanceRating.GOOD) {
			return !total
				? 0
				: Number(((this.repartition.countGoodRatingApps / total) * 100).toFixed(2));
		} else if (type === PerformanceRating.NEEDS_IMPROVEMENT) {
			return !total
				? 0
				: Number(((this.repartition.countMediumRatingApps / total) * 100).toFixed(2));
		} else {
			return !total
				? 0
				: Number(((this.repartition.countBadRatingApps / total) * 100).toFixed(2));
		}
	}

	private setChartData(): void {
		if (this.repartition.countMediumRatingApps + this.repartition.countGoodRatingApps + this.repartition.countBadRatingApps > 0) {
			this.doughnutChartData.datasets[0].data = [];
			this.doughnutChartData.datasets[0].backgroundColor = [];
			this.doughnutChartData.datasets[0].borderColor = [];
			this.keys.forEach((key, i) => {

				this.doughnutChartData.datasets[0].data.push(this.count[key]);
				//@ts-ignore
				(this.doughnutChartData.datasets[0]?.backgroundColor as string[]).push(ColorEnum[`perf_doughnut_colors_${i}`]);
				//@ts-ignore
				(this.doughnutChartData.datasets[0]?.borderColor as string[]).push(ColorEnum[`perf_doughnut_border_colors_${i}`]);

				if (i < this.keys.length) {
					this.addDataToChart(0.3, '#FFFFFF');
				}
			});
		} else {
			this.doughnutChartData.datasets[0].data = [1];
		}
	}

	protected addDoughnutColor(color:string) {
		// @ts-ignore
		this.doughnutChartData.datasets[0]?.backgroundColor?.push(color);
		// @ts-ignore
		this.doughnutChartData.datasets[0]?.borderColor?.push(color);
	}

	private addDataToChart(data: number, color: `#${string}`): void {
		this.doughnutChartData.datasets[0].data.push(data);
		(this.doughnutChartData.datasets[0]?.backgroundColor as string[]).push(color);
		(this.doughnutChartData.datasets[0]?.borderColor as string[]).push(color);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}
