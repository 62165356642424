import {
	AfterViewInit,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef, EventEmitter,
	Input,
	OnDestroy,
	Output,
	QueryList,
	ViewChild
} from '@angular/core';
import {SelectOptionComponent} from 'src/app/modules/design-system/multi-select/select-option/select-option.component';
import {FormControl} from '@angular/forms';
import {merge, startWith, Subscription} from 'rxjs';
import {MatMenuTrigger} from '@angular/material/menu';
import {SelectSearchComponent} from 'src/app/modules/design-system/multi-select/select-search/select-search.component';
import {SelectInsertComponent} from 'src/app/modules/design-system/multi-select/select-insert/select-insert.component';
import {SelectOptionGroupComponent} from 'src/app/modules/design-system/multi-select/select-option-group/select-option-group.component';
import {FocusInputService} from "../../../services/front/focus-input.service";

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements AfterViewInit, OnDestroy {

	constructor(private focusInputService: FocusInputService) {
	}

  @Input() inputControl: FormControl;
  @Input() disabled: boolean = false; // TODO @TAN inputControl.disabled instead ?
  @Input() loading: boolean = false;
  @Input() customTrigger: boolean = false;

  private _multiple: boolean
  @Input()
  get multiple() {
    return this._multiple;
  }
  set multiple(value: any) {
    this._multiple = value != null && `${value}` !== 'false';
  }

  @Output() confirm = new EventEmitter<void>();

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild('triggerContainer') triggerContainer: ElementRef;
  @ViewChild('optionContainer') optionContainer: ElementRef;
  @ContentChild(SelectSearchComponent) selectSearch: SelectSearchComponent;
  @ContentChild(SelectInsertComponent) selectInsert: SelectInsertComponent;
  @ContentChildren(SelectOptionComponent) selectOptions: QueryList<SelectOptionComponent>;
  @ContentChildren(SelectOptionGroupComponent) selectOptionGroups: QueryList<SelectOptionGroupComponent>;

  isEmpty: boolean = false;
  hasScroll: boolean = false;
  minPanelWidth: number = 55;
  optionSub: Subscription = new Subscription();
  subscription: Subscription = new Subscription();

  ngAfterViewInit() {
    this.subscription.add(merge(this.selectOptions.changes, this.selectOptionGroups.changes)
      .pipe(startWith({}))
      .subscribe(() => {
        this.subscribeToSelectChanges();
        this.setSelectedOptions();
        this.setChildrenProperties();
        this.setHasScroll();
        this.isEmpty = this.selectOptions.length === 0 && this.selectOptionGroups.length === 0;
      }));
    this.subscription.add(this.inputControl.valueChanges
      .subscribe(() => this.setSelectedOptions()));
  }

  private subscribeToSelectChanges(): void {
    this.optionSub.unsubscribe();
    this.optionSub = new Subscription();
    this.getSelectOptionList().forEach((option: SelectOptionComponent) => {
      this.optionSub.add(option.selectChanges.subscribe(() => this.onSelectionChanges(option)));
    });
  }

  private setSelectedOptions(): void {
    this.getSelectOptionList().forEach((option: SelectOptionComponent) => {
      const selected: boolean = !this.multiple
        ? this.isEquals(this.inputControl.value, option.value)
        : !!this.inputControl.value.find((v: any) => this.isEquals(v, option.value));
      option.selected.set(selected);
    });
  }

  private setChildrenProperties(): void {
    this.getSelectOptionList().forEach((option: SelectOptionComponent) => {
      option.multiple.set(this.multiple);
    });
    this.selectOptionGroups.forEach((option: SelectOptionGroupComponent) => {
      option.multiple.set(this.multiple);
    });
  }

  private onSelectionChanges(option: SelectOptionComponent): void {
    if (!this.multiple) {
      this.getSelectOptionList().forEach(opt => opt.selected.set(false));
      option.selected.set(true);
      this.inputControl.setValue(option.value);
      this.menuTrigger.closeMenu();
    } else {
      if (option.selected()) {
        this.inputControl.setValue([...this.inputControl.value, option.value]);
      } else {
        this.inputControl.setValue(this.inputControl.value.filter((v: any) => !this.isEquals(v, option.value)));
      }
    }
    this.inputControl.markAsDirty();
  }

  private getSelectOptionList(): SelectOptionComponent[] {
    return this.selectOptionGroups.toArray()
      .map(group => group.selectOptions.toArray())
      .flat()
      .concat(this.selectOptions.toArray());
  }

  isEquals(v1: any, v2: any): boolean {
    if (v1 === v2) return true;
    if (!v1 && !v2) return true;
    if (!v1 || !v2) return false;
    if (typeof v1 === 'string' || typeof v2 === 'string') return false;
    const v1Ordered: { [key: string]: any }[] = Object.keys(v1)
      .sort((a, b) => a.localeCompare(b))
      .map(key => ({ [key]: v1[key] }));
    const v2Ordered: { [key: string]: any }[] = Object.keys(v2)
      .sort((a, b) => a.localeCompare(b))
      .map(key => ({ [key]: v2[key] }));
    return v1Ordered.length > 0 && v1Ordered.length === v2Ordered.length
      && JSON.stringify(v1Ordered) === JSON.stringify(v2Ordered);
  }

  onOpen(): void {
	  this.focusInputService.sendFocusMessage();
    this.minPanelWidth = this.triggerContainer.nativeElement.clientWidth;
    this.setHasScroll();
  }

  private setHasScroll(): void {
    setTimeout(() => this.hasScroll = this.optionContainer && this.optionContainer.nativeElement.scrollHeight > this.optionContainer.nativeElement.clientHeight);
  }

  onClose(): void {
    if (!!this.selectSearch) {
      this.selectSearch.searchControl.reset('');
    }
    if (!!this.selectInsert) {
      this.selectInsert.createForm.reset('');
      this.selectInsert.openForm = false;
    }
  }

  onConfirm(): void {
	  this.confirm.emit();
	  this.menuTrigger.closeMenu();
  }

  ngOnDestroy() {
    this.optionSub.unsubscribe();
    this.subscription.unsubscribe();
  }
}
