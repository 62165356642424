import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ApplicationMiniWidgetComponent} from "./application-mini-widget.component";
import {DeckContentLoaderModule} from "../title-content-loader/deck-content-loader.module";
import {TendencyChipsModule} from "../chips/tendency-chips/tendency-chips.module";
import {TranslateModule} from "@ngx-translate/core";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {CostPipe} from 'src/app/pipes/number/cost.pipe';

@NgModule({
	declarations: [
		ApplicationMiniWidgetComponent,
	],
	exports: [
		ApplicationMiniWidgetComponent
	],
  imports: [
    CommonModule,
    DeckContentLoaderModule,
    TendencyChipsModule,
    TranslateModule,
    DesignSystemModule,
    CostPipe
  ]
})
export class ApplicationMiniWidgetModule { }
