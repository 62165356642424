import {MiniDoughnutChartComponent} from "./mini-doughnut-chart.component";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
	],
	declarations: [
		MiniDoughnutChartComponent
	],
	exports: [
		MiniDoughnutChartComponent
	]
})
export class MiniDoughnutChartModule {
}
