<div class="flex-space-around flex-align-center bkg-light-grey b-radius-9 mb-xl" style="height: 35px;">
	<div class="flex-center w-30 c-p" [ngClass]="{'active-tab': tabIndex === 0}"
		 (click)="tabIndex = 0"><span class="font-M fw-500 grey">{{'global.general'|translate}}</span></div>
	<div class="flex-center w-30 c-p" [ngClass]="{'active-tab': tabIndex === 1}"
		 (click)="tabIndex = 1"><span class="font-M fw-500 grey">{{'global.advanced'|translate}}</span></div>
	<div class="flex-center w-30 c-p" [ngClass]="{'active-tab': tabIndex === 2}"
		 (click)="tabIndex = 2"><span class="font-M fw-500 grey">{{'global.documents'|translate}}</span></div>
</div>
<form [formGroup]="formGroup">
	<!-- GENERAL -->
	<div [style.display]="tabIndex !== 0 ? 'none' : 'flex'" class="flex-column gap-M" style="height: 366px;">
		<div class="flex-column gap-XS">
			<div class="font-M fw-500 grey">{{'page.appDetails.architecture.dialog.source'|translate}}</div>
			<app-multi-select class="w-100" [inputControl]="sourceFormControl"
							  [disabled]="_initializing || _saving || isSource">
				<app-select-trigger appearance="field">
					<app-display-application [application]="sourceFormValue"></app-display-application>
				</app-select-trigger>
				<app-select-search [searchControl]="searchSourceControl"></app-select-search>
				<app-select-option *ngFor="let source of sourceFilteredList" [value]="source">
					<app-display-application [application]="source"></app-display-application>
				</app-select-option>
			</app-multi-select>
		</div>
		<div class="flex-center" style="margin-bottom: -15px;">
			<app-icon-button color="accent" shape="round" size="s" [panelClass]="'border-grey'" class="btn-animation" [ngClass]="{'icon-down': iconReversed === true, 'icon-up': iconReversed === false}"
							 [img]="'assets/icons/flow.svg'"
							 (click)="toggleDirection()">
			</app-icon-button>
		</div>
		<div class="flex-column gap-XS">
			<div class="font-M fw-500 grey">{{'page.appDetails.architecture.dialog.target'|translate}}</div>
			<app-multi-select class="w-100" [inputControl]="targetFormControl"
							  [disabled]="_initializing || _saving || !isSource">
				<app-select-trigger appearance="field">
					<app-display-application [application]="targetFormValue"></app-display-application>
				</app-select-trigger>
				<app-select-search [searchControl]="searchTargetControl"></app-select-search>
				<app-select-option *ngFor="let target of targetFilteredList" [value]="target">
					<app-display-application [application]="target"></app-display-application>
				</app-select-option>
			</app-multi-select>
		</div>
		<div class="flex-column gap-XS">
			<div class="font-M fw-500 grey">{{'page.appDetails.architecture.dialog.data'|translate}}</div>
			<app-multi-select class="w-100" [inputControl]="mappingDataFormControl" multiple
							  [disabled]="_initializing || _saving">
				<app-select-trigger appearance="field">{{dataSelectorName}}</app-select-trigger>
				<app-select-search [searchControl]="searchDataControl"></app-select-search>
				<app-select-insert [saving]="_savingData" (create)="createData($event)"></app-select-insert>
				<app-select-option *ngFor="let data of mappingDataFilteredList" [value]="data">{{data.name}}</app-select-option>
			</app-multi-select>
		</div>
		<div class="flex-column gap-XS">
			<div class="flex-space-between w-100">
				<div class="font-M fw-500 grey">{{'page.appDetails.architecture.dialog.comment'|translate}}</div>
				<div class="font-S fw-500 grey">{{formGroup.get(formName.comment)!.value?.length || 0}} / 200</div>
			</div>
			<textarea maxlength="200" class="textarea" [formControlName]="formName.comment"></textarea>
		</div>
	</div>
	<!-- ADVANCED -->
	<div [style.display]="tabIndex !== 1 ? 'none' : 'flex'" class="flex-column gap-M" style="height: 366px;">
		<div class="flex-column gap-XS">
			<div class="font-M fw-500 grey">{{ 'page.appDetails.architecture.dialog.protocol' | translate }}</div>
			<app-multi-select [inputControl]="protocolFormControl"
							  [disabled]="_initializing || _saving">
				<app-select-trigger appearance="field">{{ protocolFormControl.value?.name || '-' }}</app-select-trigger>
				<app-select-search [searchControl]="searchProtocolControl"></app-select-search>
				<app-select-option [value]="undefined">-</app-select-option>
				<app-select-option *ngFor="let protocol of protocolFilteredList" [value]="protocol">{{protocol.name}}</app-select-option>
			</app-multi-select>
		</div>
		<div class="flex-column gap-XS">
			<div class="font-M fw-500 grey">{{ 'page.appDetails.architecture.dialog.format' | translate }}</div>
			<app-multi-select [inputControl]="formatFormControl"
							  [disabled]="_initializing || _saving">
				<app-select-trigger appearance="field">{{ formatFormControl.value?.name || '-' }}</app-select-trigger>
				<app-select-search [searchControl]="searchFormatControl"></app-select-search>
				<app-select-option [value]="undefined">-</app-select-option>
				<app-select-option *ngFor="let format of formatFilteredList" [value]="format">{{format.name}}</app-select-option>
			</app-multi-select>
		</div>
		<div class="flex-column gap-XS">
			<div class="font-M fw-500 grey">{{ 'page.appDetails.architecture.dialog.exchangeFrequency' | translate }}</div>
			<app-multi-select [inputControl]="exchangeFrequencyFormControl"
							  [disabled]="_initializing || _saving">
				<app-select-trigger appearance="field">{{ exchangeFrequencyFormControl.value?.name || '-' }}</app-select-trigger>
				<app-select-option [value]="undefined">-</app-select-option>
				<app-select-option *ngFor="let exchangeFrequency of exchangeFrequencyTypeChoices" [value]="exchangeFrequency">{{exchangeFrequency.name}}</app-select-option>
			</app-multi-select>
		</div>
		<div class="flex gap-L">
			<div class="flex-column gap-XS w-20">
				<div class="font-M fw-500 grey">{{ 'page.appDetails.architecture.dialog.portType' | translate }}</div>
				<app-multi-select [inputControl]="portTypeFormControl"
								  [disabled]="_initializing || _saving">
					<app-select-trigger appearance="field">{{ portTypeFormControl.value?.name || '-' }}</app-select-trigger>
					<app-select-option [value]="undefined">-</app-select-option>
					<app-select-option *ngFor="let portType of portTypeChoices" [value]="portType">{{portType.name}}</app-select-option>
				</app-multi-select>
			</div>
			<div class="flex-column gap-XS w-80">
				<div class="font-M fw-500 grey">{{ 'page.appDetails.architecture.dialog.port' | translate }}</div>
				<app-text-input [placeholder]="'Ex: &quot;8080-9000&quot;, &quot;8080,8081,8082&quot;, &quot;8080&quot;'" [input]="portFormControl"></app-text-input>
			</div>
		</div>

		<div class="flex-column gap-XS">
			<div class="font-M fw-500 grey">{{ 'page.appDetails.architecture.dialog.flowEncryption' | translate }}</div>
			<app-multi-select [inputControl]="flowEncryptionFormControl"
							  [disabled]="_initializing || _saving">
				<app-select-trigger appearance="field">{{ flowEncryptionFormControl.value?.name || '-' }}</app-select-trigger>
				<app-select-option [value]="undefined">-</app-select-option>
				<app-select-option *ngFor="let flowEncryption of flowEncryptionTypeChoices" [value]="flowEncryption">{{flowEncryption.name}}</app-select-option>
			</app-multi-select>
		</div>
	</div>
	<!-- DOCUMENTS -->
	<div [style.display]="tabIndex !== 2 ? 'none' : 'block'" style="height: 366px;">
		<div style="height: 410px;">
			<app-upload-file [defaultFiles]="!data.defaultData ? [] : data.defaultData.documents"
							 [sizeLimit]="50"
							 (filesChange)="$any(formGroup.get(formName.fileLinkIds)).setValue($event)"
							 (uploading)="_uploading = $event"></app-upload-file>
		</div>
	</div>
</form>
<div class="flex gap-M pad-top-L">
    <app-text-button type="stroked" class="w-50" [disabled]="_saving"
                     (click)="cancel.emit()">
        {{'button.cancel'|translate}}
    </app-text-button>
    <app-text-button type="flat" class="w-50" [disabled]="_initializing || formGroup.invalid" [loading]="_saving || _uploading"
                     (click)="save()">
        {{(!data.defaultData ? 'button.add' : 'button.edit')|translate}}
    </app-text-button>
</div>
