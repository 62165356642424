<div class="flex gap-M pad-XL-XL-0 w-100 border-box" style="margin-bottom: -2px;">
    <app-diagram-breadcrumb class="w-100"></app-diagram-breadcrumb>

    <div class="filter-panel-container bkg-white b-radius-9">
        <app-icon-button color="accent" type="stroked" [img]="'assets/icons/settings.svg'" class="filter-panel-btn" panelClass="h-100-imp w-100-imp bkg-transparent"
                         [disabled]="_initializing || _loading" [loading]="_loadingFilter" [focused]="miniDrawer.opened()"
                         [miniDrawerTriggerFor]="miniDrawer" [matBadge]="activeFilters > 0 ? activeFilters : undefined" matBadgeColor="accent">
        </app-icon-button>
        <app-mini-drawer #miniDrawer>
            <div class="flex-column gap-M" *ngIf="!_loadingFilter">
                <div class="flex-column gap-S">
                    <div class="flex-align-center flex-space-between">
                        <span class="font-M fw-700">{{'page.diagram.filters.organization'|translate}}</span>
                        <span *ngIf="activeFilters > 0" class="font-M fw-500 danger underlined-hover c-p"
                              (click)="resetFilters()">{{'button.deleteFilters'|translate}}</span>
                    </div>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.organization))" multiple>
                        <app-select-trigger appearance="field">{{!organizationFormValue.length ? ('page.diagram.filters.allOrganization'|translate) : (organizationFormValue[0].name  + (organizationFormValue.length > 1 ? ' (+' + (organizationFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-search [searchControl]="searchOrganizationControl"></app-select-search>
                        <app-select-option-group *ngFor="let group of filterData.organization" [label]="group.organization.name" noOptionPrefix>
                            <app-select-option *ngFor="let org of group.children" [value]="org.organization">
                                <div class="flex-align-center gap-M">
                                    <img [src]="!org.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + org.organization.icon + '.svg')" class="filter-accent" height="16px">
                                    <span>{{org.organization.name}}</span>
                                </div>
                            </app-select-option>
                        </app-select-option-group>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-column gap-S">
                    <span class="font-M fw-700">{{'page.diagram.filters.criticality'|translate}}</span>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.criticality))" multiple>
                        <app-select-trigger appearance="field">{{!criticalityFormValue.length ? ('page.diagram.filters.allCriticality'|translate) : ((('page.appDetails.businessCriticality.' + criticalityFormValue[0].toLowerCase())|translate) + (criticalityFormValue.length > 1 ? ' (+' + (criticalityFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-option *ngFor="let level of filterData.criticality" [value]="level">
                            <div class="flex-align-center gap-M">
                                <img src="assets/icons/criticality-{{level.toLowerCase()}}.svg" height="16" alt=""/>
                                <span>{{('page.appDetails.businessCriticality.' + level.toLowerCase())|translate}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-column gap-S">
                    <span class="font-M fw-700">{{'page.diagram.filters.category'|translate}}</span>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.category))" multiple>
                        <app-select-trigger appearance="field">{{!categoryFormValue.length ? ('page.diagram.filters.allCategory'|translate) : (categoryFormValue[0].name  + (categoryFormValue.length > 1 ? ' (+' + (categoryFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-search [searchControl]="searchCategoryControl"></app-select-search>
                        <app-select-option *ngFor="let category of filterData.category" [value]="category">
                            {{category.name}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-align-center flex-space-between gap-S">
                    <div class="font-M fw-700">{{ "page.diagram.filters.showNoDataApplications" | translate}}</div>
                    <app-toggle-button [toggleInput]="$any(filterForm.get(formName.withNoData))"></app-toggle-button>
                </div>
            </div>
        </app-mini-drawer>
    </div>

    <div style="min-width: 100px;">
        <app-text-button type="flat" panelClass="h-100-imp" [tooltip]="'Coming soon...'"
                         [disabled]="_initializing || _loading || _loadingFilter || !lifeCycles.length || true" [loading]="_exporting"
                         (click)="export()">
            <img src="assets/icons/share.svg" alt="share">
        </app-text-button>
    </div>
</div>

<div class="h-100 pad-left-XL pad-top-M border-box w-100" style="height: calc(100% - 175px);">
    <div class="p-absolute overflow-x-y c-g" style="z-index: 3; margin-top: 40px; width: calc(100% - 25px); height: calc(100% - 215px);" #applicationPanel
         appPinchZoom [scaleFactor]="0.2" [zoomThreshold]="9" [initialZoom]="INITIAL_ZOOM"
         (onZoom)="onZoom($event)">
        <div class="flex-column gap-XXL" [style.width.px]="monthScales.length|applyScale:unit:unit+8">
            <div></div>
            @for (app of lifeCycles; track app.application.id) {
                <div matRipple class="flex-align-center bkg-white b-radius-14 border-grey-XS pad-S w-100 c-p greyed-hover border-box" style="z-index: 4; width: 300px; min-height: 60px; position: sticky; left: 0; box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.05);"
                     (click)="openApplicationDrawer(app.application.id)">
                    <app-display-application class="w-100" size="L" [application]="app.application" [description]="app.application.description" criticality bold></app-display-application>
                </div>
            }
        </div>
    </div>

    <div class="p-absolute overflow-hidden" style="z-index: 2; margin-top: 40px; width: calc(100% - 25px); height: calc(100% - 225px);" #lifeCyclePanel>
        <div class="flex-column gap-XXL" [style.width.px]="monthScales.length|applyScale:unit:unit">
            <div></div>
            @for (app of lifeCycles; track app.application.id) {
                <div class="flex-align-center phase-row" style="z-index: 2; min-height: 60px;">
                    <div class="flex-align-center phase-item delta-left"
                         [style.width.px]="app.deltaLeftWidth|applyScale:unit:2"></div>
                    <div class="flex-align-center flex-justify-end phase-item phase-in" *ngIf="app.phaseInWidth > 0"
                         [style.width.px]="app.phaseInWidth|applyScale:unit:2">
                        <div class="p-sticky text-overflow-ellipsis accent-secondary" style="right: 10px; margin: 0 10px;"><span class="font-M fw-500">Phase in</span></div>
                    </div>
                    <div class="flex-align-center flex-justify-end phase-item phase-deployed" *ngIf="app.deployedWidth > 0"
                         [style.width.px]="app.deployedWidth|applyScale:unit:2">
                        <div class="p-sticky text-overflow-ellipsis success" style="right: 10px; margin: 0 10px;"><span class="font-M fw-500">Deployed</span></div>
                    </div>
                    <div class="flex-align-center flex-justify-end phase-item phase-out" *ngIf="app.phaseOutWidth > 0"
                         [style.width.px]="app.phaseOutWidth|applyScale:unit:2">
                        <div class="p-sticky text-overflow-ellipsis warning" style="right: 10px; margin: 0 10px;"><span class="font-M fw-500">Phase out</span></div>
                    </div>
                    <div class="flex-center phase-item phase-retired" *ngIf="app.retiredWidth > 0"
                         [style.width.px]="app.retiredWidth">
                        <img class="filter-danger" ngSrc="assets/icons/power.svg" height="12" width="12" alt=""/>
                    </div>
                </div>
            }
            <div class="p-absolute bkg-accent-secondary" style="z-index: 2; width: 2px;" *ngIf="nowIndex"
                 [style.left.px]="(nowIndex * unit) + (unit / 2)"
                 [style.height.px]="lifeCyclePanel.scrollHeight"></div>
        </div>
    </div>

    <div class="p-absolute overflow-hidden" style="z-index: 1; width: calc(100% - 25px); height: calc(100% - 195px); padding-top: 15px;" #backgroundPanel>
        <div class="flex w-100 h-100" [style.width.px]="monthScales.length|applyScale:unit:unit">
            @for (month of monthScales; track month; let idx = $index) {
                <div class="h-100 border-grey-left" [id]="month.getMonth() === now.getMonth() && month.getFullYear() === now.getFullYear() ? 'today' : 'month-' + idx"
                     [style.border-color]="unit >= 40 || (unit >= 20 && month.getMonth() % 3 === 0) || (unit < 20 && month.getMonth() % 6 === 0) ? '#DCDCE9 !important' : '#F7F8FA !important'"
                     [style.min-width.px]="unit"
                     [style.border-width]="month.getMonth() === 0 ? '1px !important' : '0.5px !important'"
                     [style.margin]="month.getMonth() === 0 ? '0 -0.5px' : '0 -0.25px'">
                    <div *ngIf="month.getMonth() === 0" class="p-absolute b-radius-half border-grey" style="z-index: 3; width: 0; height: 0; top: 5px; margin-left: -5.5px; border-width: 5px !important;"></div>
                    @if (unit >= 40) {
                        <div class="flex-justify-center flex-align-end pad-bottom-XS bkg-light-grey-2 p-absolute" style="z-index: 2; height: 20px;" [style.width.px]="unit - 2">
                            <span class="font-MM fw-500" [class]="month.getTime() < now.getTime() ? 'grey-border' : 'grey'">{{month|date:'MMMMM yy'}}</span>
                        </div>
                    } @else if (unit >= 20 && month.getMonth() % 3 === 0) {
                        <div class="flex-justify-center flex-align-end pad-bottom-XS bkg-light-grey-2 p-absolute" style="z-index: 2; height: 20px;" [style.width.px]="3*unit - 2">
                            <span class="font-MM fw-500" [class]="month.getTime() < now.getTime() ? 'grey-border' : 'grey'">{{month|customDate:'quarter'}}</span>
                        </div>
                    } @else if (unit < 20 && month.getMonth() % 12 === 0) {
                        <div class="flex-justify-center flex-align-end pad-bottom-XS bkg-light-grey-2 p-absolute" style="z-index: 2; height: 20px;" [style.width.px]="12*unit - 2">
                            <span class="font-MM fw-500" [class]="month.getTime() < now.getTime() ? 'grey-border' : 'grey'">{{month.getFullYear()}}</span>
                        </div>
                    }
                    @if (month.getMonth() === now.getMonth() && month.getFullYear() === now.getFullYear()) {
                        <div class="flex-column flex-align-center w-100 h-100">
                            <div class="flex-center bkg-accent-secondary p-absolute b-radius-14" style="z-index: 3; width: 59px; height: 25px;">
                                <span class="font-MM fw-500 white">{{month|date:'MMMMM yy'}}</span>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>
