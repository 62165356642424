import {Component, OnDestroy, OnInit} from "@angular/core";
import {TopbarService} from "src/app/services/front/topbar.service";
import {TranslateService} from "@ngx-translate/core";
import {
	CreateOrganizationForm,
	Organization,
	OrganizationService,
	OrganizationTreeStatistic
} from "src/app/services/organization.service";
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from "rxjs";
import {RightSliderService} from "src/app/services/front/right-slider.service";
import {StructureComponent, StructureComponentData, StructureComponentResponse} from "./structure/structure.component";
import {BusinessUnitComponent, BusinessUnitComponentData} from "./business-unit/business-unit.component";
import {ActivatedRoute, Router} from "@angular/router";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {MatDialog} from "@angular/material/dialog";
import {filter} from 'rxjs/operators';
import {OrganizationType} from "../../../services/model/autodiscover.model";
import {CreateTeamComponent} from "./create-team/create-team.component";
import {CreateTeamGroupComponent} from "./create-team-group/create-team-group.component";

@Component({
	templateUrl: './organization.component.html',
	styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit, OnDestroy {

	constructor(private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							private rightSliderService: RightSliderService,
							private activatedRoute: ActivatedRoute,
							private topbarService: TopbarService,
							private translate: TranslateService,
							private dialog: MatDialog,
							private router: Router) {
	}

	tenantId: string;
	isEditor: boolean = false;

	_initializing: boolean;
	_loading: boolean;
	_refreshing: boolean;

	organizationTree: OrganizationTreeStatistic;
	openedOrganizations: OrganizationTreeStatistic[] = [];
	openedBusinessUnit: OrganizationTreeStatistic|undefined;

	onDrawerCloseEvent: Subject<any> = new Subject<any>();

	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.topbarService.onTitleChange(this.translate.instant('menu.organization'), this.translate.instant('menu.subtitle.organization'));
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(
				tap(tenant => this.tenantId = tenant.configuration.id),
				tap(tenant => this.isEditor = tenant.configuration.role !== 'read_only'))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.onDrawerCloseEvent
			.subscribe(() => this.rightSliderService.close()));
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => this.fetchOrganizationTree()),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.checkOrganizationDrawerToOpen());
	}

	private fetchOrganizationTree(): Observable<{}> {
		return this.organizationService.getOrganizationTreeStatisticByTenantId(this.tenantId)
			.pipe(tap(tree => this.setOrganizationTree(tree)));
	}

	private setOrganizationTree(organizationTree: OrganizationTreeStatistic): void {
		this.organizationTree = organizationTree;
		this.organizationTree.children
			.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		this.organizationTree.children
			.forEach(structure => structure.children
				.sort((a, b) => a.organization.name.localeCompare(b.organization.name)));
	}

	private checkOrganizationDrawerToOpen(): void {
		if (this.activatedRoute.snapshot.queryParamMap.has('businessUnit')) {
			this.openedBusinessUnit = this.organizationTree.children
				.flatMap(structure => structure.children)
				.find(bu => bu.organization.organizationId === this.activatedRoute.snapshot.queryParamMap.get('businessUnit'));
			const org: OrganizationTreeStatistic|undefined = this.organizationTree.children
				.find(o => o.children.find(bu => bu.organization.organizationId === this.activatedRoute.snapshot.queryParamMap.get('businessUnit')));
			if (this.openedBusinessUnit && org) {
				this.onOpenBusinessUnitDetails(org, this.openedBusinessUnit);
			}
			if (org) {
				this.openedOrganizations = [org];
			}
		}
	}

	onOpenOrganization(tree: OrganizationTreeStatistic): void {
		this.openedBusinessUnit = undefined;
		if (this.openedOrganizations.find(o => o.organization.organizationId === tree.organization.organizationId)) {
			this.openedOrganizations = this.openedOrganizations.filter(o => o.organization.organizationId !== tree.organization.organizationId);
		} else {
			this.openedOrganizations.push(tree);
		}
		this.resetAllQueryParams();
	}

	private onDeleteLeaf(organizationId: string): void {
		if (this.openedOrganizations.find(o => o.organization.organizationId === organizationId)) {
			this.openedOrganizations = this.openedOrganizations.filter(o => o.organization.organizationId !== organizationId);
		}
		if (this.openedBusinessUnit?.organization.organizationId === organizationId) {
			this.openedBusinessUnit = undefined;
		}
		this.organizationService.delete(this.tenantId, organizationId)
			.subscribe(() => this.onRefresh());
	}

	onRefresh() {
		if (!this._refreshing) {
			this.switchRefreshing()
				.pipe(
					switchMap(() => this.fetchOrganizationTree()),
					finalize(() => this.switchRefreshing()))
				.subscribe(() => this.checkOpenedOrganizations());
		}
	}

	private checkOpenedOrganizations(): void {
		if (this.openedOrganizations) {
			this.openedOrganizations = this.openedOrganizations.filter(o => {
				const opened = this.organizationTree.children.find(r => r.organization.organizationId === o.organization.organizationId);
				if (opened) {
					return true;
				} else {
					this.resetAllQueryParams();
					return false;
				}
			});
		}
	}

	onOpenStructureDetails(structure: OrganizationTreeStatistic) {
		this.resetSettingsQueryParams();
		const data: StructureComponentData = {
			structure: structure.organization,
			parent: this.organizationTree.organization,
			children: structure.children.map(c => c.organization)
		};
		this.rightSliderService.openComponent(StructureComponent, data, this.onDrawerCloseEvent)
			.subscribe((r: StructureComponentResponse) => {
				if (r && r.event === 'delete' && r.id) {
					this.onDeleteLeaf(r.id);
				} else {
					this.onRefresh();
				}
				this.resetSettingsQueryParams();
			});
	}

	onOpenBusinessUnitDetails(structure: OrganizationTreeStatistic, businessUnit: OrganizationTreeStatistic) {
		this.router.navigate([], {
			queryParams: {businessUnit: businessUnit.organization.organizationId, settings: null},
			queryParamsHandling: 'merge',
		});
		const data: BusinessUnitComponentData = {
			businessUnit: businessUnit.organization,
			parent: structure.organization
		}
		this.rightSliderService.openComponent(BusinessUnitComponent, data, this.onDrawerCloseEvent)
			.subscribe(r => {
				if (r && r.event === 'delete' && r.id) {
					this.onDeleteLeaf(r.id);
				} else {
					this.onRefresh();
				}
				this.resetAllQueryParams();
			});
	}

	findStructureInOpenedOrganizations(structure: OrganizationTreeStatistic): boolean {
		return !!this.openedOrganizations.find(o => o.organization.organizationId === structure.organization.organizationId);
	}

	resetSettingsQueryParams(): void {
		this.router.navigate([], {
			queryParams: {settings: null},
			queryParamsHandling: 'merge',
		});
	}

	resetAllQueryParams(): void {
		this.router.navigate([], {
			queryParams: {businessUnit: null, settings: null},
			queryParamsHandling: 'merge',
		});
	}

	onCreate(organization: OrganizationTreeStatistic) {
		const data: OrganizationCreationPopupData = {
			parent: organization.organization,
			structures: this.organizationTree.children.map(c => c.organization),
			editor: this.isEditor
		};

		switch (data.parent.type) {
			case OrganizationType.ORGANIZATION:
				this.dialog.open(CreateTeamGroupComponent, { width: '450px', data: data })
					.afterClosed()
					.pipe(
						filter(result => !!result),
						switchMap((form: CreateOrganizationForm) => this.organizationService.create(this.tenantId, form)))
					.subscribe(() => this.onRefresh());
				break;
			case OrganizationType.AFFILIATE:
				this.dialog.open(CreateTeamComponent, { width: '450px', data: data })
					.afterClosed()
					.pipe(
						filter(result => !!result),
						switchMap((form: CreateOrganizationForm) => this.organizationService.create(this.tenantId, form)))
					.subscribe(() => this.onRefresh());
				break;
		}
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchRefreshing(): Observable<{}> {
		this._refreshing = !this._refreshing;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.onDrawerCloseEvent.complete();
		this.rightSliderService.close();
		this.resetAllQueryParams();
	}
}

export interface OrganizationCreationPopupData {
	parent: Organization;
	structures: Organization[];
	editor: boolean;
}

