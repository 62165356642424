import {ClickableObject, DrawableObject, Point} from "../diagram";
import {Layers} from "../layer";
import {ApplicationCard} from "./application-card";

export interface RowState {}

export class TableRow implements DrawableObject<RowState> {

	constructor(
		protected ctx: CanvasRenderingContext2D,
		protected name: string,
		protected origin: Point,
		protected width: number,
		protected height: number) {
	}

    draw(layers: Layers): void {
		layers.getLayer(1).addObject(this.drawBackground());
		layers.getLayer(0).addObject(this.drawTitle());
	}

    update(state: RowState): void {
    }

	protected drawBackground(): ()=>void {
		return () => {
			this.ctx.beginPath()
			this.ctx.lineWidth = 1;
			this.ctx.setLineDash([5, 3])
			this.ctx.strokeStyle = '#A890F3';
			this.ctx.roundRect(this.origin.x, this.origin.y, this.width, this.height, 18);
			this.ctx.fillStyle = 'rgba(168,144,243,0.10)';
			this.ctx.fill();
			this.ctx.stroke()
			this.ctx.setLineDash([])
		}
	}

	protected drawTitle(): ()=>void {
		return () => {
			this.ctx.beginPath()

			this.ctx.font = "bold 16px proxima-nova";
			const marginTop = 30;
			this.ctx.fillStyle = "#A890F3";

			this.ctx.fillText(this.name, this.origin.x + 20, this.origin.y + marginTop);
		}
	}
}
