export enum MainMenuPath {
	DASHBOARD = 'dashboard',
	HOME = 'home',
	OPERATION = 'operation',
	FINANCE = 'finance',
	USAGE = 'usage',
	ORGANIZATION = 'organization',
	APPLICATIONS = 'applications',
	INFRASTRUCTURE = 'infrastructure',
	ARCHITECTURE = 'architecture',
	DIAGRAMS = 'diagrams',
	SETTINGS = 'settings',
	INTERCOM = 'intercom',
	LOGIN = 'login',
	EXTENSION = 'extension'
}

export enum InfrastructurePath {
	INSTANCES = 'instances',
	CLOUD_PROVIDERS = 'cloud-providers'
}

export enum SettingsPath {
	ACCOUNT = 'account',
	ROVERS = 'rovers',
	BILLING = 'billing',
	CREDENTIALS = 'credentials',
	FLEET = 'fleet',
	MEMBERS = 'members',
	TENANT = 'tenant'
}

export enum LoginPath {
	SIGNUP = 'signup',
	RENEW = 'renew'
}

export enum DiagramPath {
	APP_MATRIX = 'application-matrix',
	APP_MAPPING = 'flow',
	APP_LIFE_CYCLE = 'life-cycle',
	APP_COMPARISON = 'comparison'
}

export type AppRouteUrl = `/${MainMenuPath}` | `/${MainMenuPath}/${string}`;

export const LOGIN_URL: AppRouteUrl = `/${MainMenuPath.LOGIN}`;
export const LOGIN_SIGNUP_URL: AppRouteUrl = `/${MainMenuPath.LOGIN}/${LoginPath.SIGNUP}`;
export const LOGIN_RENEW_URL: AppRouteUrl = `/${MainMenuPath.LOGIN}/${LoginPath.RENEW}`;
export const DASHBOARD_URL: AppRouteUrl = `/${MainMenuPath.DASHBOARD}`;
export const HOME_URL: AppRouteUrl = `/${MainMenuPath.HOME}`;
export const OPERATION_URL: AppRouteUrl = `/${MainMenuPath.OPERATION}`;
export const FINANCE_URL: AppRouteUrl = `/${MainMenuPath.FINANCE}`;
export const USAGE_URL: AppRouteUrl = `/${MainMenuPath.USAGE}`;
export const ORGANIZATION_URL: AppRouteUrl = `/${MainMenuPath.ORGANIZATION}`;
export const ARCHITECTURE_URL: AppRouteUrl = `/${MainMenuPath.ARCHITECTURE}`;
export const APPLICATIONS_URL: AppRouteUrl = `/${MainMenuPath.APPLICATIONS}`;
export const INFRASTRUCTURE_URL: AppRouteUrl = `/${MainMenuPath.INFRASTRUCTURE}`;
export const EXTENSION_URL: AppRouteUrl = `/${MainMenuPath.EXTENSION}`;
export const INFRA_INSTANCES_URL: AppRouteUrl = `/${MainMenuPath.INFRASTRUCTURE}/${InfrastructurePath.INSTANCES}`;
export const INFRA_CLOUD_URL: AppRouteUrl = `/${MainMenuPath.INFRASTRUCTURE}/${InfrastructurePath.CLOUD_PROVIDERS}`;
export const DIAGRAMS_URL: AppRouteUrl = `/${MainMenuPath.DIAGRAMS}`;
export const DIAGRAMS_APP_MATRIX_URL: AppRouteUrl = `/${MainMenuPath.DIAGRAMS}/${DiagramPath.APP_MATRIX}`;
export const DIAGRAMS_APP_MAPPING_URL: AppRouteUrl = `/${MainMenuPath.DIAGRAMS}/${DiagramPath.APP_MAPPING}`;
export const DIAGRAMS_APP_LIFE_CYCLE_URL: AppRouteUrl = `/${MainMenuPath.DIAGRAMS}/${DiagramPath.APP_LIFE_CYCLE}`;
export const DIAGRAMS_APP_COMPARISON_URL: AppRouteUrl = `/${MainMenuPath.DIAGRAMS}/${DiagramPath.APP_COMPARISON}`;
export const INTERCOM_URL: AppRouteUrl =`/${MainMenuPath.INTERCOM}`;
export const SETTINGS_URL: AppRouteUrl = `/${MainMenuPath.SETTINGS}`;
export const SETTINGS_ACCOUNT_URL: AppRouteUrl = `/${MainMenuPath.SETTINGS}/${SettingsPath.ACCOUNT}`;
export const SETTINGS_ROVERS_URL: AppRouteUrl = `/${MainMenuPath.SETTINGS}/${SettingsPath.ROVERS}`;
export const SETTINGS_BILLING_URL: AppRouteUrl = `/${MainMenuPath.SETTINGS}/${SettingsPath.BILLING}`;
export const SETTINGS_MEMBERS_URL: AppRouteUrl = `/${MainMenuPath.SETTINGS}/${SettingsPath.MEMBERS}`;
export const SETTINGS_CREDENTIALS_URL: AppRouteUrl = `/${MainMenuPath.SETTINGS}/${SettingsPath.CREDENTIALS}`;
export const SETTINGS_FLEET_URL: AppRouteUrl = `/${MainMenuPath.SETTINGS}/${SettingsPath.FLEET}`;
export const SETTINGS_TENANT_URL: AppRouteUrl = `/${MainMenuPath.SETTINGS}/${SettingsPath.TENANT}`;
