import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {SecurityUtils} from "./security.utils";

@Injectable()
export class NotificationService {

  constructor(protected httpClient: HttpClient) {}

	public getNotifications(tenantId: string): Observable<NotificationResponse> {
		return this.httpClient.get<NotificationResponse>(environment.engineURL + '/tenants/' + tenantId + '/notifications', {headers: SecurityUtils.getAuthHttpHeaders()});
	}

	public markCommentNotificationsRead(tenantId: string, applicationId: string): Observable<any> {
	  return this.httpClient.post(environment.engineURL + '/tenants/' + tenantId + '/notifications/applications/' + applicationId + '/mark-comment-notifications-read', {},{headers: SecurityUtils.getAuthHttpHeaders()});
	}

	@Output() recheckNotificationsEvent = new EventEmitter<boolean>();

	recheckNotifications() {
		// TODO Rework this
		this.recheckNotificationsEvent.emit(true);
	}
}

export interface NotificationResponse {
	notifications: Notification[];
}

export interface Notification {
	sender: Sender;
	type: NotificationType;
	date: Date;
	status: 'read' | 'unread';
	applicationId?: string;
	applicationName?: string;
}

export interface Sender {
	id: string;
	firstName: string;
	lastName: string;
}

export enum NotificationType {
	COMMENT_ADDED = 'COMMENT_ADDED',
	USER_TAGGED_IN_COMMENT = 'USER_TAGGED_IN_COMMENT',
}

