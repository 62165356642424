import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TipCard, TipCarousel} from "../home-page.component";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-home-page-drawer',
  templateUrl: './home-page-drawer.component.html',
  styleUrls: ['./home-page-drawer.component.scss']
})
export class HomePageDrawerComponent implements OnInit{
	@Input() data: {carousel: TipCarousel, tipIndex: number};

	@Output() onClose = new EventEmitter<any>();

	url: SafeResourceUrl | undefined;
	index: number = 0
	tip: TipCard

	list: number[];

	constructor(private sanitizer: DomSanitizer) {
	}

	videoUrl(tipCard: TipCard) {
		const url = !tipCard.video ? undefined : 'https://www.loom.com/embed/' + tipCard.video + '?sid=' + tipCard.sid;
		this.url = !url ? undefined : this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}

	setTipCard(carousel: TipCarousel, tipIndex: number) {
		if (carousel != undefined) {
			this.videoUrl(carousel.cards[tipIndex]);
			this.index = tipIndex;
			this.tip = carousel.cards[tipIndex];
			const listSize = carousel.cards[tipIndex].listSize;
			// create an array from 1 to listSize
			this.list = Array.from({length: listSize}, (v, k) => k + 1);
		}
	}

	openDoc(url?: string) {
		window.open(url, '_blank');
	}

	ngOnInit(): void {
		this.setTipCard(this.data.carousel, this.data.tipIndex)
	}

	close(): void {
		this.onClose.emit();
	}
	next() {
		this.setTipCard(this.data.carousel, this.index + 1)
	}
}
