import {Component, Input} from '@angular/core';
import {Router} from "@angular/router";
import {APPLICATIONS_URL} from "src/app/models/home/navigation.model";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {ApplicationHealth} from 'src/app/services/model/new-application.model';

@Component({
  selector: 'app-application-table',
  templateUrl: './application-table.component.html',
  styleUrls: ['./application-table.component.scss']
})
export class ApplicationTableComponent {

	@Input() applicationInstances: ApplicationHealth[];

	openAll: boolean = false;

	constructor(private router: Router,
							private currentTenantService: CurrentTenantService) {
	}

	goToApp(applicationInstanceId: string) {
		this.currentTenantService.getCurrentTenantId().subscribe(tenantId => {
			this.router.navigate([APPLICATIONS_URL], {queryParams: {tenant: tenantId, applicationId: applicationInstanceId}});
		});
	}
}
