import {NgModule} from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {DiagramBreadcrumbComponent} from 'src/app/modules/home/diagrams/diagram-breadcrumb/diagram-breadcrumb.component';
import {TranslateModule} from '@ngx-translate/core';
import {RouterLink} from '@angular/router';

@NgModule({
  declarations: [
    DiagramBreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
    TranslateModule,
    NgOptimizedImage,
  ],
  exports: [
    DiagramBreadcrumbComponent
  ]
})
export class DiagramBreadcrumbModule {}
