import {Component, Input} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent {
	@Input() input: FormControl;
	@Input() label: string;
	@Input() rows: number = 3;
	@Input() placeholder: string;
}
