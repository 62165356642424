import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {ApplicationLifeCycle, ApplicationLifeCycleForm} from 'src/app/services/model/application-life-cycle.model';

@Injectable()
export class ApplicationLifeCycleService {

  private serviceUrl = (tenantId: string, applicationId: string) => `${environment.engineURL}/tenants/${tenantId}/applications/${applicationId}/life-cycle`;

  constructor(protected httpClient: HttpClient) {
  }

  getApplicationLifeCycle(tenantId: string, applicationId: string): Observable<ApplicationLifeCycle|null> {
    return this.httpClient.get<ApplicationLifeCycle|null>(`${this.serviceUrl(tenantId, applicationId)}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }

  updateApplicationLifeCycle(tenantId: string, applicationId: string, form: ApplicationLifeCycleForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId, applicationId)}`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }
}
