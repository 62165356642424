<div class="flex-center w-100 h-100 badge-content font-M"
     [ngClass]="['color-' + color]" [matBadge]="badge" [matBadgePosition]="badgePosition"
     [class.cursor-disabled]="disabled" [class.cursor-loading]="loading"
     (click)="disabled || loading ? $event.stopPropagation() : {}">
    <button matRipple class="text-button w-100" [class]="panelClass"
            [ngClass]="['color-' + color, 'type-' + type, 'shape-' + shape]"
            [class.greyed-hover]="!disabled && !loading && type !== 'flat'"
            [class.button-disabled]="disabled" [class.button-loading]="loading">
        <div [style.visibility]="loading ? 'hidden' : 'visible'" class="flex-center fw-500"><ng-content></ng-content></div>
        <app-spinner *ngIf="loading" class="p-absolute" [color]="'grey'" [scale]="0.5" ></app-spinner>
    </button>
</div>
