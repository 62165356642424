import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {ServerService} from 'src/app/services/back/server.service';
import {MetricAverage} from 'src/app/services/model/resource.model';

@Component({
	selector: 'infrastructure-widget',
	templateUrl: './infrastructure-widget.component.html',
	styleUrls: ['./infrastructure-widget.component.scss']
})
export class InfrastructureWidgetComponent implements OnInit, OnDestroy {

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	loadAndStorage: LoadAndStorage[] = [];

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private serverService: ServerService) {
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.serverService.getTenantMonthlyAverageMetrics(this.tenantId)),
				tap(data => this.setInfraMetrics(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	setInfraMetrics(data: MetricAverage): void {
		this.loadAndStorage = [];
		this.loadAndStorage.push({
			legend: 'page.dashboard.infrastructure.load',
			percent: data.load ? data.load : undefined
		});
		this.loadAndStorage.push({
			legend: 'page.dashboard.infrastructure.storage',
			percent: data.storage ? data.storage : undefined
		});
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}

interface LoadAndStorage {
	legend: string;
	percent: number|undefined;
}