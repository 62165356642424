<div class="font-L fw-700">{{ 'page.settings.menu.plan' | translate}}</div>

<div class="w-100 disp-f a-i-c j-c-c h-100 mt-xl">
    <app-text-button type="flat" color="accent" [disabled]="tenant?.configuration?.role !== 'admin'" [loading]="isLoadingBillingUrl" (click)="onOpenBillingPortal()">
        {{ 'page.settings.plan.openBilling' | translate}}
    </app-text-button>
</div>

<div class="w-100 text-center primary" *ngIf="tenant?.configuration?.role !== 'admin'">
	{{ 'page.settings.plan.notAllowed' | translate }}
</div>

