import {ChangeDetectorRef, Component, Input, ViewChild} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {UsageHistory} from "../../../../services/usage.service";
import {ChartData, ChartOptions, ChartType} from "chart.js";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {UsageLineChartData} from "../../usage-dashboard/usage-graph/usage-graph.component";
import {BaseChartDirective, NgChartsModule} from "ng2-charts";
import {DeckContentLoaderModule} from "../../../global/title-content-loader/deck-content-loader.module";
import {ColorEnum} from "../../../../../_variables";

@Component({
  selector: 'app-extensions-deployed-graph',
  standalone: true,
	imports: [CommonModule, DeckContentLoaderModule, NgChartsModule, NgOptimizedImage, TranslateModule],
  templateUrl: './extensions-deployed-graph.component.html',
  styleUrl: './extensions-deployed-graph.component.scss'
})
export class ExtensionsDeployedGraphComponent {
	@Input()
	data: UsageHistory[];

	public tooltip: UsageTooltip = {display: "none", top: "0px", left: "0px", day: 0, month: 0, value: 0}

	@ViewChild(BaseChartDirective)
	chart: BaseChartDirective;

	public chartType: ChartType = 'line';

	public chartData: ChartData<'line', {date: Date, amount: number} []> = {
		labels: [],
		datasets: []
	};

	chartOptions: ChartOptions<any>;

	public lineChartData: UsageLineChartData[] = []

	constructor(private cdr: ChangeDetectorRef, private translate: TranslateService) {
	}

	ngOnInit() {
		this.createOptions();
		this.createLabels();
		this.createDataset();
		this.updateChart();
	}

	createOptions() {
		this.chartOptions = {
			interaction: {
				intersect: false,
				mode: 'index',
			},
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					display: false
				},
				tooltip: {
					enabled: false,
					external: (context: { chart: any; tooltip: any; }) => {
						const {chart, tooltip} = context;

						if (tooltip.opacity === 0) {
							if (this.tooltip.display !== "none") {
								this.tooltip.display = "none";
								this.cdr.detectChanges();
							}
							return
						}
						const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

						const canvasWidth = chart.canvas.getBoundingClientRect().width;

						const canvasHeight = chart.canvas.getBoundingClientRect().height;

						let xPosition = positionX + tooltip.caretX;
						if (xPosition + 200 > canvasWidth) {
							xPosition = positionX + tooltip.caretX + 200 - canvasWidth
							xPosition = positionX + tooltip.caretX - xPosition
						}

						let yPosition = positionY + tooltip.caretY;
						if (yPosition > canvasHeight) {
							yPosition = positionY + tooltip.caretY - canvasHeight
							yPosition = positionY + tooltip.caretY - yPosition
						}

						// @ts-ignore
						const date: Date  = tooltip.dataPoints[0].raw.date

						const month = date.getMonth() + 1

						const day = date.getDate()

						// @ts-ignore
						const value = tooltip.dataPoints[0].raw.amount;

						this.tooltip = {
							display: "block",
							left: xPosition + 'px',
							top: yPosition + 'px',
							month: month,
							day: day,
							value: value,
						}
						this.cdr.detectChanges();
					}
				}
			},
			scales: {
				y: {
					suggestedMin: 0,
					suggestedMax: 2,
					display: true,
					grid: {
						lineWidth: 2,
						borderDash: [5,5],
						drawTicks: false,
						drawBorder: false,
						color: ColorEnum.medium_grey,
					},
					ticks: {
						autoSkip: true,
						maxTicksLimit: 5,
						crossAlign:"center",
						callback: (value?: number) => {if (typeof value == "number" && value % 1 === 0) {return value;} else {return undefined}},
						padding: 6,
						font: {
							family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
						},
						color: "#1D3B58"
					}
				},
				x: {
					ticks: {
						font: {
							family: "'Proxima Nova', 'Helvetica Neue', sans-serif",
							size: 9
						},
						color: "#B5B5C3"
					},
					grid: {
						lineWidth: 0,
						drawBorder: false
					}
				},

			}
		};
	}

	createLabels() {
		this.chartData.datasets = [];
		this.chartData.labels = [];

		this.chartData.labels = this.data.map(health =>  {
			return new Date(health.date).getDate()
		});
	}

	createDataset() {
		this.data.forEach(data => {
			this.lineChartData.push({day: new Date(data.date), amount: data.usage.value ?? 0})
		})
		this.chartData.datasets.push(
			{
				data: this.lineChartData.map(data => {
					return {
						date: data.day,
						amount: data.amount,
					}
				}),
				backgroundColor: (context) => {
					const chart = context.chart;
					const {ctx, chartArea} = chart;

					if (!chartArea) {
						return;
					}
					return this.getGradient(ctx, chartArea);
				},
				parsing: {
					xAxisKey: 'date',
					yAxisKey: 'amount'
				},
				tension: 0.1,
				borderColor: "#3DC0FF",
				hoverBackgroundColor: "#3DC0FF",
				fill: true,
				pointStyle: "circle",
				pointRadius: 0.01,
				pointBorderColor: "#FFFFFF",
				pointBorderWidth: 1.5,
				pointBackgroundColor: "#FFFFFF",
				pointHoverBackgroundColor: "#3DC0FF",
				pointHoverRadius: 6,
				pointHoverBorderColor: "#FFFFFF",
				pointHoverBorderWidth: 2,
				borderWidth: 3,
				cubicInterpolationMode: "monotone"
			}
		)
	}

	getGradient(ctx: any, chartArea: any) {
		let width, height, gradient;
		const chartWidth = chartArea.right - chartArea.left;
		const chartHeight = chartArea.bottom - chartArea.top;
		if (gradient === null || width !== chartWidth || height !== chartHeight) {
			gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
			gradient.addColorStop(0, 'rgba(61, 192, 255, 0)');
			gradient.addColorStop(0.5, 'rgba(61, 192, 255, 0.2)');
			gradient.addColorStop(1, 'rgba(61, 192, 255, 0.6)');
		}

		return gradient;
	}

	updateChart() {
		if (this.chart) this.chart.update();
	}
}

export interface UsageTooltip {
	display: "block" | "none",
	top: string,
	left: string,
	day: number,
	month: number,
	value: number
}
