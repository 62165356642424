<div class="flex-column gap-M">
	<div class="flex gap-L">
		<div class="bg-rounded pad-M w-100" style="height: 99px;">
            <div class="flex-column flex-space-between gap-XL w-100 h-100">
                <div class="flex gap-S">
                    <span class="font-MM fw-700">{{'page.appDetails.complexity'|translate}}</span>
                </div>
                <app-progress-bar-indicator [unit]="'letter'"
                                            [lowerBest]="true"
                                            [levelPercent]="complexity?.percent">
                </app-progress-bar-indicator>
            </div>
		</div>
		<div class="bg-rounded pad-M w-100" style="height: 99px;">
            <div class="flex-column flex-space-between gap-XL w-100 h-100">
                <div class="flex gap-S">
                    <span class="font-MM fw-700">{{'page.appDetails.obsolescence'|translate}}</span>
                </div>
                <app-progress-bar-indicator [unit]="'letter'"
                                            [lowerBest]="true"
                                            [levelPercent]="obsolescence?.percent">
                </app-progress-bar-indicator>
            </div>
		</div>
		<div class="bg-rounded pad-M w-100" style="height: 99px;">
            <div class="flex-column flex-space-between gap-XL w-100 h-100">
                <div class="flex gap-S">
                    <span class="font-MM fw-700">{{'page.appDetails.documentation'|translate}}</span>
                </div>
                <app-progress-bar-indicator [color]="'accent'"
                                            [levelPercent]="docStatistic?.documentationPercent">
                </app-progress-bar-indicator>
            </div>
		</div>
	</div>

	<div class="bg-rounded pad-M">
		<app-application-life-cycle></app-application-life-cycle>
	</div>

	<div class="bg-rounded pad-M">
		<app-application-technology-preview (updated)="refreshComplianceIndicators()"></app-application-technology-preview>
	</div>

	<div class="bg-rounded pad-M">
		<app-application-mapping-resource-preview (updated)="refreshComplianceIndicators()"></app-application-mapping-resource-preview>
	</div>

	<div class="bg-rounded pad-M">
		<app-application-mapping-application-preview (updated)="refreshComplianceIndicators()"></app-application-mapping-application-preview>
	</div>

	<div class="bg-rounded pad-M">
		<app-application-document (updated)="refreshDocumentationIndicator()"></app-application-document>
	</div>
</div>
