import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DesignSystemModule} from "../../../../../design-system/design-system.module";
import {TranslateModule} from "@ngx-translate/core";
import {animate, style, transition, trigger} from "@angular/animations";
import {CopyTextModule} from "../../../../../global/input/copy-text/copy-text.module";
import {SnackbarModule} from "../../../../../global/snackbar/snackbar.module";
import {CurrentTenantService} from "../../../../../../services/front/current-tenant.service";
import {ApikeyService} from "../../../../../../services/apikey.service";
import {environment} from "../../../../../../../environments/environment";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: 'app-add-server-automatically',
	standalone: true,
	imports: [CommonModule, DesignSystemModule, TranslateModule, NgOptimizedImage, CopyTextModule, SnackbarModule],
	templateUrl: './add-server-automatically.component.html',
	styleUrl: './add-server-automatically.component.scss',
	animations: [
		trigger(
			'inAnimation1',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(100%)' }),
						animate('300ms 0ms cubic-bezier(.47,1.64,.41,.8)',
							style({ transform: 'translateY(0)'}))
					]
				),
			]
		),
		trigger(
			'inAnimation2',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(100%)' }),
						animate('300ms 100ms cubic-bezier(.47,1.64,.41,.8)',
							style({ transform: 'translateY(0)'}))
					]
				),
			]
		),
		trigger(
			'inAnimation3',
			[
				transition(
					':enter',
					[
						style({ transform: 'translateY(300%)' }),
						animate('300ms 200ms cubic-bezier(.47,1.64,.41,.8)',
							style({ transform: 'translateY(0)'}))
					]
				),
			]
		)
	],
})
export class AddServerAutomaticallyComponent implements OnInit {
	step: number = 1;
	selectedOs: 'windows' | 'red_hat' | 'ubuntu' | 'suse' | 'debian' = 'windows';
	apiKey: string;

	manualInstall: boolean = false;

	@Output() onGoBack = new EventEmitter<void>();

	constructor(private currentTenantService: CurrentTenantService,
				private apiKeyService: ApikeyService) { }

	ngOnInit() {
		this.currentTenantService.getCurrentTenantIdChanges().subscribe(tenantId => {
			this.apiKeyService.getDefaultApiKey(tenantId).subscribe(apiKey => {
				this.apiKey = apiKey.apiKey.value;
			});
		});
	}

	selectOs(os: 'windows' | 'red_hat' | 'ubuntu' | 'suse' | 'debian') {
		this.selectedOs = os;
	}

	formatOs() {
		return this.selectedOs.replace('_', ' ');
	}

	downloadKapsulOnOs() {
		const link = this.selectedOs === 'windows' ? environment.downloadKapsulURL
			: this.selectedOs === 'debian' || this.selectedOs === 'ubuntu' ? environment.downloadKapsulURLDeb
				: environment.downloadKapsulURLRPM;
		return window.open(link, '_blank', 'noopener');
	}

	goBack() {
		this.onGoBack.emit();
	}

	openDoc() {
		return window.open('https://help.kabeen.io/fr/collections/3331016-deploiement-de-l-agent-kapsul', '_blank', 'noopener');
	}
}
