import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ContentLoaderComponent} from "@ngneat/content-loader";
import {ColorEnum} from "../../../../_variables";

@Component({
	selector: 'app-dashboard-page-loader',
	template: `
    <content-loader class="border-box-full svg-sizing"
					[foregroundColor]="colorEnum.medium_grey"
					[backgroundColor]="'#fff'" viewBox="0 0 100% 100%">
		<svg:rect x="0" y="0" width="100%" height="100%"/>
	</content-loader>
  `,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardPageLoaderComponent extends ContentLoaderComponent {
	colorEnum = ColorEnum;
	// TODO @TAN Remove error message in console
}
