@if (_initializing || _loading) {
    <div class="border-box-full">
        <app-dashboard-page-loader></app-dashboard-page-loader>
    </div>
} @else {
<div class="flex-column gap-M pad-M border-box-full">
	<div class="flex" [style.height.px]="20">
		<span class="font-MM fw-700">{{'page.dashboard.documentation.title'|translate}}</span>
	</div>
	<div class="flex-column flex-space-between h-100">
		<div class="flex-center" style="height: 200px;">
			<svg viewBox="-13 33 110 70">
				<linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
					<stop offset="0%" stop-color="#56c4fb" />
					<stop offset="100%" stop-color="#3DC0FF" />
				</linearGradient>
				<path class="grey" d="M3,90 A40,40 0 1,1 80,90" fill='none'/>
				<path fill='none'  class="blue" d="M3,90 A40,40 0 1,1 80,90" [style.stroke-dashoffset]="progress"/>
				<text x="37.5%" y="100%" dominant-baseline="middle" text-anchor="middle" style="font-size:11px; fill: #3DC0FF; font-weight: 700;">
					{{percentage}}%</text>
				<text x="37.5%" y="115%" dominant-baseline="middle" text-anchor="middle" style="font-size:4px;">
					{{'page.dashboard.documentation.level'|translate}}</text>
			</svg>
		</div>
		<div class="flex-align-end flex-space-between border-grey-top" [style.height.px]="30">
			<span class="font-M fw-500">{{'page.dashboard.documentation.total'|translate}}</span>
			<span class="font-M fw-700">{{totalDocument}}</span>
		</div>
	</div>
</div>
}
