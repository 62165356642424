import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild} from "@angular/core";
import {BehaviorSubject, delayWhen, finalize, forkJoin, Observable, of, Subscription, switchMap} from "rxjs";
import {CriticalityLevel, DiagramApplicationMatrix, DiagramApplicationMatrixFilterForm, TenantService} from "src/app/services/tenant.service";
import {Organization, OrganizationService, OrganizationTree} from "src/app/services/organization.service";
import {TranslateService} from "@ngx-translate/core";
import {ApplicationMatrixEngine} from "./engine/application-matrix-engine";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {filter, first, map, tap} from "rxjs/operators";
import {ApplicationCategoryService} from "src/app/services/back/application-category.service";
import {RightSliderService} from "src/app/services/front/right-slider.service";
import {Category} from "src/app/services/model/application-category.model";
import {Router} from "@angular/router";
import {ApplicationDetailComponent} from 'src/app/modules/home/applications/application-detail/application-detail.component';
import {APPLICATIONS_URL} from 'src/app/models/home/navigation.model';
import {FormControl, FormGroup} from '@angular/forms';
import {MenuStateService} from 'src/app/services/front/menu-state.service';
import jsPDF from 'jspdf';

@Component({
	selector: 'app-diagram-app-matrix',
	templateUrl: './diagram-app-matrix.component.html',
	styleUrls: ['./diagram-app-matrix.component.scss']
})
export class DiagramAppMatrixComponent implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;
	@ViewChild('canvasContainer', { static: true }) canvasContainer: ElementRef<HTMLElement>;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;
	_refreshing: boolean;
	_exporting: boolean;

	filterForm: FormGroup;
	searchOrganizationControl: FormControl;
	organizations: OrganizationTree[] = [];
	searchCategoryControl: FormControl;
	categories: Category[] = [];
	filterData: {
		organization: OrganizationTree[],
		criticality: CriticalityLevel[],
		category: Category[],
	};
	formName: typeof Form = Form;

	isEmpty: boolean = false;
	displayProgressBar: boolean = true;
	topOrganizationName: string;
	menuWidth: number = 90;
	engine: ApplicationMatrixEngine;
	width:number;
	height: number;
	activeFilters: number = 0;
	viewInit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	subscription: Subscription = new Subscription();

	constructor(private applicationCategoryService: ApplicationCategoryService,
							private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							private rightSliderService: RightSliderService,
							private changeDetectorRef: ChangeDetectorRef,
							private menuStateService: MenuStateService,
							private tenantService: TenantService,
							private translate: TranslateService,
							private renderer: Renderer2,
							private router: Router) {
	}

	ngOnInit() {
		this.createFilterForm();
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.menuStateService.onMenuStateChange()
			.pipe(tap(state => this.menuWidth = state.width))
			.subscribe(() => setTimeout(() => this.onResize({}))));
		this.renderer.addClass(document.body, 'disable-two-finger-back');
	}

	ngAfterViewInit() {
		this.viewInit.next(true);
		this.width = this.canvasContainer.nativeElement.clientWidth;
		this.height = this.canvasContainer.nativeElement.clientHeight;
		this.changeDetectorRef.detectChanges();
	}

	private createFilterForm(): void {
		this.filterForm = new FormGroup({
			[Form.organization]: new FormControl([]),
			[Form.criticality]: new FormControl([]),
			[Form.category]: new FormControl([]),
		});
		this.subscription.add(this.filterForm.valueChanges
			.pipe(
				tap(() => this.setActiveFilters()),
				switchMap(() => this.fetchDiagramApplicationMatrix()))
			.subscribe());
		this.searchOrganizationControl = new FormControl('');
		this.subscription.add(this.searchOrganizationControl.valueChanges
			.subscribe(search => this.searchOrganizationFilter(search)));
		this.searchCategoryControl = new FormControl('');
		this.subscription.add(this.searchCategoryControl.valueChanges
			.subscribe(search => this.searchCategoryFilter(search)));
	}

	initialize(): void {
		this.subscription.add(this.switchLoading()
			.pipe(
				map(() => this.buildDiagramApplicationMatrixFilterForm()),
				switchMap(form => forkJoin([
					this.tenantService.getAllDiagramApplicationMatrix(this.tenantId, form),
					this.applicationCategoryService.getAllApplicationCategoryByTenantId(this.tenantId),
					this.organizationService.getOrganizationTreeByTenantId(this.tenantId)
				])),
				delayWhen(() => this.viewInit.pipe(filter(init => init), first())),
				tap(([apps, categories, organization])=> this.setDiagramApplicationMatrix(apps, categories, organization)),
				tap(([_, categories, organization])=> this.setFilterData(organization, categories)),
				finalize(() => this.switchLoading()))
			.subscribe());
	}

	fetchDiagramApplicationMatrix(): Observable<{}> {
		return this.switchRefreshing().pipe(
			map(() => this.buildDiagramApplicationMatrixFilterForm()),
			switchMap(form => this.tenantService.getAllDiagramApplicationMatrix(this.tenantId, form)),
			tap(apps => this.updateDiagramApplicationMatrix(apps)),
			finalize(() => this.switchRefreshing()));
	}

	private buildDiagramApplicationMatrixFilterForm(): DiagramApplicationMatrixFilterForm {
		return {
			organizations: this.organizationFormValue.map(o => o.organizationId),
			categories: this.categoryFormValue.map(c => c.categoryId),
			criticality: this.criticalityFormValue,
		}
	}

	private setDiagramApplicationMatrix(apps: DiagramApplicationMatrix[], categories: Category[], organization: OrganizationTree): void {
		const organizations: OrganizationTree[] = organization.children.filter(c => c.children.length > 0);
		this.isEmpty = apps.length === 0;
		if (!this.isEmpty) {
			setTimeout(() => this.startEngine(apps, categories, organizations));
		}
	}

	private startEngine(applications: DiagramApplicationMatrix[], categories: Category[], organizations: OrganizationTree[]): void {
		const context: CanvasRenderingContext2D|null = this.canvas.nativeElement.getContext('2d');
		if (context !== null) {
			this.engine = new ApplicationMatrixEngine(
				context,
				this.canvas.nativeElement,
				applications,
				categories,
				organizations,
				this.canvasContainer.nativeElement.clientHeight,
				this.canvasContainer.nativeElement.clientWidth
			);
			this.engine.warmUp();
			this.subscription.add(this.engine.onObjectClicked
				.pipe(
					switchMap(event => this.rightSliderService.openComponent(ApplicationDetailComponent, {applicationId: event?.id})),
					switchMap(() => this.fetchDiagramApplicationMatrix()))
				.subscribe());
		}
	}

	private updateDiagramApplicationMatrix(applications: DiagramApplicationMatrix[]): void {
		let organizations: OrganizationTree[] = JSON.parse(JSON.stringify(this.organizations))
		organizations.forEach(o => o.children = o.children.filter(c => this.organizationFormValue.length === 0 || this.organizationFormValue.map(org => org.organizationId).includes(c.organization.organizationId)));
		organizations = organizations.filter(o => o.children.length > 0);
		const categories = this.categories
			.filter(o => this.categoryFormValue.length === 0
				|| this.categoryFormValue.map(org => org.categoryId).includes(o.categoryId))
		this.engine.update(applications, organizations, categories);
	}

	private setFilterData(organization: OrganizationTree, categories: Category[]): void {
		this.topOrganizationName = organization.organization.name;
		this.organizations = organization.children
			.filter(c => c.children.length > 0)
			.sort((a, b) => a.organization.name.localeCompare(b.organization.name));
		this.organizations.forEach(o => o.children.sort((a, b) => a.organization.name.localeCompare(b.organization.name)));
		this.categories = categories
			.sort((a, b) => a.name.localeCompare(b.name));
		this.filterData = {
			organization: this.organizations,
			category: this.categories,
			criticality: [CriticalityLevel.HIGH, CriticalityLevel.MEDIUM, CriticalityLevel.LOW]
		};
	}

	private searchOrganizationFilter(search?: string): void {
		const organizationGroups: OrganizationTree[] = JSON.parse(JSON.stringify(this.organizations.filter((group: OrganizationTree) => !search || group.organization.name.toLowerCase().includes(search.toLowerCase()) || group.children.some((option: OrganizationTree) => option.organization.name.toLowerCase().includes(search.toLowerCase())))));
		organizationGroups.forEach((group: OrganizationTree) => group.children = group.children.filter((option: OrganizationTree) => !search || option.organization.name.toLowerCase().includes(search.toLowerCase())));
		this.filterData.organization = organizationGroups;
	}

	private searchCategoryFilter(search?: string): void {
		this.filterData.category = this.categories.filter(c => !search || c.name.toLowerCase().includes(search.toLowerCase()));
	}

	exportToPdf() {
		this.switchExporting()
			.pipe(
				map(() => [this.translate.instant('page.diagram.applicationMatrix.pdf.title'), this.buildPdfSubtitle()]),
				switchMap(([title, subtitle]) => this.engine.exportToPDF(title, subtitle)),
				finalize(() => this.switchExporting()))
			.subscribe(pdf => this.savePdf(pdf));
	}

	private buildPdfSubtitle(): string {
		return !this.organizationFormValue.length
			? this.topOrganizationName
			: this.organizations
				.filter(parent => parent.children.some(c => this.organizationFormValue.map(o => o.organizationId).includes(c.organization.organizationId)))
				.map(parent => parent.organization.name
					+ ' ('
					+ parent.children
						.filter(c => this.organizationFormValue.map(o => o.organizationId).includes(c.organization.organizationId))
						.map(c => c.organization.name)
						.join(', ')
					+ ')')
				.join(' - ');
	}

	private savePdf(pdf: jsPDF): void {
		const titleDate: string = new Date().toISOString().split('T')[0];
		pdf.save('matrix-' + titleDate + '.pdf');
	}

	setActiveFilters(): void {
		this.activeFilters = this.organizationFormValue.length
			+ this.criticalityFormValue.length
			+ this.categoryFormValue.length;
	}

	resetFilters(): void {
		this.filterForm.setValue({
			[Form.organization]: [],
			[Form.criticality]: [],
			[Form.category]: [],
		});
		this.filterForm.markAsPristine();
	}

	protected onMouseOut(): void {
		this.engine?.onFocusOut();
	}

	protected redirectToApps(): void {
		this.router.navigate([APPLICATIONS_URL]);
	}

	get organizationFormValue(): Organization[] {
		return this.filterForm.get(Form.organization)!.value;
	}

	get criticalityFormValue(): CriticalityLevel[] {
		return this.filterForm.get(Form.criticality)!.value;
	}

	get categoryFormValue(): Category[] {
		return this.filterForm.get(Form.category)!.value;
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any): void{
		this.engine?.onResize(
			this.canvasContainer.nativeElement.getBoundingClientRect().height,
			this.canvasContainer.nativeElement.getBoundingClientRect().width
		);
	}

	onZoomIn(): void {
		this.engine?.zoomIn();
	}

	onZoomOut(): void {
		this.engine?.zoomOut();
	}

	onRecenter(): void {
		this.engine?.recenter();
	}

	getZoomLevel(): number {
		return this.engine?.getZoomPercent() ?? -1;
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchRefreshing(): Observable<{}> {
		this._refreshing = !this._refreshing;
		return of({});
	}

	private switchExporting(): Observable<{}> {
		this._exporting = !this._exporting;
		return of({});
	}

	ngOnDestroy() {
		this.engine?.shutdown();
		this.subscription.unsubscribe();
		this.renderer.removeClass(document.body, 'disable-two-finger-back');
	}
}

enum Form {
	organization = 'organization',
	criticality = 'criticality',
	category = 'category',
}
