@if (_initializing || _loading) {
    <div class="border-box-full">
        <app-dashboard-page-loader></app-dashboard-page-loader>
    </div>
} @else {
<div class="flex-column gap-M pad-M border-box-full">
	<div class="flex-space-between">
		<span class="font-MM fw-700">{{'page.architectureDashboard.applicationDistribution.title'|translate}}</span>
		<div class="flex-align-center gap-XS c-p" (click)="switchTypeOrCategory()">
			<div class="font-M fw-500 grey">{{filterType === 'hosting' ? ('page.architectureDashboard.applicationDistribution.filter.type'|translate) : ('page.architectureDashboard.applicationDistribution.filter.category'|translate)}}</div>
			<img class="filter-grey" src="assets/icons/card-arrow.svg" height="7" width="11" alt="">
		</div>
	</div>
	<div class="flex-center gap-L h-100">
		<div class="flex-center">
			<div [style.height.px]="200" [style.width.px]="200">
				<canvas baseChart
						[data]="doughnutChartData"
						[type]="'doughnut'"
						[options]="chartOptions">
				</canvas>
			</div>
			<div class="p-absolute">
				<div class="p-relative flex-center">
					<div class="flex-column gap-S">
						<span class="font-XL fw-700 text-center accent" style="line-height: 10px;">{{applications.length}}</span>
						<span class="font-M fw-500 text-center" style="line-height: 10px;">{{'page.architectureDashboard.applicationDistribution.applications'|translate}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-column flex-justify-center gap-XL h-100 w-100 mr-s">
            @for (key of keys; track key; let i = $index) {
			<div class="flex-column gap-XS">
				<div class="disp-f j-c-sb">
					<div class="font-M fw-500">
						<span class="font-M fw-500">{{label[key]}}</span>
					</div>
					<div class="font-M fw-700">
						<span class="font-M fw-700" style="white-space: nowrap;">{{count[key] + ' %'}}</span>
					</div>
				</div>
				<div style="position: relative;">
					<div class="progression-bg"></div>
					<div [ngClass]="'progression-'+i" [style.width.%]="width[key]"></div>
				</div>
			</div>
            }
		</div>
	</div>
</div>
}
