import {Observable, of, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';

export class HttpClientUtils {

	public static handleError<T = any>(error: any, defaultValue?: any): Observable<T> {
		if (!environment.production && environment.errorAlert && !!error) {
			console.error(error);
			const errorMessage = !!error.title ? error.title.toString()
				: (!!error.error?.title ? error.error?.title
					: (!!error.message ? error.message.toString()
						: (!!error.error ? error.error.toString()
							: error.toString())));
			window.alert(errorMessage);
		}
		return defaultValue !== undefined ? of(defaultValue as unknown as T) : throwError(error);
	}
}
