import {NgModule} from "@angular/core";
import {MatRippleModule} from "@angular/material/core";
import {DialogPlaceholderCardComponent} from "./dialog-placeholder-card.component";
import {DesignSystemModule} from "../design-system.module";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
	declarations: [
		DialogPlaceholderCardComponent
	],
	exports: [
		DialogPlaceholderCardComponent
	],
	imports: [
		// MatModule
		MatRippleModule,
		TranslateModule,
		DesignSystemModule,
	]
})
export class DialogPlaceholderCardModule {}
