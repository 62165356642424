import {Component, ContentChildren, ElementRef, EventEmitter, Input, Output, QueryList, ViewChild} from "@angular/core";
import {TabComponent} from "./tab/tab.component";

@Component({
	selector: 'app-tabs-group',
	styleUrls: ['./tabs.component.scss'],
	templateUrl: './tabs.component.html'
})
export class TabsComponent {
	@ContentChildren(TabComponent) viewChildren!: QueryList<TabComponent>;

	@ViewChild('selectedTabBackground')
	selectedTabView: ElementRef

	@Input() selectedIndex: number = 0;

	@Output() selectedIndexChange = new EventEmitter<number>();

	public tabs: Tab[] = [];

	get tabCount() { return this.viewChildren.length }

	ngAfterViewInit() {
		setTimeout(() => {
			this.tabs = this.viewChildren.map((item: any, idx) => ({item: item, label: () => item.tabLabel, badge:() => item.tabBadge, id:idx}));

			this.tabs.forEach((item: any, idx) => {
				if (idx === this.selectedIndex) {
					item.item.display();
					this.selectedTabView.nativeElement.style.transform = 'translateX(calc('+ (idx * 100) +'% + ' + (idx * 10) + 'px))'
				} else {
					item.item.hide();
				}
			})
		})
	}

	displayTab(tab: Tab) {
		this.tabs.forEach((item: any) => {
			if (item.id === tab.id) {
				item.item.display();
				this.selectedTabView.nativeElement.style.transform = 'translateX(calc(' + (tab.id * 100) +'% + ' + (tab.id * 10) + 'px))'
				this.selectedIndexChange.emit(tab.id)
			} else {
				item.item.hide();
			}
		})
	}
}

export interface Tab {
	id: number,
	item: TabComponent,
	label: () => string,
	badge: () => string | undefined
}
