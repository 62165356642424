import {Component, EventEmitter, Output} from "@angular/core";

@Component({
	selector: 'app-dialog-placeholder-card',
	templateUrl: './dialog-placeholder-card.component.html',
	styleUrls: ['./dialog-placeholder-card.component.scss'],
})
export class DialogPlaceholderCardComponent {
	@Output() onAction: EventEmitter<PlaceholderActionEvent> = new EventEmitter<PlaceholderActionEvent>()
}

export interface PlaceholderActionEvent {
	reason: 'close' | 'cancel' | 'apply';
}
