<div class="flex-align-center gap-M pad-XL-XL-0 w-100 border-box">
    <div class="flex-center title-icon radius-S">
        <img src="assets/icons/document-outline.svg" class="filter-grey" height="24" alt=""/>
    </div>
    <div class="flex-column gap-XS">
        <span class="font-L fw-700">{{'page.appDetails.files.dialog.title'|translate}}</span>
        <span class="font-M fw-300">{{'page.appDetails.files.dialog.subtitle'|translate}}</span>
    </div>
    <!--app-icon-button class="ml-auto" type="stroked" background
                     [img]="'assets/icons/close.svg'" [panelClass]="'img-size-30 darken-icon'"
                     (onClick)="close()"></app-icon-button-->
</div>
<div class="pad-XL">
    <app-application-document-form [data]="data"
                                   (cancel)="close()"
                                   (saved)="close($event)">
    </app-application-document-form>
</div>
