import {NgModule} from "@angular/core";
import {AddTeamDialogComponent} from "./add-team-dialog.component";
import {CommonModule} from "@angular/common";
import {ChipsModule} from "../../../../../global/chips/chips/chips.module";
import {TranslateModule} from "@ngx-translate/core";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DeckContentLoaderModule} from "../../../../../global/title-content-loader/deck-content-loader.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {
    DialogPlaceholderCardModule
} from "../../../../../design-system/dialog-placeholder-card/dialog-placeholder-card.module";

@NgModule({
    imports: [
        CommonModule,
        ChipsModule,
        TranslateModule,
        MatCheckboxModule,
        DeckContentLoaderModule,
        DesignSystemModule,
        DialogPlaceholderCardModule
    ],
	declarations: [
		AddTeamDialogComponent
	],
	exports: [
		AddTeamDialogComponent
	]
})
export class AddTeamDialogModule {
}
