import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {FileLink, FileLinkForm, FileUploadForm} from 'src/app/services/model/tenant-file.model';

@Injectable()
export class TenantFileService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/files`;

	constructor(protected httpClient: HttpClient) {
	}

	getTenantFileLink(tenantId: string, fileLinkId: string) : Observable<FileLink> {
		return this.httpClient.get<FileLink>(`${this.serviceUrl(tenantId)}/${fileLinkId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	downloadTenantFile(tenantId: string, fileLinkId: string) : Observable<Blob> {
		return this.httpClient.get(`${this.serviceUrl(tenantId)}/${fileLinkId}/download`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			responseType: 'blob',
			observe: 'body'
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	createTenantFileUpload(tenantId: string, form: FileUploadForm): Observable<HttpEvent<string>> {
		const formData = new FormData();
		formData.append("file", form.file);
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}/upload`, formData, {
			headers: SecurityUtils.getRawAuthHttpHeaders(),
			reportProgress: true,
			observe: 'events'
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	createTenantFileLink(tenantId: string, form: FileLinkForm): Observable<string> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}/link`, form, {
			headers: SecurityUtils.getAuthHttpHeaders(),
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	deleteTenantFile(tenantId: string, fileLinkId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/${fileLinkId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}
}
