import {NgModule} from "@angular/core";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatRippleModule} from "@angular/material/core";
import {CommonModule} from "@angular/common";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {TextareaComponent} from "./textarea.component";

@NgModule({
	imports: [
		MatInputModule,
		CommonModule,
		ReactiveFormsModule,
		MatIconModule,
		MatRippleModule,
		MatProgressSpinnerModule
	],
	declarations: [
		TextareaComponent
	],
	exports: [
		TextareaComponent
	]
})
export class TextareaModule {
}
