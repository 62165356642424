import {NgModule} from "@angular/core";
import {EditableTextInputComponent} from "./editable-text-input.component";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatRippleModule} from "@angular/material/core";
import {CommonModule} from "@angular/common";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";

@NgModule({
	imports: [
		MatInputModule,
		CommonModule,
		ReactiveFormsModule,
		MatIconModule,
		MatRippleModule,
		MatProgressSpinnerModule
	],
	declarations: [
		EditableTextInputComponent
	],
	exports: [
		EditableTextInputComponent
	]
})
export class EditableTextInputModule {
}
