import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {TenantOverlapStatistic, TenantService} from 'src/app/services/tenant.service';

@Component({
  selector: 'app-overlaps',
  templateUrl: './overlaps.component.html',
  styleUrls: ['./overlaps.component.scss']
})
export class OverlapsComponent implements OnInit, OnDestroy {

	@Input() filter: Subject<Organization|null|undefined>;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	data: TenantOverlapStatistic;

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private tenantService: TenantService) {
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getTenantOverlapStatistic(this.tenantId, org?.organizationId, QueryRangeType.CURRENT_YEAR)),
				tap(data => this.data = data),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	getRightPercent(): string {
		if (this.data) {
			if (this.data.overlapPercent.toString().length == 2) {
				return 'right: 25%';
			}
			if (this.data.overlapPercent.toString().length == 3) {
				return 'right: 15%';
			}
			return 'right: 40%';
		}
		return 'right: 20%';
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}
