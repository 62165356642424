import {NgModule} from "@angular/core";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule} from "@angular/common";
import {OrganizationService} from "../../../../services/organization.service";
import {StructureComponent} from "./structure.component";
import {DeckContentLoaderModule} from "../../../global/title-content-loader/deck-content-loader.module";
import {MatIconModule} from "@angular/material/icon";
import {MatRippleModule} from "@angular/material/core";
import {LineCardModule} from "../../../global/cards/line-card/line-card.module";
import {CardModule} from "../../../global/cards/card/card.module";
import {DoughnutModule} from "../../../global/charts/doughnut/doughnut.module";
import {EditableTextInputModule} from "src/app/modules/global/input/text-input/editable-text-input.module";
import {BadgeModule} from "../../../global/badge/badge.module";
import {MiniButtonModule} from "../../../global/button/mini-button/mini-button.module";
import {AlertModule} from "../../../global/alert/alert.module";
import {ApplicationMiniWidgetModule} from "../../../global/application-mini-widget/application-mini-widget.module";
import {ElementListModule} from "../element-list/element-list.module";
import {ApplicationTableModule} from "../../../global/application-table/application-table.module";
import {ContentLoaderModule} from "@ngneat/content-loader";
import {OrganizationSettingModule} from "../organization-setting/organization-setting.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
	imports: [
		MatIconModule,
		TranslateModule,
		CommonModule,
		DeckContentLoaderModule,
		MatRippleModule,
		LineCardModule,
		CardModule,
		DoughnutModule,
		EditableTextInputModule,
		BadgeModule,
		MiniButtonModule,
		AlertModule,
		ApplicationMiniWidgetModule,
		ElementListModule,
		ApplicationTableModule,
		ContentLoaderModule,
		OrganizationSettingModule,
		DesignSystemModule,
	],
	providers: [
		OrganizationService
	],
	declarations: [
		StructureComponent,
	],
	exports: [
		StructureComponent
	]
})
export class StructureModule {
}
