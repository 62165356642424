import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {SecurityUtils} from '../security.utils';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {ApplicationGeneric} from 'src/app/services/model/new-application.model';

@Injectable()
export class PerformanceService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/performance`;

	constructor(protected httpClient: HttpClient) {
	}

	getTenantPerformanceRepartition(tenantId: string, organizationId?: string, range?: string): Observable<PerformanceRepartition> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (range) params = params.append('range', range);
		return this.httpClient.get<PerformanceRepartition>(`${this.serviceUrl(tenantId)}/repartition`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getAllWorstApplicationPerformance(tenantId: string, organizationId?: string, limit?: number, range?: QueryRangeType): Observable<ApplicationPerformance[]> {
		let params: HttpParams = new HttpParams();
		if (organizationId) params = params.append('organizationId', organizationId);
		if (limit) params = params.append('limit', limit);
		if (range) params = params.append('range', range);
		return this.httpClient.get<ApplicationPerformance[]>(`${this.serviceUrl(tenantId)}/worst-performance-apps`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: params
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}
}

export interface ApplicationPerformance {
	application: ApplicationGeneric;
	userCount: number;
	lcp: number|null;
	fcp: number|null;
}

export interface PerformanceRepartition {
	countGoodRatingApps: number;
	countMediumRatingApps: number;
	countBadRatingApps: number;
}
