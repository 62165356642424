<div class="flex-column gap-L">
    <div class="flex-align-center gap-S">
        <div class="font-MM fw-700">{{'page.home.homepage.history.lastActions'|translate}}</div>
    </div>
    <div class="flex-column">
        <div *ngFor="let log of logs; let i = index">
            <div class="flex-space-between flex-align-center">
                <div class="flex-align-center gap-XS">
                    <app-display-user [user]="log.user" bold></app-display-user>
                    <div class="font-M fw-500">{{getActionLabel(log)}}</div>
                    <div class="font-M fw-700">{{getFieldLabel(log)}}</div>
                </div>
                <div>{{getDateGap(log.creationDate)}}</div>
            </div>
            <div class="flex" *ngIf="i < logs.length - 1">
                <div class="border-grey-left" style="height: 20px; margin: 3px 14.5px;"></div>
            </div>
        </div>
    </div>
</div>
