<div class="h-100">
	<div  class="pl-xl pr-xl pt-l pb-l slider-header">
		<div class="disp-f j-c-s a-i-c w-100">
			<div>
				<div class="disp-f a-i-c j-c-c col bkg-accent-light radius-10" style="height: 45px; width: 45px">
					<img src="assets/icons/bullhorn.svg" class="svg-24 filter-accent">
				</div>
			</div>
			<div class="ml-s flex-column gap-XS">
				<div class="font-L fw-700" style="display:inline-block;">{{ 'global.announcements.deployAnAnnouncement' | translate }}</div>
				<div class="font-M fw-300">{{ 'global.announcements.keepYourUserInformed' | translate }}</div>
			</div>
		</div>

		<div class="flex-align-center gap-S">
			<app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
							 (click)="close()">
			</app-icon-button>
		</div>
	</div>

	<div class="pt-xl pr-xl pl-xl pb-xl disp-f f-d-col j-c-sb drawer-content h-auto">
		<div class="bg-rounded pt-l pb-l pr-l pl-l h-100">
			<div class="disp-f f-d-col gap-S">
				<div class="announcement-table grey pl-s pr-s">
					<div style="width: 48px; min-width: 48px;"></div>
					<div style="width: 35%; min-width: 35%;">
						{{ 'global.announcements.title' | translate }}
					</div>
					<div style="width: 30%; min-width: 30%;">
						{{ 'global.announcements.target' | translate }}
					</div>
					<div style="width: 10%; min-width: 10%;">
						{{ 'global.announcements.views' | translate }}
					</div>
					<div style="width: 20%; text-align: end;">
						{{ 'global.actions' | translate }}
					</div>
				</div>
				<div class="single-announcement" *ngFor="let announcement of data?.announcements">
					<div class="announcement-table h-100">
						<div class="disp-f a-i-c" style="width: 48px; min-width: 48px;">
							<div class="logo-bubble w-100">
								<img *ngIf="announcement.type === 'general'" class="filter-accent" ngSrc="assets/icons/message-alert-square.svg" height="20" width="20" alt="">
								<img *ngIf="announcement.type === 'network'" class="filter-accent" ngSrc="assets/icons/wifi-off.svg" height="20" width="20" alt="">
								<img *ngIf="announcement.type === 'maintenance'" class="filter-accent" ngSrc="assets/icons/tools.svg" height="20" width="20" alt="">
							</div>
						</div>
						<div class="disp-f a-i-c" style="width: 35%; min-width: 35%;">
							<!-- NAME AND DATE -->
							<div class="disp-f f-d-col j-c-c w-100 gap-XS" style="overflow: hidden;">
								<span class="font-M fw-700" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
									{{ announcement.title }}
								</span>
								<span class="font-M fw-500" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ 'global.day.' + (announcement.updatedAt.getDay() == 0 ? '7' : announcement.updatedAt.getDay().toString()) | translate }} {{ announcement.updatedAt.getDate() }} {{ 'global.month.' + (announcement.updatedAt.getMonth() + 1) | translate }} {{ announcement.updatedAt.toLocaleTimeString().slice(0, 5) }}</span>
							</div>
						</div>
						<div class="disp-f a-i-c" style="width: 30%; min-width: 30%;">
							<app-display-logo *ngIf="announcement.app" [logo]="announcement.app.logo" [size]="40"></app-display-logo>
							<div *ngIf="announcement.teams && announcement.teams.length > 0" class="disp-f f-d-r a-i-c j-c-s" style="padding-left: 16px">
                                <div class="disp-f f-d-r a-i-c j-c-s"
                                     [tooltipTriggerFor]="tooltip1" tooltipPosition="right"
                                     [tooltipDisabled]="!announcement.teamsTooltip?.length">
                                    <div *ngFor="let team of announcement.teams | slice:0:3;let i = index" style="margin-left: -16px;" [style.z-index]="5 + i">
                                        <div class="disp-f a-i-c j-c-c col bkg-accent-light radius-half white-border" style="height: 38px; width: 38px">
                                            <img [src]="!!$any(team)?.icon ? '/assets/icons/team_icon/' + $any(team)?.icon + '.svg' : 'assets/icons/team.svg'" class="svg-22 filter-accent">
                                        </div>
                                    </div>
                                    <div *ngIf="announcement.teams.length > 3" class="disp-f a-i-c j-c-c col bkg-accent-light radius-half white-border" style="height: 38px; width: 38px; margin-left: -16px; z-index: 8">
                                        <span class="font-M fw-500 accent">+{{announcement.teams.length - 3}}</span>
                                    </div>
                                </div>
                                <app-tooltip #tooltip1>
                                    <div class="flex-column" *ngFor="let structure of announcement.teamsTooltip">
                                        <span class="fw-700">{{ structure.parentName }}</span>
                                        <ul class="pl-l pr-l">
                                            <li class="font-S" [ngClass]="{'mb-s': i + 1 < structure.teams.length}"
                                                *ngFor="let team of structure.teams; let i = index">{{ team.name }}</li>
                                        </ul>
                                    </div>
                                </app-tooltip>
							</div>
						</div>
						<div class="disp-f a-i-c" style="width: 10%; min-width: 10%;">
                            <app-chips-count [value]="announcement.seenCount" type="visibility" color="accent"></app-chips-count>
                        </div>
						<div class="disp-f a-i-c" style="width: 20%;">
							<div class="disp-f a-i-c j-c-fe w-100" style="height: 100%;">
								<app-icon-button color="accent" size="m" [img]="'assets/icons/edit.svg'" [tooltip]="'button.edit'|translate" [panelClass]="'fix-edit-margin'"
												 (click)="openAnnouncement(announcement)">
								</app-icon-button>
								<app-icon-button color="danger" size="m" [img]="'assets/icons/trash.svg'" [tooltip]="'button.delete'|translate"
												 (click)="deleteAnnouncement(announcement.id)">
								</app-icon-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
