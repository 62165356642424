@if (_initializing || _loading) {
    <div class="border-box-full">
        <app-dashboard-page-loader></app-dashboard-page-loader>
    </div>
} @else {
<div>
	<div class="flex-align-center pl-m pr-m pt-m pb-m">
		<span class="font-MM fw-700">{{'Performances'}}</span>
	</div>
	<div class="flex-column flex-justify-center gap-M pl-xl pr-xl" style="height: 217px;">
        @if (repartition.countBadRatingApps + repartition.countGoodRatingApps + repartition.countMediumRatingApps > 0) {
		<div class="flex-center pr-xs" style="gap: 30px;">
			<!-- Doughnut with data -->
			<div>
				<div class="disp-b" style="width: 200px; height: 200px;">
					<canvas baseChart style="width: 200px; height: 200px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
				</div>
				<div class="doughnut-center">
					<div class="doughnut-flex">
						<div class="doughnut-total">
							<span [ngClass]="{ 'good-rating': overallRating.label === 'GOOD', 'medium-rating': overallRating.label === 'NEEDS_IMPROVEMENT', 'poor-rating': overallRating.label === 'POOR' }" *ngIf="overallRating">{{ overallRating.rating }}</span>
							<span *ngIf="!overallRating">-</span>
						</div>
						<div class="doughnut-label">
							{{ 'global.overallRating' | translate }}
						</div>
					</div>
				</div>
			</div>
			<div class="flex-column flex-justify-center w-100" style="height: 100%; gap: 40px;">
				<div *ngFor="let key of keys; let i = index">
					<div class="flex-column">
						<div class="flex-space-between">
							<div>
								<span >{{ label[key] }}</span>
							</div>
							<div class="fw-700">
								<span>{{ count[key] + ' %' }}</span>
							</div>
						</div>
						<div style="position: relative;">
							<div class="progression-bg"></div>
							<div *ngIf="count[key] > 0" [ngClass]="'progression-'+i" [style.width.%]="count[key]"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
        } @else {
		<div class="flex-center" style="gap: 70px;">
			<!-- Doughnut with data -->
			<div>
				<div class="disp-b" >
					<canvas baseChart style="width: 150px; height: 150px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
				</div>
				<div class="doughnut-center">
					<div class="doughnut-flex">
						<div class="doughnut-total-no-data">
							-
						</div>
						<div class="doughnut-label">
							Niveau global
						</div>
					</div>
				</div>
			</div>
			<div class="flex-column flex-justify-center w-100 gap-XXL" style="height: 100%;">
				<div class="flex-column">
					<div class="flex-space-between">
						<div class="donut-placeholder"></div>
						<div class="fw-700">
							-
						</div>
					</div>
					<div class="no-data-bar w-100"></div>
				</div>
				<div class="flex-column">
					<div class="flex-space-between">
						<div class="donut-placeholder"></div>
						<div class="fw-700">
							-
						</div>
					</div>
					<div class="no-data-bar w-100"></div>
				</div>
				<div class="flex-column">
					<div class="flex-space-between">
						<div class="donut-placeholder"></div>
						<div class="fw-700">
							-
						</div>
					</div>
					<div class="no-data-bar w-100"></div>
				</div>
			</div>
		</div>
        }
	</div>
</div>
}
