<div class="flex-align-center gap-M pad-XL-XL-0 w-100 border-box">
    <div class="flex-center title-icon radius-S">
        <img src="assets/icons/document-outline.svg" class="filter-grey" height="24" alt=""/>
    </div>
    <div class="flex-column gap-XS">
        <span *ngIf="!data.info" class="font-L fw-700">{{ 'page.appDetails.finance.contracts.addContract' | translate }}</span>
        <span *ngIf="!!data.info" class="font-L fw-700">{{ 'page.appDetails.finance.contracts.updateContract' | translate }}</span>
        <span class="font-M fw-300">{{ 'page.appDetails.finance.contracts.fillContractInformation' | translate }}</span>
    </div>
    <!--app-icon-button class="ml-auto" type="stroked" background
                     [img]="'assets/icons/close.svg'" [panelClass]="'img-size-30 darken-icon'"
                     (onClick)="close()"></app-icon-button-->
</div>
<div class="flex-column pad-XL gap-M">
    <div class="flex-space-around flex-align-center bkg-light-grey b-radius-9" style="height: 35px;">
        <div class="flex-center w-30 c-p" [ngClass]="{'active-tab': tabIndex === 0}"
             (click)="tabIndex = 0"><span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.generalPanel'|translate}}</span></div>
        <div class="flex-center w-30 c-p" [ngClass]="{'active-tab': tabIndex === 1}"
             (click)="tabIndex = 1"><span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.advancedPanel'|translate}}</span></div>
        <div class="flex-center w-30 c-p" [ngClass]="{'active-tab': tabIndex === 2}"
             (click)="tabIndex = 2"><span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.documentPanel'|translate}}</span></div>
    </div>
    <div [hidden]="tabIndex !== 0">
        <div class="flex-column gap-M">
            <div class="flex-column gap-XS">
                <span class="font-M fw-500 grey">{{ 'page.appDetails.finance.contracts.contractNature' | translate }}</span>
                <app-multi-select [inputControl]="$any(contractForm.get(form.type))"
                                  [disabled]="!!data.info || _saving">
                    <app-select-trigger appearance="field">{{$any(contractForm.get(form.type)).value.name}}</app-select-trigger>
                    <app-select-option *ngFor="let type of typeChoices" [value]="type">{{type.name}}</app-select-option>
                </app-multi-select>
            </div>
            <app-subscription-form *ngIf="contractType === types.SUBSCRIPTION"
                                   [organization]="data.organization"
                                   [info]="data.info"
                                   (onChange)="onSubscriptionFormChange($event)">
            </app-subscription-form>
            <app-licence-form *ngIf="contractType === types.LICENSE"
                              [organization]="data.organization"
                              [info]="data.info"
                              (onChange)="onLicenseFormChange($event)">
            </app-licence-form>
            <app-service-form *ngIf="contractType === types.SERVICE"
                              [organization]="data.organization"
                              [info]="data.info"
                              (onChange)="onServiceFormChange($event)">
            </app-service-form>
        </div>
    </div>
    <div [hidden]="tabIndex !== 1">
        <div class="flex-column gap-M">
            <div class="flex-column gap-XS">
                <div class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.licensedUsers'|translate}}</div>
                <app-text-input [input]="$any(contractForm.get(form.licensedUsers))" type="number"
                                [placeholder]="'page.appDetails.finance.contracts.licensedUsersPlaceholder'|translate"></app-text-input>
            </div>
            <div class="flex-column gap-XS">
                <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.noticePeriod'|translate}}</span>
                <app-multi-select [inputControl]="$any(contractForm.get(form.noticePeriod))"
                                  [disabled]="_saving">
                    <app-select-trigger appearance="field">{{$any(contractForm.get(form.noticePeriod)).value?.name}}</app-select-trigger>
                    <app-select-option *ngFor="let type of noticeChoices" [value]="type">{{type.name}}</app-select-option>
                </app-multi-select>
            </div>
            <div class="flex-column gap-XS">
                <div class="flex-space-between w-100">
                    <div class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.comment'|translate}}</div>
                    <div class="font-S fw-500 grey">{{$any(contractForm.get(form.comment)).value?.length ?? 0}} / 200</div>
                </div>
                <textarea maxlength="200" class="textarea" [formControl]="$any(contractForm.get(form.comment))"
                          [style.height.px]="contractType === types.SUBSCRIPTION && contractForm.get(form.durationOfCommitment)!.value !== 'custom' ? 94 : 169"></textarea>
            </div>
        </div>
    </div>
    <div [hidden]="tabIndex !== 2" [style.height.px]="contractType === types.SUBSCRIPTION && contractForm.get(form.durationOfCommitment)!.value !== 'custom' ? 286 : 361">
        <app-upload-file [defaultFiles]="!data.info ? [] : data.info.contract.documents"
                         (filesChange)="$any(contractForm.get(form.fileLinkIds)).setValue($event)"
                         (uploading)="_uploading = $event"></app-upload-file>
    </div>
    <div class="flex gap-M pad-top-M">
        <app-text-button type="stroked" class="w-50"
                         (click)="close(false)">
            {{'page.appDetails.finance.contracts.cancel'|translate}}
        </app-text-button>
        <app-text-button type="flat" class="w-50" [disabled]="contractForm.invalid" [loading]="_saving || _uploading"
                         (click)="save()">
            {{(!data.info ? 'page.appDetails.finance.contracts.add' : 'page.appDetails.finance.contracts.save')|translate}}
        </app-text-button>
    </div>
</div>
