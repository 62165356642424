<app-snackbar></app-snackbar>
<form [formGroup]="subscriptionForm">
    <div class="flex-column gap-M">
        <div class="flex-column gap-XS">
            <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.subscribers'|translate}}</span>
            <app-multi-select class="w-100" [inputControl]="$any(subscriptionForm.get(form.organizations))" multiple>
                <app-select-trigger appearance="field">{{!subscriptionForm.get(form.organizations)!.value.length ? '-' : (subscriptionForm.get(form.organizations)!.value[0].name  + ($any(subscriptionForm.get(form.organizations)).value.length > 1 ? ' (+' + ($any(subscriptionForm.get(form.organizations)).value.length - 1) + ')' : ''))}}</app-select-trigger>
                <app-select-search [searchControl]="searchOrganizationControl"></app-select-search>
                <app-select-option-group *ngFor="let group of filteredOrganizations" [label]="group.organization.name" noOptionPrefix>
                    <app-select-option *ngFor="let org of group.children" [value]="org.organization">
                        <div class="flex-align-center gap-M">
                            <img [src]="!org.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + org.organization.icon + '.svg')" class="filter-accent" height="16px">
                            <span>{{org.organization.name}}</span>
                        </div>
                    </app-select-option>
                </app-select-option-group>
            </app-multi-select>
        </div>
        <div class="flex gap-L">
            <div class="flex-column gap-XS w-70">
                <div class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.amount'|translate}}</div>
                <app-text-input [input]="$any(subscriptionForm.get(form.amount))" type="number"
                                [placeholder]="'page.appDetails.finance.contracts.amountPlaceholder'|translate">
                    <div icon-suffix class="pad-S font-L fw-500">{{currencySymbol}}</div>
                </app-text-input>
            </div>
            <div class="flex-column gap-XS w-30">
                <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.recurrence'|translate}}</span>
                <app-multi-select [inputControl]="$any(subscriptionForm.get(form.recurrencePeriod))">
                    <app-select-trigger appearance="field">{{$any(subscriptionForm.get(form.recurrencePeriod)).value.name}}</app-select-trigger>
                    <app-select-option *ngFor="let type of recurrenceChoices" [value]="type">{{type.name}}</app-select-option>
                </app-multi-select>
            </div>
        </div>
        <div class="flex gap-L">
            <div class="flex-column gap-XS w-50">
                <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.subscriptionDate'|translate}}</span>
                <div class="flex-align-center custom-calendar-input" (click)="picker1.open()">
                    <input [formControl]="$any(subscriptionForm.get(form.subscriptionDate))" [matDatepicker]="picker1" [placeholder]="'DD/MM/YYYY'">
                    <img class="c-p" src="assets/icons/calendar2.svg" alt="" width="18" height="18">
                    <mat-datepicker #picker1></mat-datepicker>
                </div>
            </div>
            <div class="flex-column gap-XS w-50">
                <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.commitmentPeriod'|translate}}</span>
                <app-multi-select [inputControl]="$any(subscriptionForm.get(form.commitmentPeriod))">
                    <app-select-trigger appearance="field">{{$any(subscriptionForm.get(form.commitmentPeriod)).value.name}}</app-select-trigger>
                    <app-select-option *ngFor="let type of commitmentPeriodChoices" [value]="type">{{type.name}}</app-select-option>
                </app-multi-select>
            </div>
        </div>
        @if ($any(subscriptionForm.get(form.commitmentPeriod)).value.id === commitment.CUSTOM) {
            <div class="flex-column gap-XS">
                <span class="font-M fw-500 grey">{{'page.appDetails.finance.contracts.endDate'|translate}}</span>
                <div class="flex-align-center custom-calendar-input" (click)="picker2.open()">
                    <input [formControl]="$any(subscriptionForm.get(form.customEndDate))" [matDatepicker]="picker2" [placeholder]="'DD/MM/YYYY'">
                    <img class="c-p" src="assets/icons/calendar2.svg" alt="" width="18" height="18">
                    <mat-datepicker #picker2></mat-datepicker>
                </div>
            </div>
        }
    </div>
</form>
