import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';

@Directive({
  selector: '[appTextareaAutoresize]'
})
export class TextareaAutoresizeDirective implements OnInit{
	constructor(private elementRef: ElementRef) {}

	resize() {
		this.elementRef.nativeElement.style.height = '20px';
		this.elementRef.nativeElement.style.height = this.elementRef.nativeElement.scrollHeight + 'px';
	}

	@HostListener(':input')
	onInput() {
		this.resize();
	}

	ngOnInit() {
		if (this.elementRef.nativeElement.scrollHeight) {
			setTimeout(() => this.resize());
		}
	}
}
