import {NgModule} from '@angular/core';
import {ContentLoaderModule} from '@ngneat/content-loader';
import {CommonModule} from '@angular/common';
import {AutodiscoverPageLoaderComponent} from "./autodiscover-page-loader.component";
import {DashboardPageLoaderComponent} from "./dashboard-page-loader.component";
import {LinechartLoaderComponent} from "./linechart-loader.component";
import {TablePageLoaderComponent} from "./table-page-loader.component";
import {TitleContentLoaderComponent} from "./title-content-loader.component";
import {OrganizationLoaderComponent} from "./organization-loader.component";
import {ListLoaderComponent} from "./list-loader.component";
import {DonutLoaderComponent} from "./donut-loader.component";
import {InlineBarLoaderComponent} from "./inline-bar-loader.component";

@NgModule({
	imports: [
		ContentLoaderModule,
		CommonModule
	],
	declarations: [
		AutodiscoverPageLoaderComponent,
		DashboardPageLoaderComponent,
		LinechartLoaderComponent,
		TablePageLoaderComponent,
		TitleContentLoaderComponent,
		OrganizationLoaderComponent,
		ListLoaderComponent,
		DonutLoaderComponent,
		InlineBarLoaderComponent
	],
	exports: [
		AutodiscoverPageLoaderComponent,
		DashboardPageLoaderComponent,
		LinechartLoaderComponent,
		TablePageLoaderComponent,
		TitleContentLoaderComponent,
		OrganizationLoaderComponent,
		ListLoaderComponent,
		DonutLoaderComponent,
		InlineBarLoaderComponent
	]
})
export class DeckContentLoaderModule {
}
