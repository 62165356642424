import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {AuthenticationService, UserInfo} from "src/app/services/authentication.service";
import {HOME_URL} from "src/app/models/home/navigation.model";
import {Router} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-onboarding-step-1',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, NgOptimizedImage, TranslateModule],
  templateUrl: './onboarding-step-1.component.html',
  styleUrl: '../onboarding.component.scss'
})
export class OnboardingStep1Component {
	constructor(private breakpointObserver: BreakpointObserver, private authenticationService: AuthenticationService, private router: Router) {
	}

	@Input() stepCount: number = 6;
	@Input() canClose: boolean = false;
	@Input() isMobile: boolean;

	@Output() onNextClick = new EventEmitter<string>();

	currentUser: UserInfo;

	panelClass: string = '';

	ngOnInit() {
		this.currentUser = this.authenticationService.getUserInfo()!;

		this.breakpointObserver
			.observe(['(max-width: 1280px)'])
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
					this.panelClass = ''
				} else {
					this.panelClass = 'big-button'
				}
			});
	}

	goBackHome() {
		return this.router.navigate([HOME_URL])
	}
}
