import {ChangeDetectorRef, Component, DestroyRef, Input, OnInit, ViewChild} from "@angular/core";
import {distinctUntilChanged, startWith, tap} from "rxjs";
import {filter, map} from "rxjs/operators";
import {NavigationExtras, Router, RoutesRecognized} from "@angular/router";
import {MenuGroup} from "./main-menu.model";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {CurrentTenantService} from '../../../services/front/current-tenant.service';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MenuStateService} from "../../../services/front/menu-state.service";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatDividerModule} from "@angular/material/divider";
import {MatRippleModule} from "@angular/material/core";
import {AppRouteUrl, INTERCOM_URL} from "../../../models/home/navigation.model";
import * as AppUrl from "../../../models/home/navigation.model";
import {IntercomService} from "../../../services/front/intercom.service";
import {DropdownComponent} from "../../design-system/dropdown/dropdown.component";
import {DropdownTriggerDirective} from "../../design-system/dropdown/dropdown-trigger.directive";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

declare const Intercom: any;

@Component({
	selector: 'app-main-menu',
	templateUrl: './main-menu.component.html',
	styleUrls: ['./main-menu.component.scss'],
	standalone: true,
  imports: [CommonModule, TranslateModule, MatDividerModule, MatRippleModule, DropdownTriggerDirective, NgOptimizedImage, DesignSystemModule]
})
export class MainMenuComponent implements OnInit {

	@Input() menuGroups: MenuGroup[] = [];
	@Input() latestAtBottom: boolean = true;

	@ViewChild('dropdown', {static: false}) dropdown: DropdownComponent | undefined;

	isDropdownOpen: boolean = false;

	// Used in HTML, not unused.
	menuState$ = this.menuStateService.onMenuStateChange().pipe(takeUntilDestroyed(this.destroyRef));

	private lastSelectedItemNotIntercom: undefined | AppRouteUrl;

	constructor(public translateService: TranslateService,
				public currentTenantService: CurrentTenantService,
				public destroyRef: DestroyRef,
				public changeDetectorRef: ChangeDetectorRef,
				public menuStateService: MenuStateService,
				public intercomService: IntercomService,
				public router: Router) {
	}

	ngOnInit() {
		this.router.events.pipe(
				takeUntilDestroyed(this.destroyRef),
				filter((event: any) => event instanceof RoutesRecognized),
				map((event: RoutesRecognized) => event.url),
				startWith(this.router.url),
				map(url => url.split('?')[0]),
				distinctUntilChanged(),
				tap(url => this.lastSelectedItemNotIntercom = url as AppRouteUrl),
				tap(url => this.selectActiveMenuEntry(url)))
			.subscribe();

		// Subscribe to intercom events
		this.intercomService.onEvent().pipe(
			takeUntilDestroyed(this.destroyRef),
			tap(event => this.selectActiveMenuEntry(event === 'show' ? INTERCOM_URL : this.lastSelectedItemNotIntercom || '')),
			tap(() => this.changeDetectorRef.detectChanges())
		).subscribe();
	}

	selectActiveMenuEntry(url: string): void {
		this.menuGroups
			.flat()
			.forEach(group => group.entries.forEach(entry => entry.isSelected = !!entry.link && url.includes(entry.link as string)));

		// set parent selected if any child is selected
		this.menuGroups
			.forEach(group => group.parent ? group.parent.isSelected = group.entries.some(entry => entry.isSelected) : false);
	}

	goTo(url: AppUrl.AppRouteUrl|`https://${string}`|`http://${string}`|undefined): void {
		if (url) {
			if (url.trim().startsWith('https') || url.trim().startsWith('http')) {
				window.open(url, '_blank');
			} else if (url.trim().startsWith(INTERCOM_URL)) {
				this.intercomService.toggle();
			} else {
				this.intercomService.hide();
				this.lastSelectedItemNotIntercom = url as AppRouteUrl;
				this.currentTenantService.getCurrentTenantId()
					.pipe(map(tenantId => ({queryParamsHandling: 'preserve', queryParams: { tenant: tenantId }} as NavigationExtras)))
					.subscribe(extras => this.router.navigate([url], extras));
			}
		}
	}

	closeDropdown() {
		if (this.dropdown) {
			this.dropdown.close()
		}
	}
}
