import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-display-logo',
  templateUrl: './display-logo.component.html',
  styleUrl: './display-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayLogoComponent {
	@Input() logo: string;
	@Input() size: number = 30;
	@Input() loading: boolean = false;
}
