import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {FormControl, ValidatorFn, Validators} from "@angular/forms";
import {deepCopy} from "@angular-devkit/core/src/utils/object";
import {PHONE_REGEXP, URL_REGEXP} from "../../../../utils/forms.utils";
import {Observable} from "rxjs";

@Component({
  	selector: 'app-simple-text-input',
  	templateUrl: './simple-text-input.component.html',
  	styleUrls: ['./simple-text.component.scss']
})
export class SimpleTextInputComponent implements OnChanges {


	@Output() onEmit = new EventEmitter<InputTransaction>();
	@Output() onEmitBlocking = new EventEmitter<boolean>();
	@Output() onValueChange = new EventEmitter<string>();

	@Input() initialValue: string | null | undefined;
	@Input() isEditor: boolean;
	@Input() placeholder: string | undefined;
	@Input() autofocus = false;
	@Input() padding: 'xs' | 'none' = 'none';
	@Input() isUrl = false;
	@Input() required = false;
	@Input() isEmail = false;
	@Input() blockToggle = false;
	@Input() disabled: boolean = false;
	@Input() isPhone = false;
	@Input() clearEvent: Observable<any>;

	input: FormControl;

	@ViewChild('inputElement', {static: true})
	inputElement: ElementRef<HTMLInputElement>;
	constructor() { }

	protected buildValidators() {
		const validators: ValidatorFn[] = []

		if (this.isUrl) {
			validators.push(Validators.pattern(URL_REGEXP));
		}

		if (this.required) {
			validators.push(Validators.required)
		}

		if (this.isEmail) {
			validators.push(Validators.email)
		}

		if (this.isPhone) {
			validators.push(Validators.pattern(PHONE_REGEXP));
		}

		return validators;
	}

	ngOnInit(): void {
		const validators = this.buildValidators();
		this.input = new FormControl(this.initialValue || '', validators);

		if (this.clearEvent) {
			this.clearEvent.subscribe(() => {
				this.input.setValue('');
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {

		const validators = this.buildValidators();

		this.input = new FormControl('', validators);

		if (this.initialValue && changes.initialValue) {
			this.input.setValue(changes.initialValue.currentValue);
		}

		if (this.disabled) {
			this.input.disable()
		}
	}

	inputState = {
		isEditing: false,
		isInside: false,
	};

	get style() {
		return getComputedStyle(this.inputElement.nativeElement, '');
	}


	onQuitEdit() {
		if (this.input.value !== this.initialValue) {
			const previousValue = deepCopy(this.initialValue);
			let value: any;
			if (this.input.value == '') {
				value = null;
			} else {
				value = this.input.value;
			}
			const transaction: InputTransaction = {
				value: deepCopy(value),
				valid: this.input.valid,
				begin: () => {
				},
				commit: () => {
					this.initialValue = deepCopy(value);
				},
				rollback: () => {
					this.input.setValue(previousValue);
				},
				error: errorMessage => {
					this.input.setValue(previousValue);
				}
			};

			this.onEmit.emit(transaction);
		} else {
			if (this.blockToggle) this.onEmitBlocking.emit(false);
		}
	}

	closeInput() {
		this.onQuitEdit()
	}

	keyUpEnter(event: any) {
		event.target.blur();
	}

	@HostListener("click")
	openEdit() {
		this.input.markAsTouched();
		this.inputState.isInside = true;
		if (this.blockToggle) {
			this.onEmitBlocking.emit(true);
		}
	}
}

export interface InputTransaction {
	value: string;
	valid: boolean;
	begin: () => void;
	commit: () => void;
	rollback: () => void;
	error: (errorMessage?: string) => void;
}
