import {AfterViewInit, Component, ContentChildren, Input, QueryList, signal, WritableSignal} from '@angular/core';
import {SelectOptionComponent} from 'src/app/modules/design-system/multi-select/select-option/select-option.component';

@Component({
  selector: 'app-select-option-group',
  templateUrl: './select-option-group.component.html',
  styleUrls: ['../multi-select.component.scss']
})
export class SelectOptionGroupComponent implements AfterViewInit {

  @Input() label: string;
  @Input() disabled: boolean = false;

  private _noOptionPrefix: boolean
  @Input()
  get noOptionPrefix() {
    return this._noOptionPrefix;
  }
  set noOptionPrefix(value: any) {
    this._noOptionPrefix = value != null && `${value}` !== 'false';
  }

  @ContentChildren(SelectOptionComponent) selectOptions: QueryList<SelectOptionComponent>;

  multiple: WritableSignal<boolean> = signal(false);

  ngAfterViewInit() {
    this.selectOptions.forEach((option: SelectOptionComponent) => {
      option.disabled = option.disabled || this.disabled;
      option.prefix.set(!this.noOptionPrefix);
    });
    this.disabled = this.disabled || this.selectOptions.length === 0 || this.selectOptions.toArray().every((option: SelectOptionComponent) => option.disabled);
  }

  get selected(): boolean {
    return this.selectOptions.length > 0
      && this.selectOptions.toArray()
        .filter((option: SelectOptionComponent) => !option.disabled)
        .every((option: SelectOptionComponent) => option.selected());
  }

  get indeterminate(): boolean {
    return this.selectOptions.length > 0
      && !this.selectOptions.toArray()
        .filter((option: SelectOptionComponent) => !option.disabled)
        .every((option: SelectOptionComponent) => option.selected())
      && this.selectOptions.toArray()
        .filter((option: SelectOptionComponent) => !option.disabled)
        .some((option: SelectOptionComponent) => option.selected());
  }

  onSelect(): void {
    const selected: boolean = this.selected;
    this.selectOptions
      .filter((option: SelectOptionComponent) => option.selected() === selected && !option.disabled)
      .forEach((option: SelectOptionComponent) => option.onSelect());
  }
}
