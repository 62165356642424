<app-alert class="general-container">
	<div class="http-check">
		<div class="disp-f a-i-c w-100 flex-space-between c-p" (click)="openGeneral = !openGeneral">
			<div class="font-M fw-700 primary">{{ 'menu.general' | translate }}</div>
			<div class="ml-s">
				<button mat-icon-button  [class.img-close]="!openGeneral" [class.img-open]="openGeneral">
					<img src="assets/icons/arrow-right.svg" width="9px" style="vertical-align: middle" class="mr-xs ml-xs"/>
				</button>
			</div>
		</div>

		<div *ngIf="openGeneral" class="pb-m">
			<div class="mt-s">
				<div class="flex a-i-c" style="flex-direction: column; gap: 10px;">
					<div *ngIf="organization.type === types.BUSINESS_UNIT" class="square-icon-accent" style="height: 67px; width: 67px">
						<img [src]="!!selectedIcon ? ('/assets/icons/team_icon/' + selectedIcon + '.svg') : 'assets/icons/team.svg'" style="height: 45px!important; width: 45px!important;">
					</div>

					<div *ngIf="organization.type === types.AFFILIATE" class="square-icon-accent" style="height: 67px; width: 67px">
						<img src="assets/icons/building.svg" style="height: 45px!important; width: 45px!important;">
					</div>

					<div *ngIf="organization.type === types.BUSINESS_UNIT" class="c-p mb-s mt-s"
                         (click)="iconSelection = !iconSelection">
                        <span class="font-M fw-500 grey text-underline">{{'page.organization.creationPopup.iconSwap' | translate}}</span>
                    </div>
					<div *ngIf="iconSelection" class="iconChoiceWrapper disp-f a-i-c j-c-sb pl-m pr-m pb-l pt-l gap-L">
						<div class="iconChoice disp-f a-i-c j-c-c c-p" [class.selected]="icon == selectedIcon"
                             *ngFor="let icon of icons"
                             (click)="onUpdateIcon(icon)">
							<img [src]="'assets/icons/team_icon/' + icon + '.svg'" class="filter-accent">
						</div>
					</div>
				</div>
				<div class="flex-column gap-XS mt-xl">
					<div class="font-M fw-500 grey">{{'page.organization.creationPopup.name' | translate}}</div>
					<app-simple-text-input class="w-100"
								   [initialValue]="organization.name"
								   [isEditor]="isEditor"
								   (onEmit)="onUpdateName(organization, $event)"
								   [required]="false"
								   [isUrl]="false"
					></app-simple-text-input>
				</div>
                <div class="flex-column gap-XS mt-s">
                    <div class="flex-align-center gap-XS">
                        <div class="font-M fw-500 grey">{{'page.organization.creationPopup.organizationId' | translate}}</div>
                        <img src="assets/icons/help-2.svg" width="12" height="12" alt=""
                             [tooltip]="'page.organization.creationPopup.organizationIdHint' | translate" tooltipPosition="right"/>
                    </div>
                    <app-copy-text class="w-100 font-M fw-500" [text]="organization.organizationId"></app-copy-text>
                </div>
			</div>
		</div>
	</div>
</app-alert>
