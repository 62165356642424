import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {TenantAccountRoleForm, TenantAccountWeeklyReportForm, TenantAccount} from 'src/app/services/model/account.model';

@Injectable()
export class TenantAccountService {

  private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/accounts`;

  constructor(protected httpClient: HttpClient) {
  }

  getTenantAccount(tenantId: string, accountId: string): Observable<TenantAccount|null> {
    return this.httpClient.get<TenantAccount|null>(`${this.serviceUrl(tenantId)}/${accountId}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }

  getAllTenantAccountByTenantId(tenantId: string, filterPending?: boolean): Observable<TenantAccount[]> {
    return this.httpClient.get<TenantAccount[]>(`${this.serviceUrl(tenantId)}`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: filterPending !== undefined ? new HttpParams().set('filterPending', filterPending.toString()) : new HttpParams()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  updateTenantAccountRole(tenantId: string, accountId: string, form: TenantAccountRoleForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${accountId}/role`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  updateTenantAccountWeeklyReport(tenantId: string, accountId: string, form: TenantAccountWeeklyReportForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${accountId}/weekly-report`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  deleteTenantAccount(tenantId: string, accountId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/${accountId}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }
}
