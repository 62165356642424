import {ApplicationCatalog} from 'src/app/services/model/new-application.model';
import {Organization} from 'src/app/services/organization.service';

export interface ApplicationAutodiscover {
  autodiscoverId: string;
  application: ApplicationCatalog;
  dailyUsage: number|null;
  businessUnits: Organization[];
  status: DiscoveredAppStatus;
  totalUniqueUser: number|null;
  fqdns: string[];
  discoveredAt: string;
}

export interface AppCount {
  // TODO @TAN move to other file
  count: number;
}

export interface ApplicationAutodiscoverStatusForm {
  status: DiscoveredAppStatus;
}

export enum OrganizationType {
  // TODO @TAN move to other file
  ORGANIZATION = 'organization',
  AFFILIATE = 'affiliate',
  BUSINESS_UNIT = 'business_unit',
  PROCESS = 'process',
}

export enum DiscoveredAppStatus {
  ACTIVE = 'active',
  ACCEPTED = 'accepted',
  DISMISSED = 'dismissed',
}

export enum OrganizationIcon {
  // TODO @TAN move to other file
  SHARE = 'share',
  CART = 'cart',
  FLASK = 'flask',
  PALETTE = 'palette',
  CHART_PIE = 'chart_pie',
  PRESENT = 'present',
  AWARD = 'award',
  DIAMOND = 'diamond',
  GLOB = 'glob',
  PROCESSOR = 'processor',
  GAMEPAD = 'gamepad',
  BOOKMARK = 'bookmark',
  INVOICE = 'invoice',
  EXPLORE = 'explore',
  MOON = 'moon',
  UNIVERSITY = 'university',
  LIGHTNING = 'lightning',
  CHECKBOX = 'checkbox',
}
