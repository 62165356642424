import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {SecurityUtils} from "./security.utils";
import {map} from "rxjs/operators";

@Injectable()
export class RoverService {
	constructor(protected httpClient: HttpClient) {
	}

	public get(tenantId: string): Observable<RoverSettings | undefined> {
		return this.httpClient.get<{settings: RoverSettings | undefined}>(environment.engineURL + '/tenants/' + tenantId + '/rovers/settings', {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(map(result => result.settings));
	}

	public renew(tenantId: string): Observable<string> {
		return this.httpClient.post<{token: string}>(environment.engineURL + '/tenants/' + tenantId + '/rovers/token/_renew', {},{
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(map(result => result.token));
	}

	public updateSettings(tenantId: string, data: RoverSettingsPayload): Observable<{}> {
		return this.httpClient.put(environment.engineURL + '/tenants/' + tenantId + '/rovers/settings', data, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	public getRoverAuthenticationCredentials(tenantId: string): Observable<RoverAuthenticationCredentials> {
		return this.httpClient.get<RoverAuthenticationCredentials>(environment.engineURL + '/tenants/' + tenantId + '/rovers/authentication-credentials', {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}
}

export interface RoverSettings {
	token: string;
	satisfactionActivated: boolean;
	satisfactionFrequency: SatisfactionFrequency;
}

export interface RoverAuthenticationCredentials {
	token: string;
	structureId: string;
	teamId: string;
}

export type RoverSettingsPayload = Omit<RoverSettings, "token">

export enum SatisfactionFrequency {
	MONTHLY = '1',
	QUARTERLY = '2',
	ANNUALLY = '3'
}
