export class Layer {
	protected objects: Array<()=>void> = [];

	addObject(object: ()=>void): void {
		this.objects.push(object);
	}

	draw(): void {
		this.objects.forEach(object => object());
	}
}

export class Layers {
	protected layers: Map<number, Layer> = new Map<number, Layer>();

	getLayer(weight: number = 0): Layer {
		if (this.layers.has(weight)) {
			return this.layers.get(weight) as Layer;
		} else {
			const layer = new Layer();
			this.layers.set(weight, layer);
			return layer;
		}
	}

	draw(): void {
		new Array(...this.layers.entries()).sort(([a1], [b1]) => a1 > b1 ? 1 : -1).forEach(([_, layer]) => layer.draw());
	}
}
