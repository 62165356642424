import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from "@angular/core";
import {animate, style, transition, trigger} from "@angular/animations";
import {DropdownTriggerDirective} from "./dropdown-trigger.directive";

@Component({
	selector: "app-dropdown",
	templateUrl: "./dropdown.component.html",
	styleUrls: ["./dropdown.component.scss"],
	animations: [
		trigger('in-out-animation', [
			transition(':enter', [
				style({ opacity: 0.01 }),
				animate('300ms ease-in-out', style({ opacity: 1 }))
			]),
			transition(':leave', [
				animate('300ms ease-in-out', style({ display: 'none', opacity: 0 }))
			])
		])
	]
})
export class DropdownComponent {
	@Input() minimumMargin: number = 35;
	@Input() arrow: boolean = true;
	@Input() paddingX: number = 20;
	@Input() paddingY: number = 20;
	@Input() animate: boolean = true;
	@Input() radius: number = 9;
	@Output() closed = new EventEmitter<void>();
	@Output() opened = new EventEmitter<void>();

	translationX: number = 0;

	display = false;
	protected triggerDirective: DropdownTriggerDirective | undefined;

	arrowPosition: number = 0;

	@ViewChild('dropdownContainer', {static: false}) dropdownContainer: ElementRef;

	constructor(private elementRef: ElementRef) {}

	toggle(trigger?: DropdownTriggerDirective) {
		this.triggerDirective = trigger;
		this.display = !this.display;

		this.repositionArrow();

		if (this.display) {
			this.opened.emit()
		} else {
			this.closed.emit()
		}
	}

	private repositionArrow() {
		if (this.triggerDirective) {
			const triggerWidth = this.triggerDirective.getWidth();
			const arrowWidth = 15;
			this.arrowPosition = (triggerWidth / 2) - (arrowWidth / 2);
		}
	}

	onAnimationStart(event: any) {
		if (this.dropdownContainer && this.translationX === 0) {
			this.computePosition();
		}
	}

	protected computePosition() {
		const windowWidth = document.body.getBoundingClientRect().width
		const boxRight = this.dropdownContainer.nativeElement.getBoundingClientRect().right
		const boxWidth = this.dropdownContainer.nativeElement.getBoundingClientRect().width - 50;

		if (windowWidth - boxRight < this.minimumMargin) {
			this.translationX = -Math.min((this.minimumMargin - (windowWidth - boxRight)), boxWidth);
		}
	}

	open(trigger?: DropdownTriggerDirective) {
		this.triggerDirective = trigger;
		this.display = true;
		this.repositionArrow();
		this.opened.emit()
	}

	close(trigger?: DropdownTriggerDirective) {
		this.triggerDirective = trigger;
		this.display = false;
		this.repositionArrow();
		this.closed.emit()
	}

	@HostListener('document:click', ['$event'])
	onGlobalClick(event: PointerEvent): void {
		const rect = this.dropdownContainer?.nativeElement?.getBoundingClientRect();
		const arrowSize: number = 15;
		const clickedInside: boolean = rect
			&& event.clientX > rect.x && event.clientX < (rect.x + rect.width)
			&& event.clientY > (rect.y + arrowSize) && event.clientY < (rect.y + arrowSize + rect.height);
		if (this.display && !clickedInside) {
			if (this.triggerDirective) {
				if (!this.triggerDirective.elementRef.nativeElement.contains(event.target)) {
					this.display = false;
					this.closed.emit();
				}
			} else {
				this.display = false;
				this.closed.emit();
			}
		}
	}
}
