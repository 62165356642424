<div class="bg-rounded pad-M" style="min-height: 300px;">
	<div class="flex-align-center flex-space-between" style="height: 34px;">
		<div class="font-MM fw-700">{{ 'page.appDetails.finance.distributionOfCosts' | translate }}</div>
	</div>
	<div *ngIf="_initializing || _loading" style="height: 280px;">
		<list-content-loader></list-content-loader>
	</div>
	<div *ngIf="!_initializing && !_loading" class="flex-column flex-justify-center gap-M pl-xl pr-xl" style="height: 280px;">
		<div class="flex-center w-100" style="gap: 70px;" *ngIf="costs.total > 0">
			<!-- Doughnut with data -->
			<div>
				<div class="disp-b" >
					<canvas baseChart style="width: 250px; height: 250px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
				</div>
				<div class="doughnut-center">
					<div class="doughnut-flex">
						<div class="doughnut-total">
                            {{costs.total|cost}}
						</div>
						<div class="doughnut-label">
							{{ 'page.appDetails.finance.contracts.totalCostForYear' | translate:{"year": year } }}
						</div>
					</div>
				</div>
			</div>
			<div class="flex-column flex-justify-center w-100 h-100 gap-XXL">
				<div class="flex-column" *ngIf="costs.licensePercentage > 0">
					<div class="flex-space-between mb-xs">
						<div class="font-M fw-500">{{'page.appDetails.license'|translate}}</div>
						<div class="font-M fw-700">{{ costs.licensePercentage }}%</div>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
						<div class="progression-licence" [ngStyle]="{ 'width': costs.licensePercentage+'%' }"></div>
					</div>
				</div>
				<div class="flex-column" *ngIf="costs.servicePercentage > 0">
					<div class="flex-space-between">
						<div class="font-M fw-500 mb-xs">{{ 'page.appDetails.finance.contracts.service' | translate }}</div>
						<div class="font-M fw-700">{{ costs.servicePercentage }}%</div>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
						<div class="progression-services" [ngStyle]="{ 'width': costs.servicePercentage+'%' }"></div>
					</div>
				</div>
				<div class="flex-column" *ngIf="costs.licensePercentage <= 0 && costs.total > 0">
					<div class="flex-space-between mb-xs">
						<div class="font-M fw-500">{{'page.appDetails.license'|translate}}</div>
						<div class="font-M fw-700">0%</div>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
						<div class="progression-licence"></div>
					</div>
				</div>
				<div class="flex-column" *ngIf="costs.servicePercentage <= 0 && costs.total > 0">
					<div class="flex-space-between">
						<div class="font-M fw-500 mb-xs">{{ 'page.appDetails.finance.contracts.service' | translate }}</div>
						<div class="font-M fw-700">0%</div>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
						<div class="progression-services"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-center w-100" style="gap: 70px;" *ngIf="costs.total <= 0">
			<!-- Doughnut with data -->
			<div>
				<div class="disp-b" >
					<canvas baseChart style="width: 250px; height: 250px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
				</div>
				<div class="doughnut-center">
					<div class="doughnut-flex">
						<div class="font-XL fw-700 text-center grey">-</div>
						<div class="font-S fw-700 text-center">
							{{ 'page.appDetails.finance.contracts.totalCostForYear' | translate:{"year": year } }}
						</div>
					</div>
				</div>
			</div>
			<div class="flex-column flex-justify-center w-100 h-100 gap-XXL">
				<div class="flex-column">
					<div class="flex-space-between mb-xs">
						<div class="font-M fw-500">{{'page.appDetails.license'|translate}}</div>
						<div class="font-M fw-700">- %</div>
					</div>
					<div class="no-data-bar w-100"></div>
				</div>
				<div class="flex-column">
					<div class="flex-space-between">
						<div class="font-M fw-500 mb-xs">{{ 'page.appDetails.finance.contracts.service' | translate }}</div>
						<div class="font-M fw-700">- %</div>
					</div>
					<div class="no-data-bar w-100"></div>
				</div>
			</div>
		</div>
	</div>
</div>
