import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FilterComponent} from "./filter.component";
import {DeckContentLoaderModule} from "../title-content-loader/deck-content-loader.module";
import {MatLegacyProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {TranslateModule} from "@ngx-translate/core";
import {DesignSystemModule} from "../../design-system/design-system.module";

@NgModule({
  declarations: [
	  FilterComponent
  ],
  imports: [
    CommonModule,
    DeckContentLoaderModule,
    MatLegacyProgressSpinnerModule,
    TranslateModule,
    DesignSystemModule
  ],
	exports: [
		FilterComponent
	]
})
export class FilterModule { }
