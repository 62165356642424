import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {InfrastructureOverview, ServerOverview, MetricAverage, ServerAgent, ServerForm} from 'src/app/services/model/resource.model';
import {ApplicationHealth} from 'src/app/services/model/new-application.model';

@Injectable()
export class ServerService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/resources`;

	private infraUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/infrastructures`;

	constructor(protected httpClient: HttpClient) {
	}

	deleteServer(tenantId: string, resourceId: string, isManual: boolean): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/${resourceId}`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: new HttpParams().append('manual', isManual)
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	getAllServersByTenantId(tenantId: string, fullRepresentation = false, disableMetrics = true): Observable<ServerOverview[]> {
		return this.httpClient.get<ServerOverview[]>(`${this.serviceUrl(tenantId)}/compute-list`, {
			headers: SecurityUtils.getAuthHttpHeaders(),
			params: (fullRepresentation ? new HttpParams().append('format', 'full-representation') : new HttpParams()).append('disableMetrics', disableMetrics)
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}
	
	getAllApplicationHealthByResourceId(tenantId: string, resourceId: string): Observable<ApplicationHealth[]> {
		return this.httpClient.get<ApplicationHealth[]>(`${this.serviceUrl(tenantId)}/${resourceId}/application-list`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	getServerMetrics(tenantId: string, infrastructureId: string, os: string): Observable<InfrastructureOverview> {
		return this.httpClient.get<any>(`${this.infraUrl(tenantId)}/${infrastructureId}/${os}/metrics`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getTenantMonthlyAverageMetrics(tenantId: string): Observable<MetricAverage> {
		return this.httpClient.get<MetricAverage>(`${this.infraUrl(tenantId)}/monthly-average`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	getServerAgentId(tenantId: string, resourceId: string): Observable<ServerAgent> {
		return this.httpClient.get<ServerAgent>(`${this.serviceUrl(tenantId)}/${resourceId}/agent`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	createManualServer(tenantId: string, body: ServerForm): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.serviceUrl(tenantId)}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	updateManualServer(tenantId: string, resourceId: string, body: ServerForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${resourceId}`, body, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}
}
