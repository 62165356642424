import {Point, Rectangle, Vector} from "./diagram";

export class PointUtils {

	static isLeftOf(point1: Point, point2: Point): boolean {
		return point1.x > point2.x
	}

	static isVerticallyAligned(point1: Point, point2: Point): boolean {
		return point1.x === point2.x
	}

	static isHorizontallyAligned(point1: Point, point2: Point): boolean {
		return point1.y === point2.y
	}

	static isRightOf(point1: Point, point2: Point): boolean {
		return point1.x < point2.x
	}

	static isAboveOf(point1: Point, point2: Point): boolean {
		return point1.y < point2.y
	}

	static isBelowOf(point1: Point, point2: Point): boolean {
		return point1.y > point2.y
	}
}

export class RectangleUtils {
	static lineIntersectsRectangle(line: Vector, rectangle: Rectangle): boolean {
		const { topLeft, bottomRight } = rectangle;
		const { start, end } = line;

		// Vérifie si la droite traverse le rectangle en comparant les coordonnées x et y
		if (
			(start.x <= topLeft.x && end.x <= topLeft.x) && // Tous les points de la droite sont à gauche du rectangle
			(start.x >= bottomRight.x && end.x >= bottomRight.x) && // Tous les points de la droite sont à droite du rectangle
			(start.y <= topLeft.y && end.y <= topLeft.y) && // Tous les points de la droite sont au-dessus du rectangle
			(start.y >= bottomRight.y && end.y > bottomRight.y) // Tous les points de la droite sont en-dessous du rectangle
		) {
			return true;
		} else {
			return false;
		}
	}

	static isLineOutsideRectangle(line: Vector, rect: Rectangle): boolean {
		// Vérifier si les deux points de la ligne sont à l'extérieur des côtés du rectangle
		const { topLeft, bottomRight } = rect;
		const x1 = line.start.x;
		const y1 = line.start.y;
		const x2 = line.end.x;
		const y2 = line.end.y;

		return (
			(x1 < topLeft.x && x2 < topLeft.x) || // à gauche
			(x1 > bottomRight.x && x2 > bottomRight.x) || // à droite
			(y1 < topLeft.y && y2 < topLeft.y) || // en haut
			(y1 > bottomRight.y && y2 > bottomRight.y) // en bas
		);
	}

	static isSegmentInsideRectangle(segment: Vector, rectangle: Rectangle): boolean {
		const xMin = Math.min(rectangle.topLeft.x, rectangle.bottomRight.x);
		const xMax = Math.max(rectangle.topLeft.x, rectangle.bottomRight.x);
		const yMin = Math.min(rectangle.topLeft.y, rectangle.bottomRight.y);
		const yMax = Math.max(rectangle.topLeft.y, rectangle.bottomRight.y);

		return (
			segment.start.x >= xMin &&
			segment.start.x <= xMax &&
			segment.start.y >= yMin &&
			segment.start.y <= yMax &&
			segment.end.x >= xMin &&
			segment.end.x <= xMax &&
			segment.end.y >= yMin &&
			segment.end.y <= yMax
		);
	}

	static isPointInsideRectangle(point: Point, rectangle: Rectangle): boolean {
		const xMin = Math.min(rectangle.topLeft.x, rectangle.bottomRight.x);
		const xMax = Math.max(rectangle.topLeft.x, rectangle.bottomRight.x);
		const yMin = Math.min(rectangle.topLeft.y, rectangle.bottomRight.y);
		const yMax = Math.max(rectangle.topLeft.y, rectangle.bottomRight.y);

		return point.x >= xMin && point.x <= xMax && point.y >= yMin && point.y <= yMax;
	}

	static doSegmentsIntersect(s1: Vector, s2: Vector): boolean {
		const { start: p1, end: p2 } = s1;
		const { start: q1, end: q2 } = s2;
		const det = (p2.x - p1.x) * (q2.y - q1.y) - (q2.x - q1.x) * (p2.y - p1.y);
		if (det === 0) {
			return false;
		} else {
			const lambda = ((q2.y - q1.y) * (q2.x - p1.x) + (q1.x - q2.x) * (q2.y - p1.y)) / det;
			const gamma = ((p1.y - p2.y) * (q2.x - p1.x) + (p2.x - p1.x) * (q2.y - p1.y)) / det;
			return lambda > 0 && lambda < 1 && gamma > 0 && gamma < 1;
		}
	}

	static doesSegmentIntersectRectangle(segment: Vector, rectangle: Rectangle): boolean {
		const { topLeft, bottomRight } = rectangle;
		const topRight = { x: bottomRight.x, y: topLeft.y };
		const bottomLeft = { x: topLeft.x, y: bottomRight.y };
		const left = { start: topLeft, end: bottomLeft, direction: 270 };
		const top = { start: topLeft, end: topRight, direction: 0 };
		const right = { start: topRight, end: bottomRight, direction: 270 };
		const bottom = { start: bottomLeft, end: bottomRight, direction: 0};
		return (
			RectangleUtils.isSegmentInsideRectangle(segment, rectangle) ||
			RectangleUtils.isPointInsideRectangle(segment.start, rectangle) ||
			RectangleUtils.isPointInsideRectangle(segment.end, rectangle) ||
			RectangleUtils.doSegmentsIntersect(segment, left) ||
			RectangleUtils.doSegmentsIntersect(segment, top) ||
			RectangleUtils.doSegmentsIntersect(segment, right) ||
			RectangleUtils.doSegmentsIntersect(segment, bottom)
		);
	}
}

export class VectorUtils {
	static getMagnitude(line: Vector): number {
		const { start, end } = line;
		return Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2));
	}
}
