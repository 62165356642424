import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {ApplicationContract, ApplicationContractDeadline, ApplicationCostUsage, ContractApplicationCategoryCost, ContractMonthlyCost, ContractOrganizationCost, Cost} from 'src/app/services/model/application-contract.model';

@Injectable()
export class TenantFinanceService {

  private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/finances`;

  constructor(protected httpClient: HttpClient) {
  }

  getTenantContractCount(tenantId: string): Observable<number> {
    return this.httpClient.get<number>(`${this.serviceUrl(tenantId)}/contract-count`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, 0)));
  }

  getAllApplicationContract(tenantId: string, organizationId?: string): Observable<ApplicationContract[]> {
    let params: HttpParams = new HttpParams();
    if (organizationId) params = params.append("organizationId", organizationId);
    return this.httpClient.get<number>(`${this.serviceUrl(tenantId)}/contracts`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, 0)));
  }

  getAllSoonExpiredApplicationContract(tenantId: string, organizationId?: string, limit?: number): Observable<ApplicationContractDeadline[]> {
    let params: HttpParams = new HttpParams();
    if (organizationId) params = params.append("organizationId", organizationId);
    if (limit) params = params.append('limit', limit);
    return this.httpClient.get<ApplicationContractDeadline[]>(`${this.serviceUrl(tenantId)}/expiring-contracts`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllMostExpensiveApplicationCost(tenantId: string, organizationId?: string, limit?: number, costRange?: QueryRangeType, elasticRange?: QueryRangeType): Observable<ApplicationCostUsage[]> {
    let params: HttpParams = new HttpParams();
    if (organizationId) params = params.append('organizationId', organizationId);
    if (costRange) params = params.append('costRange', costRange);
    if (elasticRange) params = params.append('elasticRange', elasticRange);
    if (limit) params = params.append('limit', limit);
    return this.httpClient.get<ApplicationCostUsage[]>(`${this.serviceUrl(tenantId)}/most-expensive-apps`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllContractOrganizationCost(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<ContractOrganizationCost[]> {
    let params: HttpParams = new HttpParams();
    if (organizationId) params = params.append("organizationId", organizationId);
    if (range) params = params.append('range', range);
    return this.httpClient.get<ContractOrganizationCost[]>(`${this.serviceUrl(tenantId)}/cost-by-organization`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllContractApplicationCategoryCost(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<ContractApplicationCategoryCost[]> {
    let params: HttpParams = new HttpParams();
    if (organizationId) params = params.append("organizationId", organizationId);
    if (range) params = params.append('range', range);
    return this.httpClient.get<ContractApplicationCategoryCost[]>(`${this.serviceUrl(tenantId)}/cost-by-category`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllContractMonthlyCostByTenantId(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<ContractMonthlyCost[]> {
    let params: HttpParams = new HttpParams();
    if (organizationId) params = params.append('organizationId', organizationId);
    if (range) params = params.append('range', range);
    return this.httpClient.get<ContractMonthlyCost[]>(`${this.serviceUrl(tenantId)}/cost-by-month`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getContractTotalCostByTenantId(tenantId: string, organizationId?: string, range?: QueryRangeType): Observable<Cost> {
    let params: HttpParams = new HttpParams();
    if (organizationId) params = params.append('organizationId', organizationId);
    if (range) params = params.append('range', range);
    return this.httpClient.get<Cost>(`${this.serviceUrl(tenantId)}/cost-total`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }
}

export enum QueryRangeType {
  CURRENT_MONTH = 'current_month',
  CURRENT_YEAR = 'current_year',
  PAST_1_YEAR = 'past_1_year',
  PAST_1_MONTH = 'past_1_month',
  PAST_1_WEEK = 'past_1_week',
  PAST_1_DAY = 'past_1_day',
  PROJECTION_1_YEAR = 'projection_1_year',
  PROJECTION_3_YEAR = 'projection_3_year',
  PROJECTION_10_YEAR = 'projection_10_year'
}
