<app-chips [size]="'n'" [color]="getTendencyColor()">
	<div class="disp-f a-i-c j-c-c f-d-r pl-xs pr-xs">
		<div class="mr-xxs">
			<img src="assets/icons/tendency_arrow.svg" [class]="getArrowFilter()" [class.rotated]="numericTendency != null && this.numericTendency < 0 && this.getAbsValue(this.numericTendency) != '0' && this.getAbsValue(this.numericTendency) != '-'" [class.half-rotated]="numericTendency != null && this.getAbsValue(this.numericTendency) == '0'">
		</div>
		<div [class.grey]="getTendencyColor() == 'secondary'">
			{{getAbsValue(numericTendency)!= '-' ? getAbsValue(numericTendency) + '%' : '-' }}
		</div>
	</div>
</app-chips>
