<app-snackbar></app-snackbar>

<div *ngIf="!tenant?.plan?.isTrialPlan() && !tenant?.plan?.isActivePlan()" class="pl-l pr-l mt-m">
	<app-chips color="error">{{'page.dashboard.limited.badge' | translate}}</app-chips>
	<div class="font-S fw-500 grey mt-xs">{{'page.dashboard.limited.headline' | translate}}</div>
	<div class="font-S fw-500 grey">{{'page.dashboard.limited.link.0' | translate}} <a [routerLink]="[settingsBillingUrl]" [queryParams]="activatedRoute.snapshot.queryParams"> {{'page.dashboard.limited.link.1' | translate}} </a> {{'page.dashboard.limited.link.2' | translate}}</div>
</div>

<div class="main-container pt-xl pb-l">
	<div class="ml-xl disp-g-3 mr-xl semi-responsive" style="grid-column-gap: 20px; grid-row-gap: 20px">
		<div style="grid-column: span 8;">
			<app-filter [organizationTree]="organizationTree"
                        [filtersLoading]="_initializing || _loadingOrganization"
                        (changes)="refreshWithFilters($event)">
			</app-filter>
		</div>
		<!-- Applications widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="applicationWidget" [responsive]="true"></app-mini-widget>
		</div>
		<!-- Users widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="usersWidget" [responsive]="true"></app-mini-widget>
		</div>
		<!-- Providers widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="providerWidget" [responsive]="true"></app-mini-widget>
		</div>
		<!-- TCO widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="tcoWidget" [responsive]="true"></app-mini-widget>
		</div>
		<!-- Health graph -->
		<div class="widget" style="grid-column: span 5;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-dashboard-page-loader *ngIf="_initializing || _loadingOrganization || _loading"></app-dashboard-page-loader>
			<app-health-graph *ngIf="!_initializing && !_loadingOrganization && !_loading" [data]="healthList" [height]="300"></app-health-graph>
		</div>
		<!-- Worst performance apps -->
		<div class="widget" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-most-impacted-apps [filter]="filter" [limit]="6"></app-most-impacted-apps>
		</div>
		<!-- Performance widget -->
		<div class="widget widget-semi" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-performance-repartition-doughnut [filter]="filter"></app-performance-repartition-doughnut>
		</div>
		<div class="widget widget-semi" style="grid-column: span 2;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<infrastructure-widget></infrastructure-widget>
		</div>
		<div class="widget widget-semi" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-worst-performance-apps [filter]="filter" [limit]="5"></app-worst-performance-apps>
		</div>
	</div>
</div>
