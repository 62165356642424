<div class="flex delete-hover">
	<div class="flex-align-center flex-space-between radius-M pad-XS-0 pad-left-XS pad-right-M border-light-grey">
        <app-application-icon [logo]="technology.logo"></app-application-icon>
		<div class="flex" style="max-width: 200px; margin-left: 10px; margin-right: 30px;">
			<span class="font-M fw-500 text-overflow-ellipsis">{{technology.name}}</span>
		</div>
		<div class="flex-justify-end">
			<span class="font-M fw-500 grey">{{version?.name || '-'}}</span>
		</div>
	</div>
	<app-icon-button *ngIf="!!delete" color="danger" shape="round" class="delete-btn h-100" size="xs" [panelClass]="'border-grey bkg-white'"
					 [img]="'assets/icons/trash.svg'" [disabled]="disabled"
					 (click)="delete(technology.technologyId)">
	</app-icon-button>
</div>
