import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {ApplicationContractForm, Contract, ContractInfo, ContractTypeCost} from 'src/app/services/model/application-contract.model';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Injectable()
export class ApplicationContractService {

  private serviceUrl = (tenantId: string, applicationId: string) => `${environment.engineURL}/tenants/${tenantId}/applications/${applicationId}/contracts`;

  constructor(protected httpClient: HttpClient) {
  }

  getAllApplicationContract(tenantId: string, applicationId: string): Observable<Contract[]> {
    return this.httpClient.get<ContractInfo[]>(`${this.serviceUrl(tenantId, applicationId)}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllApplicationContractInfo(tenantId: string, applicationId: string): Observable<ContractInfo[]> {
    return this.httpClient.get<ContractInfo[]>(`${this.serviceUrl(tenantId, applicationId)}/infos`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  getAllApplicationContractTypeCost(tenantId: string, applicationId: string, range?: QueryRangeType): Observable<ContractTypeCost[]> {
    let params: HttpParams = new HttpParams();
    if (range) params = params.append('range', range);
    return this.httpClient.get<ContractTypeCost[]>(`${this.serviceUrl(tenantId, applicationId)}/cost-by-type`, {
      headers: SecurityUtils.getAuthHttpHeaders(),
      params: params
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  createApplicationContract(tenantId: string, applicationId: string, form: ApplicationContractForm): Observable<string> {
    return this.httpClient.post<string>(`${this.serviceUrl(tenantId, applicationId)}`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }

  updateApplicationContract(tenantId: string, applicationId: string, contractId: string, form: ApplicationContractForm): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId, applicationId)}/${contractId}`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }

  deleteContract(tenantId: string, applicationId: string, contractId: string): Observable<boolean> {
    return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId, applicationId)}/${contractId}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
  }
}
