import {Component, Input, OnInit} from '@angular/core';
import {Organization, OrganizationService, UpdateOrganizationIconForm, UpdateOrganizationNameForm} from "src/app/services/organization.service";
import {mergeMap} from "rxjs/operators";
import {TextInputTransaction} from "../../../global/input/text-input/editable-text-input.component";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {OrganizationIcon, OrganizationType} from 'src/app/services/model/autodiscover.model';

@Component({
  selector: 'app-organization-setting',
  templateUrl: './organization-setting.component.html',
  styleUrls: ['./organization-setting.component.scss']
})
export class OrganizationSettingComponent implements OnInit {

	@Input() isEditor: boolean = false;
	@Input() organization: Organization;
	@Input() settings: number = 0;

	icons: string[] = Object.values(OrganizationIcon);
	selectedIcon: OrganizationIcon|null;
	iconSelection: boolean = false
	openGeneral: boolean = false;
	types: typeof OrganizationType = OrganizationType;

	constructor(protected organizationService: OrganizationService,
							protected currentTenantService: CurrentTenantService) {
	}

	ngOnInit(): void {
		this.selectedIcon = this.organization.icon;
		this.openGeneral = [1].includes(this.settings);
	}

	onUpdateName(organization: Organization, nameTransaction: TextInputTransaction) {
		if (nameTransaction.value?.trim() !== organization.name) {
			if (nameTransaction.value && nameTransaction.value.trim().length > 0) {
				nameTransaction.begin();

				const form: UpdateOrganizationNameForm = {
					name: nameTransaction.value
				};
				this.currentTenantService.getCurrentTenantId()
					.pipe(mergeMap(tenantId => this.organizationService.updateName(tenantId, organization.organizationId, form)))
					.subscribe( {
						next: () => {
							nameTransaction.commit();
							organization.name = nameTransaction.value;
						},
						error: () => nameTransaction.error()
					});
			} else {
				nameTransaction.rollback();
			}
		}
	}

	onUpdateIcon(icon: string): void {
		this.selectedIcon = this.selectedIcon === icon ? null : icon as OrganizationIcon;
		if (this.selectedIcon != this.organization.icon) {
			const form: UpdateOrganizationIconForm = {
				icon: this.selectedIcon
			};
			this.currentTenantService.getCurrentTenantId()
				.pipe(mergeMap(tenantId => this.organizationService.updateIcon(tenantId, this.organization.organizationId, form)))
				.subscribe({
					next: () => this.organization.icon = this.selectedIcon,
					error: () => this.selectedIcon = this.organization.icon
				});
		}
	}
}
