<div class="tags disp-if a-i-c j-c-c" (mouseenter)="onHover = true" (mouseleave)="onHover = false" [attr.color]="color">
	<mat-icon *ngIf="(canBeDeleted && !onHover)" class="icon-12 ml-xs">
	</mat-icon>
	<mat-icon *ngIf="icon === 'add'" class="icon-12 ml-xs">
		add
	</mat-icon>
	<mat-icon *ngIf="canBeDeleted && onHover" (click)="onDelete.emit()" class="c-p icon-12 ml-xs">
		cancel
	</mat-icon>

	<div [class.ml-s]="!canBeDeleted && icon !== 'add'" [class.mr-s]="!canBeDeleted" [class.ml-xs]="canBeDeleted && icon !== 'add'"  [class.mr-l]="canBeDeleted" [ngClass]="getSizeClass()">
		<ng-content></ng-content>
	</div>
</div>
