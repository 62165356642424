export interface CatalogTag {
  tagId: string;
  name: string;
  color: TagColor;
  tenantId: string|null;
}

export interface CatalogTagForm {
  name: string;
  color: TagColor;
}

export enum TagColor {
  BRONZE = 'bronze',
  GOLD = 'gold',
  HIGH = 'high',
  HIGH_PRIORITY = 'high-priority',
  INTEGRATION = 'integration',
  LOW = 'low',
  LOW_PRIORITY = 'low-priority',
  MEDIUM = 'medium',
  MEDIUM_PRIORITY = 'medium-priority',
  PRODUCTION = 'production',
  SECONDARY = 'secondary',
  SILVER = 'silver',
  STAGING = 'staging',
}
