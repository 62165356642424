import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TenantComponent} from "./tenant.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatDividerModule} from "@angular/material/divider";
import {ChipsModule} from "../../../global/chips/chips/chips.module";
import {ReactiveFormsModule} from "@angular/forms";
import {UsersModule} from "../users/users.module";
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {CopyTextModule} from "../../../global/input/copy-text/copy-text.module";

@NgModule({
	declarations: [
		TenantComponent,
	],
	imports: [
		CommonModule,
		TranslateModule,
		MatDividerModule,
		ChipsModule,
		ReactiveFormsModule,
		UsersModule,
		DesignSystemModule,
		CopyTextModule,
	],
	exports: [
		TenantComponent
	]
})
export class TenantModule {
}
