import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {SseClient} from "ngx-sse-client";
import {Observable, takeWhile} from "rxjs";
import {environment} from "src/environments/environment";
import {SecurityUtils} from "src/app/services/security.utils";
import {map} from "rxjs/operators";
import {ApplicationAutodiscover, ApplicationAutodiscoverStatusForm} from 'src/app/services/model/autodiscover.model';

@Injectable()
export class ApplicationAutodiscoverService {

	serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/applications/autodiscover`;

	constructor(protected httpClient: HttpClient,
							protected translateService: TranslateService,
							protected activatedRoute: ActivatedRoute,
							protected sse: SseClient,
							protected router: Router) {
	}

	testLiveAutodiscover(tenantId: string): Observable<ApplicationAutodiscover> {
		return this.sse.stream(`${this.serviceUrl(tenantId)}/live`, { responseType:'event', keepAlive: false}, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(takeWhile(event => event.type === 'message'), map((event: any) => JSON.parse(event.data) as ApplicationAutodiscover));
	}

	getAllApplicationAutodiscover(tenantId: string): Observable<ApplicationAutodiscover[]> {
		return this.httpClient.get<ApplicationAutodiscover[]>(`${this.serviceUrl(tenantId)}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		});
	}

	updateApplicationAutodiscoverStatus(tenantId: string, autodiscoverId: string, form: ApplicationAutodiscoverStatusForm): Observable<any> {
		return this.httpClient.post(`${this.serviceUrl(tenantId)}/${autodiscoverId}`, form,{
			headers: SecurityUtils.getAuthHttpHeaders()
		})
	}
}
