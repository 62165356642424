import {ApplicationGroup} from "./application-group";

export interface GroupMap {
	groups: ApplicationGroup[],
	width: number,
	height: number
}

export class ApplicationGroupOrganizer {
	private static readonly GROUP_VERTICAL_COLUMN_MARGIN = 50
	private static readonly GROUP_HORIZONTAL_COLUMN_MARGIN = 180
	private static readonly GROUP_HORIZONTAL_COLUMN_MARGIN_FOLDED = 36

	organize(applicationGroups: ApplicationGroup[], isFolded = true): GroupMap {
		const columns: {height: number, groups: ApplicationGroup[]}[] = [{height: 0, groups:[]}, {height: 0, groups:[]}, {height: 0, groups:[]}]
		const sortedApplicationGroups = applicationGroups.sort((a,b) => b.getHeight() - a.getHeight())

		sortedApplicationGroups.forEach((applicationGroup) => {
			let hasFoundColumn = false
			for (let column of columns) {
				if (column.groups.length === 0) {
					column.height = applicationGroup.getHeight() + ApplicationGroupOrganizer.GROUP_VERTICAL_COLUMN_MARGIN;
					column.groups.push(applicationGroup)
					hasFoundColumn = true
					break
				}
			}

			if (!hasFoundColumn) {
				const highestColumn = columns.reduce((prev, current) => (prev.height > current.height) ? prev : current)
				const orderedColumns = columns.sort((a,b) => a.height - b.height)
				for (let column of orderedColumns) {
					if (column.height + applicationGroup.getHeight() <= highestColumn.height) {
						column.height += (applicationGroup.getHeight() + ApplicationGroupOrganizer.GROUP_VERTICAL_COLUMN_MARGIN);
						column.groups.push(applicationGroup)
						hasFoundColumn = true
						break
					}
				}

				// If we still haven't found a column, try to extends the smallest
				if (!hasFoundColumn) {
					// Get smallest column
					const smallestColumn = columns.reduce((prev, current) => (prev.height < current.height) ? prev : current)

					// If it does not break golden number (1.6), extend it
					const height = smallestColumn.height
					const width = columns.length * applicationGroup.getWidth();
					const ratio = width / height;

					if (ratio > 1.6) {
						smallestColumn.height += (applicationGroup.getHeight() + ApplicationGroupOrganizer.GROUP_VERTICAL_COLUMN_MARGIN);
						smallestColumn.groups.push(applicationGroup)
					} else {
						columns.push({height: applicationGroup.getHeight() + ApplicationGroupOrganizer.GROUP_VERTICAL_COLUMN_MARGIN, groups: [applicationGroup]});
					}
				}
			}
		});

		const highestColumns = columns.reduce((prev, current) => (prev.height > current.height) ? prev : current)
		const height = highestColumns.height + ApplicationGroupOrganizer.GROUP_VERTICAL_COLUMN_MARGIN * highestColumns.groups.length;
		const width = columns.filter(c => c.height !== 0 ).length * ((applicationGroups[0]?.getWidth() ?? 1) + (isFolded ? ApplicationGroupOrganizer.GROUP_HORIZONTAL_COLUMN_MARGIN_FOLDED : ApplicationGroupOrganizer.GROUP_HORIZONTAL_COLUMN_MARGIN));

		const groups = columns.flatMap((column, indexColumn) => {
			let columnHeightCursor = 0;
			const groupHMargin = (isFolded ? ApplicationGroupOrganizer.GROUP_HORIZONTAL_COLUMN_MARGIN_FOLDED : ApplicationGroupOrganizer.GROUP_HORIZONTAL_COLUMN_MARGIN)

			column.groups.forEach((group, indexGroup) => {
				const x = indexColumn === 0
					? 25
					: indexColumn * group.getWidth() + groupHMargin * indexColumn + 25;

				const y = indexGroup === 0
					? 30
					: columnHeightCursor + 30

				group.moveTo({x: x, y: y });
				columnHeightCursor += group.getHeight() + ApplicationGroupOrganizer.GROUP_VERTICAL_COLUMN_MARGIN;
			})
			return column.groups
		})

		return {groups, width, height }
	}
}
