<div class="w-100 disp-f f-d-col" style="height: 230px">
	<div class="gaugeWrapper" style="height: calc(230px - 1.2rem)">
		<div *ngFor="let nb of [1,2,3]" class="gaugeLine w-100"></div>
		<div class="gaugeContent" [ngClass]="'chips-' + getGaugeColor(percent)" [style.height.%]="percent" *ngIf="percent != null">
			<img [src]="'/assets/icons/'+getGaugeIcon(percent)" [classList]="['filter-' + getGaugeColor(percent)]" *ngIf="percent >= 15">
		</div>
	</div>
	<div class="font-M fw-700 disp-f f-d-r j-c-sb a-i-c mt-xs">
		<div>{{legend | translate}}</div>
		<div [ngClass]="getGaugeColor(percent)">{{ (percent != null ? (percent | number: '1.0-0') + '%' : '-') }}</div>
	</div>
</div>
