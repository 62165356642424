export class DateUtils {
	public static secondsToDhms(seconds: number | undefined): string {
		if (seconds) {
			seconds = Number(seconds);
			const d = Math.floor(seconds / (3600*24));
			const h = Math.floor(seconds % (3600*24) / 3600);
			const m = Math.floor(seconds % 3600 / 60);
			const s = Math.floor(seconds % 60);

			const dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
			const hDisplay = h > 0 ? h + (h == 1 ? " h, " : " h, ") : "";
			const mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min, ") : "";
			const sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
			return dDisplay + hDisplay + mDisplay + sDisplay;
		} else {
			return "";
		}
	}

	public static secondsOrDhms(seconds: number | undefined): string  {
		if (seconds) {
			seconds = Number(seconds);
			const d = Math.floor(seconds / (3600*24));
			const h = Math.floor(seconds % (3600*24) / 3600);
			const m = Math.floor(seconds % 3600 / 60);
			const s = Math.floor(seconds % 60);

			const dDisplay = d > 0 ? d + (d == 1 ? " day" : " days") : null;
			const hDisplay = h > 0 ? h + (h == 1 ? " h" : " h") : null;
			const mDisplay = m > 0 ? m + (m == 1 ? " min" : " min") : null;
			const sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : null;
			return dDisplay || hDisplay || mDisplay || sDisplay || "";
		} else {
			return "";
		}
	}

	public static toSecondUTC(date: Date) {
		return  date.getTime() + (date.getTimezoneOffset() * 60000);
	}

	public static hourDayWeekOrMonth(seconds: number | undefined): DateTranslateRef|null  {
		if (seconds) {
			seconds = Number(seconds);
			const m: number = Math.floor(seconds / (3600*24*30));
			const w: number = Math.floor(seconds / (3600*24*7));
			const d: number = Math.floor(seconds / (3600*24));
			const h: number = Math.floor(seconds / (3600));

			const mDisplay: DateTranslateRef|null = m > 0 ? {value: m, translateRef:(m == 1 ? 'month' : 'months')} : null;
			const wDisplay: DateTranslateRef|null = w > 0 ? {value: w, translateRef:(w == 1 ? 'week' : 'weeks')} : null;
			const dDisplay: DateTranslateRef|null = d > 0 ? {value: d, translateRef:(d == 1 ? 'day' : 'days')} : null;
			const hDisplay: DateTranslateRef|null = h > 0 ? {value: h, translateRef:(h == 1 ? 'hour' : 'hours')} : null;

			return mDisplay || wDisplay || dDisplay || hDisplay || {value: 0, translateRef: 'instant'};
		} else {
			return null;
		}
	}

	public static getDateTranslateRef(date: string): DateTranslateRef|null  {
		const localOffset: number = new Date().getTimezoneOffset() * 60 * 1000;
		const gapMillis: number = new Date().getTime() - (new Date(date).getTime() - localOffset);
		const gapSeconds: number = Math.floor(gapMillis / 1000);
		return DateUtils.hourDayWeekOrMonth(gapSeconds);
	}
}

export interface DateTranslateRef {
	value: number | undefined;
	translateRef: 'month' | 'months' | 'week' | 'weeks' | 'day' | 'days' | 'hour' | 'hours' | 'instant';
}
