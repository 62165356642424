<app-snackbar></app-snackbar>
<div class="pl-l pb-l pr-l pt-l">
	<form [formGroup]="announcementForm">
		<div style="height: 100%; width: 75vw">
			<!-- Title -->
			<div class="disp-f j-c-sb a-i-c">
				<div class="disp-f a-i-c j-c-c">
					<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10" style="height: 45px; width: 45px;">
						<img ngSrc="assets/icons/bullhorn.svg" class="svg-24" alt="" height="24" width="24">
					</div>
					<div class="ml-s flex-column gap-XS">
						<div class="font-L fw-700">{{ 'global.announcements.deployAnAnnouncement' | translate }}</div>
						<div class="font-M fw-300">{{ 'global.announcements.keepYourUserInformed' | translate }}</div>
					</div>
				</div>
				<!-- Close button -->
				<app-icon-button (click)="onClose()" [img]="'assets/icons/close.svg'" type="stroked" background [panelClass]="'img-size-30 darken-icon'"></app-icon-button>
			</div>

			<!-- Content -->
			<div class="disp-f mt-l" style="min-height: 70vh;height: 100%;">
				<!-- Search & category -->
				<div class="disp-f f-d-col j-c-sb pr-l gap-L" style="flex: 1; max-height: 77vh;">
					<div *ngIf="!_loading" class="disp-f f-d-col gap-L" style="overflow-y: auto; overflow-x: hidden;">
						<app-text-input [label]="'global.announcements.title' | translate" [input]="titleFormControl" [placeholder]="'global.announcements.titlePlaceholder' | translate"></app-text-input>
						<div>
							<label>{{ 'global.announcements.type' | translate }}</label>
							<app-multi-select [inputControl]="typeFormControl">
								<app-select-trigger appearance="field">{{ typeFormControl.value?.name || '-' }}</app-select-trigger>
								<app-select-option *ngFor="let type of announcementTypeChoices" [value]="type">{{type.name}}</app-select-option>
							</app-multi-select>
						</div>
						<div>
							<label *ngIf="typeFormValue?.id === AnnouncementType.GENERAL || typeFormValue?.id === AnnouncementType.NETWORK">{{ 'global.announcements.target' | translate }}</label>
							<label *ngIf="typeFormValue?.id === AnnouncementType.MAINTENANCE">{{ 'global.announcements.application' | translate }}</label>
							<app-multi-select *ngIf="typeFormValue?.id === AnnouncementType.GENERAL || typeFormValue?.id === AnnouncementType.NETWORK" class="w-100" [inputControl]="teamsFormControl" multiple>
								<app-select-trigger appearance="field">{{!announcementForm.get(form.teams)!.value.length ? '-' : (announcementForm.get(form.teams)!.value[0].name  + ($any(announcementForm.get(form.teams)).value.length > 1 ? ' (+' + ($any(announcementForm.get(form.teams)).value.length - 1) + ')' : ''))}}</app-select-trigger>
								<app-select-search [searchControl]="searchTeamControl"></app-select-search>-->
								<app-select-option-group *ngFor="let group of filteredOrganizations" [label]="group.organization.name" noOptionPrefix>
									<app-select-option *ngFor="let org of group.children" [value]="org.organization">
										<div class="flex-align-center gap-M">
											<img [src]="!org.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + org.organization.icon + '.svg')" class="filter-accent" height="16px">
											<span>{{org.organization.name}}</span>
										</div>
									</app-select-option>
								</app-select-option-group>
							</app-multi-select>

							<app-multi-select *ngIf="typeFormValue?.id === AnnouncementType.MAINTENANCE" class="w-100" [inputControl]="appFormControl">
								<app-select-trigger appearance="field">
									<app-display-application *ngIf="appFormValue" [application]="appFormValue"></app-display-application>
									<span *ngIf="!appFormValue">-</span>
								</app-select-trigger>
								<app-select-search [searchControl]="searchAppControl"></app-select-search>-->
								<app-select-option *ngFor="let app of filteredApps" [value]="app">
									<app-display-application [application]="app"></app-display-application>
								</app-select-option>
							</app-multi-select>
						</div>
						<app-text-area [label]="'global.announcements.content' | translate" [rows]="11" [input]="contentFormControl" [placeholder]="('global.announcements.contentPlaceholder.1' | translate) + '&#10;&#10;' + ('global.announcements.contentPlaceholder.2' | translate) + '&#10;&#10;' + ('global.announcements.contentPlaceholder.3' | translate) + '&#10;&#10;' + ('global.announcements.contentPlaceholder.4' | translate) + '&#10;&#10;'"></app-text-area>
					</div>
					<div *ngIf="_loading" class="disp-f f-d-col gap-L" style="overflow: auto">
						<div>
							<app-list-loader [rowCount]="7"></app-list-loader>
						</div>
					</div>
					<div class="disp-f a-i-c j-c-c gap-L">
						<app-text-button class="w-100" type="stroked"
                                         (click)="onClose()">{{ 'global.announcements.cancel' | translate }}</app-text-button>
						<app-text-button class="w-100" type="flat" [disabled]="announcementForm.invalid" [loading]="_saving"
                                         (click)="save()">{{ (!data.announcement ? 'global.announcements.publish' : 'global.announcements.update') | translate }}</app-text-button>
					</div>
				</div>
				<div class="disp-f f-d-col preview-box"  style="padding: 20px; max-height: 70vh; overflow: auto; flex: 2; box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.08) inset;">
					<div class="disp-f" style="height: 100%; margin: auto; gap: 60px; min-height: 400px; min-width: 550px;">
						<!-- FIRST SCREEN PREVIEW -->
						<div class="screen-preview disp-f f-d-col j-c-sb" id="screen-1">
							<div style="overflow: hidden;">
								<!-- HEADER -->
								<div class="rover-header">
									<div class="disp-f a-i-c j-c-c" style="width: 20px; height: 20px;">
										<img class="filter-grey" ngSrc="assets/icons/close.svg" height="9" width="9" alt="">
									</div>
									<div class="disp-f a-i-c j-c-c">
										<img ngSrc="{{ environment.applicationLogo }}" height="25" width="25" alt="">
									</div>
									<div class="disp-f j-c-c a-i-c">
										<img ngSrc="assets/icons/rover-toggle.svg" height="20" width="37" alt="">
									</div>
								</div>
								<!-- BODY -->
								<div class="rover-body">
									<span class="font-S fw-700 mb-l">{{ 'global.announcements.lastAlerts' | translate }}</span>
									<div class="announcement-list">
										<div class="disp-f a-i-c j-c-sb">
											<div class="disp-f a-i-c j-c-c gap-M w-100">
												<!-- LOGO BUBBLE -->
												<div class="logo-bubble">
													<img *ngIf="typeFormValue?.id === AnnouncementType.GENERAL" class="filter-accent" ngSrc="assets/icons/message-alert-square.svg" height="20" width="20" alt="">
													<img *ngIf="typeFormValue?.id === AnnouncementType.NETWORK" class="filter-accent" ngSrc="assets/icons/wifi-off.svg" height="20" width="20" alt="">
													<img *ngIf="typeFormValue?.id === AnnouncementType.MAINTENANCE" class="filter-accent" ngSrc="assets/icons/tools.svg" height="20" width="20" alt="">
												</div>
												<!-- NAME AND DATE -->
												<div class="flex-column gap-XS w-100 overflow-hidden">
													<span *ngIf="titleFormControl.valid" class="font-S fw-700" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
														{{ titleFormValue }}
													</span>
													<span *ngIf="titleFormControl.invalid" class="font-S fw-700" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
														{{ 'global.announcements.titlePlaceholder' | translate }}
													</span>
													<span class="font-S fw-500">{{ 'global.announcements.datePlaceholder' | translate }}</span>
												</div>
												<div class="disp-f a-i-c j-c-c" style="height: 100%;">
													<img class="filter-grey" ngSrc="assets/icons/arrow-right-black.svg" height="14" width="14" alt="">
												</div>
											</div>
										</div>
										<div *ngFor="let _ of [].constructor(5)" class="disp-f a-i-c j-c-sb">
											<div class="disp-f a-i-c j-c-c gap-M w-100">
												<!-- LOGO BUBBLE -->
												<div class="loading-bubble"></div>
												<!-- NAME AND DATE -->
												<div class="disp-f f-d-col j-c-c w-100 gap-S" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
													<div class="announcement-name-loading"></div>
													<div class="announcement-date-loading"></div>
												</div>
												<div class="disp-f a-i-c j-c-c" style="height: 100%;">
													<img class="filter-grey" ngSrc="assets/icons/arrow-right-black.svg" height="14" width="14" alt="">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="disp-f">
								<!-- FOOTER -->
								<div class="rover-footer">
									<div>
										<img ngSrc="assets/icons/rover/home.svg" height="24" width="24" alt="">
									</div>
									<div>
										<img ngSrc="assets/icons/rover/apps.svg" height="24" width="24" alt="">
									</div>
									<div class="disp-f a-i-c j-c-c f-d-col" style="gap: 2px;">
										<img ngSrc="assets/icons/rover/bell.svg" height="24" width="24" alt="">
										<div class="bottom-bar"></div>
									</div>
									<div>
										<img ngSrc="assets/icons/rover/settings.svg" height="24" width="24" alt="">
									</div>
								</div>
							</div>
						</div>
						<!-- SECOND SCREEN PREVIEW -->
						<div class="screen-preview disp-f f-d-col j-c-sb">
							<div style="overflow: auto;">
								<!-- HEADER -->
								<div class="rover-header">
									<div class="disp-f a-i-c j-c-c" style="width: 20px; height: 20px;">
										<img class="filter-grey" ngSrc="assets/icons/close.svg" height="9" width="9" alt="">
									</div>
									<div class="disp-f a-i-c j-c-c">
										<img ngSrc="{{ environment.applicationLogo }}" height="25" width="25" alt="">
									</div>
									<div class="disp-f j-c-c a-i-c">
										<img ngSrc="assets/icons/rover-toggle.svg" height="20" width="37" alt="">
									</div>
								</div>
								<!-- BODY -->
								<div class="rover-body">
									<span class="font-S fw-700 mb-l">{{ 'global.announcements.lastAlerts' | translate }}</span>
									<div class="announcement-list">
										<div class="disp-f a-i-c j-c-sb">
											<div class="disp-f a-i-c j-c-c gap-M w-100">
												<!-- LOGO BUBBLE -->
												<div class="logo-bubble">
													<img *ngIf="typeFormValue?.id === AnnouncementType.GENERAL" class="filter-accent" ngSrc="assets/icons/message-alert-square.svg" height="20" width="20" alt="">
													<img *ngIf="typeFormValue?.id === AnnouncementType.NETWORK" class="filter-accent" ngSrc="assets/icons/wifi-off.svg" height="20" width="20" alt="">
													<img *ngIf="typeFormValue?.id === AnnouncementType.MAINTENANCE" class="filter-accent" ngSrc="assets/icons/tools.svg" height="20" width="20" alt="">
												</div>
												<!-- NAME AND DATE -->
												<div class="flex-column gap-XS w-100 overflow-hidden">
													<span *ngIf="titleFormControl.valid" class="font-S fw-700" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
														{{ titleFormValue }}
													</span>
													<span *ngIf="titleFormControl.invalid" class="font-S fw-700" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
														{{ 'global.announcements.titlePlaceholder' | translate }}
													</span>
													<span class="font-S fw-500">{{ 'global.announcements.datePlaceholder' | translate }}</span>
												</div>
												<div class="disp-f a-i-c j-c-c" style="height: 100%;">
													<img class="filter-grey" style="transform: rotate(90deg);" ngSrc="assets/icons/arrow-right-black.svg" height="14" width="14" alt="">
												</div>
											</div>
										</div>
										<div class="announcement-content font-S fw-500" *ngIf="contentFormControl.valid">
											{{ contentFormValue }}
										</div>
										<div class="announcement-content font-S fw-500" *ngIf="contentFormControl.invalid">
											{{ 'global.announcements.contentPlaceholder.1' | translate }}<br><br>
											{{ 'global.announcements.contentPlaceholder.2' | translate }}<br><br>
											{{ 'global.announcements.contentPlaceholder.3' | translate }}<br><br>
											{{ 'global.announcements.contentPlaceholder.4' | translate }}
										</div>
										<div *ngFor="let _ of [].constructor(2)" class="disp-f a-i-c j-c-sb">
											<div class="disp-f a-i-c j-c-c gap-M w-100">
												<!-- LOGO BUBBLE -->
												<div class="loading-bubble"></div>
												<!-- NAME AND DATE -->
												<div class="disp-f f-d-col j-c-c w-100 gap-S" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
													<div class="announcement-name-loading"></div>
													<div class="announcement-date-loading"></div>
												</div>
												<div class="disp-f a-i-c j-c-c" style="height: 100%;">
													<img class="filter-grey" ngSrc="assets/icons/arrow-right-black.svg" height="14" width="14" alt="">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="disp-f">
								<!-- FOOTER -->
								<div class="rover-footer">
									<div>
										<img ngSrc="assets/icons/rover/home.svg" height="24" width="24" alt="">
									</div>
									<div>
										<img ngSrc="assets/icons/rover/apps.svg" height="24" width="24" alt="">
									</div>
									<div class="disp-f a-i-c j-c-c f-d-col" style="gap: 2px;">
										<img ngSrc="assets/icons/rover/bell.svg" height="24" width="24" alt="">
										<div class="bottom-bar"></div>
									</div>
									<div>
										<img ngSrc="assets/icons/rover/settings.svg" height="24" width="24" alt="">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>
