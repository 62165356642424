import jsPDF from "jspdf";
import {forkJoin, mergeMap, Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {observableFromSync} from 'src/app/utils/observable.utils';

export interface BlockProperties {x: number, y: number, width: number, height: number}

const blockSize = 1024;
const padding = 15;
const headerHeight = 80;
const footerHeight = 70;

export class PdfGenerator {
	constructor(private sourceCanvas: HTMLCanvasElement, private currentWidth: number, private currentHeight: number) {}

	protected buildCanvasBlock(): BlockProperties[] {
		// Cut canvas to small canvas
		const blocks : BlockProperties[] = [];
		for (let i=0; i < this.sourceCanvas.height; i += blockSize) {
			for (let j=0; j < this.sourceCanvas.width; j += blockSize) {
				const width = Math.min(blockSize, this.sourceCanvas.width - j);
				const height = Math.min(blockSize, this.sourceCanvas.height - i);
				blocks.push({y: i, x: j, width, height})
			}
		}
		return blocks;
	}

	protected buildCanvas(): HTMLCanvasElement {
		const canvas =  document.createElement('canvas')
		canvas.width = blockSize;
		canvas.height = blockSize;
		return canvas;
	}

	protected buildBasePdf(): {pdf: jsPDF, scaleFactor: number} {
		let pdf = new jsPDF({
			orientation: 'landscape',
			unit: 'px',
			userUnit: 96,
			format: 'a3'
		});

		// Calculate scale factor
		const scaleFactor = Math.min(pdf.internal.pageSize.getWidth() / this.currentWidth, (pdf.internal.pageSize.getHeight() - headerHeight - footerHeight) / this.currentHeight);

		return {pdf, scaleFactor};
	}

	protected writeCanvasToPdf(pdf: jsPDF, scaleFactor: number, canvas: HTMLCanvasElement,  blocks: BlockProperties[]): Observable<jsPDF> {
		const context = canvas.getContext('2d') as CanvasRenderingContext2D;

		const imagePromises = blocks.map(block => {
			return observableFromSync(() => {
				context.clearRect(0, 0, blockSize, blockSize);
				context.drawImage(this.sourceCanvas, block.x, block.y, block.width, block.height, 0, 0, blockSize, blockSize)
				const data = canvas.toDataURL('image/png')
				return {data, block};
			}).pipe(
				mergeMap((picture) => {
					return observableFromSync(() => {
						pdf.addImage(picture.data, 'PNG', picture.block.x * scaleFactor + padding, picture.block.y * scaleFactor + headerHeight, picture.block.width * scaleFactor, picture.block.height * scaleFactor, undefined, 'FAST');
						return pdf;
					});
				})
			)
		});

		return forkJoin(imagePromises).pipe(map(() => pdf));
	}

	buildHeaderIcon(pdf: jsPDF): Observable<jsPDF> {
		return observableFromSync(() => {
			// Draw main icon background
			pdf.setFillColor('#EEF9FE');
			pdf.roundedRect(padding, padding, 40, 40, 12, 12, 'F');
			return pdf;
		}).pipe(
			mergeMap(() =>{
				return fromPromise<jsPDF>(new Promise((resolve, reject) => {
					const image = new Image();
					image.src = 'assets/icons/carto.png';
					image.onload = () => {
						pdf.addImage(image, 'PNG', padding + 10, padding + 10, 20, 20, undefined, 'FAST');
						resolve(pdf);
					}
					image.onerror = () => {
						reject();
					}
				}))
			}),
			catchError(err => {
				console.error('Error while loading image', err);
				return of(pdf)
			})
		);
	};

	buildHeader(pdf: jsPDF, title: string, subtitle: string): Observable<jsPDF> {
		return observableFromSync(() => {
			// Add title
			pdf.setFontSize(16);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.setTextColor('#0C2633');
			pdf.text(title, padding + 50, padding + 17);

			// Add subtitle
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '400');
			pdf.setTextColor('#B5B5C3');
			pdf.text(subtitle, padding + 50, padding + 32);

			// Add date
			pdf.setFontSize(14);
			pdf.setFont('Helvetica', 'normal', '700');
			pdf.setTextColor('#0C2633');
			const currentDate = new Date().toLocaleDateString('fr-FR', {year: 'numeric', month: '2-digit', day: '2-digit'});
			const textSize = pdf.getTextWidth(currentDate)
			pdf.text(currentDate, pdf.internal.pageSize.width - padding - textSize, padding + 15);

			// Add date comment
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '400');
			pdf.setTextColor('#B5B5C3');
			const comment = 'Sur les 30 derniers jours'
			const commentSize = pdf.getTextWidth(comment)
			pdf.text(comment, pdf.internal.pageSize.width - padding - commentSize, padding + 30);

			// Bottom line
			pdf.setLineWidth(1);
			pdf.setDrawColor('#F2F2F8');
			pdf.line(padding, 70, pdf.internal.pageSize.width - padding, 70);

			return pdf;
		}).pipe(
			mergeMap(pdf => this.buildHeaderIcon(pdf))
		);
	}

	buildFooter(pdf: jsPDF): Observable<jsPDF> {
		return observableFromSync(() => {
			// Bottom line
			pdf.setLineWidth(1);
			pdf.setDrawColor('#F2F2F8');
			pdf.line(padding, pdf.internal.pageSize.height - (2*padding + 30), pdf.internal.pageSize.width - padding, pdf.internal.pageSize.height - (2*padding + 30));

			// Add page number
			pdf.setFontSize(12);
			pdf.setFont('Helvetica', 'normal', '400');
			pdf.setTextColor('#B5B5C3');
			const text = 'Generated by Kabeen on ' + new Date().toLocaleDateString('fr-FR', {year: 'numeric', month: '2-digit', day: '2-digit'}) + ' at ' + new Date().toLocaleTimeString('fr-FR', {hour: '2-digit', minute: '2-digit'});
			pdf.text(text, pdf.internal.pageSize.width - padding - pdf.getTextWidth(text), pdf.internal.pageSize.height - (padding + 15));
			return pdf;
		});
	}

	buildPdf(title: string, subtitle: string):Observable<jsPDF> {
		const {pdf, scaleFactor} = this.buildBasePdf();

		const canvas = this.buildCanvas();
		const blocks = this.buildCanvasBlock();

		return this.buildHeader(pdf, title, subtitle).pipe(
			mergeMap(pdf => this.writeCanvasToPdf(pdf, scaleFactor, canvas, blocks)),
			mergeMap(pdf => this.buildFooter(pdf)),
		)
	}
}
