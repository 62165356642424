import {ApplicationGeneric} from 'src/app/services/model/new-application.model';
import {FileLink} from "./tenant-file.model";

export interface ApplicationMappingDataForm {
	name: string;
}

export interface ApplicationMappingForm {
	sourceId: string;
	targetId: string;
	mappingDataList: string[];
	description?: string;
	protocol?: ProtocolType;
	format?: FormatType;
	exchangeFrequency?: ExchangeFrequencyType;
	portType?: string;
	port?: string;
	flowEncryption?: boolean;
	documents?: string[];
}

export interface ApplicationMappingData {
	dataId: string;
	name: string;
}

export interface ApplicationMapping {
	flowId: string;
	description: string;
	source: ApplicationGeneric;
	target: ApplicationGeneric;
	mappingDataList: ApplicationMappingData[];
	protocol?: string;
	format?: string;
	exchangeFrequency?: string;
	portType?: string;
	port?: string;
	flowEncryption?: boolean;
	documents: FileLink[];
}

export enum ProtocolType {
	HTTP = 'http',
	FTP = 'ftp',
	SFTP = 'sftp',
	MQTT = 'mqtt',
	AMQP = 'amqp',
	gRPC = 'grpc',
	SMB = 'smb',
	NFS = 'nfs',
	LDAP = 'ldap',
	CIFS = 'cifs',
	RTSP = 'rtsp',
	DICOM = 'dicom',
	HL7 = 'hl7',
	FHIR = 'fhir',
	XDS = 'xds',
	IHE = 'ihe',
	CDA = 'cda',
	NCPDP = 'ncpdp',
	CCDA = 'ccda',
	AS2 = 'as2',
	EDI = 'edi',
	SEPA = 'sepa',
	EBICS = 'ebics',
}

export enum FormatType {
	JSON = 'json',
	CSV = 'csv',
	XML = 'xml',
	YAML = 'yaml',
	PLAIN_TEXT = 'plain_text',
	HTML = 'html',
	BINARY = 'binary',
}

export enum ExchangeFrequencyType {
	REAL_TIME = 'real_time',
	DAILY = 'daily',
	WEEKLY = 'weekly',
	MONTHLY = 'monthly',
}
