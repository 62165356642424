import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from 'src/app/services/front/snackbar.service';
import {MatDialog} from '@angular/material/dialog';
import {filter, map} from 'rxjs/operators';
import {ConfirmComponent, ConfirmModel} from '../../../../../global/dialog/confirm/confirm.component';
import {ApplicationMappingResourceFormData} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-resource-form/application-mapping-resource-form.component';
import {ApplicationMappingResourceFormDialogComponent} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-mapping-resource-form/dialog/application-mapping-resource-form-dialog.component';
import {ResourceMappingService} from 'src/app/services/back/resource-mapping.service';
import {ServerService} from 'src/app/services/back/server.service';
import {ServerOverview} from 'src/app/services/model/resource.model';
import {ResourceMapping} from 'src/app/services/model/resource-mapping.model';
import {NewApplicationService} from 'src/app/services/back/new-application.service';

@Component({
	selector: 'app-application-mapping-resource-preview',
	templateUrl: './application-mapping-resource-preview.component.html',
	styleUrls: ['./application-mapping-resource-preview.component.scss']
})
export class ApplicationMappingResourcePreviewComponent implements OnInit, OnDestroy {

	@Output() updated = new EventEmitter<void>();

	tenantId: string;
	instanceId: string;
	resourceMappings: ResourceMapping[] = [];

	_initializing: boolean;
	_loading: boolean;
	_loadingDialog: boolean;
	initialized: boolean = false;

	tenantResources: ServerOverview[] = [];
	deleteResourceMapping = (mappingId: string) => this.doDeleteResourceMapping(mappingId);

	subscription: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private resourceMappingService: ResourceMappingService,
							private applicationService: NewApplicationService,
              private serverService: ServerService,
							private translate: TranslateService,
							private snackBar: SnackbarService,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscription.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.instanceId = data.instance.applicationId;
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => this.resourceMappingService.getAllResourceMappingByApplicationId(this.tenantId, this.instanceId)),
				tap(resources => this.setAllResourceMapping(resources)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true);
	}

	setAllResourceMapping(resources: ResourceMapping[]): void {
		this.resourceMappings = resources
			.sort((a, b) => a.resource.name.localeCompare(b.resource.name));
	}

	openApplicationInfrastructureFormDialog(): void {
		this.switchLoadingDialog()
			.pipe(
				switchMap(() => this.getTenantResources()),
				map(resources => this.buildApplicationInfrastructureFormData(resources)),
				switchMap(data => this.dialog.open(ApplicationMappingResourceFormDialogComponent, { position: { right: '162.5px' }, width: '475px', data: data }).afterClosed()),
				filter(updated => !!updated),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()),
				finalize(() => this.switchLoadingDialog()))
			.subscribe(() => this.initialize());
	}

	private getTenantResources(): Observable<ServerOverview[]> {
		return of(this.tenantResources).pipe(
			switchMap(tenantResources => tenantResources.length > 0
				? of(tenantResources)
				: this.serverService.getAllServersByTenantId(this.tenantId, false, true)),
			tap(tenantResources => this.tenantResources = tenantResources));
	}

	private buildApplicationInfrastructureFormData(resources: ServerOverview[]): ApplicationMappingResourceFormData {
		return {
			tenantId: this.tenantId,
			instanceId: this.instanceId,
			defaultData: this.resourceMappings,
			defaultList: {
				resources: resources
			}
		}
	}

	doDeleteResourceMapping(mappingId: string): void {
		const data: ConfirmModel = {
			message: this.translate.instant('confirmModal.deleteResource'),
			closeButton: this.translate.instant('button.cancel'),
			confirmButton: this.translate.instant('button.delete')
		};
		this.dialog.open<ConfirmComponent, any, boolean>(ConfirmComponent, { position: { right: '162.5px' }, width: '475px', data: data })
			.afterClosed()
			.pipe(
				filter(result => !!result),
				switchMap(() => this.resourceMappingService.removeResourceMapping(this.tenantId, mappingId)),
				filter(deleted => !!deleted),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()))
			.subscribe(() => this.initialize());
	}

	switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	switchLoadingDialog(): Observable<{}> {
		this._loadingDialog = !this._loadingDialog;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
