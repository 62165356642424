import {Routes} from "@angular/router";
import {AuthGuard} from "../../../guards/auth.guard";
import {UsersComponent} from "./users/users.component";
import {AccountComponent} from "./account/account.component";
import {PlanComponent} from "./plan/plan.component";
import {TenantComponent} from "./tenant/tenant.component";
import {MainMenuPath, SettingsPath} from "../../../models/home/navigation.model";
import {SettingsComponent} from "./settings.component";

export const settingsRoutes: Routes = [
	{
		path: MainMenuPath.SETTINGS,
		canActivate: [AuthGuard],
		component: SettingsComponent,
		children: [
			{
				path: '',
				redirectTo: SettingsPath.ACCOUNT,
				pathMatch: 'full'
			},
			{
				path: SettingsPath.ACCOUNT,
				canActivate: [AuthGuard],
				component: AccountComponent
			},
			{
				path: SettingsPath.TENANT,
				canActivate: [AuthGuard],
				component: TenantComponent
			},
			{
				path: SettingsPath.MEMBERS,
				canActivate: [AuthGuard],
				component: UsersComponent
			},
			{
				path: SettingsPath.BILLING,
				canActivate: [AuthGuard],
				component: PlanComponent
			}
		]
	}
];
