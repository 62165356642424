import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {AccountService} from 'src/app/services/account.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup, UntypedFormBuilder, Validators} from "@angular/forms";
import {TenantAccountWeeklyReportForm, TenantAccount} from 'src/app/services/model/account.model';
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {MatDialog} from "@angular/material/dialog";
import {filter} from "rxjs/operators";
import {Subscription, switchMap} from "rxjs";
import {LOGIN_URL} from "src/app/models/home/navigation.model";
import {DeleteDialogComponent} from "../../../design-system/delete-dialog/delete-dialog.component";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {TenantAccountService} from 'src/app/services/back/tenant-account.service';

@Component({
	selector: 'app-settings-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

	tenantId: string;
	currentUser: TenantAccount;

	isLoadingPasswordChange: boolean = false;

	passwordForm: FormGroup;
	langForm: FormControl<string|null>;
	weeklyReportControl: FormControl<boolean>;

	subscription: Subscription = new Subscription();

	constructor(protected currentTenantService: CurrentTenantService,
							protected translateService: TranslateService,
							protected authService: AuthenticationService,
							protected accountService: AccountService,
							protected tenantAccountService: TenantAccountService,
							protected snackBar: SnackbarService,
							protected router: Router,
							protected dialog: MatDialog,
							protected fb: UntypedFormBuilder) {
	}

	ngOnInit(): void {
		this.createForm();
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.subscribe(tenantId => this.tenantId = tenantId));
		this.subscription.add(this.currentTenantService.getTenantAccountChanges()
			.subscribe(currentUser => this.setCurrentUser(currentUser)));
	}

	createForm(): void {
		this.langForm = new FormControl(this.translateService.getDefaultLang());
		this.subscription.add(this.langForm.valueChanges
			.subscribe(lang => this.onChangeLang(lang ?? 'en')));

		this.passwordForm = this.fb.group({});
		this.passwordForm.addControl('oldPassword', new FormControl(null, [Validators.required]));
		this.passwordForm.addControl('newPassword', new FormControl(null, [Validators.required]));
		this.passwordForm.addControl('newPasswordConfirmation', new FormControl(null, [Validators.required]));

		this.weeklyReportControl = new FormControl();
		this.subscription.add(this.weeklyReportControl.valueChanges
			.subscribe(value => this.updateAccountWeeklyReportSubscription(value)));
	}

	setCurrentUser(currentUser: TenantAccount): void {
		this.currentUser = currentUser;
		this.weeklyReportControl.setValue(currentUser.isWeeklySubscriber, {emitEvent: false});
		this.weeklyReportControl.enable({emitEvent: false});
	}

	onChangeLang(lang: string): void {
		localStorage.setItem('lang', lang);
		this.translateService.setDefaultLang(lang);
	}

	getDefaultLang(): 'Français'|'English' {
		return this.translateService.getDefaultLang() === 'fr' ? 'Français' : 'English';
	}

	onDeleteAccount(): void {
		this.dialog.open(DeleteDialogComponent, {
			width: '475px',
			data: {
				icon: '/assets/illustrations/delete/account.svg',
				title: this.translateService.instant('page.settings.account.delete.title'),
				subtitle: this.translateService.instant('page.settings.account.delete.subtitle', { email: this.currentUser.account.email }),
				warningMessage: this.translateService.instant('page.settings.account.delete.warningMessage'),
				deleteButton: this.translateService.instant('page.settings.account.delete.deleteButton')
			}})
			.afterClosed()
			.pipe(
				filter(result => result),
				switchMap(() => this.accountService.deleteAccount()),
			).subscribe({
				next: () => {
					this.authService.logout();
					return this.router.navigate([LOGIN_URL, {}]);
				},
				error: error => {
					this.snackBar.show(this.translateService.instant('page.account.deleteFailed'),'danger-snack');
				}
			});
	}

	updateAccountWeeklyReportSubscription(isSubscribed: boolean): void {
		const form: TenantAccountWeeklyReportForm = {
			isSubscribed: isSubscribed
		};
		this.tenantAccountService.updateTenantAccountWeeklyReport(this.tenantId, this.currentUser.account.accountId, form)
			.subscribe(() => this.currentTenantService.refreshTenantAccount());
	}

	onChangePassword() {
		if (!this.isLoadingPasswordChange && this.passwordForm.valid) {
			this.isLoadingPasswordChange = true;
			if (this.passwordForm.get('newPassword')?.value !== this.passwordForm.get('newPasswordConfirmation')?.value) {
				this.snackBar.show(this.translateService.instant('page.account.changePassword.message.passwordNotMatching'), 'danger-snack')
				this.passwordForm.get('newPasswordConfirmation')?.setErrors({notMatching: true});
				this.isLoadingPasswordChange = false;
			} else if (this.passwordForm.get('newPassword')?.value?.length < 8) {
				this.snackBar.show(this.translateService.instant('page.login.minLength'),  'danger-snack')
				this.isLoadingPasswordChange = false;
			} else {
				this.accountService.changePasswordWithCheck({
					oldPassword: this.passwordForm.get('oldPassword')?.value,
					newPassword: this.passwordForm.get('newPassword')?.value,
					userId: ''
				}).subscribe(r => {
					this.snackBar.show(this.translateService.instant('page.account.changePassword.message.passwordChanged'), 'success-snack')
					this.isLoadingPasswordChange = false;
				}, error => {
					if (error.error.type == 'forbidden_account_update') {
						this.snackBar.show(this.translateService.instant('page.account.changePassword.message.wrongCredential'), 'danger-snack');
						this.isLoadingPasswordChange = false;
					} else {
						this.snackBar.show(this.translateService.instant('page.account.changePassword.message.error'), 'danger-snack');
						this.isLoadingPasswordChange = false;
					}
				})
			}
		}
	}
}
