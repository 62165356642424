<div class="alert" [ngClass]="getClass()" [ngStyle]="getStyle()" [attr.size]="size">

	<mat-progress-bar *ngIf="autoclose" mode="determinate" [value]="progressPercent"></mat-progress-bar>
	<div *ngIf="closable" (click)="closeAlert()" class="alert-close-button">
		<mat-icon>cancel</mat-icon>
	</div>
	<div class="alert-content">{{ message }}
		<ng-content></ng-content>
	</div>

</div>
