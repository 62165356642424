import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TagsComponent} from "./tags.component";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
	imports: [
		CommonModule,
		MatIconModule
	],
	declarations: [
		TagsComponent
	],
	exports: [
		TagsComponent
	]
})
export class TagsModule {
}
