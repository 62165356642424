<div>
	<div class="disp-f a-i-c j-c-sb pl-m pr-m pt-m pb-m">
        <span class="font-MM fw-700">{{ 'global.health' | translate }}</span>
		<span class="font-MM fw-700" style="font-weight: 700;" [class]="health >= 99 ? 'success'
				: health < 99 && health >= 95 ? 'warning'
				: 'danger'"
			  	*ngIf="health">
			{{ getHealth(health) }}
		</span>
	</div>
	<div class="pl-m pr-m" (mouseout)="tooltip.display = 'none'" [style.height.px]="height">
		<canvas
				baseChart
				[data]="chartData"
				[type]="'line'"
				[options]="chartOptions"
		></canvas>
		<div class="tooltip" style="padding: 3px 7px; pointer-events: none;" [ngStyle]="{'top': tooltip.top, 'left': tooltip.left, 'display': tooltip.display}" >
			<div class="font-M fw-700 mb-s">
				<span>{{ tooltip.day }} {{ 'global.monthShort.' + tooltip.month | translate }}</span>
			</div>
			<div class="disp-f a-i-c j-c-sb mb-xs font-S fw-700">
				<span>{{ 'global.health' | translate }} : </span>
				<span [ngClass]="{ 'health-good': tooltip.value >= 99, 'health-warning': tooltip.value >= 95 && tooltip.value < 99, 'health-danger': tooltip.value < 95 }">{{ tooltip.value }}%</span>
			</div>
			<div class="disp-f a-i-c j-c-sb font-S fw-700">
				<span>{{ 'global.errorCount' | translate }} : </span>
				<span>{{ tooltip.errors }}</span>
			</div>
		</div>
	</div>
</div>
