import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TechnologyChipsComponent} from './technology-chips.component';
import {ApplicationIconModule} from 'src/app/modules/global/application-icon/application-icon.module';
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
  imports: [
    CommonModule,
    ApplicationIconModule,
    DesignSystemModule
  ],
	declarations: [
		TechnologyChipsComponent
	],
	exports: [
		TechnologyChipsComponent
	]
})
export class TechnologyChipsModule {
}
