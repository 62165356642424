<div class="disp-f a-i-c j-c-sb f-d-col w-100 pad-XL-XL-0 border-box" style="min-height: 560px" >
	<div class="ml-auto">
		<app-icon-button background (click)="onAction.emit({reason:'close'})" [panelClass]="'img-size-30'" type="default"  color="accent" [img]="'assets/icons/close.svg'"></app-icon-button>
	</div>

	<ng-content select="[content]"></ng-content>

	<div class="disp-f f-d-col a-i-c j-c-c mb-xxl">
		<app-text-button type="flat" color="accent" (click)="onAction.emit({reason:'apply'})" class=""><ng-content select="[action-button]"></ng-content></app-text-button>
		<div class="font-M grey mt-m c-p" matRipple (click)="onAction.emit({reason:'cancel'})">{{ 'confirmModal.placeholder.later'| translate}}</div>
	</div>
</div>
