import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {LineCardComponent} from "./line-card.component";
import {MatRippleModule} from "@angular/material/core";

@NgModule({
	imports: [
		CommonModule,
		MatRippleModule
	],
	declarations: [
		LineCardComponent,
	],
	exports: [
		LineCardComponent,
	]
})
export class LineCardModule {
}
