<div class="flex gap-M pad-XL-XL-0 w-100 border-box">
    <app-diagram-breadcrumb class="w-100"></app-diagram-breadcrumb>

    <div class="filter-panel-container bkg-white b-radius-9">
        <app-icon-button color="accent" type="stroked" [img]="'assets/icons/settings.svg'" class="filter-panel-btn" panelClass="h-100-imp w-100-imp bkg-transparent"
                         [disabled]="_initializing || _loading" [loading]="_loadingFilter" [focused]="miniDrawer.opened()"
                         [miniDrawerTriggerFor]="miniDrawer" [matBadge]="activeFilters > 0 ? activeFilters : undefined" matBadgeColor="accent">
        </app-icon-button>
        <app-mini-drawer #miniDrawer>
            <div class="flex-column gap-M" *ngIf="!_loadingFilter">
                <div class="flex-column gap-S">
                    <div class="flex-align-center flex-space-between">
                        <span class="font-M fw-700">{{'page.diagram.filters.organization'|translate}}</span>
                        <span *ngIf="activeFilters > 0" class="font-M fw-500 danger underlined-hover c-p"
                              (click)="resetFilters()">{{'button.deleteFilters'|translate}}</span>
                    </div>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.organization))" multiple>
                        <app-select-trigger appearance="field">{{!organizationFormValue.length ? ('page.diagram.filters.allOrganization'|translate) : (organizationFormValue[0].name  + (organizationFormValue.length > 1 ? ' (+' + (organizationFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-search [searchControl]="searchOrganizationControl"></app-select-search>
                        <app-select-option-group *ngFor="let group of filterData.organization" [label]="group.organization.name" noOptionPrefix>
                            <app-select-option *ngFor="let org of group.children" [value]="org.organization">
                                <div class="flex-align-center gap-M">
                                    <img [src]="!org.organization.icon ? 'assets/icons/team.svg' : ('/assets/icons/team_icon/' + org.organization.icon + '.svg')" class="filter-accent" height="16px">
                                    <span>{{org.organization.name}}</span>
                                </div>
                            </app-select-option>
                        </app-select-option-group>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-column gap-S">
                    <span class="font-M fw-700">{{'page.diagram.filters.application'|translate}}</span>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.application))" multiple>
                        <app-select-trigger appearance="field">{{!applicationFormValue.length ? ('page.diagram.filters.allApplication'|translate) : (applicationFormValue[0].name  + (applicationFormValue.length > 1 ? ' (+' + (applicationFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-search [searchControl]="searchApplicationControl"></app-select-search>
                        <app-select-option *ngFor="let app of filterData.application" [value]="app">
                            <app-display-application [application]="app"></app-display-application>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-column gap-S">
                    <span class="font-M fw-700">{{'page.diagram.filters.category'|translate}}</span>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.category))" multiple>
                        <app-select-trigger appearance="field">{{!categoryFormValue.length ? ('page.diagram.filters.allCategory'|translate) : (categoryFormValue[0].name  + (categoryFormValue.length > 1 ? ' (+' + (categoryFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-search [searchControl]="searchCategoryControl"></app-select-search>
                        <app-select-option *ngFor="let category of filterData.category" [value]="category">
                            {{category.name}}
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-column gap-S">
                    <span class="font-M fw-700">{{'page.diagram.filters.criticality'|translate}}</span>
                    <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.criticality))" multiple>
                        <app-select-trigger appearance="field">{{!criticalityFormValue.length ? ('page.diagram.filters.allCriticality'|translate) : ((('page.appDetails.businessCriticality.' + criticalityFormValue[0].toLowerCase())|translate) + (criticalityFormValue.length > 1 ? ' (+' + (criticalityFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                        <app-select-option *ngFor="let level of filterData.criticality" [value]="level">
                            <div class="flex-align-center gap-M">
                                <img src="assets/icons/criticality-{{level.toLowerCase()}}.svg" height="16" alt=""/>
                                <span>{{('page.appDetails.businessCriticality.' + level.toLowerCase())|translate}}</span>
                            </div>
                        </app-select-option>
                    </app-multi-select>
                </div>
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-align-center flex-space-between gap-S">
                    <div class="font-M fw-700">{{ "page.diagram.filters.showNoFlowApplications" | translate}}</div>
                    <app-toggle-button [toggleInput]="$any(filterForm.get(formName.withNoData))"></app-toggle-button>
                </div>
                <!-- TODO when the feature is ready on canvas side to display external flows
                @if (!withNoDataFormValue) {
                    <div class="border-light-grey-top w-100"></div>
                    <div class="flex-align-center flex-space-between gap-S">
                        <div class="font-M fw-700">{{ "page.diagram.filters.showExternalFlow" | translate}}</div>
                        <app-toggle-button [toggleInput]="$any(filterForm.get(formName.withExternal))"></app-toggle-button>
                    </div>
                }-->
                <div class="border-light-grey-top w-100"></div>
                <div class="flex-align-center flex-space-between gap-S c-p"
                     (click)="expandAdvancedFilters = !expandAdvancedFilters">
                    <div class="font-M fw-700">{{'page.diagram.filters.advanced'|translate}}</div>
                    <img src="assets/icons/arrow-right.svg" [ngClass]="{'rotate-90': expandAdvancedFilters}" alt="">
                </div>
                @if (expandAdvancedFilters) {
                    <div class="flex-column gap-M">
                        <div class="flex-column gap-S">
                            <div class="font-M fw-700">{{'page.diagram.filters.protocol'|translate}}</div>
                            <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.protocol))" multiple>
                                <app-select-trigger appearance="field">{{!protocolFormValue.length ? ('page.diagram.filters.allProtocol'|translate) : (protocolFormValue[0]  + (protocolFormValue.length > 1 ? ' (+' + (protocolFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                                <app-select-option *ngFor="let protocol of filterData.protocol" [value]="protocol">{{protocol}}</app-select-option>
                            </app-multi-select>
                        </div>
                        <div class="flex-column gap-S">
                            <div class="font-M fw-700">{{'page.diagram.filters.port'|translate}}</div>
                            <app-multi-select class="w-100" [inputControl]="$any(filterForm.get(formName.port))" multiple
                                              [disabled]="!filterData.port.length">
                                <app-select-trigger appearance="field">{{!portFormValue.length ? ('page.diagram.filters.allPort'|translate) : (portFormValue[0]  + (portFormValue.length > 1 ? ' (+' + (portFormValue.length - 1) + ')' : ''))}}</app-select-trigger>
                                <app-select-option *ngFor="let port of filterData.port" [value]="port">{{port}}</app-select-option>
                            </app-multi-select>
                        </div>
                    </div>
                }
            </div>
        </app-mini-drawer>
    </div>

    <div style="min-width: 100px;">
        <app-text-button type="flat" panelClass="h-100-imp" [tooltip]="'page.diagram.filters.exportButton' | translate"
                         [disabled]="_initializing || _loading || _loadingFilter || isEmpty" [loading]="_exporting"
                         (click)="exportToPdf()">
            <img src="assets/icons/share.svg" alt="share">
        </app-text-button>
    </div>
</div>

<div class="overflow-hidden" style="height: calc(100% - 165px)"
     [ngClass]="{'no-app-background': !_loading && isEmpty && !displayProgressBar}">

	<div #canvasContainer class="canvas-container w-100 h-100"
		 [style.width]="isEmpty && !_loading ? '0' : ''"
		 [style.height]="isEmpty && !_loading ? '0' : ''"
         [style.visibility]="!_loading && !isEmpty && !displayProgressBar ? 'visible' : 'hidden'"
         (mouseleave)="onMouseOut()">

		<diagram-toolbar *ngIf="toolbarVisible" [applicationId]="toolbarApplicationId" [positionLeft]="toolbarPositionLeft" [positionTop]="toolbarPositionTop"
                         (focus)="focusApplication($event)"></diagram-toolbar>

		<canvas #canvas [width]="width" [height]="height"></canvas>

		<div (mousedown)="$event.stopPropagation()" class="diagram-zoom">
			<div class="diagram-zoom-button" (click)="onRecenter();" matRipple><img src="assets/icons/center.svg" width="18" class="filter-primary"></div>
			<div class="diagram-zoom-button" (click)="onZoomOut();" matRipple><img src="assets/icons/minus.svg" width="24" class="filter-primary"></div>
			<div class="diagram-zoom-info" *ngIf="getZoomLevel() >= 0">{{ getZoomLevel() | number: '1.0-0'}}%</div>
			<div class="diagram-zoom-info" *ngIf="getZoomLevel() < 0">- %</div>
			<div class="diagram-zoom-button" (click)="onZoomIn();" matRipple><img src="assets/icons/add.svg" width="14" class="filter-primary"></div>
		</div>
	</div>

	<div *ngIf="displayProgressBar" class="p-absolute" style="left: 37%; width: 25%; top: 50vh;">
		<app-progress-bar-loader [loadingTime]="1000" (onFinish)="displayProgressBar = false"></app-progress-bar-loader>
	</div>

	<div *ngIf="!_loading && isEmpty && !displayProgressBar" style="height: calc(100% - 82px);">
		<div class="flex-column flex-center gap-20 h-100">
			<span class="font-XL fw-700 text-center primary">{{ 'page.application.noApplication.1' | translate }}</span>
			<span class="font-L fw-500 text-center primary" style="line-height: 1.6rem;">
                {{ 'page.diagram.flowDiagram.noApplication.2' | translate }}
                <span class="fw-700">{{ 'page.diagram.flowDiagram.noApplication.3' | translate }}</span>
                {{ 'page.diagram.flowDiagram.noApplication.4' | translate }}
                <span class="fw-700">{{ 'page.diagram.flowDiagram.noApplication.5' | translate }}</span>
				<br>
                {{ 'page.diagram.flowDiagram.noApplication.6' | translate }}
            </span>
			<app-text-button type="flat" class="mt-l w-20" style="min-width: 200px;"
                             (click)="redirectToApps()">
				{{ 'global.letsGo' | translate }}
			</app-text-button>
		</div>
	</div>
</div>
