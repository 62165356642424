import {NgModule} from "@angular/core";
import {DiagramsComponent} from "src/app/modules/home/diagrams/diagrams.component";
import {CommonModule} from "@angular/common";
import {DiagramOverviewModule} from 'src/app/modules/home/diagrams/diagram-overview/diagram-overview.module';
import {DiagramsRoutingModule} from 'src/app/modules/home/diagrams/diagrams-routing.module';
import {DiagramAppMappingModule} from 'src/app/modules/home/diagrams/diagram-app-mapping/diagram-app-mapping.module';
import {DiagramAppMatrixModule} from 'src/app/modules/home/diagrams/diagram-app-matrix/diagram-app-matrix.module';
import {DiagramLifeCycleModule} from 'src/app/modules/home/diagrams/diagram-life-cycle/diagram-life-cycle.module';

@NgModule({
	declarations: [
		DiagramsComponent,
	],
  imports: [
    CommonModule,
    DiagramAppMappingModule,
    DiagramAppMatrixModule,
    DiagramLifeCycleModule,
    DiagramOverviewModule,
    DiagramsRoutingModule,
  ],
  exports: [
  ]
})
export class DiagramsModule {}
