import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Organization, OrganizationService, OrganizationStatistic} from "src/app/services/organization.service";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {filter, map} from "rxjs/operators";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {MatDialog} from "@angular/material/dialog";
import {AddAppsToTeamComponent, AddAppsToTeamDialogData} from "./add-apps-to-team/add-apps-to-team.component";
import {NewApplicationService} from 'src/app/services/back/new-application.service';
import {ApplicationGeneric, ApplicationHealth} from 'src/app/services/model/new-application.model';
import {DeleteDialogComponent, DeleteDialogData} from "../../../design-system/delete-dialog/delete-dialog.component";
import {TendencyWidgetContent} from 'src/app/modules/global/application-mini-widget/application-mini-widget.component';

@Component({
	templateUrl: './business-unit.component.html',
	styleUrls: ['./business-unit.component.scss']
})
export class BusinessUnitComponent implements OnInit, OnDestroy {

	@Input() data: BusinessUnitComponentData;

	@Output() onClose: EventEmitter<BusinessUnitComponentResponse> = new EventEmitter<BusinessUnitComponentResponse>();

	tenantId: string;
	isEditor: boolean = true;

	loading: boolean = false;

	tcoWidget: TendencyWidgetContent|undefined;
	usersWidget: TendencyWidgetContent|undefined;
	availabilityWidget: TendencyWidgetContent|undefined;
	settings: number|null = null;
	applications: ApplicationHealth[] = [];
	allTenantApplications: ApplicationGeneric[];

	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							private newApplicationService: NewApplicationService,
							private translateService: TranslateService,
							private snackBarService: SnackbarService,
							private activatedRoute: ActivatedRoute,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscription.add(this.activatedRoute.queryParams
			.subscribe(() => this.settings = this.organizationService.getParameter()));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.organizationService.getOrganizationStatistic(this.tenantId, this.data.businessUnit.organizationId),
					this.organizationService.getAllApplicationHealthByOrganizationId(this.tenantId, this.data.businessUnit.organizationId)
				])),
				tap(([statistic, applications]) => this.setOrganizationData(statistic, applications)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private setOrganizationData(statistic: OrganizationStatistic, applications: ApplicationHealth[]): void {
		this.applications = applications;
		this.availabilityWidget = {
			value: applications.length > 0 ? statistic.availability.percent : undefined,
			day: statistic.availability.queryType
		};
		this.tcoWidget = {
			value: statistic.cost.value,
			day: statistic.cost.queryType
		};
		this.usersWidget = {
			value: statistic.usage.value,
			day: statistic.usage.queryType
		};
	}

	close(): void {
		this.onClose.emit({ event: 'close' });
	}

	onDelete(): void {
		const data: DeleteDialogData = {
			icon: '/assets/illustrations/delete/organization.svg',
			title: this.translateService.instant('page.organization.team.delete.title'),
			subtitle: this.translateService.instant('page.organization.team.delete.subtitle'),
			warningMessage: this.translateService.instant('page.organization.team.delete.warningMessage'),
			deleteButton: this.translateService.instant('page.organization.team.delete.deleteButton')
		};
		this.dialog.open(DeleteDialogComponent, { width: '475px', position: { right: '150px' }, data: data })
			.afterClosed()
			.pipe(filter(result => result))
			.subscribe(() => this.onClose.emit({event: 'delete', id: this.data.businessUnit.organizationId }));
	}

	onSaveSettings() {
		this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'));
		this.organizationService.navigateInAndOutOfParameter(null);
	}

	onOpenOrgSettings() {
		if (this.settings != null) {
			this.organizationService.navigateInAndOutOfParameter(null);
		} else {
			this.organizationService.navigateInAndOutOfParameter(1);
		}
	}

	openLinkAppToTeam() {
		of(this.allTenantApplications)
			.pipe(
				switchMap(apps => !apps?.length
					? this.newApplicationService.getAllApplication(this.tenantId)
					: of(apps)),
				tap(apps => this.allTenantApplications = apps),
				map(apps => this.buildAddAppsToTeamDialogData(apps)),
				switchMap(data => this.dialog.open(AddAppsToTeamComponent, { position: { right: '162.5px' }, width: '475px', data: data }).afterClosed()),
				filter(result => result && result.success),
				tap(() => this.snackBarService.show(this.translateService.instant('page.organization.appsSuccessfullyAdded'))))
			.subscribe(() => this.initialize());
	}

	private buildAddAppsToTeamDialogData(apps: ApplicationGeneric[]): { organizationId: string, apps: AddAppsToTeamDialogData[] } {
		return {
			organizationId: this.data.businessUnit.organizationId,
			apps: apps.map(r => ({
				id: r.id,
				name: r.name,
				logo: r.logo,
				selected: this.applications.some(la => la.application.id === r.id)
			}))
		}
	}

	removeAppFromTeam(applicationId: string): void {
		this.applications = this.applications.filter(app => app.application.id !== applicationId);
	}

	switchLoading(): Observable<{}> {
		this.loading = !this.loading;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface BusinessUnitComponentData {
	businessUnit: Organization;
	parent: Organization;
}

export interface BusinessUnitComponentResponse {
	event: 'close'|'delete';
	id?: string;
}
