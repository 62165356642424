import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubmenuComponent} from "./submenu.component";
import {RouterModule} from "@angular/router";

@NgModule({
	imports: [
		CommonModule,
		RouterModule
	],
	declarations: [
		SubmenuComponent
	],
	exports: [
		SubmenuComponent
	]
})
export class SubmenuModule {
}
