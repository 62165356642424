import {Injectable} from '@angular/core';
import {environment} from "src/environments/environment";
import {SecurityUtils} from "../security.utils";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ResourceMapping, ResourceMappingApplicationListForm, ResourceMappingForm, ResourceMappingResourceListForm} from 'src/app/services/model/resource-mapping.model';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';

@Injectable()
export class ResourceMappingService {

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/resource-mapping`;

	constructor(protected httpClient: HttpClient) {
	}

	getAllResourceMappingByApplicationId(tenantId: string, applicationId: string): Observable<ResourceMapping[]> {
		return this.httpClient.get<ResourceMapping[]>(`${this.serviceUrl(tenantId)}/applications/${applicationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	createResourceMapping(tenantId: string, form: ResourceMappingForm): Observable<string> {
		return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	updateResourceMapping(tenantId: string, mappingId: string, form: ResourceMappingForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/${mappingId}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	updateResourceMappingResourceListForm(tenantId: string, form: ResourceMappingResourceListForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/resource-form-list`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	updateResourceMappingApplicationListForm(tenantId: string, form: ResourceMappingApplicationListForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId)}/application-form-list`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	removeResourceMapping(tenantId: string, mappingId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/${mappingId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	removeResourceMappingByResourceAndApplicationId(tenantId: string, resourceId: string, applicationId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId)}/resources/${resourceId}/applications/${applicationId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}
}
