export class ApplicationUtils {
	public static generateApplicationTileName(applicationName: string): string {
		const nameArray = applicationName.split(' ');

		if (nameArray.length == 2) {
			return (nameArray[0].substring(0,1) + nameArray[1].substring(0,1)).toUpperCase();
		} else {
			return nameArray[0].length < 2 ? nameArray[0].toUpperCase() : nameArray[0].substring(0, 2).toUpperCase();
		}
	}

	public static getApplicationTypes(): AppType[] {
		return [
			{id: 'saas', name: 'SaaS'},
			{id: 'hosted', name: 'Hosted'},
			{id: 'homemade', name: 'Home-Made'}
		];
	}
}

export interface AppType {
	id: string;
	name: string;
}
