import {NgModule} from "@angular/core";
import {CardComponent} from "./card.component";

@NgModule({
	imports: [

	],
	declarations: [
		CardComponent
	],
	exports: [
		CardComponent
	]
})
export class CardModule {}
