import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApplicationTechnology} from 'src/app/services/model/application-technology.model';
import {CatalogTechnology} from 'src/app/services/model/catalog-technology.model';
import {finalize, max, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization} from 'src/app/services/organization.service';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {TenantService} from 'src/app/services/tenant.service';

@Component({
	selector: 'app-most-used-technology',
	templateUrl: './most-used-technology.component.html',
	styleUrls: ['./most-used-technology.component.scss']
})
export class MostUsedTechnologyComponent implements OnInit, OnDestroy {

	@Input() filter: Subject<Organization|null|undefined>;

	tenantId: string;

	_initializing: boolean;
	_loading: boolean;

	maxCount: number = 0;
	count: Record<string, number> = {};
	sortedTechnologies: CatalogTechnology[] = [];

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private tenantService: TenantService) {
	}

	ngOnInit() {
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getAllApplicationTechnologyByTenantId(this.tenantId, org?.organizationId)),
				tap(data => this.setApplicationTechnologies(data)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	setApplicationTechnologies(applications: ApplicationTechnology[]): void {
		this.count = {};
		this.sortedTechnologies = [];
		applications.flatMap(a => a.technologies).forEach(tech => {
			if (!this.count[tech.technology.technologyId]) {
				this.count[tech.technology.technologyId] = 1;
				this.sortedTechnologies.push(tech.technology);
			} else {
				this.count[tech.technology.technologyId] += 1;
			}
			this.maxCount = Math.max(this.maxCount, this.count[tech.technology.technologyId]);
		});
		this.sortedTechnologies
			.sort((a, b) => this.count[b.technologyId] - this.count[a.technologyId]);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}
