import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SimpleTextInputComponent} from "./simple-text-input.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {TranslateModule} from "@ngx-translate/core";



@NgModule({

  	imports: [
    	CommonModule,
		ReactiveFormsModule,
		MatInputModule,
		MatIconModule,
		MatProgressSpinnerModule,
		TranslateModule
	],
	declarations: [
		SimpleTextInputComponent
	],
	exports: [
		SimpleTextInputComponent
	]
})
export class SimpleTextInputModule { }
