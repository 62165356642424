import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {TenantFileService} from 'src/app/services/back/tenant-file.service';
import {TranslateService} from '@ngx-translate/core';
import {filter} from 'rxjs/operators';
import {ApplicationDocumentService} from 'src/app/services/back/application-document.service';
import {ApplicationDocument} from 'src/app/services/model/application-document.model';
import {FileLink, FileType} from 'src/app/services/model/tenant-file.model';
import {ConfirmComponent, ConfirmModel} from 'src/app/modules/global/dialog/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationDocumentFormData} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-document-form/application-document-form.component';
import {ApplicationDocumentFormDialogComponent} from 'src/app/modules/home/applications/application-detail/architecture-tab/application-document-form/dialog/application-document-form-dialog.component';
import {SnackbarService} from 'src/app/services/front/snackbar.service';

@Component({
	selector: 'app-application-document',
	templateUrl: './application-document-preview.component.html',
	styleUrls: ['./application-document-preview.component.scss']
})
export class ApplicationDocumentPreviewComponent implements OnInit, OnDestroy {

	@Output() updated: EventEmitter<void> = new EventEmitter<void>();

	tenantId: string;
	isEditor: boolean;
	applicationId: string;

	_initializing: boolean;
	_loading: boolean;
	_deleting: string|undefined;
	initialized: boolean = false;

	documents: ApplicationDocument[] = [];
	fileType: typeof FileType = FileType;
	subscriptions: Subscription = new Subscription();

	constructor(private applicationDetailService: ApplicationDetailService,
							private applicationDocumentService: ApplicationDocumentService,
							private tenantFileService: TenantFileService,
							private translate: TranslateService,
							private snackBar: SnackbarService,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscriptions.add(this.applicationDetailService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscriptions.add(this.applicationDetailService.getApplicationDetailDataChanges()
			.pipe(tap(data => this.setApplicationDetailData(data)), filter(() => !this.initialized))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.subscriptions.add(this.switchLoading()
			.pipe(
				switchMap(() => this.applicationDocumentService.getAllApplicationDocument(this.tenantId, this.applicationId)),
				tap(documents => this.setDocuments(documents)),
				finalize(() => this.switchLoading()))
			.subscribe(() => this.initialized = true));
	}

	private setApplicationDetailData(data: ApplicationDetailData): void {
		this.tenantId = data.tenantId;
		this.isEditor = data.isEditor;
		this.applicationId = data.instance.applicationId;
	}

	private setDocuments(documents: ApplicationDocument[]): void {
		this.documents = documents
			.sort((a, b) => new Date(b.fileLink.createdAt).getTime() - new Date(a.fileLink.createdAt).getTime());
	}

	openApplicationDocumentFormDialog(document?: ApplicationDocument): void {
		const data: ApplicationDocumentFormData = {
			tenantId: this.tenantId,
			applicationId: this.applicationId,
			defaultData: document
		}
		this.dialog.open(ApplicationDocumentFormDialogComponent, { position: { right: '162.5px' }, width: '475px', data: data }).afterClosed()
			.pipe(
				filter(updated => !!updated),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()))
			.subscribe(() => this.initialize());
	}

	deleteApplicationDocument(document: ApplicationDocument): void {
		const data: ConfirmModel = {
			message: this.translate.instant('confirmModal.deleteContract'),
			confirmButton: this.translate.instant('button.delete'),
			closeButton: this.translate.instant('button.cancel')
		};
		this.dialog.open(ConfirmComponent, {data: data})
			.afterClosed()
			.pipe(
				filter(result => result),
				tap(() => this.switchDeleting(document.fileLink.fileLinkId)),
				switchMap(() => this.applicationDocumentService.deleteApplicationDocument(this.tenantId, this.applicationId, document.fileLink.fileLinkId)),
				tap(() => this.switchDeleting(document.fileLink.fileLinkId)),
				filter(deleted => !!deleted),
				tap(() => this.snackBar.show(this.translate.instant('page.application.detail.update.success'))),
				tap(() => this.updated.emit()))
			.subscribe(() => this.initialize());
	}

	downloadFile(upload: FileLink): void {
		this.tenantFileService.downloadTenantFile(this.tenantId, upload.fileLinkId)
			.subscribe((blob) => {
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.download = upload.name;
				link.click();
			});
	}

	openLink(link: string): void {
		window.open(link, '_blank');
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	private switchDeleting(fileLinkId: string): void {
		this._deleting = !this._deleting ? fileLinkId : undefined;
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
