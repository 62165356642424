import {DestroyRef, Injectable, OnInit} from "@angular/core";
import {MonoTypeOperatorFunction, Observable, Operator, Subject, tap} from "rxjs";
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "../authentication.service";

declare const Intercom: any;

@Injectable({
	providedIn: 'root'
})
export class IntercomService {
	private intercomEvents = new Subject<'hide' | 'show'>()
	private lastIntercomState: 'show' | 'hide' = 'hide';

	constructor(private authenticationService: AuthenticationService) {
		Intercom('onShow', () => this.intercomEvents.next('show'));
		Intercom('onHide', () => this.intercomEvents.next('hide'));

		this.intercomEvents.pipe(
			tap(event => this.lastIntercomState = event)
		).subscribe();
	}

	boot() : void {
		const userInfo = this.authenticationService.getUserInfo();
		if (userInfo) {
			const settings =  {
				app_id: environment.intercomAppId,
				name: userInfo.firstName + ' ' + userInfo.lastName,
				email: userInfo.email,
				user_id: userInfo.id,
				hide_default_launcher: true
			};
			Intercom('boot', settings);
		} else {
			let settings = {
				app_id: environment.intercomAppId,
				//custom_launcher_selector: '#custom_intercom_selector'
			};

			Intercom('boot', settings);
		}
	}

	show(): void {
		Intercom('show');
	}

	hide(): void {
		Intercom('hide');
	}

	toggle(): void {
		Intercom(this.lastIntercomState === 'show' ? 'hide' : 'show');
	}

	update(): void {
		Intercom('update', {})
	}

	onEvent(): Observable<'hide' | 'show'> {
		return this.intercomEvents.asObservable();
	}
}

export function updateIntercom<T>(intercomService: IntercomService): MonoTypeOperatorFunction<T> {
	return (source: Observable<T>) => source.pipe(
		tap(() => intercomService.update())
	);
}

