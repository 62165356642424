<div *ngIf="data.length > 0 && !displayAll" class="pl-m pr-m pt-m pb-m webkit-fill-available">
	<div class="disp-f f-d-r a-i-c j-c-sb pb-m">
		<div class="disp-f a-i-c mr-s">
            <span class="font-MM fw-700">{{ 'page.dashboard.alerts' | translate }}</span>
            <div class="disp-f a-i-c j-c-c" style="height: 25px; width: 25px;"
                 [tooltip]="'global.announcementTooltip'|translate" tooltipPosition="right">
                <img src="assets/icons/help-2.svg" width="14" height="14" alt=""/>
            </div>
		</div>
		<app-mini-button size="s" color="accent"
						 (onClick)="openCreateAnnouncement()">
			<img src="assets/icons/add.svg" class="filter-accent">
		</app-mini-button>

	</div>
	<div class="w-100 webkit-fill-available disp-f f-d-col gap-S">
		<div class="single-announcement" *ngFor="let announcement of data | slice:0:limit">
			<div class="announcement-table h-100">
				<div class="disp-f a-i-c j-c-sb announcement-first-col">
					<div class="disp-f a-i-c overflow-hidden gap-M">
						<div class="logo-bubble w-100">
							<img *ngIf="announcement.type === 'general'" class="filter-accent"
								 ngSrc="assets/icons/message-alert-square.svg" height="20" width="20" alt="">
							<img *ngIf="announcement.type === 'network'" class="filter-accent"
								 ngSrc="assets/icons/wifi-off.svg" height="20" width="20" alt="">
							<img *ngIf="announcement.type === 'maintenance'" class="filter-accent"
								 ngSrc="assets/icons/tools.svg" height="20" width="20" alt="">
						</div>
						<div class="disp-f a-i-c" style="overflow: hidden">
							<!-- NAME AND DATE -->
							<div class="disp-f f-d-col j-c-c w-100 gap-XS" style="overflow: hidden;">
								<span class="font-M fw-700"
									  style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
									{{ announcement.title }}
								</span>
								<span class="font-M fw-500"
									  style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ 'global.day.' + (announcement.updatedAt.getDay() == 0 ? '7' : announcement.updatedAt.getDay().toString()) | translate }} {{ announcement.updatedAt.getDate() }} {{ 'global.month.' + (announcement.updatedAt.getMonth() + 1) | translate }} {{ announcement.updatedAt.toLocaleTimeString().slice(0, 5) }}</span>
							</div>
						</div>
					</div>
					<div class="disp-f a-i-c" style="width: 10%; min-width: 10%;">
                        <app-chips-count [value]="announcement.seenCount" type="visibility" color="accent"></app-chips-count>
                    </div>
				</div>

				<div class="disp-f a-i-c" style="width: 20%;">
					<div class="disp-f a-i-c j-c-fe w-100" style="height: 100%;">
						<app-icon-button color="accent" size="m" [img]="'assets/icons/edit.svg'"
										 [tooltip]="'button.edit' | translate" [panelClass]="'fix-edit-margin'"
										 (click)="openAnnouncement(announcement)">
						</app-icon-button>
						<app-icon-button color="danger" size="m" [img]="'assets/icons/trash.svg'"
										 [tooltip]="'button.delete' | translate"
										 (click)="deleteAnnouncement(announcement.id)">
						</app-icon-button>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="data.length > limit" class="show-more-btn disp-f a-i-c j-c-c gap-XS c-p"
			 (click)="fromApp ? this.displayAll = true : onOpenAnnouncementDrawer()">
			<span class="font-M fw-500">{{ 'global.announcements.displayAllAnnouncements' | translate }}</span>
		</div>
	</div>
</div>
<div *ngIf="data.length > 0 && displayAll" class="pl-m pr-m pt-m pb-xl webkit-fill-available">
	<div class="disp-f f-d-r a-i-c j-c-sb pb-m">
		<div class="disp-f a-i-c mr-s">
			<span class="font-MM fw-700">{{ 'page.dashboard.alerts' | translate }}</span>
		</div>
		<app-mini-button size="s" color="accent"
						 (onClick)="openCreateAnnouncement()">
			<img src="assets/icons/add.svg" class="filter-accent">
		</app-mini-button>

	</div>
	<div class="w-100 webkit-fill-available disp-f f-d-col gap-S">
		<div class="single-announcement" *ngFor="let announcement of data">
			<div class="announcement-table h-100">
				<div class="disp-f a-i-c j-c-sb announcement-first-col">
					<div class="disp-f a-i-c overflow-hidden gap-M">
						<div class="logo-bubble w-100">
							<img *ngIf="announcement.type === 'general'" class="filter-accent"
								 ngSrc="assets/icons/message-alert-square.svg" height="20" width="20" alt="">
							<img *ngIf="announcement.type === 'network'" class="filter-accent"
								 ngSrc="assets/icons/wifi-off.svg" height="20" width="20" alt="">
							<img *ngIf="announcement.type === 'maintenance'" class="filter-accent"
								 ngSrc="assets/icons/tools.svg" height="20" width="20" alt="">
						</div>
						<div class="disp-f a-i-c" style="overflow: hidden">
							<!-- NAME AND DATE -->
							<div class="disp-f f-d-col j-c-c w-100 gap-XS" style="overflow: hidden;">
								<span class="font-M fw-700"
									  style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
									{{ announcement.title }}
								</span>
								<span class="font-M fw-500" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ 'global.day.' + (announcement.updatedAt.getDay() == 0 ? '7' : announcement.updatedAt.getDay().toString()) | translate }} {{ announcement.updatedAt.getDate() }} {{ 'global.month.' + (announcement.updatedAt.getMonth() + 1) | translate }} {{ announcement.updatedAt.toLocaleTimeString().slice(0, 5) }}</span>
							</div>
						</div>
					</div>
					<div class="disp-f a-i-c" style="width: 10%; min-width: 10%;">
                        <app-chips-count [value]="announcement.seenCount" type="visibility" color="accent"></app-chips-count>
					</div>
				</div>

				<div class="disp-f a-i-c" style="width: 20%;">
					<div class="disp-f a-i-c j-c-fe w-100" style="height: 100%;">
						<app-icon-button color="accent" size="m" [img]="'assets/icons/edit.svg'"
										 [tooltip]="'button.edit' | translate" [panelClass]="'fix-edit-margin'"
										 (click)="openAnnouncement(announcement)">
						</app-icon-button>
						<app-icon-button color="danger" size="m" [img]="'assets/icons/trash.svg'"
										 [tooltip]="'button.delete' | translate"
										 (click)="deleteAnnouncement(announcement.id)">
						</app-icon-button>
					</div>
				</div>
			</div>
		</div>
		<div class="show-more-btn disp-f a-i-c j-c-c gap-XS c-p" (click)="this.displayAll = false;">
			<span class="font-S fw-500">{{ 'global.announcements.displayLessAnnouncements' | translate }}</span>
		</div>
	</div>
</div>
<div *ngIf="data.length <= 0" class="pl-m pr-m pt-m pb-xl" [ngStyle]="{ 'height': fromApp ? '280px' : '90%' }">
	<div class="disp-f f-d-r a-i-c">
		<div class="disp-f a-i-c mr-s">
            <span class="font-MM fw-700">{{ 'page.dashboard.alerts' | translate }}</span>
            <div class="disp-f a-i-c j-c-c ml-xs"
                 [tooltip]="'global.announcementTooltip'|translate" tooltipPosition="right">
                <img src="assets/icons/help-2.svg" width="14" height="14" alt=""/>
            </div>
		</div>
	</div>
	<div class="w-100 flex-justify-center a-i-c f-d-col webkit-fill-available gap-XXL" [style.margin-top]="fromApp ? '20px' : '0'">
		<img class="mr-xs" src="assets/illustrations/no_announcement.svg" [width]="noAnnouncementWidth" alt="">
		<div class="font-M fw-500">
			<span *ngIf="!fromApp">{{ 'global.noAnnouncement' | translate }}</span>
			<span *ngIf="fromApp">{{ 'global.noAppAnnouncement' | translate }} <span class="fw-700">{{ fromApp.name }}</span></span>
		</div>
		<app-text-button type="flat" (click)="openCreateAnnouncement()">
			<span *ngIf="!fromApp" class="fw-500">{{ 'global.addAnnouncement' | translate }}</span>
			<span *ngIf="fromApp" class="fw-500">{{ 'global.addAppAnnouncement' | translate }} {{ fromApp.name }}</span>
		</app-text-button>
	</div>
</div>
