import {Observable} from "rxjs";
import {ImageUtils} from "src/app/utils/image.utils";
import {Injectable} from "@angular/core";

@Injectable()
export class ImageService {
	protected cache = new Map<string, string[]>();

	computeRGBColor(logoURL: string) : Observable<string[]>	{
		if (this.cache.has(logoURL)) {
			return new Observable<string[]>(observer => {
				observer.next(this.cache.get(logoURL))
				observer.complete()
			});
		} else {
			return new Observable<string[]>(observer => {
				const img = document.createElement('img');
				const imageURL = logoURL;
				img.crossOrigin = 'Anonymous';
				if (imageURL !== undefined) {
					img.src = imageURL;
					img.onload = () => {
						const dominantColor = ImageUtils.getAverageRGB(img);
						this.cache.set(logoURL, dominantColor)
						observer.next(dominantColor);
						observer.complete();
					};
					img.onerror = err => {
						observer.error(err);
						observer.complete();
					};
				} else {
					observer.next(["rgba(0,0,0,0.1)", "rgba(0,0,0,1)"]);
					observer.complete();
				}
			});
		}
	}
}
