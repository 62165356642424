<div class="auto-infrastructure-add-container mt-s" style="padding-left: 20px; padding-top: 20px;">
	<div class="flex gap-M flex-align-center c-p underlined-hover" style="width: fit-content" (click)="goBack()">
		<img src="assets/icons/arrow-right-black.svg" height="10" width="6" style="transform: rotate(180deg)">
		<span>{{ 'button.back' | translate }}</span>
	</div>
	<div class="flex-column flex-center gap-L h-100">
		<div [@inAnimation1] class="infrastructure-add-button step-2 flex-column gap-L">
			<span class="fw-700 font-L">{{ 'page.infrastructure.add.packageDownload' | translate }}</span>
			<span>{{ 'page.infrastructure.add.selectOs' | translate }}</span>
			<div class="flex flex-align-center gap-S flex-wrap">
				<div class="os-model-button flex-align-center gap-M" [class.selected]="selectedOs === 'windows'" (click)="selectOs('windows')">
					<img ngSrc="assets/systems/logo-windows.png" alt="" height="16" width="16">
					<span>Windows</span>
				</div>
				<div class="os-model-button flex-align-center gap-M" [class.selected]="selectedOs === 'suse'" (click)="selectOs('suse')">
					<img height="16" src="assets/systems/logo-suse.svg">
					<span>Suse</span>
				</div>
				<div class="os-model-button flex-align-center gap-M" [class.selected]="selectedOs === 'red_hat'" (click)="selectOs('red_hat')">
					<img height="16" src="assets/systems/logo-redhat.svg">
					<span>Red Hat</span>
				</div>
				<div class="os-model-button flex-align-center gap-M" [class.selected]="selectedOs === 'ubuntu'" (click)="selectOs('ubuntu')">
					<img height="16" src="assets/systems/logo-ubuntu.svg">
					<span>Ubuntu</span>
				</div>
				<div class="os-model-button flex-align-center gap-M" [class.selected]="selectedOs === 'debian'" (click)="selectOs('debian')">
					<img height="16" src="assets/systems/logo-debian.png">
					<span>Debian</span>
				</div>
			</div>
			<app-text-button type="flat" color="accent" (click)="downloadKapsulOnOs()">
				{{ 'page.infrastructure.add.downloadPackageFor' | translate }} {{ formatOs() }}
			</app-text-button>
		</div>
		<div [@inAnimation2] class="infrastructure-add-button step-2 flex-column gap-L">
			<span class="fw-700 font-L">{{ 'page.infrastructure.add.kapsulAPI' | translate }}</span>
			<span>{{ 'page.infrastructure.add.kapsulSubtitle' | translate }}</span>
			<app-copy-text style="flex-grow: 1" [text]="apiKey"></app-copy-text>
		</div>
		<app-text-button [@inAnimation3] (click)="openDoc()" color='grey' type='stroked' shape='default' [disabled]='false' [loading]='false' badge='' badgePosition='above after' panelClass=''>
			<div class="flex flex-align-center gap-M">
				<img height="20" src="assets/icons/info-black.svg" class="filter-grey">
				<span>{{ 'page.infrastructure.add.doc' | translate }}</span>
			</div>
		</app-text-button>
	</div>
</div>
