import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {DateTranslateRef, DateUtils} from "../../../../utils/date.utils";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {APPLICATIONS_URL} from "../../../../models/home/navigation.model";
import {CurrentTenantService} from '../../../../services/front/current-tenant.service';
import {Subscription, tap} from 'rxjs';
import {ActivityLogService} from 'src/app/services/back/activity-log.service';
import {ActivityLog, sortActivityLogs} from 'src/app/services/model/activity-log.model';

@Component({
  selector: 'app-home-page-log-history',
  templateUrl: './home-page-log-history.component.html',
  styleUrls: ['./home-page-log-history.component.scss']
})
export class HomePageLogHistoryComponent implements OnInit, OnDestroy {

	@Output() onClose = new EventEmitter<any>();

	tenantId: string;
	logs: ActivityLog[] = []
	loading: boolean = false;
	index = 1;
	groupSize = 20;
	noMore: boolean = false;
	subscription = new Subscription();

	constructor(private activityLogService: ActivityLogService,
							private currentTenantService: CurrentTenantService,
							private translate: TranslateService,
							private router: Router) {
	}

	ngOnInit(): void {
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.loadLogs()));
	}

	close(): void {
		this.onClose.emit();
	}

	loadLogs() {
		if (!this.noMore && !this.loading) {
			this.loading = true;
			let limit = this.groupSize
			let offset = (this.index - 1) * this.groupSize
			this.subscription.add(this.activityLogService.getAllTenantActivityLog(this.tenantId, limit, offset).subscribe((logs) => {
				if (logs.length < 1) {
					this.noMore = true
				} else {
					if (logs.length < this.groupSize)
						this.noMore = true
					this.logs.push(...logs)
					this.logs.sort(sortActivityLogs)
					this.index++
				}
				this.loading = false
			}, error => {
				this.loading = false
			}))
		}
	}

	goToApp(appId: string) {
		this.router.navigate([APPLICATIONS_URL], {queryParams: {applicationId: appId}, queryParamsHandling: "merge"})
	}

	getDateGap(date: string): string {
		let translateRef: DateTranslateRef|null = DateUtils.getDateTranslateRef(date)
		return !!translateRef
			? this.translate.instant('page.home.homepage.history.date.' + translateRef.translateRef, {value: translateRef.value})
			: '-'
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
