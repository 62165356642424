import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Organization, OrganizationService, OrganizationStatistic} from "src/app/services/organization.service";
import {finalize, forkJoin, Observable, of, Subscription, switchMap, tap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {ActivatedRoute} from "@angular/router";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {DeleteDialogComponent, DeleteDialogData} from "../../../design-system/delete-dialog/delete-dialog.component";
import {filter} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {ApplicationHealth} from 'src/app/services/model/new-application.model';
import {TendencyWidgetContent} from 'src/app/modules/global/application-mini-widget/application-mini-widget.component';

@Component({
	templateUrl: './structure.component.html',
	styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit, OnDestroy {

	@Input() data: StructureComponentData;

	@Output() onClose: EventEmitter<StructureComponentResponse> = new EventEmitter<StructureComponentResponse>();

	tenantId: string;
	isEditor: boolean = true;

	loading: boolean = false;

	tcoWidget: TendencyWidgetContent|undefined;
	usersWidget: TendencyWidgetContent|undefined;
	availabilityWidget: TendencyWidgetContent|undefined;
	applications: ApplicationHealth[] = [];
	settings: number|null = null;

	subscription: Subscription = new Subscription();

	constructor(private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							private translateService: TranslateService,
							private snackBarService: SnackbarService,
							private activatedRoute: ActivatedRoute,
							private dialog: MatDialog) {
	}

	ngOnInit(): void {
		this.subscription.add(this.activatedRoute.queryParams
			.subscribe(() => this.settings = this.organizationService.getParameter()));
		this.subscription.add(this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(tap(tenantId => this.tenantId = tenantId))
			.subscribe(() => this.initialize()));
	}

	initialize(): void {
		this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.organizationService.getOrganizationStatistic(this.tenantId, this.data.structure.organizationId),
					this.organizationService.getAllApplicationHealthByOrganizationId(this.tenantId, this.data.structure.organizationId)
				])),
				tap(([statistic, applications]) => this.setOrganizationData(statistic, applications)),
				finalize(() => this.switchLoading()))
			.subscribe()
	}

	private setOrganizationData(statistic: OrganizationStatistic, applications: ApplicationHealth[]): void {
		this.applications = applications;
		this.availabilityWidget = {
			value: applications.length > 0 ? statistic.availability.percent : undefined,
			day: statistic.availability.queryType
		}
		this.tcoWidget = {
			value: statistic.cost.value,
			day: statistic.cost.queryType
		}
		this.usersWidget = {
			value: statistic.usage.value,
			day: statistic.usage.queryType
		}
	}

	close() {
		this.onClose.emit({ event: 'close' });
	}

	onDelete(): void {
		const data: DeleteDialogData = {
			icon: '/assets/illustrations/delete/organization.svg',
			title: this.translateService.instant('page.organization.structure.delete.title'),
			subtitle: this.translateService.instant('page.organization.structure.delete.subtitle'),
			warningMessage: this.translateService.instant('page.organization.structure.delete.warningMessage'),
			deleteButton: this.translateService.instant('page.organization.structure.delete.deleteButton')
		}
		this.dialog.open(DeleteDialogComponent, { width: '475px', position: { right: '150px' }, data: data })
			.afterClosed()
			.pipe(filter(result => result))
			.subscribe(() => this.onClose.emit({ event: 'delete', id: this.data.structure.organizationId }));

	}

	onSaveSettings(): void {
		this.snackBarService.show(this.translateService.instant('page.application.detail.update.success'));
		this.organizationService.navigateInAndOutOfParameter(null);
	}

	onOpenOrgSettings(): void {
		if (this.settings != null) {
			this.organizationService.navigateInAndOutOfParameter(null);
		} else {
			this.organizationService.navigateInAndOutOfParameter(1);
		}
	}

	private switchLoading(): Observable<{}> {
		this.loading = !this.loading;
		return of({});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}

export interface StructureComponentData {
	structure: Organization;
	parent: Organization;
	children: Organization[];
}

export interface StructureComponentResponse {
	event: 'close'|'delete';
	id?: string;
}
