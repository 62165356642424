import {Component, OnDestroy, OnInit} from '@angular/core';
import {TenantOverview, TenantService, TenantUsageStatistic} from 'src/app/services/tenant.service';
import {finalize, Observable, of, Subject, Subscription, switchMap, tap} from 'rxjs';
import {Organization, OrganizationService, OrganizationTree} from 'src/app/services/organization.service';
import {TranslateService} from '@ngx-translate/core';
import {TopbarService} from 'src/app/services/front/topbar.service';
import {ActivatedRoute} from '@angular/router';
import {MiniWidget} from '../../global/mini-widget/mini-widget.component';
import {AppRouteUrl, SETTINGS_BILLING_URL} from 'src/app/models/home/navigation.model';
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Component({
  selector: 'app-usage-dashboard',
  templateUrl: './usage-dashboard.component.html',
  styleUrls: ['./usage-dashboard.component.scss']
})
export class UsageDashboardComponent implements OnInit, OnDestroy {

	tenant: TenantOverview;
	organizationTree: OrganizationTree;
	filter: Subject<Organization|null|undefined> = new Subject<Organization|null|undefined>();

	_initializing: boolean;
	_loading: boolean;
	_loadingOrganization: boolean;

	currentUsage: MiniWidget;
	satisfaction: MiniWidget;
	freshlyDiscoveredApps: MiniWidget;
	allExtensionsCount: MiniWidget;

	settingsBillingUrl: AppRouteUrl = SETTINGS_BILLING_URL;

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private topBarService: TopbarService,
							private translateService: TranslateService,
							private currentTenantService: CurrentTenantService,
							private tenantService: TenantService,
							private organizationService: OrganizationService,
							public activatedRoute: ActivatedRoute) {
	}

	ngOnInit() {
		this.setDefaultWidgetData();
		this.topBarService.onTitleChange(this.translateService.instant('menu.usage'), this.translateService.instant('menu.subtitle.usage'));
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(
				tap(tenant => this.tenant = tenant),
				switchMap(() => this.fetchOrganizationTree()))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	private setDefaultWidgetData(): void {
		this.currentUsage = {
			numericSubject: 'page.usageDashboard.activeUsers',
			iconUrl: 'assets/icons/users-widget.svg',
			iconSize: 'm'
		};
		this.satisfaction = {
			numericSubject: 'page.usageDashboard.globalSatisfaction',
			iconUrl: 'assets/icons/star.svg',
			iconSize: 'l'
		};
		this.freshlyDiscoveredApps = {
			numericSubject: 'page.usageDashboard.detectedApplications',
			iconUrl: 'assets/icons/checkmark.svg',
			iconSize: 'm'
		};
		this.allExtensionsCount = {
			numericSubject: 'page.usageDashboard.deployedExtensions',
			iconUrl: 'assets/icons/flow-icon.svg',
			iconSize: 'l'
		};
	}

	fetchOrganizationTree(): Observable<{}> {
		this._loadingOrganization = true;
		return this.organizationService.getOrganizationTreeByTenantId(this.tenant.configuration.id)
			.pipe(
				tap(organizationTree => this.organizationTree = organizationTree),
				tap(() => this._loadingOrganization = false));
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => this.tenantService.getTenantUsageStatistic(this.tenant.configuration.id, org?.organizationId, QueryRangeType.PAST_1_MONTH)),
				tap(statistic => this.setWidgetData(statistic)),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	private setWidgetData(statistic: TenantUsageStatistic): void {
		this.currentUsage.numericIndicator = statistic.usage.value ?? 0;
		this.satisfaction.numericIndicator = statistic.satisfaction.value ? Math.round((statistic.satisfaction.value + Number.EPSILON) * 100) / 100 : undefined;
		this.satisfaction.numericOutOf = statistic.satisfaction.outOf;
		this.freshlyDiscoveredApps.numericIndicator = statistic.discoveredCount.count;
		this.allExtensionsCount.numericIndicator = statistic.extensionCount;
	}

	refreshWithFilters(filter: Organization|null): void {
		this.filter.next(filter);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}
