@if (_initializing || _loading) {
    <div class="border-box-full">
        <app-dashboard-page-loader></app-dashboard-page-loader>
    </div>
} @else {
    <div class="flex-column pad-M border-box-full">
        @if (type === 'widget') {
            <div class="flex-align-center flex-space-between pad-bottom-M" [class.c-p]="applications.length > 0"
                 (click)="openDrawer()">
                <span class="font-MM fw-700">{{'page.architectureDashboard.mostComplexApps'|translate}}</span>
                <img *ngIf="applications.length > 0" src="assets/icons/arrow-right.svg" height="12" alt="">
            </div>
        }
        @if (applications.length > 0) {
            <div class="flex-column w-100 h-100" [style.gap]="type === 'widget' ? '1%' : '10px'">
                <div class="flex-align-center grey mb-s" [class]="type === 'widget' ? 'border-grey-bottom' : 'border-light-grey-bottom'" [style.height.px]="30">
                    <span class="font-M fw-500 text-overflow-ellipsis text-left pad-left-XS w-40">{{'page.application.table.application'|translate}}</span>
                    <span class="font-M fw-500 text-overflow-ellipsis text-left w-20">{{'page.application.table.criticality'|translate}}</span>
                    <span class="font-M fw-500 text-overflow-ellipsis text-center w-20">{{'page.application.table.complexity'|translate}}</span>
                    @if (type === 'widget') {
                        <span class="font-M fw-500 text-overflow-ellipsis text-right w-20">{{'page.application.table.users'|translate}}</span>
                    } @else {
                        <span class="font-M fw-500 text-overflow-ellipsis text-center w-20">{{'page.application.table.users'|translate}}</span>
                        <span style="width: 50px;"></span>
                    }
                </div>
                @for (app of filteredApplications; track app.application.id) {
                    <div class="flex-align-center greyed-hover b-radius-9 c-p" [style.height.px]="40"
                         (click)="goToApp(app.application.id)">
                        <div class="flex-align-center pad-left-XS w-40">
                            <app-display-application class="w-100" [application]="app.application"></app-display-application>
                        </div>
                        <div class="flex-align-center w-20">
                            <app-chips-criticality [criticality]="app.application.criticality"></app-chips-criticality>
                        </div>
                        <div class="flex-center w-20">
                            <div class="flex-center horizontal-bar-L pad-0-M" [class]="app.complexity.level + '-chips'">
                                <span class="font-S fw-700">{{app.complexity.level}}</span>
                            </div>
                        </div>
                        @if (type === 'widget') {
                            <div class="flex-align-center flex-justify-end w-20">
                                <app-chips-count [value]="app.usage.value" type="user" bold></app-chips-count>
                            </div>
                        } @else {
                            <div class="flex-center w-20">
                                <app-chips-count [value]="app.usage.value" type="user" bold></app-chips-count>
                            </div>
                            <div class="flex-align-center flex-justify-end" style="width: 50px;">
                                <img src="assets/icons/arrow-right.svg" class="mr-s" height="12" alt="">
                            </div>
                        }
                    </div>
                }
            </div>
        } @else {
            <div class="flex-column flex-center gap-M m-auto">
                <img src="assets/icons/new-checkmark.svg" height="34" width="34" alt=""/>
                <div class="font-M fw-500 grey">{{'page.usageDashboard.noApps'|translate}}</div>
            </div>
        }
    </div>
}
