import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-system-age-average',
	templateUrl: './system-age-average.component.html',
	styleUrls: ['./system-age-average.component.scss']
})
export class SystemAgeAverageComponent {
	@Input() loading: boolean;
	@Input() averageAge: number;
}
