import { Component } from '@angular/core';
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {Subscription} from "rxjs";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  	selector: 'app-snackbar',
  	templateUrl: './snackbar.component.html',
  	styleUrls: ['./snackbar.component.scss'],
	animations: [
		trigger('state', [
			transition(':enter', [
				style({ bottom: '-100px'}),
				animate('300ms cubic-bezier(0, 0, 0.2, 1)', style({
					bottom: '20px'
				})),
			]),
			transition(':leave', [
				animate('300ms cubic-bezier(0.4, 0.0, 1, 1)', style({
					bottom: '-100px',
				}))
			])
		])
	]
})
export class SnackbarComponent {
	show = false;
	message: string = "My snackbar";
	type: string = 'success-snack';
	snackbarSubscription: Subscription;

	constructor(private snackbarService: SnackbarService) {
	}

	ngOnInit() {
		this.snackbarSubscription = this.snackbarService.snackbarState
			.subscribe(
				(state) => {
					if (state.type) {
						this.type = state.type;
					} else {
						this.type = 'success-snack';
					}
					this.message = state.message;
					this.show = state.show;
					setTimeout(() => {
						this.show = false;
					}, 3000)
				}
			);
	}

	ngOnDestroy() {
		this.snackbarSubscription.unsubscribe();
	}
}
