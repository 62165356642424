import {Point} from "jspdf";
import {BaseEngine} from "src/app/modules/home/diagrams/common/base-engine";
import {ClickableObject} from "../../common/diagram";
import {OrganizationTree} from "src/app/services/organization.service";
import {TableColumn} from "../../common/shapes/table-column";
import {Layers} from "../../common/layer";
import {TableRow} from "../../common/shapes/table-row";
import {ApplicationSquare} from "../../common/shapes/application-square";
import {DiagramApplicationMatrix} from 'src/app/services/tenant.service';
import {Category} from 'src/app/services/model/application-category.model';

export interface Cell {
	structure: string;
	team: string;
	category: string;
	applications: DiagramApplicationMatrix[];
}

export interface Table {
	columns: Map<string, Map<string, Cell>>;
}

export interface TableColumnRepresentation { id: string, title: string, subtitle: string, origin: Point, width: number, height: number }

export interface TableRowRepresentation { id: string, title: string, origin: Point, width: number, height: number }

export interface TableCellRepresentation { applications: DiagramApplicationMatrix[], origin: Point, width: number, height: number }

export interface TableApplicationRepresentation { application: DiagramApplicationMatrix, origin: Point }

export interface TableRepresentation {
	columns: TableColumnRepresentation[];
	rows: TableRowRepresentation[];
	cells: TableCellRepresentation[];
}

export class ApplicationMatrixEngine extends BaseEngine {
	protected tableRepresentation: TableRepresentation;
	protected columnsDrawableObject: TableColumn[] = [];
	protected rowsDrawableObject: TableRow[] = [];
	protected cellsDrawableObject: ApplicationSquare[] = [];

	constructor(
		protected context2D: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D,
		protected canvas: HTMLCanvasElement,
		protected applications: DiagramApplicationMatrix[],
		protected categories: Category[],
		protected organizations: OrganizationTree[],
		protected height: number,
		protected width: number
	) {
		super(context2D, canvas, height, width);
	}

	protected onInit(): void {
		this.buildDrawables();
	}

	protected onUpdate(): void {
	}

	protected onDraw(): void {
		const layers = new Layers();

		this.renderer.draw();
		this.columnsDrawableObject.forEach(column => column.draw(layers));
		this.rowsDrawableObject.forEach(row => row.draw(layers));
		this.cellsDrawableObject.forEach(cell => cell.draw(layers));

		layers.draw();
	}

	protected onClick(point: Point): void {
		this.cellsDrawableObject.forEach(application => {
			if (application.isAtCoordinates(point)) {
				this.objectClickSubject.next({id: application.getId(), position: point});
			}
		})
	}

	protected onMouseMove(point: Point): void {
		this.cellsDrawableObject.forEach(application => {
			application.unsetMouseOver();
			if (application.isAtCoordinates(point)) {
				this.canvas.style.cursor = 'pointer';
				application.setMouseOver();
			}
		})
	}

	protected getClickableObjects(): ClickableObject[] {
		return this.cellsDrawableObject;
	}

	protected buildDrawables(): void {
		this.tableRepresentation = this.buildTableRepresentation(this.buildTable());

		this.tableRepresentation.columns.forEach(column => {
			this.columnsDrawableObject.push(new TableColumn(this.context2D as CanvasRenderingContext2D, column.title, column.subtitle, column.origin, column.width, column.height));
		});

		this.tableRepresentation.rows.forEach(row => {
			this.rowsDrawableObject.push(new TableRow(this.context2D as CanvasRenderingContext2D, row.title, row.origin, row.width, row.height));
		});

		this.tableRepresentation.cells.forEach(cell => {
			const representations = this.buildCellTable(cell);
			representations.forEach(rep => {
				this.cellsDrawableObject.push(new ApplicationSquare(this.context2D as CanvasRenderingContext2D, rep.origin, rep.application.application.id, rep.application.application.logo, rep.application.application.name, rep.application.application.criticality, ApplicationSquare.ACTIVE_FONT_COLOR, false));
			});
		});

		// Save size of the canvas for PDF export
		this.setCurrentHeight((this.tableRepresentation.rows.length * (250 + 20)) + 100);
		this.setCurrentWidth(this.tableRepresentation.columns.length * 350 + 220);
	}

	protected buildCellTable(cell: TableCellRepresentation): TableApplicationRepresentation[] {
		const innerPadding: number = 20;
		return cell.applications
			.map((application, idx) => ({
				application: application,
				origin: {
					x: innerPadding + cell.origin.x + (idx % 5) * 65,
					y: (cell.origin.y + innerPadding) + Math.floor(idx / 5) * 65
				}
			}));
	}

	protected buildTableRepresentation(table: Table): TableRepresentation {
		const columns: TableColumnRepresentation[] = [];
		const rows: TableRowRepresentation[] = [];
		const cells: TableCellRepresentation[] = [];

		// Rows
		const rowBuffer = new Map<string, { name: string, position: Point }>();
		const initialRowPadding = 100;
		const rowPadding = 20;
		const rowHeight = 250;
		let rowCount = 0;

		// Columns
		const initialPadding = 220;
		const columnWidth = 350;
		const columnBuffer = new Map<string, { name: string, position: Point }>();

		const orderedColumns = Array.from(table.columns.entries())
			.map(([columnId, column], columnIndex) => {
				const structure = Array.from(column.values()).map(cell => cell.structure)[0] ?? '';
				return {structure, column, columnId}
			})
			.sort((a, b) => a.structure.localeCompare(b.structure))
			.map(c => ({id: c.columnId, rows: c.column}));

		// Build rows
		Array.from(orderedColumns.values()).forEach((column, columnIndex) => {
			// Rows
			Array.from(column.rows.entries()).map(([key, cell]) => {
				if (!rowBuffer.has(key)) {
					rowBuffer.set(key, {name: cell.category, position: {x: 0, y: initialRowPadding + (rowCount * (rowHeight + rowPadding))}});
					rowCount++;
				}
			})
		});
		rows.push(...Array.from(rowBuffer.entries()).map(([id, row]) => ({ id, title: row.name, origin: row.position, width: table.columns.size * columnWidth + initialPadding, height: rowHeight })));

		// Build columns
		Array.from(orderedColumns.values()).forEach((column, columnIndex) => {
			const [structure, team] = Array.from(column.rows.values()).map(cell => [cell.structure, cell.team])[0] ?? '';

			columnBuffer.set(column.id, {name: structure, position: {x: columnIndex * columnWidth + initialPadding, y: 30}});

			columns.push({
				id: column.id,
				title: structure,
				subtitle: team,
				origin: {x: columnIndex * columnWidth + initialPadding, y: 30},
				width: columnWidth,
				height: (rows.length * (rowHeight + rowPadding)) + initialRowPadding
			});
		});

		// Build cells
		Array.from(orderedColumns.values()).forEach((column, columnIndex) => {
			Array.from(column.rows.entries()).forEach(([rowId, cell]) => {
				// Cells
				const cellHeight = rowHeight;
				const cellWidth = columnWidth;
				const cellOrigin = {
					x: columnBuffer.get(column.id)!.position.x ?? -1,
					y: rowBuffer.get(rowId)?.position.y ?? -1
				};

				cells.push({
					applications: cell.applications,
					origin: cellOrigin,
					width: cellWidth,
					height: cellHeight
				});
			});
		})

		return {columns, rows, cells};
	}

	protected buildTable(): Table {
		const cells: Map<string, Map<string, Cell>> = new Map();
		this.categories.sort((a, b) => a.name.localeCompare(b.name))
		this.organizations.forEach(parentOrg => {
			parentOrg.children.forEach(childOrg => {
				cells.set(childOrg.organization.organizationId, new Map());
				this.categories.forEach(category => {
					const cell: Cell = {
						structure: parentOrg.organization.name,
						team: childOrg.organization.name,
						category: category.name,
						applications: this.applications
							.filter(app => app.categoryId === category.categoryId
								&& app.organizationIds.some(orgId => orgId === childOrg.organization.organizationId))
					};
					cells.get(childOrg.organization.organizationId)!.set(category.categoryId, cell);
				});
			});
		});
		return { columns: cells }
	}

	update(applications: DiagramApplicationMatrix[], organizations: OrganizationTree[], categories: Category[]): void {
		this.columnsDrawableObject = [];
		this.rowsDrawableObject = [];
		this.cellsDrawableObject = [];
		this.applications = applications;
		this.organizations = organizations;
		this.categories = categories;
		this.buildDrawables();
	}
}
