@if (structures.length > 0 && !isLoading) {
	<div class="pl-l pr-l pt-l pb-l primary">
		<!-- Header -->
		<div class="disp-f a-i-c j-c-s f-d-r mb-xl">
			<app-chips [square]="true" [size]="'xl'" >
				<img src="/assets/icons/team.svg" class="filter-grey" height="25px">
			</app-chips>
			<div class="flex-column gap-XS ml-s">
				<div class="font-L fw-700">{{ 'page.appDetails.usage.addTeam.title' | translate }}</div>
				<div class="font-M fw-300">{{ 'page.appDetails.usage.addTeam.subtitle' | translate }}</div>
			</div>
		</div>

		<!-- Body -->
		<div class="bg-rounded" style="max-height: calc(100vh - 300px); overflow: auto">
			<div *ngIf="isLoading" class="pt-m pb-m pr-m pl-m"><app-list-loader></app-list-loader></div>

			<ng-container *ngFor="let structure of structures; let isFirst = first; let isLast = last">
				<div class="pb-s pt-s" [ngClass]="{ 'border-top': !isFirst }" *ngIf="structure.children.length > 0">
					<ng-container>
						<div class="pr-m fw-700 flex-align-center flex-space-between toggle-header" >
							<div class="flex-align-center">
								<div (click)="toggleOpen(structure)" class="pl-m pr-m c-p">
									<img class="toggle-icon" [ngClass]="{'icon-opened': isOpen(structure.organization.organizationId)}" src="assets/icons/arrow-right.svg" height="11" >
								</div>
								<div class="font-M fw-500">{{ structure.organization.name }}</div>
							</div>
							<div class="flex-align-center gap-S">
								<img class="filter-accent-secondary" *ngIf="isAutoDiscovered(structure) && !isOpen(structure.organization.organizationId)" src="assets/icons/autodiscover.svg">
								<mat-checkbox [checked]="isSelected(structure.organization.organizationId)"
                                              (change)="onStructureChange(structure, $event.checked)"></mat-checkbox>
							</div>
						</div>
						<div class="pr-m toggle-content" style="padding-left: 38px;"
                             [ngStyle]="{'max-height': isOpen(structure.organization.organizationId) ? structure.children.length * 40 + 'px' : '0'}"
                             [ngClass]="{'toggle-content-opened': isOpen(structure.organization.organizationId)}">
							<div *ngFor="let team of structure.children" class="disp-f a-i-c flex-space-between">
								<div class="font-M fw-500">{{ team.organization.name }}</div>
								<div class="flex-align-center gap-S">
									<img class="filter-accent-secondary" *ngIf="isAutoDiscovered(team)" src="assets/icons/autodiscover.svg">
									<mat-checkbox [checked]="isSelected(team.organization.organizationId)" color="accent"
                                                  (change)="onTeamChange(structure, team, $event.checked)"></mat-checkbox>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>

		<!-- Actions -->
		<div class="w-100 disp-f f-d-r j-c-sb a-i-c mt-l gap-M">
			<app-text-button class="w-50" type="stroked"
							 (click)="onDismiss()">{{ 'button.cancel' | translate }}</app-text-button>
			<app-text-button class="w-50" type="flat" [loading]="isConfirming"
							 (click)="onConfirm()">{{ 'button.add' | translate }}</app-text-button>
		</div>
	</div>
} @else if (isLoading) {
	<div class="pt-l pr-l pl-l" style="padding-bottom: 8px;">
		<app-list-loader></app-list-loader>
	</div>
} @else {
	<app-dialog-placeholder-card (onAction)="handlePlaceholderEvent($event)">
		<ng-container content>
			<img src="assets/illustrations/no-team-placeholder.svg" width="158">

			<div class="disp-f f-d-col a-i-c j-c-c mt-xl">
				<div class="font-LL text-center fw-700">{{'page.appDetails.usage.placeholder.teams.title'|translate}}</div>
				<div class="font-M text-center grey mt-m w-70">{{'page.appDetails.usage.placeholder.teams.description'|translate}}</div>
			</div>
		</ng-container>

		<ng-container action-button>
			{{'page.appDetails.usage.placeholder.teams.actionButton'|translate}}
		</ng-container>
	</app-dialog-placeholder-card>
}
