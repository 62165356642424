import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from './security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {Cost, Usage} from 'src/app/services/model/application-contract.model';
import {Health} from 'src/app/services/model/new-application.model';

@Injectable()
export class ApplicationInstanceService {
	// TODO @TAN DELETE THIS SERVICE

	private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/applications`;

	constructor(protected httpClient: HttpClient) {
	}

	public getApplicationDocumentationStatistic(tenantId: string, applicationId: string): Observable<ApplicationDocumentationStatistic> {
		// TODO @TAN Rework path and move away
		return this.httpClient.get<ApplicationDocumentationStatistic>(`${this.serviceUrl(tenantId)}/instances/${applicationId}/statistics/documentation`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}

	public getApplicationPerformanceHistory(tenantId: string, applicationId: string): Observable<ApplicationPerformanceHistory> {
		// TODO @TAN Rework path and move away
		return this.httpClient.get<ApplicationPerformanceHistory>(`${this.serviceUrl(tenantId)}/instances/${applicationId}/performance-history`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
	}
}

export interface PerformanceHistory {
	tenantId: string,
	applicationId: string,
	timestamp: Date,
	globalRating?: PerformanceRating,
	fcp?: SingleMetric,
	lcp?: SingleMetric,
}

export interface ApplicationPerformanceHistory {
	webVitals: PerformanceHistory[],
	firstEverPerformance: Date
}

export interface SingleMetric {
	type: 'FCP' | 'LCP'
	value: number,
	rating: PerformanceRating
}

export enum PerformanceRating {
	GOOD = 'GOOD',
	NEEDS_IMPROVEMENT = 'NEEDS_IMPROVEMENT',
	POOR = 'POOR'
}

export interface ApplicationDocumentationStatistic {
	documentationPercent: number
}

export interface Logo {
	url?: string|null;
	name?: string|null;
	fontColor?: string|null;
	backgroundColor?: string|null;
}

export interface Event {
	startDate: Date;
	endDate?: Date | null;
	name?: string;
	description?: string;
	picUrl?: string;
	critical?: boolean
}

export interface ApplicationStatistic {
	cost: Cost;
	usage: Usage;
	health: Health;
}
