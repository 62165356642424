<div class="flex-center" [style.width]="'calc(311px * '+ scale +')'" [style.height]="'calc(311px * '+ scale +')'" style="border-radius: 50%; border: 0.5px dashed #DCDCE9;">
	<div class="flex-center" [style.width]="'calc(241px * '+ scale +')'" [style.height]="'calc(241px * '+ scale +')'" style="border-radius: 50%; border: 0.5px dashed #DCDCE9;">
		<div class="flex-center" [style.width]="'calc(183px * '+ scale +')'" [style.height]="'calc(183px * '+ scale +')'" style="border-radius: 50%; border: 0.5px dashed #DCDCE9;">
			<div class="flex-center" [style.width]="'calc(127px * '+ scale +')'" [style.height]="'calc(127px * '+ scale +')'" style="border-radius: 50%; border: 0.5px dashed #DCDCE9;">
				<div class="flex-center p-relative" [style.width]="'calc(75px * '+ scale +')'" [style.height]="'calc(75px * '+ scale +')'" style="border-radius: 50%; border: 0.5px dashed #DCDCE9;">
					<div class="radar-triangle"
						 [style.border-right]="'calc(123px * '+ scale +') solid transparent'"
						 [style.border-bottom]="'calc(213px * '+ scale +') solid #FFFFFF'"
						 [style.border-left]="'calc(123px * '+ scale +') solid transparent'"
					></div>
					<div class="radar-inner p-absolute" style="border-radius: 50%; border: 0.5px solid #A890F3; background: radial-gradient(50% 50% at 50% 50%, rgba(168, 144, 243, 0.00) 0%, rgba(168, 144, 243, 0.00) 53%, rgba(168, 144, 243, 0.24) 100%);">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
