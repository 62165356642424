import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable()
export class ExportService {

  private saveAsFile(buffer: any, fileName: string): void {
    const blob = new Blob([buffer], { type: 'text/csv'});
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
  }

  export(type: ExportType, rows: object[], fileName: string, columns?: string[]): void {
    if (type === ExportType.CSV) {
      this.exportToCsv(rows, fileName, columns);
    } else if (type === ExportType.XLSX) {
      this.exportToXlsx(rows, fileName, columns);
    }
  }

  exportToCsv(rows: object[], fileName: string, columns?: string[]): void {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ';';
    const keys = Object.keys(rows[0]).filter(k => {
      if (columns?.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });
    const csvContent = keys.join(separator)
      + '\n'
      + rows.map(row => {
        return keys.map(k => {
          // @ts-ignore
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');
    this.saveAsFile(csvContent, `${fileName}.csv`);
  }

  exportToXlsx(rows: object[], fileName: string, columns?: string[]): void {
    if (!rows || !rows.length) {
      return;
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rows, {
      header: columns
    });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}

export enum ExportType {
  CSV = 'csv',
  XLSX = 'xlsx'
}
