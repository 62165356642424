import {NgModule} from "@angular/core";
import {TendencyChipsComponent} from "./tendency-chips.component";
import {ChipsModule} from "../chips/chips.module";

@NgModule({
	imports: [
		ChipsModule
	],
	exports: [
		TendencyChipsComponent
	],
	declarations: [
		TendencyChipsComponent
	]
})
export class TendencyChipsModule {
}
