import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AddAgentComponent} from "./add-agent/add-agent.component";
import {TranslateModule} from "@ngx-translate/core";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {TableContentLoaderModule} from "../../../global/table-content-loader/table-content-loader.module";
import {ChipsModule} from "../../../global/chips/chips/chips.module";
import {AlertModule} from "../../../global/alert/alert.module";
import {DeckContentLoaderModule} from "../../../global/title-content-loader/deck-content-loader.module";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MiniButtonModule} from "../../../global/button/mini-button/mini-button.module";
import {CardModule} from "../shared/card/card.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {MatRippleModule} from '@angular/material/core';

@NgModule({
	declarations: [
		AddAgentComponent
	],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    TableContentLoaderModule,
    DeckContentLoaderModule,
    ChipsModule,
    MatDialogModule,
    AlertModule,
    MiniButtonModule,
    CardModule,
    DesignSystemModule,
    MatRippleModule,
  ],
	exports: []
})
export class KapsulModule { }
