import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MiniButtonComponent} from "./mini-button.component";
import {MatRippleModule} from "@angular/material/core";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";

@NgModule({
	imports: [
		CommonModule,
		MatRippleModule,
		MatProgressSpinnerModule
	],
	providers: [
	],
	declarations: [
		MiniButtonComponent
	],
	exports: [
		MiniButtonComponent
	]
})
export class MiniButtonModule {
}
