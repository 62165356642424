import {ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {Logo} from "src/app/services/application-instance.service";
import {Observable} from "rxjs";
import {ImageService} from "src/app/services/front/image.service";

@Component({
  selector: 'app-application-icon',
  templateUrl: './application-icon.component.html',
  styleUrls: ['./application-icon.component.scss']
})
export class ApplicationIconComponent implements OnInit {

	@Input() logo: Logo;
	@Input() width = 20;
	@Input() height = 20;
	@Input() tileWidth = "35px";
	@Input() tileHeight = "35px";
	@Input() fontSize = "0.9rem";
	@Input() cursor: string | undefined;
	@Output() outputEvent = new EventEmitter<any>();

	// TODO : Implement Signals for this

	constructor(protected imageService:ImageService, protected  changeDetector: ChangeDetectorRef) { }

	ngOnInit(): void {
		if (this.logo.url && !this.logo.backgroundColor) {
			this.imageService.computeRGBColor(this.logo.url).subscribe({
				next: value => this.logo.backgroundColor = value[0]
			})
		}
	}
}
