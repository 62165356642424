import {NgModule} from "@angular/core";
import {OrganizationComponent} from "./organization.component";
import {MatIconModule} from "@angular/material/icon";
import {SearchModule} from "../../global/input/search/search.module";
import {TranslateModule} from "@ngx-translate/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {EditableTextInputModule} from "src/app/modules/global/input/text-input/editable-text-input.module";
import {MatRippleModule} from "@angular/material/core";
import {OrganizationService} from "src/app/services/organization.service";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {TableContentLoaderModule} from "../../global/table-content-loader/table-content-loader.module";
import {DeckContentLoaderModule} from "../../global/title-content-loader/deck-content-loader.module";
import {ChipsModule} from "../../global/chips/chips/chips.module";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {StructureModule} from "./structure/structure.module";
import {BusinessUnitModule} from "./business-unit/business-unit.module";
import {TextareaModule} from "../../global/input/textarea/textarea.module";
import {SnackbarModule} from "../../global/snackbar/snackbar.module";
import {TagsModule} from "../../global/tags/tags.module";
import {TeamCardModule} from "../applications/application-detail/usage-tab/team-card/team-card.module";
import {SimpleTextInputModule} from "../../global/input/simple-text-input/simple-text-input.module";
import {AlertModule} from "../../global/alert/alert.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
	imports: [
		MatIconModule,
		MatButtonModule,
		SearchModule,
		TranslateModule,
		CommonModule,
		DragDropModule,
		EditableTextInputModule,
		TextareaModule,
		MatRippleModule,
		MatProgressSpinnerModule,
		TableContentLoaderModule,
		DeckContentLoaderModule,
		ChipsModule,
		MatProgressBarModule,
		StructureModule,
		BusinessUnitModule,
		SnackbarModule,
		TagsModule,
		TeamCardModule,
		SimpleTextInputModule,
		AlertModule,
		NgOptimizedImage,
		DesignSystemModule,
	],
	providers: [
		OrganizationService
	],
	declarations: [
		OrganizationComponent,
	],
	exports: [
		OrganizationComponent
	]
})
export class OrganizationModule {
}
