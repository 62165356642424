@if(innerState === resourceDialogState.LOADING || innerState === resourceDialogState.READY) {
	<div class="flex-align-center gap-M pad-XL-XL-0 w-100 border-box">
		<div class="flex-center title-icon radius-S">
			<img src="assets/icons/server-04.svg" class="filter-grey" height="24" alt=""/>
		</div>
		<div class="flex-column gap-XS">
			<span class="font-LL fw-700">{{'Associer une instance'}}</span>
			<span class="font-M fw-500">{{'Renseigner les instances liées à votre application'}}</span>
		</div>
	</div>

	<div class="pad-XL">
		<app-application-mapping-resource-form [data]="data"
											   (cancel)="close()"
											   (saved)="close($event)"
												(hasResources)="changeState($event)">
		</app-application-mapping-resource-form>
	</div>
} @else {
	<app-dialog-placeholder-card (onAction)="handlePlaceholderAction($event)">
		<ng-container content>
			<img src="assets/illustrations/infra-placeholder.svg" width="158">

			<div class="disp-f f-d-col a-i-c j-c-c mt-xl">
				<div class="font-LL fw-700">{{'page.appDetails.architecture.dialog.placeholder.infrastructure.title'|translate}}</div>
				<div class="font-M text-center grey mt-m w-70">{{'page.appDetails.architecture.dialog.placeholder.infrastructure.description'|translate}}</div>
			</div>
		</ng-container>

		<ng-container action-button>
			{{'page.appDetails.architecture.dialog.placeholder.infrastructure.actionButton'|translate}}
		</ng-container>
	</app-dialog-placeholder-card>
}
