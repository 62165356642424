import {NgModule} from '@angular/core';
import {ChipsComponent} from './chips.component';
import {CommonModule} from '@angular/common';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		ChipsComponent,
	],
	exports: [
		ChipsComponent,
	]
})
export class ChipsModule {
}
