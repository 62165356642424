import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {SecurityUtils} from '../security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from '../../utils/http-client.utils';
import {ApplicationTechnologyForm, ApplicationTechnologyFormList, Technology} from 'src/app/services/model/application-technology.model';

@Injectable()
export class ApplicationTechnologyService {

	private serviceUrl = (tenantId: string, applicationId: string) => `${environment.engineURL}/tenants/${tenantId}/applications/${applicationId}/technologies`;

	constructor(protected httpClient: HttpClient) {
	}

	getAllTechnologyByApplicationId(tenantId: string, applicationId: string): Observable<Technology[]> {
		return this.httpClient.get<Technology[]>(`${this.serviceUrl(tenantId, applicationId)}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
	}

	createApplicationTechnology(tenantId: string, applicationId: string, form: ApplicationTechnologyForm): Observable<boolean> {
		return this.httpClient.post<boolean>(`${this.serviceUrl(tenantId, applicationId)}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	updateApplicationTechnology(tenantId: string, applicationId: string, form: ApplicationTechnologyForm): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId, applicationId)}`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	updateApplicationTechnologyList(tenantId: string, applicationId: string, form: ApplicationTechnologyFormList): Observable<boolean> {
		return this.httpClient.put<boolean>(`${this.serviceUrl(tenantId, applicationId)}/form-list`, form, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}

	deleteApplicationTechnology(tenantId: string, applicationId: string, technologyId: string): Observable<boolean> {
		return this.httpClient.delete<boolean>(`${this.serviceUrl(tenantId, applicationId)}/${technologyId}`, {
			headers: SecurityUtils.getAuthHttpHeaders()
		}).pipe(catchError(error => HttpClientUtils.handleError(error, false)));
	}
}
