import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityUtils} from 'src/app/services/security.utils';
import {catchError} from 'rxjs/operators';
import {HttpClientUtils} from 'src/app/utils/http-client.utils';
import {Category, ApplicationCategoryForm} from 'src/app/services/model/application-category.model';

@Injectable()
export class ApplicationCategoryService {

  private serviceUrl = (tenantId: string) => `${environment.engineURL}/tenants/${tenantId}/application-categories`;

  constructor(protected httpClient: HttpClient) {
  }

  public getAllApplicationCategoryByTenantId(tenantId: string): Observable<Category[]> {
    return this.httpClient.get<Category[]>(`${this.serviceUrl(tenantId)}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, [])));
  }

  public createApplicationCategoryForTenant(tenantId: string, form: ApplicationCategoryForm): Observable<string> {
    return this.httpClient.post<string>(`${this.serviceUrl(tenantId)}`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    }).pipe(catchError(error => HttpClientUtils.handleError(error, null)));
  }
}
