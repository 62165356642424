
export class Queue<T> {
    protected elements = {}
    protected head = 0;
    protected tail = 0;

    enqueue(element: T) {
        // @ts-ignore
		this.elements[this.tail] = element;
        this.tail++;
    }

    dequeue(): T | undefined {
        // @ts-ignore
		const item = this.elements[this.head];
		if (item) {
			// @ts-ignore
			delete this.elements[this.head];
			this.head++;
		}
        return item;
    }

    dequeueAll(): T[] {
        const buffer = [] as T[]
        while (!this.isEmpty) {
            const elem = this.dequeue()
            if (elem) {
                buffer.push(elem);
            }
        }

        return buffer;
    }

    peek(): T | undefined {
        // @ts-ignore
		return this.elements[this.head];
    }

    get length() {
        return this.tail - this.head;
    }

    get isEmpty() {
        return this.length === 0;
    }
}
