import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ApplicationTableComponent} from "./application-table.component";
import {TranslateModule} from "@ngx-translate/core";
import {ApplicationIconModule} from "../application-icon/application-icon.module";
import {TagsModule} from "../tags/tags.module";
import {ChipsModule} from "../chips/chips/chips.module";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
	declarations: [
		ApplicationTableComponent
	],
	exports: [
		ApplicationTableComponent
	],
  imports: [
    CommonModule,
    TranslateModule,
    ApplicationIconModule,
    TagsModule,
    ChipsModule,
    DesignSystemModule,
  ]
})
export class ApplicationTableModule { }
