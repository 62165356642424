<div class="flex-column h-100 w-100 gap-S prevent-select">
	@for (group of menuGroups; track group.title; let i = $index) {
		<div class="flex-column" [ngStyle]="{'margin-top': (i === menuGroups.length - 1) && latestAtBottom ? 'auto' : '0', 'margin-bottom': (i === menuGroups.length - 1) && latestAtBottom ? '20px' : '0', 'gap': (i === menuGroups.length - 1) && latestAtBottom ? '10px' : 0}">
			@if (group.title && !!(menuState$ | async)?.open) {
				<div class="grey ml-l mt-l mb-xs">{{ group.title }}</div>
			}

			@if (group.entries.length > 1 && group.parent) {
				<div class="flex-column">
					<div class="menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
						<div *ngIf="group.parent" class="menu-button" matRipple
							 [tooltip]="translateService.instant($any(group.parent).title || '')"
                             [tooltipDisabled]="!!(menuState$ | async)?.open"
							 tooltipPosition="right"
							 [dropdownTrigger]="dropdown"
							 [class.menu-opened]="(menuState$ | async)?.open"
							 [class.sub-menu-hover]="isDropdownOpen"
							 [class.selected]="$any(group.parent).isSelected">
							<div style="width: 32px" class="disp-f a-i-c j-c-c">
								<img *ngIf="group.parent?.icon" [class.filter-grey]="!group.parent.noFilter" [width]="32" [height]="group.parent.iconHeight" alt=""
									 [src]="group.parent.icon"/>
							</div>
							<div *ngIf="(menuState$ | async)?.open && group.parent?.title" class="ml-m disp-f a-i-c j-c-sb w-100">
								<span>{{ translateService.instant(group.parent.title) }}</span>
								<img class="mr-m" [class.filter-grey]="!group.parent.noFilter" alt=""
									 ngSrc="assets/icons/arrow-right-black.svg" height="12" width="8"/>
							</div>
						</div>
					</div>
				</div>
				<div class="p-absolute" style="top: 153px" [ngStyle]="{ 'left.px': (menuState$ | async)?.open ? 260 : 100 }">
					<app-dropdown (opened)="isDropdownOpen = true" (closed)="isDropdownOpen = false" [radius]="17" [animate]="false" [paddingX]="10" [paddingY]="10" [minimumMargin]="15" [arrow]="false" #dropdown>
						<div class="flex-column gap-S">
							@for (entry of group.entries; track entry.isSelected) {
								<div class="flex-align-center c-p menu-container opened" style="width: 250px; gap: 0;">
									<div class="menu-button sub-menu-opened" matRipple [class.selected]="entry.isSelected" (click)="goTo(entry.link);closeDropdown();">
										<img [class.filter-grey]="!entry.noFilter" [width]="32" [height]="entry.iconHeight" alt=""
											 [src]="entry.icon"/>
										<span class="ml-m">{{ translateService.instant(entry.title) }}</span>
									</div>
								</div>
							}
						</div>
					</app-dropdown>
				</div>
			} @else {
				@for (entry of group.entries; track entry.isSelected) {
                    <div class="flex-column">
                        <div class="flex-align-center menu-container c-p" [ngClass]="{ 'opened': (menuState$ | async)?.open }">
                            <div [tooltip]="translateService.instant(entry.title)" [tooltipDisabled]="!!(menuState$ | async)?.open" tooltipPosition="right" class="menu-button" matRipple [class.menu-opened]="(menuState$ | async)?.open" [class.selected]="entry.isSelected" (click)="goTo(entry.link)">
                                <img [class.filter-grey]="!entry.noFilter" [width]="32" [height]="entry.iconHeight" alt=""
                                     [src]="entry.icon"/>
                                <div *ngIf="(menuState$ | async)?.open" class="disp-f a-i-c j-c-sb" style="width: calc(100% - 50px)">
                                    <span class="font-M fw-500 ml-m">{{ translateService.instant(entry.title) }}</span>
                                    <span class="font-S fw-700" [class.app-count-bubble-selected]="entry.isSelected" [class.app-count-bubble]="!entry.isSelected" [class.grey]="!entry.isSelected" [class.accent]="entry.isSelected" *ngIf="entry.indicator">{{ entry.indicator }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
				}
			}
		</div>
	}
</div>
