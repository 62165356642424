<div class="flex-column gap-M">
	<div class="flex-align-center flex-space-between">
		<span class="font-MM fw-700">{{'page.appDetails.technology'|translate}}</span>
		<app-mini-button size="add" color="accent"
						 [disable]="_initializing || _loading || _loadingDialog"
						 (onClick)="openApplicationTechnologyFormDialog()">
			<img src="assets/icons/add.svg" class="filter-accent" alt="">
		</app-mini-button>
	</div>
	<div class="flex-wrap gap-M w-100" *ngIf="technologies.length > 0">
		<app-technology-chips *ngFor="let tech of technologies"
							  [technology]="tech.technology" [version]="tech.version"
							  [disabled]="_initializing || _loading || _loadingDialog"
							  [delete]="deleteTechnology">
		</app-technology-chips>
	</div>
</div>
