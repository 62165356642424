import {NgModule} from '@angular/core';
import {TableContentLoaderComponent} from './table-content-loader.component';
import {ContentLoaderModule} from '@ngneat/content-loader';
import {CommonModule} from '@angular/common';

@NgModule({
	imports: [
		ContentLoaderModule,
		CommonModule
	],
	declarations: [
		TableContentLoaderComponent
	],
	exports: [
		TableContentLoaderComponent
	],
})
export class TableContentLoaderModule {
}
