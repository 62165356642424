import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {DatePipe} from '@angular/common';
import {ContractInfo, DurationOfCommitment, DurationUnit} from 'src/app/services/model/application-contract.model';
import {TempServiceFrom, TempSubscriptionForm} from 'src/app/modules/home/applications/application-detail/finance-tab/application-contract-form-dialog/application-contract-form-dialog.component';
import {Organization, OrganizationTree} from 'src/app/services/organization.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-subscription-form',
	templateUrl: './subscription-form.component.html',
	styleUrls: ['./subscription-form.component.scss']
})
export class SubscriptionFormComponent implements OnInit {

	@Input() organization: OrganizationTree;
	@Input() info?: ContractInfo;

	@Output() onChange: EventEmitter<TempSubscriptionForm> = new EventEmitter<TempSubscriptionForm>();

	subscriptionForm: FormGroup;
	recurrenceChoices: RecurrenceChoice[] = [];
	commitmentPeriodChoices: CommitmentChoice[] = [];
	filteredOrganizations: OrganizationTree[] = [];
	searchOrganizationControl: FormControl;
	currencySymbol: string;
	commitment: typeof DurationOfCommitment = DurationOfCommitment;
	form: typeof Form = Form;
	subscription: Subscription = new Subscription();

	constructor(private translate: TranslateService,
							private datePipe: DatePipe) {
	}

	ngOnInit() {
		this.buildSelectionLists();
		this.buildForm();
		this.setDefaultData();
		this.currencySymbol = localStorage.getItem('currency')!;
	}

	private buildSelectionLists(): void {
		this.recurrenceChoices = [
			{id: DurationUnit.MONTH, name: this.translate.instant('page.appDetails.finance.contracts.monthlyPeriod')},
			{id: DurationUnit.YEAR, name: this.translate.instant('page.appDetails.finance.contracts.annuallyPeriod')}
		];
		this.commitmentPeriodChoices = [
			{id: DurationOfCommitment.CUSTOM, name: this.translate.instant('page.appDetails.finance.contracts.custom')},
			{id: DurationOfCommitment.NO_COMMITMENT, name: this.translate.instant('page.appDetails.finance.contracts.noCommitment')},
			{id: DurationOfCommitment.MONTHLY, name: this.translate.instant('page.appDetails.finance.contracts.monthlyPeriod')},
			{id: DurationOfCommitment.ANNUALLY, name: this.translate.instant('page.appDetails.finance.contracts.annuallyPeriod')}
		];
		this.filteredOrganizations = this.organization.children;
	}

	private buildForm(): void {
		this.subscriptionForm = new FormGroup({
			[Form.organizations]: new FormControl([]),
			[Form.amount]: new FormControl(undefined, Validators.min(0)),
			[Form.recurrencePeriod]: new FormControl(undefined, Validators.required),
			[Form.commitmentPeriod]: new FormControl(undefined, Validators.required),
			[Form.customEndDate]: new FormControl(undefined, Validators.required),
			[Form.subscriptionDate]: new FormControl(undefined, Validators.required),
		});
		this.subscription.add(this.subscriptionForm.valueChanges
			.subscribe(() => this.emitChanges()));
		this.searchOrganizationControl = new FormControl('');
		this.subscription.add(this.searchOrganizationControl.valueChanges
			.subscribe(search => this.searchOrganizationFilter(search)));
	}

	private setDefaultData(): void {
		if (this.info) {
			const organizations: string[] = this.info!.contract.organizations.map(o => o.organizationId)
			this.subscriptionForm.setValue({
				[Form.organizations]: this.organization.children
					.flatMap(group => group.children)
					.filter(team => organizations.includes(team.organization.organizationId))
					.map(team => team.organization),
				[Form.amount]: this.info.amount,
				[Form.recurrencePeriod]: this.recurrenceChoices
					.find(choice => choice.id === this.info!.paymentDurationUnit)!,
				[Form.commitmentPeriod]: this.commitmentPeriodChoices
					.find(choice => choice.id === this.info!.subscriptionInfo!.durationOfCommitment)!,
				[Form.customEndDate]: !this.info.contract.endDate ? null : new Date(this.info.contract.endDate),
				[Form.subscriptionDate]: new Date(this.info.contract.startDate)
			});
		} else {
			this.subscriptionForm.setValue({
				[Form.organizations]: [],
				[Form.amount]: null,
				[Form.recurrencePeriod]: this.recurrenceChoices
					.find(choice => choice.id === DurationUnit.MONTH)!,
				[Form.commitmentPeriod]: this.commitmentPeriodChoices
					.find(choice => choice.id === DurationOfCommitment.NO_COMMITMENT)!,
				[Form.customEndDate]: null,
				[Form.subscriptionDate]: new Date(),
			}, { emitEvent: false })
		}
		// Emit changes to set default values on parent component
		this.emitChanges();
	}

	private searchOrganizationFilter(search?: string): void {
		const organizationGroups: OrganizationTree[] = JSON.parse(JSON.stringify(this.organization.children.filter((group: OrganizationTree) => !search || group.organization.name.toLowerCase().includes(search.toLowerCase()) || group.children.some((option: OrganizationTree) => option.organization.name.toLowerCase().includes(search.toLowerCase())))));
		organizationGroups.forEach((group: OrganizationTree) => group.children = group.children.filter((option: OrganizationTree) => !search || option.organization.name.toLowerCase().includes(search.toLowerCase())));
		this.filteredOrganizations = organizationGroups;
	}

	emitChanges(): void {
		const form: TempServiceFrom = this.buildApplicationContractOfSubscriptionForm();
		this.onChange.emit(form);
	}

	private buildApplicationContractOfSubscriptionForm(): TempSubscriptionForm {
		return {
			amount: this.subscriptionForm.get(Form.amount)!.value,
			startDate: new Date(this.datePipe.transform(this.subscriptionForm.get(Form.subscriptionDate)!?.value, "yyyy-MM-dd")!),
			paymentDurationUnit: (this.subscriptionForm.get(Form.recurrencePeriod)!.value as RecurrenceChoice).id,
			durationOfCommitment: (this.subscriptionForm.get(Form.commitmentPeriod)!.value as CommitmentChoice).id,
			customEndDate: this.subscriptionForm.get(Form.customEndDate)!.value,
			organizationIds: (this.subscriptionForm.get(Form.organizations)!.value as Organization[]).map(o => o.organizationId),
		}
	}
}

interface RecurrenceChoice {
	id: DurationUnit;
	name: string;
}

interface CommitmentChoice {
	id: DurationOfCommitment;
	name: string;
}

enum Form {
	organizations = 'organizations',
	amount = 'amount',
	recurrencePeriod = 'recurrencePeriod',
	commitmentPeriod = 'commitmentPeriod',
	customEndDate = 'customEndDate',
	subscriptionDate = 'subscriptionDate',
}
