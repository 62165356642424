import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {DesignSystemModule} from "../../../design-system/design-system.module";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {RoverAuthenticationCredentials, RoverService} from "src/app/services/rover.service";
import {finalize, Subscription, switchMap, timer} from "rxjs";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {filter, tap} from "rxjs/operators";
import {TenantService} from "src/app/services/tenant.service";
import {TranslateModule} from "@ngx-translate/core";
import * as Bowser from "bowser";
import {AuthenticationService, UserInfo} from 'src/app/services/authentication.service';

@Component({
  selector: 'app-onboarding-step-4',
  standalone: true,
	imports: [CommonModule, DesignSystemModule, NgOptimizedImage, TranslateModule],
  templateUrl: './onboarding-step-4.component.html',
  styleUrl: '../onboarding.component.scss'
})
export class OnboardingStep4Component {

	@Input() stepCount: number = 6;
	@Input() isMobile: boolean;

	@Output() onNextClick = new EventEmitter<string>();
	@Output() onDismiss = new EventEmitter<void>();

	constructor(private breakpointObserver: BreakpointObserver,
				private authenticationService: AuthenticationService,
				private roverService: RoverService,
				private currentTenantService: CurrentTenantService,
				private tenantService: TenantService) {
	}

	tenantId: string;
	currentUser: UserInfo;

	closeLoading: boolean = false;
	_sendingEmail: boolean;

	panelClass: string = '';
	emailSent: boolean = false;

	subscriptions: Subscription = new Subscription();

	ngOnInit() {
		this.currentUser = this.authenticationService.getUserInfo()!;
		this.currentTenantService.getCurrentTenantIdChanges()
			.pipe(
				tap((tenantId: string) => this.tenantId = tenantId),
				filter(() => !this.isMobile),
				switchMap(() => this.roverService.getRoverAuthenticationCredentials(this.tenantId)),
				tap((rover) => this.setRoverAuthenticationCredentials(rover)),
				tap(() => this.checkIfExtensionInstalled()))
			.subscribe();

		this.subscriptions.add(this.breakpointObserver
			.observe(['(max-width: 1280px)'])
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
					this.panelClass = ''
				} else {
					this.panelClass = 'big-button'
				}
			}));
	}

	setRoverAuthenticationCredentials(credentials: RoverAuthenticationCredentials): void {
		const structureEltId = 'kabeen-structure-id';
		const teamEltId = 'kabeen-team-id';
		const tokenEltId = 'kabeen-token-id';
		const structure = document.createElement('div');
		const team = document.createElement('div');
		const token = document.createElement('div');
		structure.id = structureEltId;
		team.id = teamEltId;
		token.id = tokenEltId;
		structure.style.display = 'none';
		team.style.display = 'none';
		token.style.display = 'none';
		const structureData = document.createAttribute('data-kabeen-structure-id');
		const teamData = document.createAttribute('data-kabeen-team-id');
		const tokenData = document.createAttribute('data-kabeen-token-id');
		structureData.value = credentials.structureId;
		teamData.value = credentials.teamId;
		tokenData.value = credentials.token;
		structure.setAttributeNode(structureData);
		team.setAttributeNode(teamData);
		token.setAttributeNode(tokenData);
		document.body.appendChild(structure);
		document.body.appendChild(team);
		document.body.appendChild(token);
	}

	checkIfExtensionInstalled(): void {
		// check every 2 seconds if the extension is installed using rxjs interval
		this.subscriptions.add(timer(0, 2000)
			.pipe(switchMap(() => this.tenantService.checkTenantExtensionInstalled(this.tenantId)))
			.subscribe((isInstalled: boolean) => {
				if (isInstalled) {
					this.onNextClick.emit('Step 5');
				}
			}));
	}

	openBrowserCorrespondingStore(): void {
		const browser = Bowser.getParser(window.navigator.userAgent)
		const browserName: string = browser.getBrowserName();
		if (browserName === 'Chrome')
			window.open('https://chromewebstore.google.com/detail/kabeen/hkmgmmaopoofgchofbngplopnhajbojc?hl=fr', '_blank');
		else if (browserName === 'Firefox')
			window.open('https://addons.mozilla.org/fr/firefox/addon/kabeen/', '_blank');
		else if (browserName === 'Microsoft Edge')
			window.open('https://microsoftedge.microsoft.com/addons/detail/kabeen/jgdflahjneomipldehpneckiplknghbc?hl=en-US', '_blank');
		else
			window.open('https://chromewebstore.google.com/detail/kabeen/hkmgmmaopoofgchofbngplopnhajbojc?hl=fr', '_blank');
	}

	sendRedirectionEmail(): void {
		this._sendingEmail = true;
		this.tenantService.sendOnboardingRedirectionEmail(this.tenantId, this.currentUser.id)
			.pipe(finalize(() => this._sendingEmail = false))
			.subscribe(emailSent => this.emailSent = emailSent);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
		const structure = document.getElementById('kabeen-structure-id');
		const team = document.getElementById('kabeen-team-id');
		const token = document.getElementById('kabeen-token-id');
		if (structure) {
			structure.remove();
		}
		if (team) {
			team.remove();
		}
		if (token) {
			token.remove();
		}
	}
}
