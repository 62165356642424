<!-- Title -->
<div  class="pl-xl pr-xl pt-l pb-l slider-header">
	<div class="disp-f j-c-s a-i-c w-100">
		<div>
			<div class="disp-f a-i-c j-c-c col bkg-accent-light radius-10" style="height: 45px; width: 45px">
				<img src="assets/icons/building.svg" class="svg-24 filter-accent">
			</div>
		</div>
		<div class="ml-s flex-column gap-XS">
			<div class="font-L fw-700 text-overflow-ellipsis" style="display:inline-block; max-width: 500px;">{{ data.structure.name }}</div>
			<div class="font-M fw-300">{{ data.parent.name }}</div>
		</div>
	</div>

	<div class="flex-align-center gap-S">
		<app-icon-button type="stroked" background [img]="'assets/icons/close-drawer.svg'"
						 (click)="close()">
		</app-icon-button>
		<app-icon-button type="stroked" background [img]="'assets/icons/save.svg'"
						 *ngIf="!!settings"
						 (click)="onSaveSettings()">
		</app-icon-button>
		<app-icon-button type="stroked" background [img]="!settings ? 'assets/icons/cog-inactive.svg' : 'assets/icons/cog-active.svg'" [focused]="!!settings"
						 (click)="onOpenOrgSettings()">
		</app-icon-button>
		<app-icon-button type="stroked" background color="danger" [img]="'assets/icons/trash.svg'"
						 *ngIf="isEditor"
						 (click)="onDelete()">
		</app-icon-button>
	</div>
</div>

<div *ngIf="settings == null" class="pl-xl pr-l pt-m" >
	<div class="widget-group">
		<div class="one">
			<div class="full-width-widget">
				<application-mini-widget [widget]="availabilityWidget" [type]="'availability'" [title]="'menu.applicationAvailability' | translate "></application-mini-widget>
			</div>
		</div>
		<div class="two">
			<div class="full-width-widget">
				<application-mini-widget [widget]="usersWidget" [type]="'users'" [title]="'menu.users' | translate"></application-mini-widget>
			</div>
		</div>
		<div class="three">
			<div class="full-width-widget">
				<application-mini-widget [widget]="tcoWidget" [type]="'tco'" [title]="'page.organization.tco'|translate"></application-mini-widget>
			</div>
		</div>
	</div>
	<div class="mt-l bg-rounded pl-m pr-m pt-m">
		<div class="disp-f flex-between fw-700 mb-l">
			<div>
				{{ 'page.appDetails.businessUsers' | translate }}
			</div>
			<div>
				{{ data.children.length }}
			</div>
		</div>
		<app-element-list
			[elements]="data.children"
			[subtitle]="data.structure.name"
			[placeholder]="'page.appDetails.noBusinessUsers' | translate"
		>
		</app-element-list>
	</div>
	<div class="mt-l bg-rounded pl-m pr-m pt-m pb-m">
		<div class="disp-f flex-between fw-700 mb-l">
			<div>
				{{ 'page.title.applications' | translate }}
			</div>
			<div>
				{{ applications.length }}
			</div>
		</div>
		<div *ngIf="loading" class=" pl-xl pr-xl pt-l pb-l">
			<list-content-loader></list-content-loader>
		</div>
		<app-application-table *ngIf="!loading" [applicationInstances]="applications"></app-application-table>
	</div>
</div>

<div *ngIf="data && settings != null" [ngStyle]="{ 'display': settings != null ? 'block' : 'none' }" class="mt-m">
	<div class="triangle"></div>
	<app-organization-setting
		[organization]="data.structure"
		[isEditor]="isEditor"
		[settings]="settings">
	</app-organization-setting>
</div>

