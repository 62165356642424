@if (_initializing || (_loading && chartData.length === 0)) {
<div class="border-box-full">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
} @else {
<div>
	<div class="flex-space-between pad-0-M pad-top-M gap-S">
		<span class="font-MM fw-700">{{ widgetTitle }}</span>
		<div *ngIf="displayRange" class="flex-align-center c-p" style="height: fit-content"
             (click)="chartRangeChange()">
			<span class="font-M fw-500 grey">{{('global.queryTypeSelector.' + range)|translate}}</span>
			<div>
				<img class="filter-grey ml-xs" ngSrc="assets/icons/card-arrow.svg" alt="" height="7" width="11">
			</div>
		</div>
	</div>
	<div class="pl-m pr-m pb-m" (mouseout)="tooltip.display = 'none'">
		<canvas [height]="height"
				baseChart
				[data]="lineChartData"
				[type]="barChartType"
				[options]="barOptions"
		></canvas>
		<div class="tooltip" style="padding: 3px 7px;" [style.top]="tooltip.top" [style.left]="tooltip.left" [style.display]="tooltip.display">
			<div class="flex-space-between font-M fw-700">
				<div>
					<span>{{ tooltip.day }} {{ 'global.monthShort.' + tooltip.month | translate }}</span>
				</div>
				<div>
					<span style="color:#3DC0FF;">{{ tooltip.value }} {{ 'page.usageDashboard.users' | translate }}</span>
				</div>
			</div>
		</div>
	</div>
</div>
}
