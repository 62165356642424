import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TeamCardComponent} from "./team-card.component";
import {UserCountChipsComponent} from "./user-count-chips.component";
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
	declarations: [
		TeamCardComponent,
		UserCountChipsComponent
	],
	imports: [
		CommonModule,
		DesignSystemModule,
		TranslateModule
	],
	exports: [
		TeamCardComponent,
		UserCountChipsComponent
	]
})
export class TeamCardModule {

}
