import {Component, Input} from "@angular/core";
import {Team} from "src/app/services/usage.service";
import {Router} from "@angular/router";
import {ORGANIZATION_URL} from "src/app/models/home/navigation.model";

@Component({
	selector: 'app-team-card',
	templateUrl: './team-card.component.html',
	styleUrls: ['./team-card.component.scss']
})
export class TeamCardComponent {

	@Input() team: Team;

	constructor(private router: Router) {}

	onTeamClick() {
		this.router.navigate([ORGANIZATION_URL], {
			queryParams: {structure: this.team.structure, businessUnit: this.team.businessUnit, businessUnitOpened:true},
			queryParamsHandling: 'merge',
		});

	}
}
