<div class="flex-align-center gap-XS pad-left-S horizontal-bar-L bkg-light-grey-2"
     [ngClass]="{'flex-justify-start pad-right-M': !short, 'flex-justify-center pad-right-S': short}">
    @if (criticality) {
        <img src="assets/icons/criticality-{{criticality.toLowerCase()}}.svg" height="14" alt="">
        <span *ngIf="!short" class="font-M fw-500">{{('page.appDetails.businessCriticality.' + criticality.toLowerCase())|translate}}</span>
    } @else {
        <div class="flex-center flex-justify-center" [style.min-width]="!short ? '55px' : 'unset'">
            <span class="font-M fw-500">-</span>
        </div>
    }
</div>
