import {Component, Input} from '@angular/core';
import {TenantAccount} from 'src/app/services/model/account.model';

@Component({
  selector: 'app-display-user',
  templateUrl: './display-user.component.html',
  styleUrls: ['./display-user.component.scss']
})
export class DisplayUserComponent {
  @Input() user: TenantAccount|undefined|null;

  private _size: 38|30|25 = 30;
  @Input()
  get size(): 38|30|25 {
    return this._size
  }
  set size(value: 'S'|'M'|'L') {
    this._size = value === 'L' ? 38 : (value === 'M' ? 30 : 25);
  }

  private _bold: boolean;
  @Input()
  get bold() {
    return this._bold;
  }
  set bold(value: any) {
    this._bold = value != null && `${value}` !== 'false';
  }

  private _email: boolean;
  @Input()
  get email() {
    return this._email;
  }
  set email(value: any) {
    this._email = value != null && `${value}` !== 'false';
  }
}
