import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {AuthGuard} from './guards/auth.guard';
import {OnboardingComponent} from "./modules/home/onboarding/onboarding.component";

const routes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./pages/signin/signin.module').then(m => m.SigninModule)
	},
	{
		path: '',
		canActivate: [AuthGuard],
		component: HomeComponent,
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
	},
	{
		path: 'new-tenant',
		canActivate: [AuthGuard],
		component: OnboardingComponent
	}
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
	imports: [RouterModule.forRoot(routes, {useHash: false, initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled'})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
