import {ActivatedRoute, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {
	AppSettingsMode
} from "src/app/modules/home/applications/application-detail/application-settings/application-settings.component";

@Injectable()
export class ApplicationUrlService {
	constructor(protected activatedRoute: ActivatedRoute, protected router: Router) {
	}

	// Selected application tabs

	public getCurrentApplicationTab(): number {
		let index = this.activatedRoute.snapshot.queryParamMap.get('selectedApplicationTab');
		if (index != null)
			return parseInt(index)
		return 0;
	}
	public getParameter(): number | null {
		let index = this.activatedRoute.snapshot.queryParamMap.get('settings');
		if (index != null)
			return parseInt(index)
		return null
	}

	public getCommentParameter(): number | null {
		let index = this.activatedRoute.snapshot.queryParamMap.get('comments');
		if (index != null)
			return parseInt(index)
		return null
	}

	public changeCurrentApplicationTab(index: number | null) {
		this.router.navigate([this.router.url.split('?')[0]], {queryParams: {'selectedApplicationTab': index}, queryParamsHandling: "merge"})
	}

	// Selected application

	public getCurrentApplication(): string | null {
		return this.activatedRoute.snapshot.queryParamMap.get('applicationId');
	}

	public changeCurrentApplication(id: string | null) {
		if (id !== null)
			this.router.navigate([], {queryParams: {applicationId: id}, queryParamsHandling: "merge"})
		else
			this.router.navigate([], {queryParams: {applicationId: null, selectedApplicationTab: null, settings: null, comments: null}, queryParamsHandling: "merge"})
	}

	navigateInAndOutOfParameter(parameter: AppSettingsMode | null) {
		this.navigateInAndOut(parameter, null);
	}

	navigateInAndOutOfComments(parameter: number | null) {
		this.navigateInAndOut(null, parameter);
	}

	clearSettingAndCommentParameter() {
		this.navigateInAndOut(null, null);
	}

	private navigateInAndOut(setting: AppSettingsMode|null, comment: number|null): void {
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: { settings: setting, comments: comment },
			queryParamsHandling: 'merge'
		});
	}
}
