import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router, RoutesRecognized} from '@angular/router';
import {IntercomService, updateIntercom} from "./services/front/intercom.service";
import {filter} from "rxjs/operators";

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'deck';

	constructor(
		private translateService: TranslateService,
		private router: Router,
		private intercomService: IntercomService
	) {}

	ngOnInit(): void {

		const lang = localStorage.getItem('lang') || this.translateService.getBrowserLang();

		if (lang === 'en' || lang === 'fr') {
			localStorage.setItem('lang', lang);
			this.translateService.setDefaultLang(lang);
		} else {
			localStorage.setItem('lang', 'en');
			this.translateService.setDefaultLang('en');
		}

		// Subscribe to router events for Intercom updates.
		this.router.events.pipe(
			filter((event: any) => event instanceof RoutesRecognized),
			updateIntercom(this.intercomService)
		).subscribe();
	}
}
