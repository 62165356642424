import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss']
})
export class TextButtonComponent {
  @Input() color: 'primary'|'accent'|'accent-secondary'| 'accent-secondary-inverted' | 'warn'|'success'|'warning'|'danger'|'grey' = 'accent';
  @Input() type: 'default'|'stroked'|'flat'|'dashed' = 'default';
  @Input() shape: 'default'|'square'|'round' = 'default';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Input() panelClass: string = '';
  @Input() badge: string|number|undefined = undefined;
  @Input() badgePosition: 'above'|'above after'|'above before'|'after'|'before'|'below'|'below after'|'below before' = 'above after';
}
