import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {Observable} from "rxjs";

export function observableFromSync<T>(func: () => T): Observable<T> {
	return fromPromise(new Promise<T>(resolve => {
		setTimeout(() => {
			resolve(func());
		})
	}));
}
