<div class="disp-f a-i-c j-c-sb mb-s">
    <div class="flex gap-M">
        <span>Documents</span>
        <span style="letter-spacing: 1px">{{getValidFiles(false).length + '/' + limit }}</span>
    </div>
	<span *ngIf="canAddFile() && !displayFormPanel" class="text-underline accent c-p"
          (click)="displayFormPanel = true">{{ 'page.appDetails.finance.contracts.addAnotherDocument' | translate }}</span>
</div>
<div class="list-container">
	<div *ngIf="fileList.length > 0" class="mb-m">
		<div *ngFor="let data of fileList">
			<div *ngIf="data.type === FileType.FILE && (data.uploadStatus === UploadFileStatus.FAILED || data.uploadStatus === UploadFileStatus.FAILED_TOO_BIG)" class="uploading-container mb-m">
				<div class="disp-f gap-M w-100">
					<div class="icon-bg">
						<img ngSrc="assets/icons/document-outline.svg" class="filter-grey" height="24" width="24" alt="">
					</div>
					<div class="disp-f f-d-col j-c-c w-100 gap-S">
						<div class="disp-f a-i-c j-c-sb">
							<span class="text-overflow-ellipsis" [ngStyle]="{ 'max-width.px': data.uploadStatus === UploadFileStatus.FAILED ? 255 : 120 }">{{ data.name }}</span>
							<span *ngIf="data.uploadStatus === UploadFileStatus.FAILED" class="danger">Error</span>
							<span *ngIf="data.uploadStatus === UploadFileStatus.FAILED_TOO_BIG" class="danger" style="white-space: nowrap;">{{ 'page.appDetails.finance.contracts.fileTooBig' | translate: { sizeLimit } }}</span>
						</div>
						<div>
							<div class="progress-bar">
								<div class="progress-bar-inner error-inner" [style.width]="'100%'"></div>
							</div>
						</div>
					</div>
				</div>
                <!-- CANCEL BUTTON -->
                <div class="disp-f a-i-c j-c-c radius-half pb-s pt-s pr-s pl-s c-p" style="background-color: #ECF9FF;"
                     (click)="cancelUpload(data)">
                    <img ngSrc="assets/icons/close.svg" class="filter-accent" height="10" width="10" alt="">
                </div>
			</div>
			<div *ngIf="data.type === FileType.FILE && data.uploadStatus === UploadFileStatus.UPLOADING" class="uploading-container mb-m">
				<div class="disp-f gap-M w-100">
					<div class="icon-bg">
						<img ngSrc="assets/icons/document-outline.svg" class="filter-grey" height="24" width="24" alt="">
					</div>
					<div class="disp-f f-d-col j-c-c w-100 gap-S">
						<div class="disp-f a-i-c j-c-sb">
							<span class="text-overflow-ellipsis" style="max-width: 255px;">{{ data.name }}</span>
							<span class="accent">{{ data.progress }}%</span>
						</div>
						<div>
							<div class="progress-bar">
								<div class="progress-bar-inner uploading-inner" [style.width]="data.progress+'%'"></div>
							</div>
						</div>
					</div>
				</div>
				<!-- CANCEL BUTTON -->
				<div class="disp-f a-i-c j-c-c radius-half pb-s pt-s pr-s pl-s c-p" style="background-color: #ECF9FF;"
                     (click)="cancelUpload(data)">
					<img ngSrc="assets/icons/close.svg" class="filter-accent" height="10" width="10" alt="">
				</div>
			</div>
            <div *ngIf="data.type === FileType.FILE && data.uploadStatus === UploadFileStatus.UPLOADED" class="uploading-container mb-m">
                <div class="disp-f gap-M w-100">
                    <div class="icon-bg" style="background-color: #ECF9FF">
                        <img ngSrc="assets/icons/document-outline.svg" class="filter-accent" height="24" width="24" alt="">
                    </div>
                    <div class="disp-f f-d-col j-c-c w-100">
                        <div class="disp-f a-i-c j-c-sb">
                            <span class="text-overflow-ellipsis" style="max-width: 255px;"
                                  [ngClass]="{'mark-as-to-delete': data.toDelete}">{{ data.name }}</span>
                        </div>
                        <span *ngIf="data.size" class="font-S fw-500 grey">{{ data.size }}</span>
                    </div>
                </div>
                <!-- DELETE BUTTON -->
                <div *ngIf="!data.toDelete" class="disp-f a-i-c j-c-c radius-half pb-s pt-s pr-s pl-s c-p"
                     (click)="switchDelete(data)">
                    <img ngSrc="assets/icons/trash.svg" class="filter-grey" height="21" width="16" alt="">
                </div>
                <div *ngIf="data.toDelete && canAddFile()" class="disp-f a-i-c j-c-c radius-half pb-s pt-s pr-s pl-s c-p"
                     (click)="switchDelete(data)">
                    <mat-icon class="grey" style="transform: rotate(90deg);">u_turn_left</mat-icon>
                </div>
            </div>
            <div *ngIf="data.type === FileType.LINK" class="uploading-container mb-m">
                <div class="disp-f gap-M w-100">
                    <div class="icon-bg" style="background-color: #ECF9FF">
                        <img ngSrc="assets/icons/link.svg" class="filter-accent" height="20" width="20" alt="">
                    </div>
                    <div class="disp-f f-d-col j-c-c w-100">
                        <div class="disp-f a-i-c j-c-sb">
                            <a class="text-overflow-ellipsis text-underline accent" style="max-width: 255px;" target="_blank"
                               [ngClass]="{'mark-as-to-delete': data.toDelete}"
                               [href]="data.link">{{ data.name }}</a>
                        </div>
                    </div>
                </div>
                <!-- DELETE BUTTON -->
                <div *ngIf="!data.toDelete" class="disp-f a-i-c j-c-c radius-half pb-s pt-s pr-s pl-s c-p"
                     (click)="switchDelete(data)">
                    <img ngSrc="assets/icons/trash.svg" class="filter-grey" height="21" width="16" alt="">
                </div>
                <div *ngIf="data.toDelete && canAddFile()" class="disp-f a-i-c j-c-c radius-half pb-s pt-s pr-s pl-s c-p"
                     (click)="switchDelete(data)">
                    <mat-icon class="grey icon-20" style="transform: rotate(90deg);">u_turn_left</mat-icon>
                </div>
            </div>
		</div>
	</div>

	<div *ngIf="fileTypeSelected == FileType.FILE && (displayFormPanel || fileList.length === 0)" class="upload-file-container c-p"
         (click)="selectFile()" (drop)="onDrop($event)" (dragover)="$event.preventDefault()" (dragleave)="$event.preventDefault()">
		<img ngSrc="assets/icons/file-download.svg" class="filter-accent" height="24" width="24" alt="">
		<div class="font-S fw-500 mt-s">{{ 'page.appDetails.finance.contracts.dropYourDocumentsHere' | translate }}</div>
		<div class="font-S fw-500">{{ 'page.appDetails.finance.contracts.or' | translate }}</div>
		<div class="font-S fw-700 accent">{{ 'page.appDetails.finance.contracts.browse' | translate }}</div>
	</div>

	<div *ngIf="fileTypeSelected == FileType.LINK && (displayFormPanel || fileList.length === 0)" class="link-container not-started">
		<img ngSrc="assets/icons/link.svg" class="filter-accent" height="24" width="24" alt="">
		<div class="font-S fw-500 mt-s mb-s">{{ 'page.appDetails.finance.contracts.pasteYourUrl' | translate }}</div>
		<div class="disp-f a-i-c j-c-sb w-100 gap-S">
			<input [formControl]="linkInputForm" type="text" class="link-input">
            <app-text-button type="flat" [disabled]="linkInputForm.invalid"
                             (click)="uploadLink()">
                <img src="assets/icons/add.svg" [ngClass]="{'filter-grey': linkInputForm.invalid, 'filter-white': linkInputForm.valid}" alt=""/>
            </app-text-button>
		</div>
	</div>

	<div *ngIf="fileTypeSelected == FileType.FILE && (displayFormPanel || fileList.length === 0)" class="grey text-underline text-center mt-m mb-m">
		<span class="c-p" (click)="fileTypeSelected = FileType.LINK">{{ 'page.appDetails.finance.contracts.linkWithUrl' | translate }}</span>
	</div>

	<div *ngIf="fileTypeSelected == FileType.LINK && (displayFormPanel || fileList.length === 0)" class="grey text-underline text-center mt-m mb-m">
		<span class="c-p" (click)="fileTypeSelected = FileType.FILE">{{ 'page.appDetails.finance.contracts.uploadFromYourComputer' | translate }}</span>
	</div>
</div>
