@if (_initializing || _loading) {
    <div class="border-box-full">
        <app-dashboard-page-loader></app-dashboard-page-loader>
    </div>
} @else {
<div class="flex-column gap-M pad-M border-box-full">
	<div class="flex" [style.height.px]="20">
		<span class="font-MM fw-700">{{'page.architectureDashboard.technology.title'|translate}}</span>
	</div>
    @if (sortedTechnologies.length > 0) {
        <div class="flex-column" style="height: calc(100% - 35px); margin-top: 15px;">
            <div class="p-absolute" style="width: calc(100% - 30px); height: calc(100% - 110px);">
                <div class="border-grey-top h-20" style="border-top-style: dashed !important; border-width: 1px !important; margin-top: -1px;"></div>
                <div class="border-grey-top h-20" style="border-top-style: dashed !important; border-width: 1px !important; margin-top: -1px;"></div>
                <div class="border-grey-top h-20" style="border-top-style: dashed !important; border-width: 1px !important; margin-top: -1px;"></div>
                <div class="border-grey-top h-20" style="border-top-style: dashed !important; border-width: 1px !important; margin-top: -1px;"></div>
                <div class="border-grey-top h-20" style="border-top-style: dashed !important; border-width: 1px !important; margin-top: -1px;"></div>
            </div>
            <div class="flex w-100" style="height: calc(100% - 30px); margin-top: 1px; z-index: 1;">
                @for (tech of sortedTechnologies|slice:0:5; track tech;) {
                    <div class="flex-justify-center flex-align-end w-20">
                        <div class="flex-column vertical-bar-M bar-color"
                             [style.height.%]="(count[tech.technologyId] / maxCount) * 100">
                            <div class="p-absolute font-M fw-500" style="margin-left: 15px; margin-top: -15px;">{{count[tech.technologyId]}}</div>
                            <div class="flex-center bkg-white radius-S logo-size">
                                <app-application-icon [logo]="tech.logo"></app-application-icon>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div class="flex border-grey-top" style="height: 30px; margin-top: -1px;">
                @for (tech of sortedTechnologies|slice:0:5; track tech;) {
                    <div class="flex-justify-center flex-align-end w-20">
                        <span class="font-M fw-500 text-overflow-ellipsis" style="padding-bottom: 1px">{{tech.name}}</span>
                    </div>
                }
            </div>
        </div>
    } @else {
        <div class="flex-column flex-center gap-M m-auto">
            <img src="assets/icons/new-checkmark.svg" height="34" width="34" alt=""/>
            <div class="font-M fw-500 grey">{{'page.architectureDashboard.technology.noData'|translate}}</div>
        </div>
    }
</div>
}
