<div class="flex-column gap-S">
    <div class="flex-align-center flex-space-between" style="height: 34px;">
        <div class="flex-align-center gap-XS">
            <span class="font-MM fw-700">{{'page.appDetails.lifeCycle.title' | translate}}</span>
            <img src="assets/icons/help-2.svg" width="12" height="12" alt=""
                 [tooltip]="'page.appDetails.lifeCycle.hint' | translate" tooltipPosition="right"/>
        </div>
        <span *ngIf="!disabled && !_initializing && !_loading" class="font-M fw-500 accent c-p"
              (click)="editing = !editing">{{editing ? ('page.appDetails.lifeCycle.validate'|translate) : ('page.appDetails.lifeCycle.edit'|translate)}}</span>
    </div>
    <div *ngIf="!_initializing && (!_loading || initialized)" class="w-100 border-light-grey-top"></div>
    <div *ngIf="!_initializing && (!_loading || initialized)"></div>
    <div *ngIf="!_initializing && (!_loading || initialized)" class="flex" style="padding: 0 3px; height: 105px">
        <div *ngIf="percent !== undefined" class="flex p-absolute" [class]="percent === 100 ? 'width-total-2' : 'width-total-1'">
            <div class="flex-align-end p-relative" style="height: 52px; width: inherit; margin-top: 50px;">
                <div class="h-100 width-transition" [style.width]="'calc(' + percent + '% - ' + delta + 'px - 3px)'"></div>
                <div class="flex-column h-100" style="z-index: 2">
                    <div *ngIf="!editing" class="p-absolute accent-secondary font-S fw-500" style="top: -15px; margin-left: -25px; white-space: nowrap;">{{now|date:'MMM y'}}</div>
                    <div class="bkg-accent-secondary" style="width: 5px; height: 5px; border-radius: 100%;"></div>
                    <div class="bkg-accent-secondary" style="width: 1px; height: 100%; margin: -2px 2px;"></div>
                    <div class="bkg-accent-secondary" style="width: 5px; height: 5px; border-radius: 100%;"></div>
                </div>
            </div>
        </div>
        <div class="flex p-absolute" style="margin-top: -4px;">
            <div class="flex p-relative w-100">
                <div class="flex-justify-start" style="width: 187px">
                    <div class="flex-align-center custom-calendar-input" [ngClass]="{'input-disabled': !editing}"
                         (click)="!disabled ? picker1.open() : {}">
                        <input [formControl]="phaseInFormControl" [matDatepicker]="picker1" [matDatepickerFilter]="filterPhaseIn">
                        <div class="input-display font-S fw-500">{{(phaseInFormControl.value|date:'MMM y') ?? 'N/C'}}</div>
                        <img class="c-p" src="assets/icons/calendar2.svg" alt="" width="12" height="12">
                        <mat-datepicker #picker1 startView="multi-year" panelClass="month-picker"
                                        (monthSelected)="phaseInFormControl.setValue($event); picker1.close(); save();"></mat-datepicker>
                    </div>
                </div>
                <div class="flex-space-between" style="width: 299px">
                    <div class="flex-align-center custom-calendar-input" [ngClass]="{'input-disabled': !editing}"
                         (click)="!disabled ? picker2.open() : {}">
                        <input [formControl]="phaseInFormControl" [matDatepicker]="picker2" [matDatepickerFilter]="filterDeployed">
                        <div class="input-display font-S fw-500">{{(deployedFormControl.value|date:'MMM y') ?? 'N/C'}}</div>
                        <img class="c-p" src="assets/icons/calendar2.svg" alt="" width="12" height="12" (click)="picker2.open()">
                        <mat-datepicker #picker2 startView="multi-year" panelClass="month-picker"
                                        (monthSelected)="deployedFormControl.setValue($event); picker2.close(); save();"></mat-datepicker>
                    </div>
                    <div class="flex-align-center custom-calendar-input" [ngClass]="{'input-disabled': !editing}"
                         (click)="!disabled ? picker3.open() : {}">
                        <input [formControl]="phaseOutFormControl" [matDatepicker]="picker3" [matDatepickerFilter]="filterPhaseOut">
                        <div class="input-display font-S fw-500 display-right">{{(phaseOutFormControl.value|date:'MMM y') ?? 'N/C'}}</div>
                        <img class="c-p" src="assets/icons/calendar2.svg" alt="" width="12" height="12">
                        <mat-datepicker #picker3 startView="multi-year" panelClass="month-picker"
                                        (monthSelected)="phaseOutFormControl.setValue($event); picker3.close(); save();"></mat-datepicker>
                    </div>
                </div>
                <div class="flex-justify-end" style="width: 187px">
                    <div class="flex-align-center custom-calendar-input" [ngClass]="{'input-disabled': !editing}"
                         (click)="!disabled ? picker4.open() : {}">
                        <div class="input-display font-S fw-500 display-right">{{(retiredFormControl.value|date:'MMM y') ?? 'N/C'}}</div>
                        <input [formControl]="retiredFormControl" [matDatepicker]="picker4" [matDatepickerFilter]="filterRetired">
                        <img class="c-p" src="assets/icons/calendar2.svg" alt="" width="12" height="12">
                        <mat-datepicker #picker4 startView="multi-year" panelClass="month-picker"
                                        (monthSelected)="retiredFormControl.setValue($event); picker4.close(); save();"></mat-datepicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="border-grey-left flex-align-end pad-bottom-S">
                <div class="flex p-absolute" style="height: 70px; margin-bottom: -10px;"><span *ngFor="let i of [1, 2, 3, 4]" class="width-1 border-light-grey-right"></span></div>
                <div class="width-2 flex-align-center flex-justify-start horizontal-bar-XL bkg-accent-secondary-very-light border-accent-secondary border-dashed" style="z-index: 1">
                    <span class="accent-secondary pad-left-M font-S fw-500">{{'page.appDetails.lifeCycle.phase_in'|translate}}</span>
                </div>
            </div>
            <div class="border-grey-left flex-align-end pad-bottom-S">
                <div class="flex p-absolute" style="height: 70px; margin-bottom: -10px;"><span *ngFor="let i of [1, 2, 3, 4, 5, 6, 7]" class="width-1 border-light-grey-right"></span></div>
                <div class="width-3 flex-align-center flex-justify-end horizontal-bar-XL bkg-success-very-light border-success" style="z-index: 1">
                    <span class="success pad-right-M font-S fw-500">{{'page.appDetails.lifeCycle.deployed'|translate}}</span>
                </div>
            </div>
            <div class="border-grey-left flex-align-end pad-bottom-S">
                <div class="flex p-absolute" style="height: 70px; margin-bottom: -10px;"><span *ngFor="let i of [1, 2, 3, 4]" class="width-1 border-light-grey-right"></span></div>
                <div class="width-2 flex-align-center flex-justify-end horizontal-bar-XL bkg-warning-very-light border-warning border-dashed" style="z-index: 1">
                    <span class="warning pad-right-M font-S fw-500">{{'page.appDetails.lifeCycle.phase_out'|translate}}</span>
                </div>
            </div>
            <div class="border-grey-left flex-align-end pad-bottom-S">
                <div class="flex p-absolute" style="height: 70px; margin-bottom: -10px;"><span class="width-1 border-light-grey-right"></span></div>
                <div class="width-1 flex-center horizontal-bar-XL bkg-danger-very-light border-danger border-dashed" style="z-index: 1">
                    <img class="filter-danger" ngSrc="assets/icons/power.svg" height="12" width="12" alt=""/>
                </div>
            </div>
        </div>
    </div>
</div>
