<div class="flex-column gap-M">
    <div class="flex-align-center flex-space-between">
        <span class="font-MM fw-700">{{'page.appDetails.files.title'|translate}}</span>
        <app-mini-button size="add" color="accent"
                         [disable]="_initializing || _loading || !!_deleting"
                         (onClick)="openApplicationDocumentFormDialog()">
            <img src="assets/icons/add.svg" class="filter-accent" alt=""/>
        </app-mini-button>
    </div>
    <div *ngIf="!initialized" style="height: 191px;">
        <list-content-loader></list-content-loader>
    </div>
    <div *ngIf="initialized">
        <div *ngIf="documents.length <= 0" class="flex-column flex-center gap-M" style="height: 191px;">
            <div class="flex-center grey">
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.87533 16.9993L14.6253 21.7493L24.1253 12.2493M32.8337 16.9993C32.8337 25.7439
						25.7448 32.8327 17.0003 32.8327C8.25582 32.8327 1.16699 25.7439 1.16699 16.9993C1.16699
						8.25484 8.25582 1.16602 17.0003 1.16602C25.7448 1.16602 32.8337 8.25484 32.8337 16.9993Z"
                          stroke="#B5B5C3"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"/>
                </svg>
            </div>
            <div class="font-M fw-500 grey">{{'page.appDetails.files.noDocuments'|translate}}</div>
        </div>
        <div *ngIf="documents.length > 0" class="flex-column">
            <table class="w-100" aria-describedby="list-of-documents">
                <tr class="border-light-grey-bottom grey">
                    <th class="font-M fw-500 text-left w-30">{{'page.appDetails.files.table.title'|translate}}</th>
                    <th class="font-M fw-500 text-left w-30">{{'page.appDetails.files.table.type'|translate}}</th>
                    <th class="font-M fw-500 text-left w-20">{{'page.appDetails.files.table.creation'|translate}}</th>
                    <th class="font-M fw-500 text-right w-20">{{'page.appDetails.files.table.actions'|translate}}</th>
                </tr>
                <tr *ngFor="let document of documents; let i = index" [ngClass]="{'border-light-grey-bottom': i < documents.length - 1}">
                    <td class="font-M fw-500 text-left">{{document.title}}</td>
                    <td class="font-M fw-500 text-left">
                        <div class="flex">
                            <div class="flex-center horizontal-bar-L pad-0-L accent" style="background-color: #EFF8FC;">{{('page.appDetails.files.table.' + document.documentType)|translate}}</div>
                        </div>
                    </td>
                    <td class="font-M fw-500 text-left">{{document.fileLink.createdAt|date:"dd/MM/YYYY"}}</td>
                    <td class="font-M fw-500 text-right">
                        <div class="flex-align-center justify-flex-end">
                            <app-icon-button [size]="'m'" [img]="document.fileLink.type === fileType.FILE ? 'assets/icons/download-01.svg' : 'assets/icons/link.svg'"
                                             [tooltip]="(document.fileLink.type == fileType.FILE ? 'button.downloadOneFile' : 'button.openLink')|translate"
                                             [disabled]="_initializing || _loading || !!_deleting"
                                             (click)="document.fileLink.type == fileType.FILE ? downloadFile(document.fileLink) : openLink(document.fileLink.link!)">
                            </app-icon-button>
                            <app-icon-button color="accent" size="s" [img]="'assets/icons/edit.svg'" [tooltip]="'button.edit'|translate" [panelClass]="'fix-edit-margin'"
                                             [disabled]="_initializing || _loading || !!_deleting"
                                             (click)="openApplicationDocumentFormDialog(document)">
                            </app-icon-button>
                            <app-icon-button color="danger" size="s" [img]="'assets/icons/trash.svg'" [tooltip]="'button.delete'|translate"
                                             [disabled]="_initializing || _loading || !!_deleting" [loading]="_deleting === document.fileLink.fileLinkId"
                                             (click)="deleteApplicationDocument(document)">
                            </app-icon-button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
