import {Component, OnDestroy, OnInit} from '@angular/core';
import {TopbarService} from "src/app/services/front/topbar.service";
import {TranslateService} from "@ngx-translate/core";
import {finalize, forkJoin, Observable, of, Subject, Subscription, switchMap, tap} from "rxjs";
import {TenantOperationStatistic, TenantOverview, TenantService} from "src/app/services/tenant.service";
import {MiniWidget} from "../../global/mini-widget/mini-widget.component";
import {ActivatedRoute} from "@angular/router";
import {Organization, OrganizationService, OrganizationTree} from "src/app/services/organization.service";
import {AppRouteUrl, SETTINGS_BILLING_URL} from "src/app/models/home/navigation.model";
import {CurrentTenantService} from 'src/app/services/front/current-tenant.service';
import {DailyHealth, HealthService} from "src/app/services/back/health.service";
import {QueryRangeType} from 'src/app/services/back/tenant-finance.service';

@Component({
  selector: 'app-operation-dashboard',
  templateUrl: './operation-dashboard.component.html',
  styleUrls: ['./operation-dashboard.component.scss']
})
export class OperationDashboardComponent implements OnInit, OnDestroy {

	tenant: TenantOverview;
	organizationTree: OrganizationTree;
	filter: Subject<Organization|null|undefined> = new Subject<Organization|null|undefined>();

	_initializing: boolean;
	_loading: boolean;
	_loadingOrganization: boolean;

	healthList: DailyHealth[];
	applicationWidget: MiniWidget;
	providerWidget: MiniWidget;
	usersWidget: MiniWidget;
	tcoWidget: MiniWidget;

	settingsBillingUrl: AppRouteUrl = SETTINGS_BILLING_URL;

	initializeSub: Subscription;
	subscription: Subscription = new Subscription();

	constructor(private topBarService: TopbarService,
							private translateService: TranslateService,
							private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							private healthService: HealthService,
							private tenantService: TenantService,
							public activatedRoute: ActivatedRoute) {
	}

	ngOnInit(): void {
		this.setDefaultWidgetData();
		this.topBarService.onTitleChange(this.translateService.instant('menu.operation'), this.translateService.instant('menu.subtitle.operation'));
		this.subscription.add(this.currentTenantService.getInitializingChanges()
			.subscribe(initializing => this._initializing = initializing));
		this.subscription.add(this.currentTenantService.getCurrentTenantChanges()
			.pipe(
				tap(tenant => this.tenant = tenant),
				switchMap(() => this.fetchOrganizationTree()))
			.subscribe(() => this.initialize()));
		this.subscription.add(this.filter
			.subscribe(filter => this.initialize(filter)));
	}

	fetchOrganizationTree(): Observable<{}> {
		this._loadingOrganization = true;
		return this.organizationService.getOrganizationTreeByTenantId(this.tenant.configuration.id)
			.pipe(
				tap(organizationTree => this.organizationTree = organizationTree),
				tap(() => this._loadingOrganization = false));
	}

	initialize(org?: Organization|null): void {
		this.initializeSub?.unsubscribe();
		this.initializeSub = this.switchLoading()
			.pipe(
				switchMap(() => forkJoin([
					this.tenantService.getTenantOperationStatistic(this.tenant.configuration.id, org?.organizationId)
						.pipe(tap(statistic => this.setWidgetData(statistic))),
					this.fetchTenantHealthHistory(org?.organizationId)
				])),
				finalize(() => this.switchLoading()))
			.subscribe();
	}

	fetchTenantHealthHistory(organizationId?: string): Observable<{}> {
		return this.healthService.getAllDailyHealthByTenantId(this.tenant.configuration.id, organizationId, QueryRangeType.PAST_1_MONTH)
			.pipe(tap(data => this.healthList = data));
	}

	private setDefaultWidgetData(): void {
		this.applicationWidget = {
			numericSubject: 'page.dashboard.mini-widget.applications',
			iconUrl: 'assets/icons/app-list.svg',
			iconSize: 'm'
		};
		this.providerWidget = {
			numericSubject: 'page.financeDashboard.infrastructure',
			iconUrl: 'assets/icons/instances-widget.svg',
			iconSize: 'm'
		};
		this.usersWidget = {
			numericSubject: 'page.dashboard.mini-widget.users',
			iconUrl: 'assets/icons/users-widget.svg',
			iconSize: 'm'
		};
		this.tcoWidget = {
			numericSubject: 'page.dashboard.mini-widget.tco',
			iconUrl: 'assets/icons/tco-widget.svg',
			iconSize: 'm',
			type: "money"
		};
	}

	private setWidgetData(statistic: TenantOperationStatistic): void {
		this.applicationWidget.numericIndicator = statistic.totalApplication;
		this.tcoWidget.numericIndicator = statistic.totalCost.value ?? 0;
		this.usersWidget.numericIndicator = statistic.usage.value ?? 0;
		this.providerWidget.numericIndicator = statistic.serverCount;
	}

	refreshWithFilters(filter: Organization|null): void {
		this.filter.next(filter);
	}

	private switchLoading(): Observable<{}> {
		this._loading = !this._loading;
		return of({});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
		this.initializeSub?.unsubscribe();
	}
}
