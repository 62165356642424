<div *ngIf="install === undefined || install === 'automatic'" class="pad-L" style="height: 100%; width: 75vw; max-width: 1200px; min-width: 1000px; overflow: hidden;">
	<div class="disp-f j-c-s a-i-c">
		<div>
			<div class="disp-f a-i-c j-c-c col bkg-light-grey-2 radius-10"
				 style="height: 45px; width: 45px;">
				<img alt="" src="assets/icons/add-server.svg" class="svg-24 filter-grey">
			</div>
		</div>
		<div class="ml-s flex-column gap-XS">
			<div class="font-L fw-700">{{ 'page.infrastructure.add.title' | translate }}</div>
			<div class="font-M fw-300">{{ 'page.infrastructure.add.subtitle' | translate }}</div>
		</div>
		<app-icon-button (click)="closeDialog()" class="ml-auto" [img]="'assets/icons/close.svg'" type="stroked" background [panelClass]="'img-size-30 darken-icon'"></app-icon-button>
	</div>

	<div *ngIf="install === undefined" class="infrastructure-add-container mt-s flex-column justify-center gap-L" style="padding-left: 40px;">
		<div [@inAnimation1] class="infrastructure-add-button auto-infra flex-column gap-L prevent-select" (click)="install = 'automatic'">
			<div class="infrastructure-add-img stars">
				<img src="assets/icons/stars.svg" class="filter-accent-secondary" alt="">
			</div>
			<div class="flex-align-center gap-S">
				<span class="fw-700 font-L">{{ 'page.infrastructure.add.automatic' | translate }}</span>
				<img src="assets/icons/arrow-right-black.svg" class="filter-grey">
			</div>
			<div>
				<span>{{ 'page.infrastructure.add.autoDesc' | translate }}</span>
			</div>
		</div>
		<div [@inAnimation2] class="infrastructure-add-button manual-infra flex-column gap-L prevent-select" (click)="install = 'manual'">
			<div class="infrastructure-add-img manual">
				<img src="assets/icons/file.svg" class="filter-accent" alt="">
			</div>
			<div class="flex-align-center gap-S">
				<span class="fw-700 font-L">{{ 'page.infrastructure.add.manual' | translate }}</span>
				<img src="assets/icons/arrow-right-black.svg" class="filter-grey">
			</div>
			<div>
				<span>{{ 'page.infrastructure.add.manualDesc' | translate }}</span>
			</div>
		</div>
	</div>
	<app-add-server-automatically (onGoBack)="install = undefined" *ngIf="install === 'automatic'"></app-add-server-automatically>
</div>
<!-- Preload image to avoid image appearing on next step -->
<div style="opacity: 0; background: url('/assets/illustrations/add-server-auto-bg.svg')"></div>
<app-add-server-manually (onGoBack)="install = undefined" (onCloseDialog)="closeDialog()" (onAddServer)="onAddManualServer()" *ngIf="install === 'manual'"></app-add-server-manually>
