@if (_initializing || _loading) {
    <div class="border-box-full">
        <app-dashboard-page-loader></app-dashboard-page-loader>
    </div>
} @else {
    <div class="flex-column gap-M pad-M border-box-full">
        <div class="flex">
            <span class="font-MM fw-700">{{'page.architectureDashboard.ageDistribution.title'|translate}}</span>
        </div>
        <div class="flex-column flex-space-between h-100">
            <div class="flex horizontal-bar-L pad-left-S bkg-light-grey"
                 *ngFor="let level of distributions">
                <div class="flex-align-center font-M fw-500 grey" style="width: 150px;">{{ageLabel[level]}}</div>
                <div class="flex-justify-end w-100 h-100">
                    <div class="flex-align-center flex-justify-end horizontal-bar-L pad-S bkg-accent border-box"
                         [style.width.%]="agePercent[level]">
                        <span class="font-M fw-500 white">{{ageQuantity[level]}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
