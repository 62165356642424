import {Inject, Injectable} from '@angular/core';

@Injectable()
export class UserPreferenceService {
	protected localStorageUserPreferencesKey = 'user-preferences';

	getUserPreferences(tenantId: string, userId: string): UserPreferences {
		const storageData = localStorage.getItem(this.localStorageUserPreferencesKey);

		if (storageData && storageData !== '{}') {
			const id = tenantId + userId;
			const preferences: any = JSON.parse(storageData);

			return preferences[id];
		} else {
			return {};
		}
	}

	setUserPreferences(tenantId: string, userId: string, userPreferences: UserPreferences) {
		const storageData = localStorage.getItem(this.localStorageUserPreferencesKey);
		const id = tenantId + userId;

		const preferences = (storageData && storageData !== '{}') ? JSON.parse(storageData) : {};
		preferences[id] = userPreferences;
		localStorage.setItem(this.localStorageUserPreferencesKey, JSON.stringify(preferences));
	}
}

export interface UserPreferences {
	menuPreference?: MenuPreferences;
}

export interface MenuPreferences {
	isExpanded: boolean;
}
