import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CalendarComponent} from "./calendar.component";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		CalendarComponent
	],
	exports: [
		CalendarComponent
	]
})
export class CalendarModule {
}
