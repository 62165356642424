import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyTextComponent } from './copy-text.component';
import {MatRippleModule} from "@angular/material/core";
import {ClipboardModule} from "@angular/cdk/clipboard";



@NgModule({
	declarations: [
		CopyTextComponent
	],
	imports: [
		CommonModule,
		MatRippleModule,
		ClipboardModule
	],
	exports: [
		CopyTextComponent
	]
})
export class CopyTextModule { }
