import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Announcement, AnnouncementService} from "../../../../services/announcement.service";
import {ConfirmComponent} from "../../../global/dialog/confirm/confirm.component";
import {filter, tap} from "rxjs/operators";
import {switchMap} from "rxjs";
import {AnnouncementComponent} from "../../announcement/announcement.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {SnackbarService} from "src/app/services/front/snackbar.service";

@Component({
  selector: 'app-announcement-list',
  templateUrl: './announcement-list.component.html',
  styleUrls: ['./announcement-list.component.scss']
})
export class AnnouncementListComponent {
  @Input() data?: Data;
  @Output() onClose = new EventEmitter<Announcement[]>();

  constructor(private dialog: MatDialog,
              private translate: TranslateService,
              private announcementService: AnnouncementService,
              private snackbarService: SnackbarService) {
  }

  deleteAnnouncement(announcementId: string): void {
    if (this.data) {
      const tenantId = this.data.tenantId;
      const data = {
        title: '',
        message: this.translate.instant('confirmModal.deleteAnnouncement'),
        confirmButton: this.translate.instant('button.delete'),
        closeButton: this.translate.instant('button.cancel')
      };
      this.dialog.open(ConfirmComponent, {data: data})
        .afterClosed()
        .pipe(
          filter(result => result),
          tap(),
          switchMap(() => this.announcementService.deleteAnnouncement(tenantId, announcementId)))
        .subscribe(() => {
          if (this.data)
            this.data.announcements = this.data?.announcements.filter(a => a.id !== announcementId);
          this.snackbarService.show(this.translate.instant('global.announcements.announcementDeleted'))
          this.onClose.emit(this.data?.announcements);
        });
    }
  }

  openAnnouncement(announcement: Announcement) {
    if (this.data) {
      const tenantId = this.data.tenantId
      this.dialog.open(AnnouncementComponent, {data: {announcement: announcement}})
        .afterClosed()
        .pipe(
          filter(result => result && result.success && this.data),
          tap(() => this.snackbarService.show(this.translate.instant('global.announcements.announcementUpdated'))),
					switchMap(() => this.announcementService.getAnnouncements(tenantId))
        )
        .subscribe((announcements) => {
          if (this.data) {
            this.data.announcements = announcements.map(announcement => {
              announcement.createdAt = new Date(announcement.createdAt)
              announcement.updatedAt = new Date(announcement.updatedAt)

              let teamsTooltip: {
                id: string
                parentName: string
                teams: {
                  name: string
                }[]
              }[] = []

              // announcement.teamsTooltip = teams group by parentId
              announcement.teams?.forEach(team => {
                const parent = teamsTooltip.find(parent => parent.id === team.parentId)
                if (parent) {
                  teamsTooltip.find(parent => parent.id === team.parentId)?.teams.push({name: team.name})
                } else {
                  teamsTooltip.push({
                    id: team.parentId,
                    parentName: team.parentName,
                    teams: [{name: team.name}]
                  })
                }
              })
              announcement.teamsTooltip = teamsTooltip
              return announcement
            });
          }
					this.onClose.emit(this.data?.announcements);
				});
    }

  }

  close(): void {
    this.onClose.emit(this.data?.announcements);
  }
}

interface Data {
  announcements: Announcement[];
  tenantId: string;
}
