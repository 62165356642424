import {HttpHeaders} from '@angular/common/http';

export class SecurityUtils {
	protected static AUTH_TOKEN_KEY = 'auth_token';

	public static getHttpHeaders(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	public static getAuthHttpHeaders(): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + localStorage.getItem(SecurityUtils.AUTH_TOKEN_KEY),
		})
	};

	public static getRawAuthHttpHeaders(): HttpHeaders {
		return new HttpHeaders({
			'Authorization': 'Bearer ' + localStorage.getItem(SecurityUtils.AUTH_TOKEN_KEY),
		})
	};

	public static setAuthToken(token: string): boolean {
		localStorage.setItem(SecurityUtils.AUTH_TOKEN_KEY, token);
		return true;
	}

	public static getAuthToken(): string | null {
		return localStorage.getItem(SecurityUtils.AUTH_TOKEN_KEY);
	}

	public static removeAuthToken(): boolean {
		localStorage.removeItem(SecurityUtils.AUTH_TOKEN_KEY);
		return true;
	}
}
