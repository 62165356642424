import {TranslateService} from '@ngx-translate/core';
import {DataCollectionStatus} from 'src/app/services/model/resource.model';
import {Logo} from "../../../services/application-instance.service";

export class InfrastructureUtils {
	public static buildSystemLogo(os: string): Logo {
		switch (os.toUpperCase()) {
			case 'WINDOWS': return {
				url: '/assets/systems/windows.svg',
				backgroundColor: '#eff6d9'
			}
			case 'UBUNTU': return {
				url: '/assets/systems/logo-ubuntu.svg',
				backgroundColor: '#fcede7'
			}
			case 'CENTOS': return {
				url: '/assets/systems/logo-centos.svg',
				backgroundColor: '#f4e8f2'
			}
			case 'REDHAT': return {
				url: '/assets/systems/logo-redhat.svg',
				backgroundColor: '#fde6e6'
			}
			case 'DEBIAN': return {
				url: '/assets/systems/logo-debian.png',
				backgroundColor: '#fdf4ec'
			}
			case 'SUSE': return {
				url: '/assets/systems/logo-suse.svg',
				backgroundColor: '#eaf8f2'
			}
			case 'FEDORA': return {
				url: '/assets/systems/logo-fedora.svg',
				backgroundColor: '#eaecf1'
			}
			case 'IBMI': return {
				url: '/assets/systems/ibmi.svg',
				backgroundColor: '#ecf9ff'
			}
			case 'LINUX': return {
				url: '/assets/systems/linux.svg',
				backgroundColor: '#fffce6'
			}
			default: return {
				url: '/assets/icons/instance-accent.svg',
				backgroundColor: '#ecf9ff'
			}
		}
	}
}
