@if(innerState === applicationDialogState.LOADING || innerState === applicationDialogState.READY) {
	<div class="flex-align-center gap-M pad-XL-XL-0 w-100 border-box">
		<div class="flex-center title-icon radius-S">
			<img src="assets/icons/flow.svg" class="filter-grey" style="transform: rotate(45deg);" height="24" alt=""/>
		</div>
		<div class="flex-column gap-XS">
			<span class="font-LL fw-700">{{(!data.defaultData ? 'page.appDetails.architecture.dialog.title' : 'page.appDetails.architecture.dialog.titleUpdate')|translate}}</span>
			<span class="font-M fw-500">{{'page.appDetails.architecture.dialog.subtitle'|translate}}</span>
		</div>
	</div>
	<div class="pad-XL">
		<app-application-mapping-application-form [data]="data"
												  (hasApplication)="changeState($event)"
												  (cancel)="close()"
												  (saved)="close($event)">
		</app-application-mapping-application-form>

	</div>
} @else {
	<app-dialog-placeholder-card (onAction)="handlePlaceholderAction($event)">
		<ng-container content>
			<img src="assets/illustrations/one-app-sugg.svg" width="158">

			<div class="disp-f f-d-col a-i-c j-c-c mt-xl">
				<div class="font-LL text-center fw-700">{{'page.appDetails.architecture.dialog.placeholder.applications.title'|translate}}</div>
				<div class="font-M text-center grey mt-m w-70">{{'page.appDetails.architecture.dialog.placeholder.applications.description'|translate}}</div>
			</div>
		</ng-container>

		<ng-container action-button>
			{{'page.appDetails.architecture.dialog.placeholder.applications.actionButton'|translate}}
		</ng-container>
	</app-dialog-placeholder-card>
}
