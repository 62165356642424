import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'customDate',
  pure: true,
  standalone: true
})
export class CustomDatePipe implements PipeTransform {
  transform(value: Date, type: 'quarter'|'semester' = 'quarter', short: boolean = false): string {
    return (type === 'quarter' ? 'Q' : 'S')
      + Math.ceil((value.getMonth() + 1) / (type === 'quarter' ? 3 : 6))
      + (short
        ? ''
        : ' ' + value.getFullYear().toString().slice(-2));
  }
}
