import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {interval} from "rxjs";

export interface ProgressBarFinishEvent {
	status: 'timeout' | 'forced' | 'error'
}

export interface ProgressBarHasStartedEvent {
	error: (message: string) => void;
	complete: () => void;
}

@Component({
	selector: 'app-progress-bar-loader',
	templateUrl: './progress-bar-loader.component.html',
	styleUrls: ['./progress-bar-loader.component.scss']
})
export class ProgressBarLoaderComponent implements OnInit {
	@Input() loadingTime: number = 2000;
	@Output() onStart = new EventEmitter<ProgressBarHasStartedEvent>();
	@Output() onFinish = new EventEmitter<ProgressBarFinishEvent>();

	error: string | undefined;

	protected progress: number = 0.0;
	protected elapseTime: number = 0;
	protected forceComplete: boolean = false;

	ngOnInit() {
		const subscription = interval(10).subscribe(() => {
			this.elapseTime += 10;
			if (this.forceComplete) {
				this.progress = 100;
				subscription.unsubscribe();
				this.doOnFinish('forced');
			} else if (this.progress < 100) {
				const progressValue = this.linearInterpolate(0, 100, this.easeInOut(this.elapseTime / this.loadingTime)) / 100
				this.progress = progressValue * 100;
			} else {
				subscription.unsubscribe();
				this.doOnFinish('timeout');
			}
		});

		this.onStart.emit({
			error: (message: string) => {
				subscription.unsubscribe();
				this.error = message;
				this.progress = 100;
				this.doOnFinish('error');
			},
			complete: () => {
				this.forceComplete = true;
			}
		});
	}

	linearInterpolate(startValue: number, endValue: number, percent: number): number {
		return (startValue + (endValue - startValue) * percent);
	}

	flip(t: number): number {
		return 1 - t;
	}

	easeIn( t: number): number {
		return t * t;
	}

	easeOut(t: number): number {
		return this.flip(Math.pow(this.flip(t), 2));
	}

	easeInOut(t: number): number {
		return this.linearInterpolate(this.easeIn(t), this.easeOut(t), t);
	}

	doOnFinish(status: 'timeout' | 'forced' | 'error') {
		this.onFinish.emit({status});
	}
}
