<div class="flex-center h-100">
	<div class="flex-justify-center flex-align-center onboarding-step-container h-100 w-100">
		<div class="flex-justify-center gap-M h-100 w-100 h-auto m-auto">
			<div *ngIf="!isMobile" class="onboarding-stepper-container flex-column gap-M mt-xl">
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper"></div>
				<div class="onboarding-stepper active-step"></div>
				<div *ngIf="stepCount >= 4" class="onboarding-stepper"></div>
				<div *ngIf="stepCount >= 5" class="onboarding-stepper"></div>
				<div *ngIf="stepCount >= 6" class="onboarding-stepper"></div>
			</div>
			<div class="onboarding-step flex-column flex-space-between w-30 gap-L border-box pad-L">
				<div class="h-100" [ngClass]="{ 'mt-xxl': !canClose }">
					<div *ngIf="canClose" class="flex-justify-end">
						<app-icon-button (click)="goBackHome()" img='assets/icons/close.svg' size='s' color='accent' type='stroked'
										 shape='default' [disabled]='false' [focused]='false'
										 [background]='true' panelClass='img-size-30'></app-icon-button>
					</div>
					<div class="fw-700 font-XXL text-center mb-xxl onboarding-title">{{ 'page.onboarding.step3.st1' | translate }}</div>
					<div class="flex-justify-center text-center font-L fw-300 mb-l">
						<span class="onboarding-subtitle" [style.line-height.px]="panelClass === 'big-button' ? 27 : 23">{{ 'page.onboarding.step3.st2' | translate }}<span class="fw-700">{{ 'page.onboarding.step3.st3' | translate }}</span>{{ 'page.onboarding.step3.st4' | translate }}<span class="fw-700">{{ 'page.onboarding.step3.st5' | translate }}</span></span>
					</div>
					<div class="w-100 border-box onboarding-input">
						<span class="font-L fw-500 grey">{{ 'page.onboarding.step3.st6' | translate }}</span>
						<app-text-input
							[placeholder]="'page.onboarding.step3.st7' | translate"
							class="display-block"
							[class.mt-s]="panelClassInput === 'big-input'"
							[class.mt-xxs]="panelClassInput === 'little-input'"
							[panelClass]="panelClassInput"
							[input]="$any(form.get('name'))">
						</app-text-input>
					</div>
					<div class="w-100 border-box onboarding-input">
						<span class="font-L fw-500 grey">{{ 'page.onboarding.step3.st8' | translate }}</span>
						<app-multi-select [inputControl]="$any(form.get('size'))">
							<app-select-trigger appearance="field">
                                <div class="flex-align-center" [style.height.px]="height" [class]="'font-' + fontSize">{{'page.newTenant.organization.size.' + $any(form.get('size')).value | translate}}</div>
                            </app-select-trigger>
							<app-select-option *ngFor="let type of ['xs', 's', 'm', 'l', 'xl']" [value]="type">
								{{'page.newTenant.organization.size.' + type | translate}}
							</app-select-option>
						</app-multi-select>
					</div>
					<div class="w-100 border-box onboarding-input">
						<span class="font-L fw-500 grey">{{ 'page.onboarding.step3.st9' | translate }}</span>
						<app-multi-select [inputControl]="$any(form.get('activity'))">
							<app-select-trigger appearance="field">
                                <div class="flex-align-center" [style.height.px]="height" [class]="'font-' + fontSize">{{ 'page.newTenant.organization.activity.' + $any(form.get('activity')).value | translate }}</div>
                            </app-select-trigger>
							<app-select-option>
								-
							</app-select-option>
							<app-select-option *ngFor="let type of ['agrifood', 'bank', 'wood', 'btp', 'chemistry', 'sales', 'publishing', 'electronics', 'studies', 'industry', 'it', 'machinery', 'metallurgy', 'plastic', 'services', 'textile', 'transport', 'other']" [value]="type">
								{{'page.newTenant.organization.activity.' + type | translate}}
							</app-select-option>
						</app-multi-select>
					</div>
				</div>
				<div class="flex-center w-100 pb-l" style="height: 64px;">
					<app-text-button (click)="submitForm()" class="w-90" color='accent' type='flat'
									 shape='default' [disabled]='form.invalid' [loading]='_loading' badge=''
									 badgePosition='above after' [panelClass]="panelClass">{{ 'page.onboarding.step3.st10' | translate }}
					</app-text-button>
				</div>
			</div>
		</div>
	</div>
</div>
