import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatMenuPanel, MatMenuTrigger} from "@angular/material/menu";
import {FormControl, Validators} from '@angular/forms';
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {TenantAccount} from 'src/app/services/model/account.model';

@Component({
  selector: 'app-select-or-invite-user-input',
  templateUrl: './select-or-invite-user-input.component.html',
  styleUrl: './select-or-invite-user-input.component.scss'
})
export class SelectOrInviteUserInputComponent implements OnInit, OnDestroy {

	constructor(protected translate: TranslateService) {}

	@Input() value?: TenantAccount;
	@Input() list: TenantAccount[];
	@Input() searchPlaceholder: string = this.translate.instant('global.search');
	@Input() disabled: boolean = false;

	@Output() onSelect = new EventEmitter<TenantAccount>();
	@Output() onInvite = new EventEmitter<string>();

	@ViewChild('menuTrigger', { static: false}) menuTrigger: MatMenuTrigger;

	@ViewChild('displayListMenu') matMenu: MatMenuPanel;

	userElements: UserElement[];
	showInviteUser: boolean = true;
	showUserList: boolean = true;
	searchFormControl = new FormControl('');
	emailFormControl = new FormControl();
	subscription: Subscription = new Subscription();

	ngOnInit() {
		this.emailFormControl.setValidators([Validators.email, Validators.required])
		this.emailFormControl.updateValueAndValidity();
		this.userElements = this.list.map(tenantAccount => ({
			tenantAccount: tenantAccount,
			name: tenantAccount.pending ? tenantAccount.account.email : tenantAccount.account.firstName + ' ' + tenantAccount.account.lastName,
			selected: tenantAccount.account.accountId === this.value?.account.accountId,
		}));
	}

	filteredItems(): UserElement[] {
		return this.userElements
			.filter(item => !this.searchFormControl.value || item.name.toLowerCase().includes(this.searchFormControl.value!.toLowerCase()))
			//sort by selected first, then pending, then alphabetically
			.sort((a, b) => {
				if (a.selected && !b.selected) {
					return -1;
				} else if (!a.selected && b.selected) {
					return 1;
				} else if (a.tenantAccount.pending && !b.tenantAccount.pending) {
					return 1;
				} else if (!a.tenantAccount.pending && b.tenantAccount.pending) {
					return -1;
				} else {
					return a.name.localeCompare(b.name);
				}
			});
	}

	onSelectUser(user: UserElement) {
		this.userElements.forEach(u => u.selected = false);
		user.selected = true;
		this.onSelect.emit(this.list.find(a => a.account.accountId === user.tenantAccount.account.accountId));
		this.menuTrigger.closeMenu();
	}

	onInviteUser() {
		if (this.emailFormControl.valid) {
			this.onInvite.emit(this.emailFormControl.value);
			this.menuTrigger.closeMenu();
		}
	}

	onClose() {
		this.emailFormControl.reset();
		this.searchFormControl.reset();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}
}

interface UserElement {
	tenantAccount: TenantAccount;
	name: string;
	selected: boolean;
}
