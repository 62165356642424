import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {SnackbarComponent} from "./snackbar.component";

@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		SnackbarComponent
	],
	exports: [
		SnackbarComponent
	]
})
export class SnackbarModule {
}
