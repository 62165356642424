<div class="flex-column gap-XL">
	<div class="flex-space-between w-100 gap-L">
		<div class="w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
            <div class="bg-rounded w-100 top-widget-height"
                 [tooltip]="'page.appDetails.usage.tooltipDeactivated'|translate"
                 [tooltipDisabled]="!!application && application.usageActivated">
                <application-mini-widget
                        [class.c-p]="!!application && !application.usageActivated"
                        [widget]="availabilityWidget"
                        (click)="navigateToUsageParameter()"
                        [type]="'availability'"
                        [title]="'menu.applicationAvailability' | translate"
                ></application-mini-widget>
            </div>
		</div>
		<div class="w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
            <div class="bg-rounded w-100 top-widget-height"
                 [tooltip]="'page.appDetails.usage.tooltipDeactivated'|translate"
                 [tooltipDisabled]="!!application && application.usageActivated">
                <application-mini-widget
                        [class.c-p]="!!application && !application.usageActivated"
                        [widget]="applicationUsersWidget"
                        [type]="'users'"
                        [title]="'menu.users' | translate"
                        (click)="navigateToUsageParameter()"
                ></application-mini-widget>
            </div>
		</div>
		<div class="bg-rounded w-100 top-widget-height">
			<div *ngIf="isTenantInactivePlan" class="widget-plan-disabled flex-justify-center flex-align-end"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<application-mini-widget
				[widget]="tcoWidget"
				[type]="'tco'"
				[title]="'page.appDetails.overview.tco'|translate"
			></application-mini-widget>
		</div>
	</div>

	<div class="bg-rounded pad-M-M-0" style="padding-bottom: 5px">
		<app-application-information-form></app-application-information-form>
	</div>

	<div class="bg-rounded pad-M">
		<app-application-contact-preview></app-application-contact-preview>
	</div>

	<div class="bg-rounded pad-M">
		<app-application-log-preview *ngIf="tabActive"></app-application-log-preview>
	</div>
</div>
