import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import {deepCopy} from "@angular-devkit/core/src/utils/object";

@Component({
	selector: "app-mini-button",
	template: `
		<div [class.c-p]="!isLoading && !disable" [class.disable]="disable" class="button disp-f a-i-c j-c-c" [ngClass]="[size, color, fill]" matRipple (click)="onButtonClick()">
			<ng-content *ngIf="!isLoading"></ng-content>
			<mat-spinner [color]="color === 'danger' ? 'warn' : 'accent'" *ngIf="isLoading" [diameter]="16"></mat-spinner>
		</div>
	`,
	styleUrls: ['./mini-button.component.scss']
})
export class MiniButtonComponent implements OnChanges {
	@Input() size: 's' | 'm' | 'add' = 'm';
	@Input() color: 'accent' | 'danger' = 'accent';
	@Input() fill: 'plain' | 'border' = 'plain';
	@Input() disable: boolean = false;

	@Output() onClick = new EventEmitter<ButtonRemote>();

	protected lastLockId = 0;

	isLoading = false;

	onButtonClick() {
		if (!this.isLoading && !this.disable) {
			// Generate a random id
			this.lastLockId = Date.now()

			// Copy the id for closure
			const lockIdCopy = deepCopy(this.lastLockId);

			// Emit a new button-primary remote
			this.onClick.emit({
				becomeActive: () => {
					if (lockIdCopy === this.lastLockId) {
						this.isLoading = false
					}
				},
				displayLoader: () => {
					if (lockIdCopy === this.lastLockId) {
						this.isLoading = true
					}
				}
			});
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.disable) {
			this.disable = changes.disable.currentValue;
		}
	}
}

export interface ButtonRemote {
	becomeActive: ()=>void
	displayLoader: ()=>void
}
