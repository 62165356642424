import {RouterModule, Routes} from '@angular/router';
import {DiagramPath, MainMenuPath} from 'src/app/models/home/navigation.model';
import {AuthGuard} from 'src/app/guards/auth.guard';
import {DiagramsComponent} from 'src/app/modules/home/diagrams/diagrams.component';
import {NgModule} from '@angular/core';
import {DiagramOverviewComponent} from 'src/app/modules/home/diagrams/diagram-overview/diagram-overview.component';
import {DiagramAppMappingComponent} from 'src/app/modules/home/diagrams/diagram-app-mapping/diagram-app-mapping.component';
import {DiagramAppMatrixComponent} from 'src/app/modules/home/diagrams/diagram-app-matrix/diagram-app-matrix.component';
import {DiagramLifeCycleComponent} from 'src/app/modules/home/diagrams/diagram-life-cycle/diagram-life-cycle.component';
import {DiagramAppComparisonComponent} from "./diagram-app-comparision/diagram-app-comparison.component";

export const diagramsRoutes: Routes = [
  {
    path: MainMenuPath.DIAGRAMS,
    canActivate: [AuthGuard],
    component: DiagramsComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: DiagramOverviewComponent
      },
      {
        path: DiagramPath.APP_MAPPING,
        canActivate: [AuthGuard],
        component: DiagramAppMappingComponent
      },
      {
        path: DiagramPath.APP_MATRIX,
        canActivate: [AuthGuard],
        component: DiagramAppMatrixComponent
      },
      {
        path: DiagramPath.APP_LIFE_CYCLE,
        canActivate: [AuthGuard],
        component: DiagramLifeCycleComponent
      },
		{
			path: DiagramPath.APP_COMPARISON,
			canActivate: [AuthGuard],
			component: DiagramAppComparisonComponent
		}
    ]
  }
];

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [
    RouterModule.forChild(diagramsRoutes)
  ]
})
export class DiagramsRoutingModule {
}
