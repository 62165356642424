@if (_initializing || _loading) {
<div class="border-box-full">
	<app-dashboard-page-loader></app-dashboard-page-loader>
</div>
} @else {
<div>
	<div class="disp-f a-i-c pl-m pr-m pt-m pb-m">
        <span class="font-MM fw-700">{{ 'page.usageDashboard.usageRepartition' | translate }}</span>
	</div>
	<div class="disp-f j-c-c f-d-col gap-M pl-xl pr-xl" style="height: 217px;">
		<div class="disp-f a-i-c j-c-c pr-xs" style="gap: 30px;" *ngIf="realTotalUsage > 0 && (doughnutChartData.datasets[0].data.length > 0 || polarChartData.datasets[0].data.length > 0)">
			<!-- Doughnut with data -->
			<div>
				<div class="disp-b" style="width: 200px; height: 200px;">
					<canvas *ngIf="!team" baseChart style="width: 200px; height: 200px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
					<canvas *ngIf="team" baseChart style="width: 200px; height: 200px;"
							[data]="polarChartData"
							[type]="'polarArea'"
							[options]="polarChartOptions"></canvas>
				</div>
				<div *ngIf="!team" class="doughnut-center">
					<div class="doughnut-flex">
						<div class="doughnut-total">
							{{ realTotalUsage  }}
						</div>
						<div class="doughnut-label">
							utilisateurs
						</div>
					</div>
				</div>
			</div>
			<div class="w-100 disp-f f-d-col j-c-c h-100 gap-XL">
				<div *ngFor="let item of filteredData; let index = index;">
					<div class="disp-f f-d-col gap-XS">
						<div class="disp-f j-c-sb">
							<div>
								<span class="font-M fw-500">{{ item.name }}</span>
							</div>
							<div class="font-M fw-700">
								<span *ngIf="!item.percentage || item.percentage < 1"> < 1%</span>
								<span *ngIf="item.percentage && item.percentage > 0">{{ item.percentage }}%</span>
							</div>
						</div>
						<div style="position: relative;">
							<div class="progression-bg"></div>
							<div *ngIf="!team" [ngClass]="'progression-'+index" [ngStyle]="{ 'width': item.percentage+'%' }"></div>
							<div *ngIf="team" [ngClass]="'polar-'+index" [ngStyle]="{ 'width': item.percentage+'%' }"></div>
							<div *ngIf="team" style="width: 0"></div>
						</div>
					</div>
				</div>
				@for (number of [1,2,3,4]; track number) {
					@if (filteredDataLength && filteredDataLength < number) {
						<div class="disp-f f-d-col">
							<div class="disp-f j-c-sb">
								<div class="donut-placeholder"></div>
								<span class="font-M fw-700">-</span>
							</div>
							<div style="position: relative;">
								<div class="progression-bg"></div>
							</div>
						</div>
					}
				}
			</div>
		</div>
		<div class="disp-f a-i-c j-c-c" style="gap: 70px;" *ngIf="!realTotalUsage || (doughnutChartData.datasets[0].data.length <= 0 && polarChartData.datasets[0].data.length <= 0)">
			<!-- Doughnut without data -->
			<div>
				<div class="disp-b" >
					<canvas *ngIf="!team" baseChart style="width: 150px; height: 150px;"
							[data]="doughnutChartData"
							[type]="'doughnut'"
							[options]="chartOptions"
					></canvas>
					<canvas *ngIf="team" baseChart style="width: 200px; height: 200px;"
							[data]="polarChartData"
							[type]="'polarArea'"
							[options]="polarChartOptions"></canvas>
				</div>
				<div *ngIf="!team" class="doughnut-center">
					<div class="doughnut-flex">
						<div class="doughnut-total-no-data">
							-
						</div>
						<div class="doughnut-label">
							utilisateurs
						</div>
					</div>
				</div>
			</div>
			<div class="w-100 disp-f f-d-col j-c-c gap-XXL" style="height: 100%;">
				<div class="disp-f f-d-col">
					<div class="disp-f j-c-sb">
						<div class="donut-placeholder"></div>
						<span class="font-M fw-700">-</span>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
					</div>
				</div>
				<div class="disp-f f-d-col">
					<div class="disp-f j-c-sb">
						<div class="donut-placeholder"></div>
						<span class="font-M fw-700">-</span>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
					</div>
				</div>
				<div class="disp-f f-d-col">
					<div class="disp-f j-c-sb">
						<div class="donut-placeholder"></div>
						<span class="font-M fw-700">-</span>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
					</div>
				</div>
				<div class="disp-f f-d-col">
					<div class="disp-f j-c-sb">
						<div class="donut-placeholder"></div>
						<span class="font-M fw-700">-</span>
					</div>
					<div style="position: relative;">
						<div class="progression-bg"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
}
