import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrganizationService} from "src/app/services/organization.service";
import {Router} from "@angular/router";
import {CurrentTenantService} from "src/app/services/front/current-tenant.service";
import {APPLICATIONS_URL} from "src/app/models/home/navigation.model";
import {Subscription, switchMap} from "rxjs";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {ConfirmComponent} from "../../../../global/dialog/confirm/confirm.component";
import {filter, tap} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {MatDialog} from "@angular/material/dialog";
import {ApplicationHealth} from 'src/app/services/model/new-application.model';

@Component({
  selector: 'app-team-applications',
  templateUrl: './team-applications.component.html',
  styleUrls: ['./team-applications.component.scss']
})
export class TeamApplicationsComponent {

	@Input() applicationInstances:ApplicationHealth[]
	@Input() teamId: string;

	@Output() onDelete: EventEmitter<string> = new EventEmitter<string>();

	tenantId: string
	openAll: boolean = false;
	subscriptions: Subscription = new Subscription();

	constructor(private router: Router,
							private currentTenantService: CurrentTenantService,
							private organizationService: OrganizationService,
							private snackbarService: SnackbarService,
							private translate: TranslateService,
							private dialog: MatDialog) {
	}

	ngOnInit() {
		this.subscriptions.add(this.currentTenantService.getCurrentTenantIdChanges()
			.subscribe(tenantId => this.tenantId = tenantId));
	}

	goToApp(applicationInstanceId: string) {
		this.currentTenantService.getCurrentTenantId().subscribe(tenantId => {
			this.router.navigate([APPLICATIONS_URL], {queryParams: {tenant: tenantId, applicationId: applicationInstanceId}});
		});
	}

	deleteAppFromTeam(applicationId: string): void {
		const data = {
			title: '',
			message: this.translate.instant('confirmModal.removeAppFromTeam'),
			confirmButton: this.translate.instant('button.delete'),
			closeButton: this.translate.instant('button.cancel')
		};
		this.dialog.open(ConfirmComponent, {data: data})
			.afterClosed()
			.pipe(
				filter(result => result),
				tap(),
				switchMap(() => this.organizationService.changeTeamApplications(this.tenantId, this.teamId, [{appId: applicationId, checked: false}])))
			.subscribe(() => {
				this.onDelete.emit(applicationId)
				this.snackbarService.show(this.translate.instant('global.announcements.announcementDeleted'))
			}, () => {
				this.snackbarService.show(this.translate.instant('global.announcements.error'), 'danger-snack')
			});
	}
}
