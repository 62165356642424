import {Component, Input} from '@angular/core';
import {Announcement, AnnouncementService} from "../../../../services/announcement.service";
import {MatDialog} from "@angular/material/dialog";
import {AnnouncementComponent} from "../../announcement/announcement.component";
import {SnackbarService} from "src/app/services/front/snackbar.service";
import {ConfirmComponent} from "../../../global/dialog/confirm/confirm.component";
import {filter, tap} from "rxjs/operators";
import {Subscription, switchMap} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {RightSliderService} from "src/app/services/front/right-slider.service";
import {AnnouncementListComponent} from "../announcement-list/announcement-list.component";
import {ApplicationInstance} from "../../../../services/model/new-application.model";

@Component({
  selector: 'app-announcements-widget',
  templateUrl: './announcements-widget.component.html',
  styleUrls: ['./announcements-widget.component.scss']
})
export class AnnouncementsWidgetComponent {
  @Input() data: Announcement[];
  @Input() tenantId: string;
  @Input() fromApp: ApplicationInstance;
  @Input() limit: number = 4;
  @Input() noAnnouncementWidth = 200;

  displayAll = false;

  subscription: Subscription = new Subscription();

  constructor(private dialog: MatDialog,
              private snackbarService: SnackbarService,
              private translate: TranslateService,
              private announcementService: AnnouncementService,
              private rightSliderService: RightSliderService) {
  }

  openAnnouncement(announcement: Announcement) {
    this.dialog.open(AnnouncementComponent, {data: {announcement: announcement}})
      .afterClosed()
      .pipe(
        filter(result => result && result.success),
				tap(() => this.snackbarService.show(this.translate.instant('global.announcements.announcementUpdated'))),
		  this.fromApp ? switchMap(() => this.announcementService.getApplicationAnnouncements(this.tenantId, this.fromApp.applicationId)) : switchMap(() => this.announcementService.getAnnouncements(this.tenantId))
      )
      .subscribe((announcements) => {
        this.data = announcements.map(announcement => {
          announcement.createdAt = new Date(announcement.createdAt)
          announcement.updatedAt = new Date(announcement.updatedAt)

          let teamsTooltip: {
            id: string
            parentName: string
            teams: {
              name: string
            }[]
          }[] = []

          // announcement.teamsTooltip = teams group by parentId
          announcement.teams?.forEach(team => {
            const parent = teamsTooltip.find(parent => parent.id === team.parentId)
            if (parent) {
              teamsTooltip.find(parent => parent.id === team.parentId)?.teams.push({name: team.name})
            } else {
              teamsTooltip.push({
                id: team.parentId,
                parentName: team.parentName,
                teams: [{name: team.name}]
              })
            }
          })
          announcement.teamsTooltip = teamsTooltip
          return announcement
        });
      });
  }

  deleteAnnouncement(announcementId: string): void {
    const data = {
      title: '',
      message: this.translate.instant('confirmModal.deleteAnnouncement'),
      confirmButton: this.translate.instant('button.delete'),
      closeButton: this.translate.instant('button.cancel')
    };
    this.dialog.open(ConfirmComponent, {data: data})
      .afterClosed()
      .pipe(
        filter(result => result),
        tap(),
        switchMap(() => this.announcementService.deleteAnnouncement(this.tenantId, announcementId)))
      .subscribe(() => {
        this.data = this.data?.filter(a => a.id !== announcementId);
        this.snackbarService.show(this.translate.instant('global.announcements.announcementDeleted'))
      });
  }

  openCreateAnnouncement() {
    this.dialog.open(AnnouncementComponent, {data: {appId: this.fromApp?.applicationId}})
      .afterClosed()
      .pipe(
        filter(result => result && result.success),
        tap(() => this.snackbarService.show(this.translate.instant('global.announcements.announcementCreated'))),
				this.fromApp ? switchMap(() => this.announcementService.getApplicationAnnouncements(this.tenantId, this.fromApp.applicationId)) : switchMap(() => this.announcementService.getAnnouncements(this.tenantId))
      )
      .subscribe((announcements) => {
        this.data = announcements.map(announcement => {
          announcement.createdAt = new Date(announcement.createdAt)
          announcement.updatedAt = new Date(announcement.updatedAt)

          let teamsTooltip: {
            id: string
            parentName: string
            teams: {
              name: string
            }[]
          }[] = []

          announcement.teams?.forEach(team => {
            const parent = teamsTooltip.find(parent => parent.id === team.parentId)
            if (parent) {
              teamsTooltip.find(parent => parent.id === team.parentId)?.teams.push({name: team.name})
            } else {
              teamsTooltip.push({
                id: team.parentId,
                parentName: team.parentName,
                teams: [{name: team.name}]
              })
            }
          })
          announcement.teamsTooltip = teamsTooltip
          return announcement
        });
      });
  }

  onOpenAnnouncementDrawer() {
    this.rightSliderService.openComponent(AnnouncementListComponent, {announcements: this.data, tenantId: this.tenantId})
      .subscribe((data?: Announcement[]) => {
        if (data)
          this.data = data;
      });
  }
}
