<div (click)="onTeamClick()" class="team-card pl-s pr-m pt-s pb-s mb-xs flex-space-between flex-align-center gap-S">
	<div class="flex-align-center">
		<div class="disp-f justify-center a-i-c gap-S">
			<div class="list-icon flex-center p-relative">
				<img [src]="!!team.icon ? ('/assets/icons/team_icon/' + team.icon + '.svg') : 'assets/icons/team.svg'" class="filter-accent">
				<div *ngIf="team.autodiscovered" class="p-absolute autodiscover-img-container flex-center">
					<img src="assets/icons/autodiscover.svg" height="20" width="20" class="filter-accent-secondary" style="transform: translateY(-1px)">
				</div>
			</div>
			<div [style.width]="team.autodiscovered ? '170px' : '300px'" [style.max-width]="team.autodiscovered ? '170px' : '300px'">
				<div class="business-unit text-overflow-ellipsis">{{ team.businessUnitName }}</div>
				<div class="structure text-overflow-ellipsis">{{ team.structureName }}</div>
			</div>
		</div>
		<div *ngIf="team.autodiscovered" class="flex-center autodiscover-bubble">
			<span class="font-M fw-700">{{'page.application.autodiscover.title'|translate}}</span>
		</div>
	</div>
	<div class="flex-align-center gap-XL">
		<div *ngIf="team.userTotalCount">
			<app-chips-count [value]="!team.userCount ? '0' + ' / ' + team.userTotalCount : team.userCount + ' / ' + team.userTotalCount" type="user" color="accent"></app-chips-count>
		</div>
		<div *ngIf="!team.userTotalCount">
			<app-chips-count [value]="undefined" type="user" bold></app-chips-count>
		</div>
		<div  style="margin-left: auto">
			<div class="rounded-button disp-f justify-center a-i-c">
				<img src="assets/icons/arrow-right.svg">
			</div>
		</div>
	</div>
</div>
