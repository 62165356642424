import {Component, Input} from '@angular/core';
import {Organization} from 'src/app/services/organization.service';
import {OrganizationType} from 'src/app/services/model/autodiscover.model';

@Component({
  selector: 'app-element-list',
  templateUrl: './element-list.component.html',
  styleUrls: ['./element-list.component.scss']
})
export class ElementListComponent {
	@Input() elements: Organization[];
	@Input() subtitle: string;
	@Input() placeholder: string = "No data";
	types: typeof OrganizationType = OrganizationType;
}
