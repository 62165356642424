<div *ngIf="!tenant?.plan?.isTrialPlan() && !tenant?.plan?.isActivePlan()" class="pl-l pr-l mt-m">
	<app-chips color="error">{{'page.dashboard.limited.badge' | translate}}</app-chips>
	<div class="font-S fw-500 grey mt-xs">{{'page.dashboard.limited.headline' | translate}}</div>
	<div class="font-S fw-500 grey">{{'page.dashboard.limited.link.0' | translate}} <a [routerLink]="[settingsBillingUrl]" [queryParams]="activatedRoute.snapshot.queryParams"> {{'page.dashboard.limited.link.1' | translate}} </a> {{'page.dashboard.limited.link.2' | translate}}</div>
</div>
@if (!noContracts || _contractCountLoading) {
<div class="main-container pt-xl pb-l">
	<div class="ml-xl disp-g-3 mr-xl semi-responsive" style="grid-column-gap: 20px; grid-row-gap: 20px">
		<div style="grid-column: span 8;">
			<app-filter [organizationTree]="organizationTree"
                        [filtersLoading]="_initializing || _loadingOrganization"
                        (changes)="refreshWithFilters($event)">
			</app-filter>
		</div>
		<!-- TCO widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="tcoTotalWidget" [responsive]="true"></app-mini-widget>
		</div>
		<!-- Application TCO widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="tcoLicenseWidget" [responsive]="true"></app-mini-widget>
		</div>
		<!-- Infrastructure TCO widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="tcoServiceWidget" [responsive]="true"></app-mini-widget>
		</div>
		<!-- TCO by user widget -->
		<div class="widget widget-mini" style="grid-column: span 2">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled widget-mini disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-mini-widget [miniWidget]="tcoPerUserWidget" [responsive]="true"></app-mini-widget>
		</div>
		<!-- TCO graph -->
		<div class="widget" style="grid-column: span 5;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
            <app-financial-projection [filter]="filter"></app-financial-projection>
		</div>
		<!-- Application deadlines -->
		<div class="widget" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-application-deadlines [filter]="filter" [limit]="6"></app-application-deadlines>
		</div>
		<!-- Most expensive structures doughnut widget -->
		<div class="widget widget-semi" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-most-expensive-doughnut [filter]="filter"></app-most-expensive-doughnut>
		</div>
		<!-- Overlaps -->
		<div class="widget widget-semi" style="grid-column: span 2;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-overlaps [filter]="filter"></app-overlaps>
		</div>
		<!-- Most expensive apps -->
		<div class="widget widget-semi" style="grid-column: span 3;">
			<div *ngIf="tenant?.plan?.isInactivePlan()" class="widget-plan-disabled disp-f a-i-e j-c-c"><mat-icon class="circle-icon circle-icon-warn mb-l font-XXL">lock</mat-icon></div>
			<app-most-expensive-apps [filter]="filter" [limit]="5"></app-most-expensive-apps>
		</div>
	</div>
</div>
} @else {
<div class="main-container w-100 flex-center flex-column gap-20" style="height: calc(100% - 80px)">
	<img src="assets/illustrations/no-contract.svg" class="w-100 h-auto" style="max-width: 800px;">
	<span class="font-XL fw-700 text-center primary">{{ 'page.financeDashboard.noContract.1' | translate }}</span>
	<span class="fw-500 text-center font-L primary" style="line-height: 1.6rem;">
        {{ 'page.financeDashboard.noContract.2' | translate }}<br>
		{{ 'page.financeDashboard.noContract.3' | translate }}
        <span class="fw-700">{{ 'page.financeDashboard.noContract.4' | translate }}</span>
        {{ 'page.financeDashboard.noContract.5' | translate }}
    </span>
	<app-text-button (click)="goToApps()" class="mt-l w-20" style="min-width: 200px;" color='accent' type='flat'>
		{{ 'global.letsGo' | translate }}
	</app-text-button>
</div>
}
