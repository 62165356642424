import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SecurityUtils} from 'src/app/services/security.utils';
import {ApplicationComment, ApplicationCommentForm} from 'src/app/services/model/application-comment.model';

@Injectable()
export class ApplicationCommentService {

  private serviceUrl = (tenantId: string, applicationId: string) => `${environment.engineURL}/tenants/${tenantId}/applications/${applicationId}/comments`;

  constructor(protected httpClient: HttpClient) {
  }

  public sendApplicationComment(tenantId: string, applicationId: string, form: ApplicationCommentForm): Observable<string> {
    return this.httpClient.post<string>(`${this.serviceUrl(tenantId, applicationId)}`, form, {
      headers: SecurityUtils.getAuthHttpHeaders()
    });
  }

  public getApplicationComments(tenantId: string, applicationId: string): Observable<ApplicationComment[]> {
    return this.httpClient.get<ApplicationComment[]>(`${this.serviceUrl(tenantId, applicationId)}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    });
  }

  public deleteApplicationComment(tenantId: string, applicationId: string, commentId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.serviceUrl(tenantId, applicationId)}/${commentId}`, {
      headers: SecurityUtils.getAuthHttpHeaders()
    });
  }
}
