<form *ngIf="!_initializing" class="flex-column gap-S" [formGroup]="usageForm">
	<div class="font-S fw-700 primary">{{'page.appDetails.settings.activateUsage'|translate}}</div>
	<div class="flex-align-center flex-space-between">
		<div class="flex-column font-S fw-500 l-h-n">
			<span>{{'page.appDetails.settings.usageFirstLine'|translate}}</span>
			<span>{{'page.appDetails.settings.usageSecondLine'|translate}}</span>
			<span>{{'page.appDetails.settings.usageThirdLine'|translate}}</span>
		</div>
		<mat-slide-toggle class="mr-s" color="accent" [formControlName]="formName.usageCheck"
						  (change)="updateUsageOnBlur.emit(formName.usageCheck)"></mat-slide-toggle>
	</div>
	<div></div>
	<div class="flex-align-center gap-S">
		<img src="assets/icons/attention.svg" height="14" width="14" alt=""/>
		<div class="orange-text">
			<span>{{'page.appDetails.settings.attention1'|translate}}</span>
			<a (click)="goToSettings()" class="link" style="text-decoration: underline">{{'page.appDetails.settings.attention2'|translate}}</a>
			<span>{{'page.appDetails.settings.attention3'|translate}}</span>
		</div>
	</div>
	<div></div>
	<div class="flex w-100 gap-M">
		<div class="flex-column w-20 gap-XS">
			<div class="grey fw-500">{{'page.appDetails.settings.usageDomain'|translate}}</div>
			<mat-form-field color="primary" appearance="outline" class="w-100">
				<input matInput type="text" #iu1
                       [placeholder]="'page.appDetails.domainPlaceholder'|translate"
                       [formControlName]="formName.usageDomain"
                       (blur)="updateUsageOnBlur.emit(formName.usageDomain)" (keyup.enter)="iu1.blur()">
			</mat-form-field>
		</div>
		<div class="flex-column w-80 gap-XS">
			<div class="grey fw-500">{{'page.appDetails.settings.usagePath'|translate}}</div>
			<div class="flex-center">
				<div class="flex-center h-100 slash">/</div>
				<mat-form-field color="primary" appearance="outline" class="w-100 no-left-radius">
					<input matInput type="text" #iu2
                           [placeholder]="'page.appDetails.pathPlaceholder'|translate"
                           [formControlName]="formName.usagePath"
                           (blur)="updateUsageOnBlur.emit(formName.usagePath)" (keyup.enter)="iu2.blur()">
				</mat-form-field>
			</div>
		</div>
	</div>
</form>
