import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, Observable, of, Subscription, switchMap, tap} from 'rxjs';
import {ApplicationDetailData, ApplicationDetailService} from 'src/app/services/front/application-detail.service';
import {ActivityLogService} from 'src/app/services/back/activity-log.service';
import {ActivityLog, LogActionType, LogFieldType, sortActivityLogs} from 'src/app/services/model/activity-log.model';
import {DateTranslateRef, DateUtils} from 'src/app/utils/date.utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-application-log-preview',
  templateUrl: './application-log-preview.component.html',
  styleUrls: ['./application-log-preview.component.scss']
})
export class ApplicationLogPreviewComponent implements OnInit, OnDestroy {

  tenantId: string;
  applicationId: string;

  _initializing: boolean;
  _loading: boolean;

  logs: ActivityLog[] = [];
  logOffset: number = 0;
  readonly LIMIT = 4;

  subscription: Subscription = new Subscription();

  constructor(private applicationDetailService: ApplicationDetailService,
              private activityLogService: ActivityLogService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.subscription.add(this.applicationDetailService.getInitializingChanges()
      .subscribe(initializing => this._initializing = initializing));
    this.subscription.add(this.applicationDetailService.getApplicationDetailDataChanges()
      .pipe(tap(data => this.setApplicationDetailData(data)))
      .subscribe(() => this.initialize()));
  }

  setApplicationDetailData(data: ApplicationDetailData): void {
    this.tenantId = data.tenantId;
    this.applicationId = data.instance.applicationId;
  }

  initialize(): void {
    this.fetchApplicationLogs(this.logOffset);
  }

  fetchApplicationLogs(offset: number): void {
    this.subscription.add(this.switchLoading()
      .pipe(
        switchMap(() => this.activityLogService.getAllApplicationActivityLog(this.tenantId, this.applicationId, this.LIMIT, offset)),
        tap(logs => this.logs = logs.sort(sortActivityLogs)),
        finalize(() => this.switchLoading()))
      .subscribe());
  }

  getDateGap(date: string): string {
    let translateRef: DateTranslateRef|null = DateUtils.getDateTranslateRef(date)
    return !!translateRef
      ? this.translate.instant('page.home.homepage.history.date.' + translateRef.translateRef, {value: translateRef.value})
      : '-'
  }

  displayMore(): void {
    // TODO
    this.logOffset += this.LIMIT;
    this.fetchApplicationLogs(this.logOffset);
  }

  switchLoading(): Observable<{}> {
    this._loading = !this._loading;
    return of({});
  }

  getActionLabel(log: ActivityLog) {
    switch (log.actionType) {
      case LogActionType.CREATE: return log.fieldType === LogFieldType.COMMENT
        ? this.translate.instant('page.home.homepage.history.writeOf')
        : this.translate.instant('page.home.homepage.history.createOf');
      case LogActionType.UPDATE: return this.translate.instant('page.home.homepage.history.updateOf');
      case LogActionType.DELETE: return this.translate.instant('page.home.homepage.history.deleteOf');
      default: this.translate.instant('page.home.homepage.history.updateOf');
    }
  }

  getFieldLabel(log: ActivityLog) {
    switch (log.fieldType) {
      case LogFieldType.APPLICATION_MAPPING: return this.translate.instant('page.home.homepage.history.field.flux');
      case LogFieldType.BUSINESS_SERVICE: return this.translate.instant('page.home.homepage.history.field.organization');
      case LogFieldType.CATEGORY: return this.translate.instant('page.home.homepage.history.field.category');
      case LogFieldType.COMMENT: return this.translate.instant('page.home.homepage.history.field.comment');
      case LogFieldType.FINANCE: return this.translate.instant('page.home.homepage.history.field.contract');
      case LogFieldType.HTTP_CHECK: return this.translate.instant('page.home.homepage.history.field.http-check');
      case LogFieldType.INSTANCE: return log.application.name;
      case LogFieldType.LIFE_CYCLE: return this.translate.instant('page.home.homepage.history.field.life-cycle');
      case LogFieldType.LINKED_CONTENT: return this.translate.instant('page.home.homepage.history.field.document');
      case LogFieldType.RESOURCE_MAPPING: return this.translate.instant('page.home.homepage.history.field.server');
      case LogFieldType.RESPONSIBLE: return this.translate.instant('page.home.homepage.history.field.responsible');
      case LogFieldType.TAGS: return this.translate.instant('page.home.homepage.history.field.tag');
      case LogFieldType.TECHNOLOGY: return this.translate.instant('page.home.homepage.history.field.technology');
      default: return log.application.name;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
