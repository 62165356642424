export enum ColorEnum {
	accent = "#36B0EB",
	light_blue = "#DBEFF8",
	chart_yellow = "#E0A22E",
	chart_yellow_hover = "#91691d",
	chart_blue_hover = "#1975a1",
	chart_placeholder_clear_grey = "#EFEFF5",
	grey = "#B5B5C3",
	medium_grey = "#F2F2F8",
	pink = "#E0346F",
	orange = "#F79939",
	licence = "#3DC0FF",
	service = "#ACE4FF",
	infrastructure = "#319ACC",
	doughnut_colors_0 = "#3DC0FF",
	doughnut_colors_1 = "#257399",
	doughnut_colors_2 = "#319ACC",
	doughnut_colors_3 = "#ACE4FF",
	transparent = "transparent",
	accent_secondary = "#A890F3",
	accent_secondary_hover = "#7a5dbf",
	red = "rgba(255, 85, 85, 0.2)",
	red_hovered = "rgba(255, 85, 85, 1)",
	perf_doughnut_colors_0 = "rgba(74, 176, 74, 0.10)",
	perf_doughnut_colors_1 = "rgba(255, 153, 0, 0.10)",
	perf_doughnut_colors_2 = "rgba(255, 85, 85, 0.10)",
	perf_doughnut_border_colors_0 = "#58BA5C",
	perf_doughnut_border_colors_1 = "#F90",
	perf_doughnut_border_colors_2 = "#F55",
}
