<div class="flex-align-center gap-M">
    <app-display-logo *ngIf="!!application" [logo]="application.logo" [size]="size"></app-display-logo>
    <div class="flex-column flex-space-evenly" [ngStyle]="{'width': 'calc(100% - 15px - ' + (!criticality ? '' : '37px - ') + size + 'px)', 'height': size + 'px'}">
        <span class="font-M text-overflow-ellipsis" [ngClass]="{'fw-700': bold, 'fw-500': !bold}">
            {{application?.name || '-'}}
        </span>
        <span *ngIf="description && description.length > 0" class="font-S fw-500 text-overflow-ellipsis">
            {{description}}
        </span>
    </div>
    <div *ngIf="criticality" class="flex-align-center" style="min-width: 12px; margin-right: 10px;">
        <img *ngIf="!!application?.criticality" src="assets/icons/criticality-{{application!.criticality!.toLowerCase()}}.svg" height="19" alt="">
    </div>
</div>
