@if (loading) {
    <div class="border-box-full">
        <app-dashboard-page-loader></app-dashboard-page-loader>
    </div>
} @else {
    <div class="flex-column gap-M pad-M border-box-full">
        <div class="flex">
            <span class="font-MM fw-700">{{'page.architectureDashboard.averageAge'|translate}}</span>
        </div>
        <div class="flex-end">
            <span class="font-XXL fw-700 accent">{{averageAge + ' ' + (averageAge > 1 ? ('page.appDetails.finance.contracts.yearLittlePlural'|translate) : ('page.appDetails.finance.contracts.yearLittle'|translate))}}</span>
        </div>
    </div>
}
