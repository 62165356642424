import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OrganizationSettingComponent} from "./organization-setting.component";
import {TranslateModule} from "@ngx-translate/core";
import {AlertModule} from "../../../global/alert/alert.module";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {SimpleTextInputModule} from "../../../global/input/simple-text-input/simple-text-input.module";
import {CopyTextModule} from 'src/app/modules/global/input/copy-text/copy-text.module';
import {DesignSystemModule} from 'src/app/modules/design-system/design-system.module';

@NgModule({
  declarations: [
	  OrganizationSettingComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    AlertModule,
    MatIconModule,
    MatButtonModule,
    SimpleTextInputModule,
    CopyTextModule,
    DesignSystemModule,
  ],
	exports: [
		OrganizationSettingComponent
	]
})

export class OrganizationSettingModule { }
