import {NgModule} from '@angular/core';
import {AlertComponent} from './alert.component';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
	imports: [
		MatProgressBarModule,
		CommonModule,
		MatIconModule
	],
	declarations: [
		AlertComponent,
	],
	exports: [
		AlertComponent,
	]
})
export class AlertModule {
}
