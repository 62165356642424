import {Component, ComponentRef, Directive, ElementRef, HostListener, Input, ViewChild, ViewContainerRef} from '@angular/core';
import {interval, Subscription} from 'rxjs';
import {filter, first} from 'rxjs/operators';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

  @ViewChild('tooltipContainer') tooltipContainer: ElementRef;

  tooltip: string|undefined;
  left: number = 0;
  top: number = 0;
  visible: boolean = false;
  position: 'left'|'right'|'top'|'bottom' = TooltipPosition.BOTTOM;
  delta: number = 0;
  maxWidth: 200|2000 = 200;

}

@Directive({
  selector: '[tooltip],[tooltipTriggerFor]'
})
export class TooltipDirective {

  @Input() tooltip?: string;
  @Input() tooltipTriggerFor?: TooltipComponent;
  @Input() tooltipPosition: 'left'|'right'|'top'|'bottom' = TooltipPosition.BOTTOM;
  @Input() tooltipDisabled: boolean = false;
  @Input() tooltipOffset: number = 0;

  childNode: Node|null = null;
  componentRef: ComponentRef<TooltipComponent>|null = null;
  subscription: Subscription = new Subscription();

  readonly MIN_MARGIN: number = 5;
  readonly POINTER_SIZE: number = 5;

  constructor(private viewContainerRef: ViewContainerRef,
              private elementRef: ElementRef) {
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.destroy();
    if (this.tooltipTriggerFor) {
      this.tooltipTriggerFor.maxWidth = 2000;
      this.setTooltipComponentProperties(this.tooltipTriggerFor);
    } else if (this.tooltip && this.tooltip.length > 0) {
      this.componentRef = this.viewContainerRef.createComponent(TooltipComponent);
      this.componentRef.instance.maxWidth = 200;
      this.componentRef.instance.tooltip = this.tooltip.replace('<script>', '').replace('</script>', '');
      this.setTooltipComponentProperties(this.componentRef.instance);
    }
  }

  private setTooltipComponentProperties(tooltipComp: TooltipComponent): void {
    if (this.tooltipDisabled) {
      tooltipComp.visible = false;
    } else {
      this.subscription = interval(10)
        .pipe(filter(() => !!tooltipComp.tooltipContainer), first())
        .subscribe(() => {
          this.childNode = document.body.appendChild(tooltipComp.tooltipContainer.nativeElement);
          const trigger = this.elementRef.nativeElement.getBoundingClientRect();
          const tooltip = tooltipComp.tooltipContainer.nativeElement.getBoundingClientRect();
          if (this.tooltipPosition === TooltipPosition.LEFT) {
            if (trigger.left - tooltip.width - this.MIN_MARGIN - this.POINTER_SIZE < 0) {
              this.computePositionRight(tooltipComp, trigger, tooltip);
            } else {
              this.computePositionLeft(tooltipComp, trigger, tooltip);
            }
          } else if (this.tooltipPosition === TooltipPosition.RIGHT) {
            if (trigger.right + tooltip.width + this.MIN_MARGIN - this.POINTER_SIZE > window.innerWidth) {
              this.computePositionLeft(tooltipComp, trigger, tooltip);
            } else {
              this.computePositionRight(tooltipComp, trigger, tooltip);
            }
          } else if (this.tooltipPosition === TooltipPosition.TOP) {
            if (trigger.top - tooltip.height - this.MIN_MARGIN - this.POINTER_SIZE < 0) {
              this.computePositionBottom(tooltipComp, trigger, tooltip);
            } else {
              this.computePositionTop(tooltipComp, trigger, tooltip);
            }
          } else if (this.tooltipPosition === TooltipPosition.BOTTOM) {
            if (trigger.bottom + tooltip.height + this.MIN_MARGIN - this.POINTER_SIZE > window.innerHeight) {
              this.computePositionTop(tooltipComp, trigger, tooltip);
            } else {
              this.computePositionBottom(tooltipComp, trigger, tooltip);
            }
          }
          tooltipComp.visible = true;
        });
    }
  }

  private computePositionLeft(tooltipComp: TooltipComponent, trigger: any, tooltip: any): void {
    tooltipComp.position = TooltipPosition.LEFT;
    tooltipComp.left = trigger.left - tooltip.width - this.POINTER_SIZE - this.tooltipOffset;
    this.computeVerticalPosition(tooltipComp, trigger, tooltip);
  }

  private computePositionRight(tooltipComp: TooltipComponent, trigger: any, tooltip: any): void {
    tooltipComp.position = TooltipPosition.RIGHT;
    tooltipComp.left = trigger.right + this.POINTER_SIZE + this.tooltipOffset;
    this.computeVerticalPosition(tooltipComp, trigger, tooltip);
  }

  private computeVerticalPosition(tooltipComp: TooltipComponent, trigger: any, tooltip: any): void {
    const top: number = (trigger.top + (trigger.height / 2)) - (tooltip.height / 2);
    tooltipComp.top = Math.min(Math.max(this.MIN_MARGIN, top), window.innerHeight - tooltip.height - this.MIN_MARGIN);
    tooltipComp.delta = Math.min(Math.max(0, this.MIN_MARGIN - top), tooltipComp.top - top);
  }

  private computePositionBottom(tooltipComp: TooltipComponent, trigger: any, tooltip: any): void {
    tooltipComp.position = TooltipPosition.BOTTOM;
    tooltipComp.top = trigger.bottom + this.POINTER_SIZE + this.tooltipOffset;
    this.computeHorizontalPosition(tooltipComp, trigger, tooltip);
  }

  private computePositionTop(tooltipComp: TooltipComponent, trigger: any, tooltip: any): void {
    tooltipComp.position = TooltipPosition.TOP;
    tooltipComp.top = trigger.top - tooltip.height - this.POINTER_SIZE - this.tooltipOffset;
    this.computeHorizontalPosition(tooltipComp, trigger, tooltip);
  }

  private computeHorizontalPosition(tooltipComp: TooltipComponent, trigger: any, tooltip: any): void {
    const left: number = (trigger.left + (trigger.width / 2)) - (tooltip.width / 2);
    tooltipComp.left = Math.min(Math.max(this.MIN_MARGIN, left), window.innerWidth - tooltip.width - this.MIN_MARGIN);
    tooltipComp.delta = Math.min(Math.max(0, this.MIN_MARGIN - left), tooltipComp.left - left);
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.destroy();
  }

  destroy(): void {
    this.subscription.unsubscribe();
    if (!!this.tooltipTriggerFor) {
      this.tooltipTriggerFor.visible = false;
    }
    if (!!this.componentRef) {
      this.componentRef.instance.visible = false;
      this.componentRef.destroy();
      this.componentRef = null;
    }
    if (!!this.childNode) {
      document.body.removeChild(this.childNode);
      this.childNode = null;
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}

enum TooltipPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right'
}
