<div #tooltipContainer class="tooltip-container"
     [style.left.px]="left" [style.top.px]="top"
     [style.opacity]="visible ? '0.8' : '0'"
     [style.max-width.px]="maxWidth">
    @if (tooltip && tooltip.length > 0) {
        <div class="font-M fw-500" [innerHTML]="tooltip"></div>
    } @else {
        <ng-content></ng-content>
    }
    <div class="tooltip-pointer" [class]="'tooltip-' + position"
         [style.left]="position === 'bottom' || position === 'top' ? 'calc(50% - ' + (5 + delta) + 'px)' : position === 'right' ? '-5px' : ''"
         [style.right]="position === 'left' ? '-5px' : ''"
         [style.top]="position === 'left' || position === 'right' ? 'calc(50% - ' + (5 + delta) + 'px)' : position === 'bottom' ? '-5px' : ''"
         [style.bottom]="position === 'top' ? '-5px' : ''">
    </div>
</div>
