<div>
	<div>
		<div class="w-100 td-no-padding" style="margin-top: 14px;">
			<div class="drawer">
				<table *ngIf="!openAll" class="w-100">
					<tr class="t-bottom-border grey">
						<th style="font-weight: 500; padding: 0px 0px 4px 0px; text-align: left;">{{ 'page.organization.appTable.name' | translate }}</th>
						<th style="font-weight: 500; padding: 0px 0px 4px 0px; text-align: left;">{{ 'page.organization.appTable.status' | translate }}</th>
						<th style="font-weight: 500; padding: 0px 0px 4px 0px; text-align: left;">{{ 'page.organization.appTable.criticality' | translate }}</th>
						<th style="font-weight: 500; padding: 0px 0px 4px 0px; text-align: left;">{{ 'page.organization.appTable.popularity' | translate }}</th>
						<th style="width: 20px;"></th>
					</tr>
					<tr *ngFor="let app of applicationInstances | slice:0:4" class="primary t-row" (click)="goToApp(app.application.id)">
						<td>
                            <div class="flex">
                                <app-display-application [application]="app.application"></app-display-application>
                            </div>
						</td>
						<td>
                            <div class="flex">
                                <app-chips-health [health]="app.health"></app-chips-health>
							</div>
						</td>
						<td>
                            <div class="flex">
                                <app-chips-criticality [criticality]="app.application.criticality"></app-chips-criticality>
                            </div>
						</td>
						<td>
							-
						</td>
						<td>
							<div class="rounded-button disp-f justify-center a-i-c">
								<img src="assets/icons/arrow-right.svg">
							</div>
						</td>
					</tr>
				</table>
				<table *ngIf="openAll" class="w-100">
					<tr class="t-bottom-border grey">
						<th style="font-weight: 500; padding: 0px 0px 4px 0px; text-align: left;">{{ 'page.organization.appTable.name' | translate }}</th>
						<th style="font-weight: 500; padding: 0px 0px 4px 0px; text-align: left;">{{ 'page.organization.appTable.status' | translate }}</th>
						<th style="font-weight: 500; padding: 0px 0px 4px 0px; text-align: left;">{{ 'page.organization.appTable.criticality' | translate }}</th>
						<th style="font-weight: 500; padding: 0px 0px 4px 0px; text-align: left;">{{ 'page.organization.appTable.popularity' | translate }}</th>
						<th style="width: 20px;"></th>
					</tr>
					<tr *ngFor="let app of applicationInstances" class="primary t-row" (click)="goToApp(app.application.id)">
						<td>
                            <div class="flex">
                                <app-display-application [application]="app.application"></app-display-application>
                            </div>
						</td>
						<td>
                            <div class="flex">
                                <app-chips-health [health]="app.health"></app-chips-health>
                            </div>
						</td>
						<td>
                            <div class="flex">
                                <app-chips-criticality [criticality]="app.application.criticality"></app-chips-criticality>
                            </div>
						</td>
						<td>
							-
						</td>
						<td>
							<div class="rounded-button disp-f justify-center a-i-c">
								<img src="assets/icons/arrow-right.svg">
							</div>
						</td>
					</tr>
				</table>
			</div>
			<div *ngIf="applicationInstances.length <= 0">
				<div class="disp-f a-i-c j-c-c f-d-col" style="gap: 15px; margin-top: 60px; margin-bottom: 60px;">
					<div class="disp-f a-i-c j-c-c grey">
						<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9.87533 16.9993L14.6253 21.7493L24.1253 12.2493M32.8337 16.9993C32.8337 25.7439
							25.7448 32.8327 17.0003 32.8327C8.25582 32.8327 1.16699 25.7439 1.16699 16.9993C1.16699
							8.25484 8.25582 1.16602 17.0003 1.16602C25.7448 1.16602 32.8337 8.25484 32.8337 16.9993Z"
								  stroke="#B5B5C3"
								  stroke-width="2"
								  stroke-linecap="round"
								  stroke-linejoin="round"/>
						</svg>
					</div>
					<div class="grey" style="font-weight: 500;">
						{{ 'page.application.autodiscover.noApplication' | translate }}
					</div>
				</div>
			</div>
			<div (click)="openAll = !openAll" *ngIf="applicationInstances.length > 4" class="flex justify-center display-more-btn">
				<span *ngIf="!openAll">{{ 'page.appDetails.overview.displayMoreApps' | translate }}</span>
				<span *ngIf="openAll">{{ 'page.appDetails.overview.displayLessApps' | translate }}</span>
			</div>
		</div>
	</div>
</div>
