<div class="delete-dialog-container flex-column flex-space-between pad-L">
	<div class="flex-column flex-align-center gap-XL mt-xl">
		<img [ngSrc]="data.icon" alt="" width="188.237" height="194.067">
		<span class="fw-700 text-center font-LL l-h-n">{{ data.title }}</span>
		<p class="text-center w-85" [innerHTML]="data.subtitle"></p>
		<div class="warning-content flex-center flex-column gap-M">
			<img src="assets/icons/info-black.svg" class="filter-danger" alt="" width="30" height="30">
			<p class="text-center text-red w-90" [innerHTML]="data.warningMessage"></p>
		</div>
	</div>
	<!-- BUTTONS -->
	<div class="flex-center w-100 gap-M">
		<div class="w-50"><app-text-button type="stroked" color="accent" (click)="close()">{{ 'button.cancel'| translate}}</app-text-button></div>
		<div class="w-50"><app-text-button type="flat" color="accent" (click)="confirm()">{{ data.deleteButton }}</app-text-button></div>
	</div>
</div>
