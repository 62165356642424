import {ClickableObject, DrawableObject, Point} from "../diagram";
import { Layers } from "../layer";
import {CriticalityLevel} from 'src/app/services/tenant.service';

export interface ApplicationState {
}

const criticalityHighImages =  new Image();
criticalityHighImages.src = 'assets/icons/criticality-high.svg';

const criticalityMediumImages  = new Image();
criticalityMediumImages.src = 'assets/icons/criticality-medium.svg';

const criticalityLowImages = new Image();
criticalityLowImages.src = 'assets/icons/criticality-low.svg';

const criticalityMapping = new Map<string, { image: HTMLImageElement, color: string}>(
	[
		['low', {image: criticalityLowImages, color: '#3DC0FF'}],
		['medium', {image: criticalityMediumImages, color: '#FF9900'}],
		['high', {image: criticalityHighImages, color: '#FF5555'}]
	]
);

const cacheImage = new Map<string, HTMLImageElement>();

export class ApplicationSquare implements DrawableObject<ApplicationState>, ClickableObject {
	public static readonly WIDTH: number = 50;
	public static readonly HEIGHT: number = 50;
	public static readonly ACTIVE_FONT_COLOR = '#0C2633';
	public static readonly INACTIVE_BACKGROUND_COLOR = '#F7F8FA';

	protected mouseOver = false;
	protected isSelected = false;

	constructor(
		protected ctx: CanvasRenderingContext2D,
		protected origin: Point,
		protected applicationId: string,
		protected applicationLogo: string,
		protected applicationName: string,
		protected applicationCriticality: CriticalityLevel|null|undefined,
		protected fontColor: string,
		protected selected: boolean
	) {
		this.isSelected = selected;
		this.preloadImage();
	}

	draw(layers: Layers): void {
		layers.getLayer(1).addObject(this.drawShadow());
		layers.getLayer(1).addObject(this.drawCriticalityShadow());
		layers.getLayer(2).addObject(this.drawBackground());
		layers.getLayer(3).addObject(this.drawLogo('white'));
		layers.getLayer(4).addObject(this.drawCriticality());
	}

    update(state: ApplicationState): void {

    }

    isAtCoordinates(coordinates: Point): boolean {
		return  coordinates.x >= this.origin.x && coordinates.x <= this.origin.x + ApplicationSquare.WIDTH &&
			coordinates.y >= this.origin.y && coordinates.y <= this.origin.y + ApplicationSquare.HEIGHT;
    }

	setMouseOver(): void {
		this.mouseOver = true;
	}

	unsetMouseOver(): void {
		this.mouseOver = false;
	}

	getId(): string {
		return this.applicationId;
	}

	protected preloadImage() {
		if (this.applicationLogo && !cacheImage.has(this.applicationId)) {
			const img = new Image();
			img.crossOrigin = "Anonymous";
			img.src = this.applicationLogo;
			img.onload = () => {
				const logo = this.buildCopyImage(img);
				cacheImage.set(this.applicationId, logo)
			};
		}
	}

	protected buildCopyImage(img: HTMLImageElement){
		const canvas = document.createElement('canvas');
		const ctx:any = canvas.getContext('2d');
		canvas.width = img.width;
		canvas.height = img.height;
		ctx.drawImage(img, 0, 0, img.width, img.height); // Or at whatever offset you like
		const imgPixels: ImageData = ctx.getImageData(0, 0, img.width, img.height);
		ctx.putImageData(imgPixels, 0, 0, 0, 0, img.width, img.height)
		const originalImageCopy = new Image();
		originalImageCopy.src = canvas.toDataURL();
		canvas.remove();

		return originalImageCopy;
	}

	protected drawLogo(background: string): ()=>void {
		return () => {
			this.ctx.beginPath()
			this.ctx.fillStyle =  background ?? ApplicationSquare.INACTIVE_BACKGROUND_COLOR;
			this.ctx.roundRect(this.origin.x + 7, this.origin.y + 7, 35, 35, 8);
			this.ctx.fill()

			this.ctx.beginPath()
			this.ctx.imageSmoothingEnabled = true;
			if (this.applicationLogo) {
				const logo = cacheImage.get(this.applicationId)
				if (logo) {
					this.ctx.save();
					this.roundedImage(this.origin.x + 8, this.origin.y + 8, 35, 35, 10);
					this.ctx.clip();
					this.ctx.drawImage(logo, this.origin.x + 8, this.origin.y + 8, 35, 35);
					this.ctx.restore();
				} else {
					this.drawDefaultIcon()
				}
			} else {
				this.drawDefaultIcon()
			}
		}
	}

	protected drawDefaultIcon() {
		this.ctx.font = 'bold 14px proxima-nova';
		this.ctx.textAlign = "center";
		this.ctx.fillStyle = '#0C2633';
		this.ctx.fillText(this.applicationName.slice(0,2), this.origin.x + 25, this.origin.y + 30);
		this.ctx.fill();
		this.ctx.textAlign = "start";
	}

	protected drawShadow(): ()=>void {
		return () => {
			this.ctx.beginPath()
			this.ctx.fillStyle = '#E3E3E3FF';
			this.ctx.roundRect(this.origin.x + 2, this.origin.y + 2, ApplicationSquare.WIDTH, ApplicationSquare.HEIGHT, 15);
			//this.ctx.fill();

			this.ctx.shadowColor = "#E3E3E3FF";
			this.ctx.shadowBlur = 3;
			this.ctx.shadowOffsetX = 3;
			this.ctx.shadowOffsetY = 3;
			const repeats = 2
			for(let i=0;i<repeats;i++){
				this.ctx.shadowBlur+=0.25;
			}

			this.ctx.fill();
			this.ctx.shadowBlur = 0
			this.ctx.shadowColor = "transparent";
		}
	}

	protected drawBackground(): ()=>void {
		return () => {
			this.ctx.beginPath()

			this.ctx.fillStyle = 'white';
			this.ctx.roundRect(this.origin.x, this.origin.y, ApplicationSquare.WIDTH, ApplicationSquare.HEIGHT, 15);
			this.ctx.fill()

			this.ctx.strokeStyle = (this.isSelected || this.mouseOver) ? '#36B0EB' : '#ececee';
			this.ctx.lineWidth = 1;
			this.ctx.stroke();
		}
	}

	protected drawCriticalityShadow(): ()=>void {
		return () => {
			if (this.applicationCriticality) {
				this.ctx.fillStyle = '#E3E3E3FF';
				this.ctx.arc(this.origin.x + ApplicationSquare.WIDTH - 4, this.origin.y + ApplicationSquare.HEIGHT - 2, 10, 0, 2 * Math.PI);
				//this.ctx.fill();

				this.ctx.shadowColor = "#E3E3E3FF";
				this.ctx.shadowBlur = 3;
				this.ctx.shadowOffsetX = 3;
				this.ctx.shadowOffsetY = 3;
				const repeats = 2
				for(let i=0;i<repeats;i++){
					this.ctx.shadowBlur+=0.25;
				}

				this.ctx.fill();
				this.ctx.shadowBlur = 0
				this.ctx.shadowColor = "transparent";
			}
		}

	}

	protected drawCriticality(): ()=>void {
		return () => {
			if (this.applicationCriticality) {
				const image = criticalityMapping.get(this.applicationCriticality.toLowerCase()) as {image:HTMLImageElement, color: string};

				this.ctx.beginPath();
				this.ctx.fillStyle = 'white';
				this.ctx.strokeStyle = image.color;
				this.ctx.arc(this.origin.x + ApplicationSquare.WIDTH - 6, this.origin.y + ApplicationSquare.HEIGHT - 4, 10, 0, 2 * Math.PI);
				this.ctx.fill()
				this.ctx.stroke()

				this.ctx.drawImage(image.image, this.origin.x + ApplicationSquare.WIDTH - 9.5, this.origin.y + ApplicationSquare.HEIGHT - 10, 8, 12);
			}
		}
	}

	protected roundedImage(x: number, y: number, width: number, height: number, radius: number){
		this.ctx.beginPath();
		this.ctx.moveTo(x + radius, y);
		this.ctx.lineTo(x + width - radius, y);
		this.ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
		this.ctx.lineTo(x + width, y + height - radius);
		this.ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
		this.ctx.lineTo(x + radius, y + height);
		this.ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
		this.ctx.lineTo(x, y + radius);
		this.ctx.quadraticCurveTo(x, y, x + radius, y);
		this.ctx.closePath();
	}
}
