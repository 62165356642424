import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {LOGIN_URL} from "../models/home/navigation.model";

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {

	constructor(public router: Router, protected authenticationService: AuthenticationService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next.handle(req).pipe(
			catchError((error) => {

				let handled: boolean = false;
				if (error instanceof HttpErrorResponse) {
					if (error.error instanceof ErrorEvent) {
						console.error("Error Event", error);
					} else {
						console.error(`Error status : ${error.status} ${error.statusText}`);
						switch (error.status) {
							case 401:      //login
								this.authenticationService.logout()
								setTimeout(() => this.router.navigateByUrl(LOGIN_URL));
								handled = true;
								break;
						}
					}
				} else {
					console.error("Other Errors", error);
				}

				if (handled) {
					return of(error);
				} else {
					return throwError(error);
				}
			})
		)
	}
}
