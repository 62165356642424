<div class="p-relative h-100 w-100 c-p"
	 [class.cursor-disabled]="disabled" [class.cursor-loading]="loading"
	 (click)="disabled || loading ? $event.stopPropagation() : {}">
	<div matRipple class="flex-center btn-transition" [class]="panelClass" [class.bkg-grey]="background"
		 [ngClass]="'size-' + size + ' color-' + color + ' type-' + type + ' shape-' + shape"
		 [class.button-disabled]="disabled" [class.button-loading]="loading" [class.focused]="focused">
		<img *ngIf="!loading" [src]="img" class="btn-transition" alt=""/>
		<mat-spinner *ngIf="loading" [diameter]="diameter" class="m-auto"></mat-spinner>
	</div>
	<ng-content></ng-content>
</div>
